import React from 'react'
import { NavBar } from '../../../components/navbar'
import { Loading, EmptyContent } from '../../../components/common/commons'
import firebase from '../../../config/firebase';
import 'firebase/auth';
import 'firebase/storage';
import { reloadWindow } from '../../../shared/utils';
import { addBranchAndModel, deleteBranchAndModelById, updateBranchAndModel } from '../../../store/collections/branchesAndModelsWorker';
import { getUsuarioCompact } from '../../../config/auth';
import { docsToItems } from '../../../store/transform.docs';

const initializeState = {
    id: null,
    value: '',
    type: 'carro'
}

class FactoriesAndModels extends React.Component {

    constructor(props) {
        super(props)
        this.state = initializeState
    }

    add = async () => {
        const data = {
            value: this.state.value,
            type: this.state.type,
            createdBy: getUsuarioCompact(),
            craetedAt: new Date()
        }
        await addBranchAndModel({
            data: data
        })
        reloadWindow()
    }

    update = async () => {
        const data = {
            value: this.state.value,
            type: this.state.type,
            updatedBy: getUsuarioCompact(),
            updatedAt: new Date()
        }
        await updateBranchAndModel({
            id: this.state.id,
            data: data
        })
        reloadWindow()
    }

    onEdit = (item) => {
        this.setState({ ...item, id: item.id })
    }

    onRemove = async (item) => {
        var confirm = window.confirm(`Deseja mesmo remover este modelo?`);
        if (confirm) {
            await deleteBranchAndModelById({ id: item.id })
            reloadWindow()
        }
    }

    render() {
        return (
            <NavBar>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        {this.state.id ? 'Atualizar veículo' : 'Cadastrar veículo'}
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className="col-lg-3">
                                <label>Fabricante e modelo</label>
                                <input type="text" onChange={(e) => this.setState({ value: e.target.value })} value={this.state.value && this.state.value} className="form-control" />
                            </div>
                            <div className="col-lg-2">
                                <label>Tipo</label>
                                <select className='form-select' value={this.state.type && this.state.type} onChange={(e) => this.setState({ type: e.target.value })}>
                                    <option value="carro">Carro</option>
                                    <option value="carro_grande">Carro Grande</option>
                                    <option value="moto">Moto</option>
                                    <option value="bicicleta">Bicicleta</option>
                                    <option value="onibus">Ônibus</option>
                                    <option value="caminhao">Caminhão</option>
                                    <option value="van_furgao">Van/Furgão</option>
                                    <option value="outros">Outros</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <div className='btn-group'>
                            <button type="button" onClick={this.state.id ? this.update : this.add} className="btn btn-primary">{this.state.id ? 'Atualizar' : 'Cadastrar'}</button>
                            {
                                this.state.id && <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                            }
                        </div>
                    </div>
                </div>
                <FactoriesAndModelsList onEdit={this.onEdit} onRemove={this.onRemove} />
            </NavBar >
        )
    }
}

class FactoriesAndModelsList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: true
        }
        this.load()
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onRemove = (item) => {
        this.props.onRemove(item)
    }

    load = async () => {
        this.setState({ loading: true });
        const results = await firebase.firestore().collection('vehicle-models').get()
        var items = docsToItems(results)
        this.setState({ items: items.sort((a, b) => a.value.localeCompare(b.value)) })
        this.setState({ loading: false });
    }

    render() {
        return (
            <>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Todos as marcas e modelos
                    </div>
                    {
                        this.state.items.length === 0 && this.state.loading === false ?
                            <EmptyContent text='Nenhuma marca e modelo cadastrada' />
                            :
                            this.state.loading === false ?
                                <div className='row'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Marca / Modelo</th>
                                                    <th scope="col">Tipo</th>
                                                    <th scope="col" width={10}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.items.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{item.value}</td>
                                                            <td>{item.type}</td>
                                                            <td width={10}>
                                                                <div className='btn-group'>
                                                                    <button type="button" onClick={() => this.onEdit(item)} className="btn btn-secondary">
                                                                        <i className="fas fa-edit" />
                                                                    </button>
                                                                    <button type="button" onClick={() => this.onRemove(item)} className="btn btn-danger">
                                                                        <i className="fas fa-trash" />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                </div>
            </>
        );
    }
}

export { FactoriesAndModels, FactoriesAndModelsList };