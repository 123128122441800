import React from 'react'
import { NavBar } from '../../../components/navbar'
import { Loading, EmptyContent } from '../../../components/common/commons'
import firebase from '../../../config/firebase';
import 'firebase/auth';
import 'firebase/storage';
import { reloadWindow, toastWarning } from '../../../shared/utils';
import { docsToItems } from '../../../store/transform.docs';
import { addDoc, updateDoc } from '../../../store/collections/collectionBaseWorker';
import { Collection } from '../../../shared/constants';

const initializeState = {
    id: null,
    group: '',
    key: '',
    value: '',
    type: ''
}

class DeveloperRemoteConfig extends React.Component {

    constructor(props) {
        super(props)
        this.state = initializeState
    }

    update = async () => {
        if (this.checkFields()) {
            toastWarning("Verifique os campos são obrigatórios")
            return
        }
        const data = {
            key: this.state.key,
            value: this._convertValue(),
            group: this.state.group
        }
        await updateDoc({ collection: Collection.REMOTE_CONFIG, id: this.state.id, data: data })
        reloadWindow()
    }

    add = async () => {
        if (this.checkFields()) {
            toastWarning("Verifique os campos são obrigatórios")
            return
        }
        const data = {
            key: this.state.key,
            value: this._convertValue(),
            group: this.state.group
        }
        await addDoc({ collection: Collection.REMOTE_CONFIG, data: data })
        reloadWindow()
    }

    reset = () => {
        reloadWindow()
    }

    _convertValue() {
        if (this.state.type === "number") {
            return this.state.value.toFloat()
        }
        return this.state.value
    }

    _convertValueToType() {
        if (typeof this.state.value === "string") {
            return "string"
        }
        if (typeof this.state.value === "number") {
            return "number"
        }
    }

    checkFields = () => {
        return (this.state.group === '' || this.state.key === '' || this.state.value === '')
    }

    onEdit = (item) => {
        this.setState({ ...item, id: item.id }, () => {
            this.setState({ type: this._convertValueToType() })
        })
    }

    render() {
        return (
            <NavBar>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        {this.state.id ? 'Atualizar configuração remota' : 'Cadastrar configuração remota'}
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className="col-lg-3">
                                <label>Grupo</label>
                                <input type="text" onChange={(e) => this.setState({ group: e.target.value })} value={this.state.group && this.state.group} className="form-control" />
                            </div>
                            <div className="col-lg-3">
                                <label>Chave</label>
                                <input type="text" onChange={(e) => this.setState({ key: e.target.value.toUpperCase() })} value={this.state.key && this.state.key} className="form-control" />
                            </div>
                            <div className="col-lg-4">
                                <label>Valor</label>
                                <input type="text" onChange={(e) => this.setState({ value: e.target.value })} value={this.state.value && this.state.value} className="form-control" />
                            </div>
                            <div className="col-lg-2">
                                <label>Tipo</label>
                                <select className='form-select' value={this.state.type && this.state.type} onChange={(e) => this.setState({ type: e.target.value })}>
                                    <option value='string'>Texto</option>
                                    <option value='number'>Número</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <div className='btn-group'>
                            {
                                this.state.id && <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                            }
                            <button type="button" onClick={this.state.id ? this.update : this.add} className="btn btn-primary">{this.state.id ? 'Atualizar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <DeveloperListRemoteConfig onEdit={this.onEdit} />
            </NavBar >
        )
    }
}

class DeveloperListRemoteConfig extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: true
        }
        this.load()
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    load = async () => {
        this.setState({ loading: true });
        const results = await firebase.firestore().collection('remote-config').get()
        var items = docsToItems(results)
        this.setState({ items: items.filter(e => e.key !== "TAGGY_SESSION" && e.key !== "MINIMUM_VERSION" && e.key !== "NEW_MINIMUM_VERSION").sort((a, b) => (a.group ?? "").localeCompare(b.group) === true) })
        this.setState({ loading: false });
    }

    render() {
        return (
            <>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Todas as configurações remotas
                    </div>
                    {
                        this.state.items.length === 0 && this.state.loading === false ?
                            <EmptyContent text='Nenhuma configuração remota cadastrada' />
                            :
                            this.state.loading === false ?
                                <div className='row'>
                                    <div className='table-responsive'>
                                        <table className="table table-striped ">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="d-none d-sm-table-cell">Grupo</th>
                                                    <th scope="col">Chave</th>
                                                    <th scope="col" className="d-none d-sm-table-cell">Valor</th>
                                                    {this.props.onEdit && <th scope="col"></th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.items.map((item, index) =>
                                                        <tr key={index}>
                                                            <td className="d-none d-sm-table-cell">{item.group}</td>
                                                            <td>{item.key}</td>
                                                            <td className="d-none d-sm-table-cell">{(("" + item.value) ?? "").substring(0, 50)}</td>
                                                            {this.props.onEdit &&
                                                                <td width='10'>
                                                                    <button type="button" onClick={() => this.onEdit(item)} className="btn btn-secondary">
                                                                        <i className="fas fa-edit" />
                                                                    </button>
                                                                </td>
                                                            }
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                </div>
            </>
        );
    }
}

export { DeveloperRemoteConfig, DeveloperListRemoteConfig };