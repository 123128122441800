
import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { PDFHeaderComponent } from './pdf-shared-report';
import moment from 'moment';
import { documentMask, isNullOrEmpty, takeIfIsNotNullOrEmpty, toCurrency } from '../utils';
import RubikMedium from '../../files/fonts/Rubik/Rubik-Medium.ttf';
import RubikRegular from '../../files/fonts/Rubik/Rubik-Regular.ttf';
import { getParameter } from '../../store/collections/parameterWorker';

Font.register({
    family: 'Rubik-Regular',
    fonts: [
        {
            src: RubikRegular
        }
    ],
})

Font.register({
    family: 'Rubik-Medium',
    fonts: [
        {
            src: RubikMedium
        }
    ],
})

const styles = StyleSheet.create({
    page: {
        paddingTop: 0,
        paddingLeft: 50,
        paddingRight: 50,
        backgroundColor: '#FFFFFF',
    },
    section: {
        fontSize: 25,
        fontWeight: "bold",
        fontFamily: 'Rubik-Medium',
        marginTop: 10
    },
    subSection: {
        fontSize: 15,
        fontWeight: "bold",
        fontFamily: 'Rubik-Medium',
        marginTop: 10
    },
    normal: {
        fontSize: 12,
        fontFamily: 'Rubik-Regular',
        marginTop: 5
    },
    small: {
        fontSize: 9
    },
    redColor: {
        color: "#FF0000"
    },
    table: {
        display: 'table',
        width: 'auto',
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row'
    },
    tableColHeader: {
        width: '50%',
        textAlign: "left",
        fontFamily: 'Rubik-Medium',
        borderTopWidth: 0,
        fontSize: 22,
        padding: 5
    },
    tableCol: {
        width: '50%',
        textAlign: "right",
        fontFamily: 'Rubik-Regular',
        borderTopWidth: 0,
        fontSize: 22,
        padding: 5
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 10
    },
    subtotal: {
        alignSelf: 'flex-end',
    },
    subtotal2: {
        fontSize: 15,
        alignSelf: 'flex-end',
    },
    left: {
        flexShrink: 1,
        flexGrow: 2,
    },
    right: {
        flexShrink: 1,
        flexGrow: 2,
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        flexGrow: 1,
    },
});

class MonthlyChargeReportDocument extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            charge: {
                ...props.charge,
                newNote: {
                    document: "306.2020.5.02.001",
                }
            },
            parameters: getParameter()
        }
    }

    render() {
        return (<>
            {
                this.state.charge &&
                <Document>
                    <Page wrap={false} style={styles.page}>
                        <PDFHeaderComponent titleSize={30} subtitleSize={20} />
                        <View>
                            <Text style={[styles.section, { marginTop: 20, textAlign: "center" }]}>RECIBO DO MENSALISTA</Text>
                            <View style={[styles.table, { marginTop: 5 }]}>
                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableColHeader, { width: "100%", textAlign: "left" }]}>Cliente</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableCol, { width: "100%", textAlign: "left" }]}>{this.state.charge.clienteNome}</Text>
                                </View>
                            </View>
                            {
                                this.state.charge.newNote ?
                                    <View style={[styles.table, { marginTop: 5 }]}>
                                        {
                                            isNullOrEmpty(this.state.charge.newNote?.document) ?
                                                <View style={[styles.table, { marginTop: 5 }]}>
                                                    <View style={styles.tableRow}>
                                                        <Text style={[styles.tableColHeader, { width: "100%", textAlign: "left" }]}>Documento</Text>
                                                    </View>
                                                    <View style={styles.tableRow}>
                                                        <Text style={[styles.tableCol, { width: "100%", textAlign: "left" }]}>Não informado</Text>
                                                    </View>
                                                </View> :
                                                <View style={[styles.table, { marginTop: 5 }]}>
                                                    <View style={styles.tableRow}>
                                                        <Text style={[styles.tableColHeader, { width: "100%", textAlign: "left" }]}>Documento</Text>
                                                    </View>
                                                    <View style={styles.tableRow}>
                                                        <Text style={[styles.tableCol, { width: "100%", textAlign: "left" }]}>{documentMask(this.state.charge.newNote?.document)}</Text>
                                                    </View>
                                                </View>
                                        }
                                        {/* <View style={styles.tableRow}>
                                            <Text style={[styles.tableCol, { width: "100%", textAlign: "center" }]}>Acompanhe a geração da sua nota fiscal online.</Text>
                                        </View> */}
                                    </View> : <>
                                        {
                                            this.state.parameters?.habilitarImprimirTituloDocumentoNaoFiscal === true && this.state.charge.newNote?.document != null &&
                                            <Text style={[styles.section, { marginTop: 20, textAlign: "center" }]}>Não é documento fiscal</Text>
                                        }
                                    </>
                            }
                            <Text style={[styles.section, { marginTop: 20, textAlign: "center" }]}>PAGAMENTO</Text>
                            <View style={[styles.table, { marginTop: 12 }]}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableColHeader}>Data do pagamento</Text>
                                    <Text style={styles.tableCol}>{moment(this.state.charge.payment?.dataPagamento.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</Text>
                                </View>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableColHeader}>Data do vencimento</Text>
                                    <Text style={styles.tableCol}>{moment(this.state.charge.payment?.dataFatura.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</Text>
                                </View>
                                {
                                    this.state.charge.payment?.descontoManual?.discount != null &&
                                    <View style={styles.tableRow}>
                                        <Text style={styles.tableColHeader}>Desconto</Text>
                                        <Text style={styles.tableCol}>{toCurrency(this.state.charge.payment?.descontoManual?.discount ?? 0.0)}</Text>
                                    </View>
                                }
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableColHeader}>Valor pago</Text>
                                    <Text style={styles.tableCol}>{toCurrency(this.state.charge.payment?.total ?? 0.0)}</Text>
                                </View>

                                {/* {
                                this.state.charge.spuns && this.state.charge.spuns.length > 0 && <>
                                    <Text style={[styles.section, { marginTop: 20, textAlign: "center" }]}>PRODUTOS CONSUMIDOS</Text>
                                    {
                                        this.state.charge.spuns.map((spun, index) => {
                                            return <View key={index} style={styles.tableRow}>
                                                <Text style={styles.tableCol}>{moment(spun.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</Text>
                                                {
                                                    spun.ticket?.produtos?.map((product, index) => {
                                                        return (
                                                            <View key={index} style={styles.tableCell}>
                                                                <Text style={styles.tableCell}>{product.quantidade}x {product.descricao} - {toCurrency(product.valor ?? 0.0)}</Text>
                                                            </View>
                                                        )
                                                    })
                                                }
                                                <Text style={styles.tableCol}>Operador: {spun.createdBy?.nome ?? "-"}</Text>
                                                <Text style={styles.tableCol}>Valor 0 </Text>
                                            </View>
                                        })
                                    }
                                </>
                            } */}
                                <Text style={[styles.section, { marginTop: 20, textAlign: "center" }]}>PLANO</Text>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableColHeader}>{this.state.charge.plan?.nome}</Text>
                                    <Text style={styles.tableCol}>Das {this.state.charge.plan?.horarioInicio} às {this.state.charge.plan?.horarioFim}</Text>
                                </View>
                                <Text style={[styles.section, { marginTop: 20, textAlign: "center" }]}>VEÍCULOS ({this.state.charge.vehicle?.length ?? 0})</Text>
                                <View style={styles.tableRow}>
                                    <Text style={[styles.tableColHeader, { width: "33.33%", textAlign: "left" }]}>Placa</Text>
                                    <Text style={[styles.tableColHeader, { width: "33.33%", textAlign: "center" }]}>Modelo</Text>
                                    <Text style={[styles.tableColHeader, { width: "33.33%", textAlign: "right" }]}>Cor</Text>
                                </View>
                                {
                                    this.state.charge.vehicle?.map((vehicle, index) => {
                                        return (
                                            <View style={styles.tableRow}>
                                                <Text style={[styles.tableCol, { width: "33.33%", textAlign: "left" }]}>{takeIfIsNotNullOrEmpty(vehicle.placa) ?? "-"}</Text>
                                                <Text style={[styles.tableCol, { width: "33.33%", textAlign: "center" }]}>{takeIfIsNotNullOrEmpty(vehicle.modelo) ?? "-"}</Text>
                                                <Text style={[styles.tableCol, { width: "33.33%", textAlign: "right" }]}>{takeIfIsNotNullOrEmpty(vehicle.cor) ?? "-"}</Text>
                                            </View>)
                                    })
                                }
                                {/* <View style={[styles.table, { marginTop: 10 }]}>
                                    <Image src={"https://quickchart.io/qr?text=https://www.google.com.br"} size={100} />
                                </View> */}
                                <View style={[styles.table, { marginTop: 10 }]}>
                                    <View style={styles.tableRow}>
                                        <Text style={[styles.tableColHeader, { width: "100%", textAlign: "left" }]}>Identificador único</Text>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <Text style={[styles.tableCol, { width: "100%", textAlign: "left" }]}>{this.state.charge.id}</Text>
                                    </View>
                                </View>
                                <View style={[styles.section, { margin: 20 }]} />
                            </View>
                        </View>
                    </Page>
                </Document >
            }
        </>
        )
    }
}

export { MonthlyChargeReportDocument }