import { getEstacionamento } from "../../config/auth"
import { Collection } from "../../shared/constants";
import { addDoc, getAllDocsByParkId, updateDoc } from "./collectionBaseWorker";

export const getAllCredentialsByParkId = async () => {
    const parkId = getEstacionamento()?.id
    const docs = await getAllDocsByParkId({ collection: Collection.ACCREDITEDS, estacionamentoId: parkId })
    return docs
}

export const addCredential = async ({ data }) => {
    await addDoc({ collection: Collection.ACCREDITEDS, data: data })
}

export const updateCredential = async ({ id, data }) => {
    await updateDoc({ collection: Collection.ACCREDITEDS, id: id, data: data })
}