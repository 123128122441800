
import { getEstacionamento, getRevendaIdByPark } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { fetchFunction } from '../../shared/function-request';
import { getClient, getClientById } from './clienteWorker';
import { addDoc } from './collectionBaseWorker';

export const addSMS = async ({
    message,
    phone,
    createBy,
    park }) => {
    const data = {
        message: message,
        phone: phone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").replaceAll("_", ""),
        revendaId: getRevendaIdByPark(),
        type: 'sms',
        createBy: createBy,
        createAt: new Date(),
        park: park,
        isDeleted: false,
    }
    const doc = await addDoc({ collection: Collection.QUEUE_MAILING, data: data })
    return doc.id;
}

export const addWhatsApp = async ({
    message,
    phone,
    createBy,
    park }) => {
    const data = {
        message: message,
        revendaId: getRevendaIdByPark(),
        phone: phone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").replaceAll("_", ""),
        type: 'whatsapp',
        createBy: createBy,
        createAt: new Date(),
        park: park,
        isDeleted: false,
    }
    const doc = await addDoc({ collection: Collection.QUEUE_MAILING, data: data })
    return doc.id;
}

export const sendWhatsApp = async ({
    message,
    phone }) => {
    const body = {
        message: message,
        phone: phone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", "").replaceAll("_", ""),
        estacionamentoId: getEstacionamento()?.id
    }
    const options = {
        method: 'POST',
        body: body
    };
    await fetchFunction("/sendWhatsApp", options)
}

export const sendTemplateWhatsApp = async ({
    template,
    data }) => {
    const body = {
        type: template,
        ...data
    }
    const options = {
        method: 'POST',
        body: body
    };
    await fetchFunction("/sendWhatsAppMessageWithTemplate", options)
}

export const sendCurrentChargeByWhatsApp = async ({ park, url, prompt }) => {
    const client = await getClientById({ id: park.clienteId })
    const representante = client.representante
    const celular = client.celular;
    const message = `Olá, aqui é da equipe SelfParking.

Sua cobrança do mês já esta disponível no link abaixo, faça o pagamento para continuar usando todas as funcionalidades do SelfParking.

${url}

Ah, fique atento você consegue pagar até dia 15

Até logo!`
    if (prompt === undefined || prompt === true) {
        const confirm = window.confirm(`Deseja mesmo enviar a mensagem:\n\n${message}\n\nVia WhatsApp para o representante: ${representante}\npara o número: ${celular}?`);
        if (confirm) {
            await sendWhatsApp({
                phone: client.celular,
                message: message
            })
            return true
        }
        return false
    } else {
        await sendWhatsApp({
            phone: client.celular,
            message: message
        })
        return true
    }
}