import React from 'react'
import { fetchFunction } from '../../../shared/function-request';
import { EmptyContent, Loading } from '../../../components/common/commons';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CreditCardInput from 'react-credit-card-input';
import { isAdministrador } from '../../../config/auth';
import Tooltip from '@mui/material/Tooltip';
import { toastError, toastWarning } from '../../../shared/utils';

class CardManagerLicence extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            cards: [],
            name: "",
            number: "",
            date: "",
            cvv: ""
        }
        this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        const option = {
            method: "GET"
        }
        this.setState({ cobrancas: [] })
        const result = await fetchFunction("/newSubscription/cards", option)
        this.setState({ isLoading: false })
        if (result.success) {
            this.setState({ cards: result.data })
        } else {
            this.setState({ message: result.message })
        }
    }

    onCardAdded = () => {
        this.props.onCardAdded()
    }

    remove = async (card) => {
        if (this.state.cards.length === 1 && !isAdministrador()) {
            toastWarning("Entre em contato com o seu representante comercial para remover o último cartão cadastrado")
        } else {
            this.setState({ isLoading: true })
            const option = {
                method: "DELETE"
            }
            const result = await fetchFunction(`/newSubscription/card/${card.id}`, option)
            if (result.success) {
                this.load()
            } else {
                this.setState({ isLoading: false })
                toastError(result.message)
            }
        }
    }

    principal = async (card) => {
        this.setState({ isLoading: true })
        const option = {
            method: "PUT"
        }
        const result = await fetchFunction(`/newSubscription/card/${card.id}`, option)
        if (result.success) {
            this.load()
        } else {
            this.setState({ isLoading: false })
            toastError(result.message)
        }
    }

    addNewCard = async () => {
        this.setState({ isLoadingAddCard: true })
        const option = {
            method: 'POST',
            body: {
                name: this.state.name,
                number: this.state.cardNumber,
                date: this.state.date,
                cvv: this.state.cvv
            }
        }
        const result = await fetchFunction(`/newSubscription/card`, option)
        this.setState({ isLoadingAddCard: false })
        if (result.success) {
            this.load()
            this.hideNewCardModal()
            this.onCardAdded()
        } else {
            toastError(result.message)
        }
    }

    showNewCardModal = () => {
        this.setState({ isOpenModalUpdateCard: true })
    }

    hideNewCardModal = () => {
        this.setState({ isOpenModalUpdateCard: false })
    }

    handleCardNumberChange = (e) => {
        this.setState({ cardNumber: e.target.value })
    }

    handleCardExpiryChange = (e) => {
        this.setState({ date: e.target.value })
    }

    handleCardCVCChange = (e) => {
        this.setState({ cvv: e.target.value })
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading && <Loading />
                }
                {
                    !this.state.isLoading && this.state.cards.length === 0 && <EmptyContent text="Nenhum cartão cadastrado até o momento." />
                }
                {
                    !this.state.isLoading && this.state.cards.length > 0 &&
                    <>
                        <h5 className='text-center'>Cartões cadastrados</h5>
                        <div className='table-responsive'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td><strong>Final</strong></td>
                                        <td><strong>Vencimento</strong></td>
                                        <td className="d-none d-sm-table-cell"><strong>Principal</strong></td>
                                        <td width={10} align='right' />
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.cards.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.lastNumbers}</td>
                                                <td>{item.validate}</td>
                                                <td className="d-none d-sm-table-cell">{item.isPrincipal ? "Sim" : "Não"}</td>
                                                <td align='right'>
                                                    <div className='btn-group'>
                                                        <Tooltip title="Remover cartão" placement="top">
                                                            <button type="button" onClick={(e) => this.remove(item)} className="btn btn-danger">
                                                                <i className="fas fa-trash" />
                                                            </button>
                                                        </Tooltip>
                                                        {
                                                            !item.isPrincipal &&
                                                            <Tooltip title="Definir como principal" placement="top">
                                                                <button button type="button" onClick={(e) => this.principal(item)} className="btn btn-success">
                                                                    <i className="fas fa-shield" />
                                                                </button>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                }
                <div className='alert alert-success'>
                    <strong>Informação </strong> Usamos o cartão principal para tentar efetuar a cobrança, caso não seja efetuado com sucesso tentaremos nos cartões secundários. <br />
                    <button button type="button" onClick={this.showNewCardModal} className="btn btn-success btn-sm mt-2">
                        <i className="fas fa-plus mx-2" />
                        Cadastrar novo cartão
                    </button>
                </div>
                <Dialog maxWidth='xs' fullWidth open={this.state.isOpenModalUpdateCard}>
                    <MuiDialogTitle className='text-center'>
                        Cadastrar cartão de cobrança
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        {
                            !this.state.isLoadingAddCard &&
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className="alert alert-secondary" role="alert">
                                        <strong>Informação </strong>Nós não salvamos nenhum dado do seu cartão na nossa base de dados, todas as informações de pagamentos são gerenciadas por <a href='https://www.safe2pay.com.br/' target="_blank"><strong>safe2pay.com.br</strong></a>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className="alert alert-warning" role="alert">
                                        <strong>Atenção </strong>Ao cadastrar um cartão de crédito, todas as cobrancas futuras que já existem como pagamento em boleto serão migradas para cartão de crédito.
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <label>Dados do cartão de crédito</label>
                                    <CreditCardInput
                                        cardNumberInputProps={{ value: this.state.cardNumber, onChange: this.handleCardNumberChange }}
                                        cardExpiryInputProps={{ value: this.state.date, onChange: this.handleCardExpiryChange }}
                                        cardCVCInputProps={{ value: this.state.cvv, onChange: this.handleCardCVCChange }}
                                        fieldClassName="form-control"
                                        customTextLabels={{
                                            invalidCardNumber: 'Número do cartão inválido',
                                            expiryError: {
                                                invalidExpiryDate: 'Data de expiração inválida',
                                                monthOutOfRange: 'Mês de expiração inválida',
                                                yearOutOfRange: 'Ano de expiração inválida',
                                                dateOutOfRange: 'A data de expiração não pode ser anterior a de hoje'
                                            },
                                            invalidCvc: 'Código do CVV Inválido',
                                            invalidZipCode: 'CEP inválido',
                                            cardNumberPlaceholder: 'Número do cartão',
                                            expiryPlaceholder: 'MM/AA',
                                            cvcPlaceholder: 'CVV',
                                            zipPlaceholder: 'CEP'
                                        }}
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <label>Nome como aparece no cartão</label>
                                    <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" required />
                                </div>
                            </div>
                        }
                        {
                            this.state.isLoadingAddCard &&
                            <Loading />
                        }
                    </MuiDialogContent>
                    {
                        !this.state.isLoadingAddCard &&
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={this.hideNewCardModal} className='btn btn-secondary'>Fechar</button>
                                <button onClick={this.addNewCard} className='btn btn-success'>Adicionar</button>
                            </div>
                        </MuiDialogActions>
                    }
                </Dialog>
            </>)
    }
}

export { CardManagerLicence };