import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItems } from '../transform.docs';

export const getFormasPagamentos = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.FORMAS_PAGAMENTOS).where(WhereField.PARKID, '==', estacionamentoId).get()
    return docsToItems(result)
}

export const getFormasPagamentosAtivas = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.FORMAS_PAGAMENTOS).where(WhereField.PARKID, '==', estacionamentoId).where("status", "==", "Ativo").get()
    return docsToItems(result)
}

export const getAllPaymentMethodByType = async ({ estacionamentoId, type }) => {
    const result = await firebase.firestore().collection(Collection.FORMAS_PAGAMENTOS).where(WhereField.PARKID, '==', estacionamentoId).where('tipo', '==', type).where("status", "==", "Ativo").get()
    return docsToItems(result)
}

export const getAllFormasPagamentos = async () => {
    const result = await firebase.firestore().collection(Collection.FORMAS_PAGAMENTOS).get()
    return docsToItems(result)
}