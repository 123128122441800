import { addPark } from "../../../store/collections/parkWorker"
import { addParameter } from "../../../store/collections/parameterWorker"
import { addLicenca } from "../../../store/collections/licencaWorker"
import { addPayment } from "../../../store/collections/paymentWorker"
import { getUsuarioCompact } from "../../../config/auth"

export const createNewParkAccount = async ({ clientId, agentName, parkName, parkInternalName }, updateStatus, error, completed) => {
    const responseValidation = validateFields({
        agentName: agentName,
        parkName: parkName,
        parkInternalName: parkInternalName
    })
    if (responseValidation.success === false) {
        error(responseValidation.message)
        return
    }
    updateStatus({ progress: 30, text: "Cadastrando estabelecimento..." })
    const estacionamentoId = await addPark({
        clienteId: clientId,
        nome: parkName,
        email: getUsuarioCompact().email,
        nomeInterno: parkInternalName,
        representante: agentName
    })

    await addParameter({
        clienteId: clientId,
        estacionamentoId: estacionamentoId,
    })

    updateStatus({ progress: 40, text: "Cadastrando licença..." })
    await addLicenca({
        clienteId: clientId,
        estacionamentoId: estacionamentoId,
    })

    updateStatus({ progress: 90, text: "Finalizando cadastro..." })
    const types = ["Débito", "Crédito", "Dinheiro", "Pix", "Cortesia"]
    for (let index = 0; index < types.length; index++) {
        const type = types[index];
        await addPayment({
            clienteId: clientId,
            estacionamentoId: estacionamentoId,
            nome: type,
            tipo: type,
            status: "Ativo"
        })
    }
    completed()
}

const validateFields = ({ agentName, parkName, parkInternalName }) => {
    if (agentName === '') {
        return { success: false, message: 'Informe o nome do representante.' }
    }
    if (parkName === '') {
        return { success: false, message: 'Informe o nome do estabelecimento.' }
    }
    if (parkInternalName === '') {
        return { success: false, message: 'Informe o nome interno do estabelecimento.' }
    }
    return { success: true }
}