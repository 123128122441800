import React from 'react'
import { NavBar } from '../../components/navbar'
import { Loading } from '../../components/common/commons'
import 'firebase/auth';
import 'firebase/storage';
import { getEstacionamento, getRevenda, getRevendaIdByPark } from '../../config/auth';
import Moment from 'moment';
import { getAllCashiersByAnalytics } from '../../store/collections/cashierWorker';
import { getAllParks, getAllParksByRessaleId } from '../../store/collections/parkWorker';
import { getAllMonthlies, getAllMonthliesMigrate } from '../../store/collections/mensalistaWorker';
import { takeIfIsNotNullOrEmpty, toCurrency } from '../../shared/utils';

class AnalyticsMensalistas extends React.Component {

    render() {
        return (
            <>
                <NavBar>
                    <AnalyticsMensalistasBody props={this.props} />
                </NavBar>
            </>)
    }
}

class AnalyticsMensalistasBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            limit: 50,
            items: [],
            isLoading: true,
            parks: []
        }
        this.load()
        this.loadParks()
    }

    loadParks = async () => {
        const parks = await getAllParks()
        this.setState({ parks: parks })
    }

    load = async () => {
        this.setState({ isLoading: true })
        const monthlies = await getAllMonthlies()
        const parks = await getAllParksByRessaleId({ id: getRevendaIdByPark() })
        const items = parks.map(e => {
            const all = monthlies.filter(m => m.estacionamentoId === e.id)
            return {
                ...e,
                quantity: all?.length ?? 0,
                total: all.map(f => f.plano.valor ?? 0).reduce((a, b) => a + b, 0)
            }
        }).sort((a, b) => b.quantity - a.quantity)
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    render() {
        return (
            <>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Mensalistas
                    </div>
                    {
                        this.state.isLoading ? <Loading /> :
                            <div className="row">
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td scope="col"><strong>Estabelecimento</strong></td>
                                                <td scope="col" align='center'><strong>Quantidade</strong></td>
                                                <td scope="col" align='center'><strong>Valor total</strong></td>
                                                <td scope="col" width={10}></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.items.map(item =>
                                                    <tr>
                                                        <td>{takeIfIsNotNullOrEmpty(item.nomeInterno) ?? item.nome}</td>
                                                        <td align='center'>{item.quantity}</td>
                                                        <td align='center'>{toCurrency(item.total)}</td>
                                                        <td width='10'>
                                                            <a href={'/revenda/cliente/' + item.clienteId} target='_blank'>
                                                                <button type="button" className="btn btn-primary">
                                                                    <i className="fas fa-eye" />
                                                                </button>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                </div>
            </>)
    }
}

export { AnalyticsMensalistas };