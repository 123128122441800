import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { deleteDoc, getAllDocsByClientId, getAllDocsByParkId, updateDoc } from './collectionBaseWorker';

export const getAllEquipmentsByClientId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.EQUIPMENTS, clienteId: clienteId })
}

export const getAllEquipmentsByParkId = async ({ estacionamentoId }) => {
    const docs = await getAllDocsByParkId({ collection: Collection.EQUIPMENTS, estacionamentoId: estacionamentoId })
    return docs
}

export const getAllEquipmentsByLPRIdentifierAndParkId = async ({ lprIdentifier, estacionamentoId }) => {
    const results = await firebase.firestore().collection(Collection.EQUIPMENTS)
        .where(WhereField.PARKID, '==', estacionamentoId)
        .where(WhereField.LPR_IDENTIFIER, '==', lprIdentifier).get()
    return docsToItems(results)
}

export const updateEquipmentById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.EQUIPMENTS, id: id, data: data })
}

export const deleteEquipment = async ({ id }) => {
    await deleteDoc({ collection: Collection.EQUIPMENTS, id: id })
}
