import { getUsuarioCompact } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { updateDoc } from './collectionBaseWorker';
import { getParkById } from './parkWorker';

export const addUserLog = async ({ estacionamentoId, module, message }) => {
    const user = getUsuarioCompact()
    const park = await getParkById({ id: estacionamentoId })
    const data = {
        estacionamentoId: park.id,
        estacionamentoNome: park.nome,
        clienteId: park.clienteId,
        revendaId: park.revendaId,
        createdBy: {
            id: user.id,
            name: user.nome,
            email: user.email
        },
        module: module,
        message: message,
    }
    await updateDoc({ collection: Collection.USER_LOGS, id: data.id, data: data })
}

export const getAllLogsByUserId = async ({ usuarioId }) => {
    const results = await firebase.firestore().collection(Collection.USER_LOGS).where('createdBy.id', '==', usuarioId).get()
    return docsToItems(results)
}