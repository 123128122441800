
export const docsToItems = (result) => {
    const items = result.docs.map(e => { return { ...e.data(), id: e.id } })
    return items//.filter(e => e.isDeleted !== true)
}

export const docsToItem = (result) => {
    const items = docsToItems(result)
    if (items.length > 0) {
        return items[0]
    }
    return null
}

export const docToItem = (result) => {
    if (result.exists) {
        const data = { ...result.data(), id: result.id }
        return data
        // if (data.isDeleted !== true) {
        //     return data
        // }
    }
    return null
}