
import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { getEstacionamento, getRevenda, getUsuarioCompact } from '../../config/auth';
import moment from 'moment';
import { documentMask } from '../utils';

const styles = StyleSheet.create({
    title: {
        fontSize: 20,
        fontFamily: 'Rubik-Medium',
        textAlign: "center",
        marginTop: 20
    },
    subtitle: {
        fontSize: 12,
        fontFamily: 'Rubik-Regular',
        textAlign: "center",
        marginTop: 2
    }
});

class PDFHeaderComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            titleSize: props.titleSize ?? 20,
            subtitleSize: props.subtitleSize ?? 12,
            park: getEstacionamento(),
            user: getUsuarioCompact()
        }
    }

    render() {
        return (
            <View>
                <Text style={[styles.title, { fontSize: this.state.titleSize }]}>{this.state.park.nome}</Text>
                <Text style={[styles.subtitle, { fontSize: this.state.subtitleSize }]}>{documentMask(this.state.park.documento)}</Text>
                <Text style={[styles.subtitle, { fontSize: this.state.subtitleSize }]}>{this.state.park.celular}</Text>
                <Text style={[styles.subtitle, { fontSize: this.state.subtitleSize }]}>{this.state.park.endereco?.logradouro}, {this.state.park.endereco?.numero}</Text>
                <Text style={[styles.subtitle, { fontSize: this.state.subtitleSize }]}>Gerado por {this.state.user.nome} {moment().format("[em] DD/MM/YYYY [às] HH:mm:ss")}</Text>
            </View>
        )
    }
}

class PDFFooterComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            revenda: getRevenda()
        }
        console.log(getRevenda());
    }

    render() {
        return (
            <View>
                <Text style={[styles.subtitle, { marginTop: 20 }]}>Relatório gerado por {this.state.revenda.nome}</Text>
                <Text style={styles.subtitle}>Conheça mais em {this.state.revenda.site.replaceAll("https://", "")}</Text>
            </View>
        )
    }
}

export { PDFHeaderComponent, PDFFooterComponent }