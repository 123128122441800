import { getEstacionamento, getRevendaIdByPark, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItem, docsToItems } from '../transform.docs';
import { addDoc, deleteDoc, updateDoc } from './collectionBaseWorker';

export const addVehicle = async ({ clientId, mensalistaId, placa, modelo, marca, cor, cartao }) => {
    const data = {
        clienteId: clientId,
        estacionamentoId: getEstacionamento()?.id,
        mensalistaId: mensalistaId,
        placa: placa.replaceAll("-", "").toUpperCase(),
        modelo: modelo,
        revendaId: getRevendaIdByPark(),
        marca: marca,
        cartao: String(cartao),
        cor: cor,
        createdAt: new Date(),
        createdBy: getUsuario().email,
        isDeleted: false,
    }
    await addDoc({ collection: Collection.VEHICLES, data: data })
}

export const updateVehicleById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.VEHICLES, id: id, data: data })
}

export const getAllVehiclesByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.VEHICLES).where(WhereField.PARKID, '==', estacionamentoId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getVehiclesByMonthlyId = async ({ monthlyId }) => {
    const result = await firebase.firestore().collection(Collection.VEHICLES).where(WhereField.MENSALISTA_ID, '==', monthlyId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getVehiclesByMonthlyIdAndClientId = async ({ monthlyId, clienteId }) => {
    const result = await firebase.firestore().collection(Collection.VEHICLES).where(WhereField.MENSALISTA_ID, '==', monthlyId).where(WhereField.CLIENTEID, '==', clienteId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getVehicleByPlateAndClientId = async ({ clientId, placa }) => {
    const result = await firebase.firestore().collection(Collection.VEHICLES).where(WhereField.CLIENTEID, '==', clientId).where('placa', '==', placa.toUpperCase().replace('-', '')).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getVehicleByPlateAndMonthlyId = async ({ placa, monthlyId }) => {
    const result = await firebase.firestore().collection(Collection.VEHICLES).where(WhereField.MENSALISTA_ID, '==', monthlyId).where('placa', '==', placa.toUpperCase().replace('-', '')).get()
    return docsToItem(result)
}

export const getVeiculos = async ({ mensalistaId }) => {
    const result = await firebase.firestore().collection(Collection.VEHICLES).where(WhereField.MENSALISTA_ID, '==', mensalistaId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getAllVeiculosByClientId = async ({ clientId }) => {
    const result = await firebase.firestore().collection(Collection.VEHICLES).where(WhereField.CLIENTEID, '==', clientId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getAllVeiculosByMonthlyId = async (mensalistaId) => {
    const result = await firebase.firestore().collection(Collection.VEHICLES).where(WhereField.MENSALISTA_ID, '==', mensalistaId).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getAllVeiculosByCreatedEmail = async ({ email }) => {
    const result = await firebase.firestore().collection(Collection.VEHICLES).where('createdBy', '==', email).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getAllVeiculosByMonthlyIdAndAccessNumber = async ({ mensalistaId, accessCard }) => {
    const result = await firebase.firestore().collection(Collection.VEHICLES).where(WhereField.MENSALISTA_ID, '==', mensalistaId).where('cartao', '==', parseInt(accessCard).toString()).get()
    return docsToItems(result).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const getAllVeiculosOfMonthliesByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.VEHICLES).where(WhereField.PARKID, '==', estacionamentoId).get()
    return docsToItems(result).filter(e => e.mensalistaId).sort((a, b) => -b.placa?.localeCompare(a.placa))
}

export const deleteVehicleById = async ({ id }) => {
    await deleteDoc({ collection: Collection.VEHICLES, id: id })
}