import firebase from '../../config/firebase';
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario } from "../../config/auth";
import { docsToItem } from '../transform.docs';
import { addDoc, getAllDocsByClientId, updateDoc } from './collectionBaseWorker';
import { Collection, WhereField } from '../../shared/constants';

export const saveNewPrismSettings = async ({ typePrismaEstacionamento, maxSequencePrismaEstacionamento, sequencePrismaEstacionamento }) => {
    const user = getUsuario()
    var data = {
        clienteId: getCliente()?.id,
        estacionamentoId: getEstacionamento()?.id,
        typePrismaEstacionamento: typePrismaEstacionamento,
        maxSequencePrismaEstacionamento: maxSequencePrismaEstacionamento.toString(),
        sequencePrismaEstacionamento: sequencePrismaEstacionamento.toString(),
        revendaId: getRevendaIdByPark(),
        alterByUser: {
            nome: user.nome,
            email: user.email,
        },
        alterAt: new Date(),
        updatedAt: new Date(),
        isDeleted: false,
    }
    const currentPrism = await getPrismSettings()
    if (currentPrism === null) {
        await addDoc({ collection: Collection.PRISM_SETTINGS, data: data })
    } else {
        await updateDoc({ collection: Collection.PRISM_SETTINGS, id: currentPrism.id, data: data })
    }
}

export const resetPrism = async () => {
    const user = getUsuario()
    var data = {
        sequenceUsing: [],
        sequencePrismaEstacionamento: "0",
        alterByUser: {
            nome: user.nome,
            email: user.email,
        },
        alterAt: new Date(),
        updatedAt: new Date(),
        isDeleted: false,
    }
    const currentPrism = await getPrismSettings()
    if (currentPrism === null) {
        await addDoc({ collection: Collection.PRISM_SETTINGS, data: data })
    } else {
        await updateDoc({ collection: Collection.PRISM_SETTINGS, id: currentPrism.id, data: data })
    }
}

export const resetPrismSettings = async ({ estacionamentoId, typePrismaEstacionamento, maxSequencePrismaEstacionamento, sequencePrismaEstacionamento }) => {
    const user = getUsuario()
    var data = {
        clienteId: user.clienteId,
        estacionamentoId: estacionamentoId,
        revendaId: getRevendaIdByPark(),
        typePrismaEstacionamento: typePrismaEstacionamento,
        maxSequencePrismaEstacionamento: maxSequencePrismaEstacionamento.toString(),
        sequenceUsing: [],
        sequencePrismaEstacionamento: sequencePrismaEstacionamento.toString(),
        alterByUser: {
            nome: user.nome,
            email: user.email,
        },
        alterAt: new Date(),
        updatedAt: new Date(),
        isDeleted: false,
    }
    const currentPrism = await getPrismSettings()
    if (currentPrism === null) {
        await addDoc({ collection: Collection.PRISM_SETTINGS, data: data })
    } else {
        await updateDoc({ collection: Collection.PRISM_SETTINGS, id: currentPrism.id, data: data })
    }
}

export const getPrismSettings = async () => {
    const parkId = getEstacionamento()?.id
    if (parkId) {
        return await getPrismSettingsByParkId({ estacionamentoId: parkId })
    }
    return null
}

export const getPrismSettingsByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.PRISM_SETTINGS).where(WhereField.PARKID, "==", estacionamentoId).get()
    return docsToItem(result)
}

export const getAllPrismSettingsByClientId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.PRISM_SETTINGS, clienteId: clienteId })
}

export const updatePrismById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.PRISM_SETTINGS, id: id, data: data })
}