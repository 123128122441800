import React from 'react'
import { EmptyContent } from '../../components/common/commons';
import 'firebase/auth';
import 'firebase/storage';
import Chart from "react-apexcharts";
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { isNullOrEmpty } from '../../shared/utils';

class ProductsDashboard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            products: [],
            hasProducts: false
        }
    }

    componentDidMount = () => {
        var allProducts = this.getAllProducts(this.props.tickets.filter(e => e.isSpun !== true))
        if (allProducts.length === 0) {
            this.setState({ hasProducts: false })
            return
        } else {
            this.setState({ hasProducts: true })
        }
        var identifiers = new Set(allProducts.map((e) => e.id))
        var productsTotal = []
        identifiers.forEach(identifier => {
            var product = allProducts.filter(e => e.id === identifier)
            var quantidade = product.map(e => e.quantidade).reduce((a, b) => a + b, 0);
            productsTotal.push({
                quantidade: quantidade,
                valor: product.map(e => e.valor).reduce((a, b) => a + b, 0).toFixed(2),
                descricao: product[0].descricao
            })
        })
        this.setState({ products: productsTotal })
        this.setState({ valorProdutos: productsTotal.map(e => parseFloat(e.valor)).reduce((a, b) => a + b, 0).toFixed(2) })
    }

    getAllProducts = (tickets) => {
        if (tickets !== undefined) {
            const allProducts = tickets.filter(e => e.paymentsLogged !== null)
                .flatMap(e => e.paymentsLogged)
                .filter(e => e)
                .filter(e => e.caixaId === this.props.caixa?.id)
                .filter(e => e.canceled !== undefined && e.canceled !== true)
                .filter(e => e.resumo !== null)
                .map(e => e.resumo)
                .flatMap(e => e.products)
                .filter(e => e !== null && e !== undefined)
            return allProducts
        }
        return [];
    }

    optionsProdutosMaisVendidos = () => {
        return {
            chart: {
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                }
            },
            yaxis: {
                title: {
                    text: undefined
                },
            },
            tooltip: {
                theme: 'dark',
                y: {
                    formatter: function (value) {
                        return 'R$' + value.toFixed(2)
                    }
                }
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: this.state.products.map(e => e.quantidade + ' - ' + e.descricao),
                labels: {
                    formatter: function (value) {
                        return value
                    }
                }
            }
        }
    }

    seriesProdutosMaisVendidos = () => {
        var valores = this.state.products.map(e => e.valor)
        return [
            {
                name: 'Valor total',
                data: valores
            }]
    }

    render() {
        return (
            <>
                {
                    this.state.products.length !== 0 &&
                    <div className='col-lg-3'>
                        <div className='row'>
                            <div className="col-12 col-sm-12 col-lg-12">
                                <div className="card mb-4">
                                    <div className="card-body">
                                        {
                                            this.state.products.length === 0 ?
                                                <div className='text-center'>
                                                    <EmptyContent text="Não foram faturados produtos ou serviços até o momento." />
                                                </div>
                                                :
                                                <>
                                                    <Chart
                                                        type="bar"
                                                        height={200}
                                                        options={this.optionsProdutosMaisVendidos()}
                                                        series={this.seriesProdutosMaisVendidos()} />
                                                    <div className="col-12 col-sm-12 col-lg-12">
                                                        <h5 className='text-secondary text-center'>Total de produtos: {<NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.valorProdutos} displayType={'text'} thousandSeparator={true} prefix={'R$'} />}</h5>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )

    }
}

class ProductsSimplyList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showSellers: props.showSellers,
            products: [],
            usersSell: []
        }
    }

    componentDidMount = () => {
        var allProducts = this.props.tickets.map(e => e.produtos).flatMap(e => e)
        this.setState({ products: allProducts })
        this.setState({ valorProdutos: allProducts.map(e => e.valor).reduce((a, b) => a + b, 0) })
    }

    showUserSell = (product) => {
        console.log(product);
        this.setState({ usersSell: product.usersSell })
        this.setState({ isOpenUsersSell: true })
    }

    render() {
        return (
            <>
                {
                    this.state.products.length === 0 ?
                        <EmptyContent text="Não existem produtos e serviços vendidos nos tickets deste caixa." /> :
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        {
                                            this.state.showSellers && <td width={10}></td>
                                        }
                                        <td width='50' align='center'><strong>Qtd.</strong></td>
                                        <td><strong>Nome</strong></td>
                                        <td align='right'><strong>V. Uni.</strong></td>
                                        <td align='right'><strong>Total</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.products.map((product, index) =>
                                            <tr key={index}>
                                                {
                                                    this.state.showSellers &&
                                                    <td>
                                                        <button type="button" className="btn btn-secondary btn-sm" onClick={(e) => this.showUserSell(product)}>
                                                            <i className="fas fa-user" />
                                                        </button>
                                                    </td>
                                                }
                                                <td align='center'>{product.quantidade}</td>
                                                <td>{product.descricao}</td>
                                                <td align='right'>
                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={product.valor} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                </td>
                                                <td align='right'>
                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={product.valor * product.quantidade} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                }
                <Dialog onClose={(e) => this.setState({ isOpenUsersSell: false })} fullWidth={true} maxWidth={"md"} open={this.state.isOpenUsersSell}>
                    <MuiDialogTitle className='text-center'>
                        Usuários que lançaram este produto
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        {
                            isNullOrEmpty(this.state.usersSell) ?
                                <EmptyContent text="Sem registro." /> :
                                <div className='col-lg-12'>
                                    <div className='row'>
                                        <div className='table-responsive my-2'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td><strong>Usuário</strong></td>
                                                        <td align='center'><strong>Quantidade</strong></td>
                                                        <td align='right'><strong>Valor</strong></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.usersSell.map((item, index) =>
                                                            <tr key={index}>
                                                                <td>{item.user.nome}</td>
                                                                <td align='center'>{item.quantity}</td>
                                                                <td align="right">
                                                                    <strong className='text-success'>
                                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isOpenUsersSell: false })} className='btn btn-secondary'>Fechar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

export { ProductsDashboard, ProductsSimplyList }