import { getRevendaIdByPark, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItems, docToItem } from '../transform.docs';
import { addDoc, updateDoc } from './collectionBaseWorker';

export const addRelationship = async ({ clientId, estacionamentoId, tipo, valor, nome, status, tolerancia, manterDesconto }) => {
    const data = {
        tipo: tipo,
        estacionamentoId: estacionamentoId,
        clienteId: clientId,
        valor: valor,
        porcentagem: valor,
        nome: nome,
        status: status,
        tolerancia: tolerancia,
        manterDesconto: manterDesconto,
        createdAt: new Date(),
        createdBy: getUsuario().email,
        revendaId: getRevendaIdByPark(),
        isDeleted: false,
    }
    const doc = await addDoc({ collection: Collection.PARTNERSHIP, data: data })
    return doc.id;
}

export const getAllRelationshipsByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.PARTNERSHIP).where(WhereField.PARKID, "==", estacionamentoId).where("status", "==", "Ativo").get()
    return docsToItems(result)
}

export const getRelationshipById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.PARTNERSHIP).doc(id).get()
    return docToItem(result)
}

export const getAllRelationshipsByClientId = async ({ clienteId }) => {
    const result = await firebase.firestore().collection(Collection.PARTNERSHIP).where(WhereField.CLIENTEID, "==", clienteId).where("status", "==", "Ativo").get()
    return docsToItems(result)
}

export const updateRelationshipById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.PARTNERSHIP, id: id, data: data })
}