import React from 'react'
import { NavBar } from '../../../components/navbar'

import firebase from '../../../config/firebase';
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario } from '../../../config/auth'
import { Loading, EmptyContent, HelpButton } from '../../../components/common/commons'
import CurrencyInput from '../../../components/currencyInput/CurrencyInput'
import { sendClickButton, sendLogByUser } from '../../../shared/analytics'
import { saveLastUpdate } from '../../../store/collections/lastUpdateWorker';
import 'firebase/auth';
import 'firebase/storage';
import NumberFormat from 'react-number-format';
import Tooltip from '@mui/material/Tooltip';
import { reloadWindow, reloadWithAlert, toastDismissLoading, toastError, toastLoading, toastSuccess, toastWarning } from '../../../shared/utils';
import Select from 'react-select';
import { AlertDanger, AlertSuccess } from '../../../shared/alert-manager';
import { addDoc, deleteDoc, updateDoc } from '../../../store/collections/collectionBaseWorker';
import { Collection } from '../../../shared/constants';

const initialState = {
    id: null,
    nome: '',
    horarioInicio: '',
    horarioFim: '',
    estacionamentoId: getEstacionamento()?.id,
    vagasDisponiveis: 10,
    valorMensal: 0.0,
    segunda: false,
    terca: false,
    quarta: false,
    quinta: false,
    sexta: false,
    sabado: false,
    domingo: false,
    online: false,
    tipo: "todos",
    status: 'Ativo',
    maximumCharges: "24",
    dueDays: [{ value: "Todos os dias", label: "Todos os dias" }],
    allDueDays: [],
    random: Math.random(),
    randomSelect: Math.random()
};

class CriarPlano extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    reset = () => {
        this.setState({ ...initialState })
    }

    componentDidMount = () => {
        var allDueDays = [{ value: "Todos os dias", label: "Todos os dias" }]
        for (var i = 1; i < 29; i++) {
            allDueDays.push({ value: i.toString(), label: i.toString() })
        }
        this.setState({ allDueDays: allDueDays })
    }

    updatePlano = async () => {
        if (this.checkFields()) {
            toastWarning("Todos os campos são obrigatórios")
            return
        }
        toastLoading("Atualizando plano...")
        const data = {
            nome: this.state.nome,
            horarioInicio: this.state.horarioInicio,
            horarioFim: this.state.horarioFim,
            vagasDisponiveis: this.state.vagasDisponiveis,
            valorMensal: this.state.valorMensal,
            valor: this.state.valor,
            segunda: this.state.segunda,
            terca: this.state.terca,
            quarta: this.state.quarta,
            tipo: this.state.tipo,
            quinta: this.state.quinta,
            sexta: this.state.sexta,
            sabado: this.state.sabado,
            domingo: this.state.domingo,
            dueDays: this.state.dueDays.map(e => e.value),
            maximumCharges: parseInt(this.state.maximumCharges),
            valorMensalOnline: this.state.valorMensalOnline,
            online: this.state.online,
            status: this.state.status,
            isDeleted: false,
        }
        await updateDoc({ collection: Collection.PLANS, id: this.state.id, data: data })
        await saveLastUpdate()
        toastDismissLoading()
        toastSuccess("Plano atualizado com sucesso!")
        sendLogByUser("Planos", `Atualizou o plano ${data.nome}`, data)
        this.reset()
    }

    addPlano = async () => {
        if (this.checkFields()) {
            toastWarning("Todos os campos são obrigatórios")
            return
        }
        toastLoading("Salvando plano...")
        const data = {
            clienteId: getCliente().id,
            estacionamentoId: this.state.estacionamentoId,
            nome: this.state.nome,
            horarioInicio: this.state.horarioInicio,
            revendaId: getRevendaIdByPark(),
            horarioFim: this.state.horarioFim,
            vagasDisponiveis: this.state.vagasDisponiveis,
            valorMensal: this.state.valorMensal,
            valor: this.state.valor,
            status: this.state.status,
            segunda: this.state.segunda,
            terca: this.state.terca,
            quarta: this.state.quarta,
            quinta: this.state.quinta,
            sexta: this.state.sexta,
            sabado: this.state.sabado,
            domingo: this.state.domingo,
            tipo: this.state.tipo,
            dueDays: this.state.dueDays.map(e => e.value),
            maximumCharges: parseInt(this.state.maximumCharges),
            valorMensalOnline: this.state.valorMensalOnline,
            online: this.state.online,
            createdAt: new Date(),
            createdBy: getUsuario().email,
            isDeleted: false,
        }
        await addDoc({ collection: Collection.PLANS, data: data })
        await saveLastUpdate()
        sendLogByUser("Planos", `Cadastrou o plano ${data.nome}`, data)
        toastDismissLoading()
        reloadWithAlert("Plano cadastrado com sucesso!")
    }

    onEdit = (item) => {
        this.setState({ id: item.id })
        this.onDuplicate(item)
    }

    onDuplicate = (item) => {
        this.setState({ nome: item.nome })
        this.setState({ horarioInicio: item.horarioInicio })
        this.setState({ horarioFim: item.horarioFim })
        this.setState({ vagasDisponiveis: item.vagasDisponiveis })
        this.setState({ valorMensal: item.valorMensal })
        this.setState({ valor: item.valorMensal ?? item.valor })
        this.setState({ valorMensalOnline: item.valorMensalOnline ?? item.valorMensal })
        this.setState({ estacionamentoId: item.estacionamentoId })
        this.setState({ status: item.status })
        this.setState({ segunda: item.segunda ?? true })
        this.setState({ terca: item.terca ?? true })
        this.setState({ quarta: item.quarta ?? true })
        this.setState({ quinta: item.quinta ?? true })
        this.setState({ sexta: item.sexta ?? true })
        this.setState({ sabado: item.sabado ?? true })
        this.setState({ domingo: item.domingo ?? true })
        this.setState({ tipo: item.tipo ?? "todos" })
        this.setState({ online: item.online ?? false })
        this.setState({ dueDays: item.dueDays?.map(e => { return { value: e, label: e } }) ?? [{ value: "Todos os dias", label: "Todos os dias" }] })
        this.setState({ maximumCharges: item.maximumCharges ?? 24 })
        this.setState({ randomSelect: Math.random() })
    }

    onRemove = async (item) => {
        var mensalistas = await firebase.firestore().collection('mensalistas').where('plano.planoId', '==', item.id).where('clienteId', '==', getCliente().id).get()
        if (!mensalistas.empty) {
            var monthlies = mensalistas.docs.map(e => e.data().nome + " - " + e.data().email + "\n")
            toastWarning("Não é possível remover um plano que esteja associado a um mensalista, antes você deve altera-los\n\nMensalistas com este este plano: \n\n" + monthlies)
            return
        }
        var confirm = window.confirm('Deseja mesmo remover o plano ' + item.nome + '?');
        if (confirm) {
            await saveLastUpdate()
            await deleteDoc({ collection: "planos", id: item.id })
            reloadWindow()
        }
    }

    onEstacionamentoSelected = (item) => {
        this.setState({ estacionamentoId: item.id })
        this.setState({ random: Math.random() })
    }

    atualizarValorMensal = (event, maskedvalue, floatvalue) => {
        this.setState({ valorMensal: floatvalue })
    }

    atualizarValorMensalOnline = (event, maskedvalue, floatvalue) => {
        this.setState({ valorMensalOnline: floatvalue })
    }

    atualizarValorDecontoVencimento = (event, maskedvalue, floatvalue) => {
        this.setState({ valorDescontoVencimento: floatvalue })
    }

    checkFields = () => {
        return (this.state.nome === '' ||
            this.state.horarioInicio === '' ||
            this.state.horarioFim === '' ||
            this.state.vagasDisponiveis === 0)
    }

    handleDueDays = (dueDays) => {
        if (dueDays.map(e => e.value).indexOf("Todos os dias") !== -1) {
            this.setState({ dueDays: [{ value: "Todos os dias", label: "Todos os dias" }] })
            this.setState({ randomSelect: Math.random() })
        } else {
            this.setState({ dueDays: dueDays })
        }
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Cadastrar plano
                            <HelpButton />
                        </div>
                        <div className="card-body">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <h5>Dados do plano</h5>
                                    <div className='row'>
                                        <div className="col-lg-6">
                                            <label>Status</label>
                                            <select value={this.state.status && this.state.status} onChange={(e) => this.setState({ status: e.target.value })} className="form-select">
                                                <option value='Ativo'>Ativo</option>
                                                <option value='Inativo'>Inativo</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Tipo</label>
                                            <div className="input-group mb-3">
                                                <select className="form-select" onChange={(e) => this.setState({ tipo: e.target.value })} value={this.state.tipo}>
                                                    <option value="todos">Todos</option>
                                                    <option value="carro">Carro</option>
                                                    <option value="carro_grande">Carro Grande</option>
                                                    <option value="moto">Moto</option>
                                                    <option value="bicicleta">Bicicleta</option>
                                                    <option value="onibus">Ônibus</option>
                                                    <option value="caminhao">Caminhão</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Nome</label>
                                            <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" />
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Valor</label>
                                            <CurrencyInput value={this.state.valorMensal && this.state.valorMensal} prefix="R$" className='form-control' onChangeEvent={this.atualizarValorMensal} />
                                        </div>
                                    </div>
                                    {
                                        this.state.id &&
                                        <div className="col-lg-12 mt-3">
                                            <AlertDanger>
                                                Caso você altere o valor ou a disponibilidade dos dias deste plano, <strong>Não</strong> irá refletir nos mensalistas associados a ele,
                                                se essa for a sua intenção você deve criar um novo plano.
                                            </AlertDanger>
                                        </div>
                                    }
                                </div>
                                <div className='col-lg-4'>
                                    <h5>Disponibilidade</h5>
                                    <div className='row'>
                                        <div className="col-lg-6">
                                            <label>Horário inicio</label>
                                            <NumberFormat className="form-control" value={this.state.horarioInicio} mask='_' onChange={(e) => this.setState({ horarioInicio: e.target.value })} format="##:##" />
                                        </div>
                                        <div className="col-lg-6">
                                            <label>Horário fim</label>
                                            <NumberFormat className="form-control" value={this.state.horarioFim} mask='_' onChange={(e) => this.setState({ horarioFim: e.target.value })} format="##:##" />
                                        </div>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" id="segunda" checked={this.state.segunda} onChange={(e) => this.setState({ segunda: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="segunda">
                                            Segunda-Feira
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" id="terca" checked={this.state.terca} onChange={(e) => this.setState({ terca: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="terca">
                                            Terça-Feira
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" id="quarta" checked={this.state.quarta} onChange={(e) => this.setState({ quarta: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="quarta">
                                            Quarta-Feira
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" id="quinta" checked={this.state.quinta} onChange={(e) => this.setState({ quinta: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="quinta">
                                            Quinta-Feira
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" id="sexta" checked={this.state.sexta} onChange={(e) => this.setState({ sexta: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="sexta">
                                            Sexta-Feira
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" id="sabado" checked={this.state.sabado} onChange={(e) => this.setState({ sabado: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="sabado">
                                            Sábado
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" id="domingo" checked={this.state.domingo} onChange={(e) => this.setState({ domingo: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="domingo">
                                            Domingo
                                        </label>
                                    </div>
                                </div>
                                <div className='col-lg-4'>
                                    <AlertSuccess tag="Novidade" message="Agora você pode oferecer aos seus clientes nosso cadastro online para que virem mensalistas.">
                                        <div className='row mt-2'>
                                            <div className="col-lg-12">
                                                <div className="form-check form-check-inline">
                                                    <input className="form-check-input" id="planoOnline" checked={this.state.online} onChange={(e) => this.setState({ online: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="planoOnline">
                                                        Plano disponível para assinatura online
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                this.state.online === true && <>
                                                    <div className="col-lg-6">
                                                        <label>Valor online</label>
                                                        <CurrencyInput value={this.state.valorMensalOnline && this.state.valorMensalOnline} prefix="R$" className='form-control' onChangeEvent={this.atualizarValorMensalOnline} />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label>Vagas disponíveis</label>
                                                        <input type="text" onChange={(e) => this.setState({ vagasDisponiveis: e.target.value })} value={this.state.vagasDisponiveis} className="form-control" />
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label>Quantidade máxima de cobranças</label>
                                                        <select className="form-select" value={this.state.maximumCharges} onChange={e => this.setState({ maximumCharges: e.target.value })} >
                                                            <option value='1'>1</option>
                                                            <option value='2'>2</option>
                                                            <option value='3'>3</option>
                                                            <option value='4'>4</option>
                                                            <option value='5'>5</option>
                                                            <option value='6'>6</option>
                                                            <option value='6'>6</option>
                                                            <option value='7'>7</option>
                                                            <option value='8'>8</option>
                                                            <option value='9'>9</option>
                                                            <option value='10'>10</option>
                                                            <option value='11'>11</option>
                                                            <option value='12'>12</option>
                                                            <option value='13'>13</option>
                                                            <option value='14'>14</option>
                                                            <option value='15'>15</option>
                                                            <option value='16'>16</option>
                                                            <option value='16'>16</option>
                                                            <option value='17'>17</option>
                                                            <option value='18'>18</option>
                                                            <option value='19'>19</option>
                                                            <option value='20'>20</option>
                                                            <option value='21'>21</option>
                                                            <option value='22'>22</option>
                                                            <option value='23'>23</option>
                                                            <option value='24'>24</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label>Dias de vencimento</label>
                                                        <Select
                                                            key={this.state.randomSelect}
                                                            isMulti
                                                            name="dueDays"
                                                            defaultValue={this.state.dueDays}
                                                            options={this.state.allDueDays}
                                                            onChange={this.handleDueDays}
                                                            className="basic-multi-select"
                                                        />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </AlertSuccess>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="btn-group">
                                {
                                    this.state.id && <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                                }
                                <button type="button" onClick={(e) => { this.state.id ? this.updatePlano() : this.addPlano() }} className="btn btn-primary">{this.state.id ? 'Atualizar' : 'Cadastrar'}</button>
                            </div>
                        </div>
                    </div>
                    <ListarPlanos key={this.state.random} estacionamentoId={this.state.estacionamentoId} onEdit={this.onEdit} onDuplicate={this.onDuplicate} onRemove={this.onRemove} />
                </NavBar>
            </>
        )
    }
}

class ListarPlanos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: '',
            list: [],
            loading: 1
        }
    }

    componentDidMount() {
        this.loading()
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onRemove = (item) => {
        this.props.onRemove(item)
    }

    onDuplicate = (item) => {
        this.props.onDuplicate(item)
    }

    loading = () => {
        this.setState({ loading: 1 })
        firebase.firestore().collection('planos').where('estacionamentoId', '==', this.props.estacionamentoId).get().then(async (results) => {
            this.setState({ loading: 0 })
            var list = [];
            results.docs.forEach(doc => {
                list.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            this.setState({ list: list.sort((a, b) => a.nome.localeCompare(b.nome)) })
        }).catch(error => {
            toastError(error.message)
            this.setState({ loading: 0 })
        })
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Todos os planos
                </div>
                {
                    this.state.list.length === 0 && this.state.loading === 0 ?
                        <EmptyContent text='Nenhum plano cadastrado' />
                        :
                        this.state.loading === 0 ?
                            <div className='row'>
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome</th>
                                                <th scope="col" className="d-none d-sm-table-cell">Tipo</th>
                                                <th scope="col" className="d-none d-sm-table-cell">Vagas</th>
                                                <th scope="col">Valor</th>
                                                <th scope="col" className="d-none d-sm-table-cell">Status</th>
                                                <th width="80"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.list.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            {item.nome}
                                                            {
                                                                item.online &&
                                                                <span className={`badge text-bg-success text-white mx-2`}>Online</span>
                                                            }
                                                        </td>
                                                        <td className="d-none d-sm-table-cell">{item.tipo ?? "todos"}</td>
                                                        <td className="d-none d-sm-table-cell">{item.vagasDisponiveis ?? "-"}</td>
                                                        <td><NumberFormat value={item.valorMensal} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></td>
                                                        <td className="d-none d-sm-table-cell">{item.status}</td>
                                                        <td>
                                                            <div className="btn-group">
                                                                {
                                                                    this.props.onDuplicate &&
                                                                    <button type="button" onClick={() => { this.onDuplicate(item) }} className="btn btn-warning text-white">
                                                                        <i className="fas fa-copy" />
                                                                    </button>
                                                                }
                                                                {
                                                                    this.props.onEdit &&
                                                                    <button type="button" onClick={() => { this.onEdit(item) }} className="btn btn-secondary">
                                                                        <i className="fas fa-edit" />
                                                                    </button>
                                                                }
                                                                {
                                                                    this.props.onRemove &&
                                                                    <button type="button" onClick={() => { this.onRemove(item) }} className="btn btn-danger">
                                                                        <i className="fas fa-trash" />
                                                                    </button>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <Loading />
                }
            </div>
        )
    }
}

export { CriarPlano, ListarPlanos };