import React from 'react'
import { NavBar } from '../../../components/navbar'

import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario } from '../../../config/auth'
import { Loading, EmptyContent, HelpButton } from '../../../components/common/commons'
import { saveLastUpdate } from '../../../store/collections/lastUpdateWorker';
import firebase from '../../../config/firebase';
import 'firebase/auth';
import 'firebase/storage';
import { getParameter } from '../../../store/collections/parameterWorker';
import Tooltip from '@mui/material/Tooltip';
import { getFormasPagamentos } from '../../../store/collections/formaPagamentoWorker';
import { AlertSecondary, AlertWarning } from '../../../shared/alert-manager';
import moment from 'moment';
import { sendLog, sendLogByUser } from '../../../shared/analytics';
import { takeIfIsNotNullOrEmpty, toastSuccess, toastWarning } from '../../../shared/utils';
import { addDoc, deleteDoc, updateDoc } from '../../../store/collections/collectionBaseWorker';
import { getAllRelationshipsByParkId } from '../../../store/collections/relationshipWorker';
import { Collection } from '../../../shared/constants';

const initializeState = {
    estacionamentoId: getEstacionamento()?.id,
    formaPagamentoId: '',
    nome: '',
    tipo: 'Dinheiro',
    status: 'Ativo',
    tagCode: "",
    seniorCode: "",
    pdvCode: "",
    onlyWeb: false,
    onlyWebDisabled: false,
    isEnabledSemPararPayment: true,
    isEnabledTaggyPayment: true,
    isEnabledConectCarPayment: true,
    isEnabledVeloePayment: true,
    onlyTotem: false,
    onlyTotemDisabled: false,
    isSeniorEnabled: false,
    isGWPDVEnabled: false,
    habilitarAssociacaoDeConvenioNaformaDePagamento: false,
    relationships: []
}

class CriarFormasPagamentos extends React.Component {

    constructor(props) {
        super(props)
        this.state = initializeState
        this.loadParameter()
        this._loadRelationships()
    }

    loadParameter = async () => {
        const parameter = await getParameter()
        this.setState({ isSeniorEnabled: parameter?.habilitarSeniorERP ?? false })
        this.setState({ isGWPDVEnabled: parameter?.habilitarGarcomWeb ?? false })
        this.setState({ isZanthusDVEnabled: parameter?.habilitarPDVZanthus ?? false })
        this.setState({ isEnabledSemPararPayment: parameter?.habilitarSemParar ?? false })
        this.setState({ isEnabledTaggyPayment: parameter?.habilitarTaggy ?? false })
        this.setState({ isEnabledConectCarPayment: parameter?.habilitarConectCar ?? false })
        this.setState({ isEnabledVeloePayment: parameter?.habilitarVeloe ?? false })
        this.setState({ isEnabledTotem: parameter?.habilitarTotem ?? false })
        this.setState({ codigoEstabelecimentoTaggy: parameter?.codigoEstabelecimentoTaggy ?? "" })
        if (parameter?.habilitarAssociacaoDeConvenioNaformaDePagamento === true) {
            this.setState({ habilitarAssociacaoDeConvenioNaformaDePagamento: true })
            this._loadRelationships()
        }
    }

    _loadRelationships = async () => {
        var list = await getAllRelationshipsByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ relationships: list });
    }

    addFormaPagamento = async () => {
        if (!this.checkFields()) {
            return
        }
        const data = {
            clienteId: getCliente().id,
            estacionamentoId: this.state.estacionamentoId,
            revendaId: getRevendaIdByPark(),
            nome: this.state.nome,
            tipo: this.state.tipo,
            status: this.state.status,
            tagCode: this.state.codigoEstabelecimentoTaggy,
            seniorCode: this.state.seniorCode,
            pdvCode: this.state.pdvCode,
            onlyWeb: this.state.onlyWeb,
            onlyTotem: this.state.onlyTotem,
            convenioAssociadoId: this.state.convenioAssociadoId,
            createdAt: new Date(),
            createdBy: getUsuario().email,
            isDeleted: false,
        }
        await addDoc({ collection: Collection.FORMAS_PAGAMENTOS, data: data })

        const message = `Adicionou a forma de pagamento ${data.nome}.`
        sendLogByUser("Forma pagamento", message, data)

        await saveLastUpdate()
        toastSuccess("Forma de pagamento cadastrada com sucesso!")
        this.reset()
        this.setState({ random: Math.random() })
    }

    reset = () => {
        this.setState({ formaPagamentoId: '' })
        this.setState({ nome: '' })
        this.setState({ tipo: 'Dinheiro' })
        this.setState({ status: 'Ativo' })
        this.setState({ onlyWeb: false })
        this.setState({ onlyTotem: false })
        this.setState({ seniorCode: "" })
        this.setState({ convenioAssociadoId: "" })
        this.setState({ pdvCode: "" })
        this.setState({ tagCode: "" })
    }

    onRemove = async (item) => {
        var confirm = window.confirm('Deseja mesmo remover esta forma de pagamento?');
        if (confirm) {
            await deleteDoc({ collection: "formasPagamentos", id: item.id })
            await saveLastUpdate()

            const message = `Removeu a forma de pagamento ${item.nome}.`
            sendLogByUser("Forma pagamento", message, item)

            this.setState({ random: Math.random() })
        }
    }

    updateFormaPagamento = async () => {
        if (!this.checkFields()) {
            return
        }
        const data = {
            estacionamentoId: this.state.estacionamentoId,
            nome: this.state.nome,
            tipo: this.state.tipo,
            status: this.state.status,
            onlyWeb: this.state.onlyWeb,
            onlyTotem: this.state.onlyTotem,
            seniorCode: this.state.seniorCode,
            pdvCode: this.state.pdvCode,
            tagCode: this.state.tagCode,
            convenioAssociadoId: this.state.convenioAssociadoId,
            alterAt: new Date(),
            alterBy: getUsuario().email
        }
        await updateDoc({ collection: Collection.FORMAS_PAGAMENTOS, id: this.state.formaPagamentoId, data: data })
        await saveLastUpdate()

        const message = `Editou uma forma de pagamento.`
        sendLogByUser("Forma pagamento", message, data)

        toastSuccess("Forma de pagamento atualizada com sucesso!")
        this.reset()
        this.setState({ random: Math.random() })
    }

    onEdit = (item) => {
        this.reset()
        this.setState({
            ...item,
            formaPagamentoId: item.id
        })
    }

    checkFields = () => {
        if (this.state.nome === "") {
            toastWarning("Informe o nome da forma de pagamento.")
            return false
        }
        return true
    }

    changeWayPayment = (e) => {
        const value = e.target.value
        this.setState({ tipo: value })
    }

    render() {
        return (
            <NavBar>
                <div className="card mt-2">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Cadastrar forma de pagamento
                        <HelpButton />
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className="col-lg-2">
                                <label>Status</label>
                                <select className='form-select' value={this.state.status && this.state.status} onChange={(e) => this.setState({ status: e.target.value })}>
                                    <option value='Ativo'>Ativo</option>
                                    <option value='Inativo'>Inativo</option>
                                </select>
                            </div>
                            <div className="col-lg-2">
                                <label>Tipo</label>
                                <select className='form-select' value={this.state.tipo && this.state.tipo} onChange={(e) => this.changeWayPayment(e)}>
                                    <option value='Dinheiro'>Dinheiro</option>
                                    <option value='Crédito'>Crédito</option>
                                    <option value='Débito'>Débito</option>
                                    <option value='Pix'>Pix</option>
                                    <option value='Cortesia'>Cortesia</option>
                                    <option value='Transferencia'>Transferência</option>
                                    <option value='Cheque'>Cheque</option>
                                    <option value='Boleto'>Boleto</option>
                                    <option value='semparar'>SemParar</option>
                                    <option value='taggy'>Taggy</option>
                                    <option value='conectcar'>ConectCar</option>
                                    <option value='veloe'>Veloe</option>
                                </select>
                            </div>
                            <div className="col-lg-3">
                                <label>Nome ou bandeira</label>
                                <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome && this.state.nome} className="form-control" required />
                            </div>
                            {
                                this.state.isSeniorEnabled &&
                                <div className="col-lg-2">
                                    <label>Código sênior</label>
                                    <input type="text" onChange={(e) => this.setState({ seniorCode: e.target.value })} value={this.state.seniorCode && this.state.seniorCode} className="form-control" required />
                                </div>
                            }
                            {
                                this.state.isGWPDVEnabled &&
                                <div className="col-lg-2">
                                    <label>Código Garçom Web</label>
                                    <input type="text" onChange={(e) => this.setState({ pdvCode: e.target.value })} value={this.state.pdvCode && this.state.pdvCode} className="form-control" required />
                                </div>
                            }
                            {
                                this.state.isZanthusDVEnabled &&
                                <div className="col-lg-2">
                                    <label>Código Zanthus</label>
                                    <input type="text" onChange={(e) => this.setState({ pdvCode: e.target.value })} value={this.state.pdvCode && this.state.pdvCode} className="form-control" required />
                                </div>
                            }
                            <div className="col-lg-3 mt-3">
                                <div className="form-check form-switch my-2">
                                    <input className="form-check-input" id="onlyWeb" onChange={(e) => this.setState({ onlyWeb: e.target.checked })} checked={this.state.onlyWeb} type="checkbox" />
                                    <label className="form-check-label" htmlFor="onlyWeb">
                                        Visível apenas no administrativo
                                    </label>
                                </div>
                                {
                                    this.state.isEnabledTotem &&
                                    <div className="form-check form-switch my-2">
                                        <input className="form-check-input" id="onlyTotem" onChange={(e) => this.setState({ onlyTotem: e.target.checked })} checked={this.state.onlyTotem} type="checkbox" />
                                        <label className="form-check-label" htmlFor="onlyTotem">
                                            Visível no totem
                                        </label>
                                    </div>
                                }
                            </div>
                            {
                                this.state.tipo === "taggy" && !this.state.isEnabledTaggyPayment &&
                                <div className="col-lg-12 mt-3">
                                    <AlertWarning message="Seu estabelecimento não esta configurado para receber pagamento via Taggy, entre em contato com o suporte para mais informações." />
                                </div>
                            }
                            {
                                this.state.tipo === "semparar" && !this.state.isEnabledSemPararPayment &&
                                <div className="col-lg-12 mt-3">
                                    <AlertWarning message="Seu estabelecimento não esta configurado para receber pagamento via Sem Parar, entre em contato com o suporte para mais informações." />
                                </div>
                            }
                            {
                                this.state.tipo === "conectcar" && !this.state.isEnabledConectCarPayment &&
                                <div className="col-lg-12 mt-3">
                                    <AlertWarning message="Seu estabelecimento não esta configurado para receber pagamento via ConectCar, entre em contato com o suporte para mais informações." />
                                </div>
                            }
                            {
                                this.state.tipo === "veloe" && !this.state.isEnabledVeloePayment &&
                                <div className="col-lg-12 mt-3">
                                    <AlertWarning message="Seu estabelecimento não esta configurado para receber pagamento via Veloe, entre em contato com o suporte para mais informações." />
                                </div>
                            }
                        </div>
                        <div className="row">
                            {
                                this.state.habilitarAssociacaoDeConvenioNaformaDePagamento &&
                                <div className='col-lg-4'>
                                    <AlertSecondary tag="Convênio" message="Ao selecionar esta forma de pagamento o convênio é associado automaticamente ao ticket.">
                                        <div className="input-group">
                                            <select className="form-select" value={this.state.convenioAssociadoId && this.state.convenioAssociadoId} onChange={(e) => this.setState({ convenioAssociadoId: e.target.value })} >
                                                <option key='1' value=''>Selecione</option>
                                                {
                                                    this.state.relationships.map(item => <option key={item.id} value={item.id}>{item.nome}</option>)
                                                }
                                            </select>
                                        </div>
                                    </AlertSecondary>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='card-footer'>
                        <div className="btn-group">
                            {
                                this.state.formaPagamentoId !== '' && <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                            }
                            <button type="button" onClick={this.state.formaPagamentoId !== '' ? this.updateFormaPagamento : this.addFormaPagamento} className="btn btn-primary">{this.state.formaPagamentoId !== '' ? 'Atualizar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <ListarFormasPagamentos key={this.state.random}
                    onEdit={this.onEdit}
                    isSeniorEnabled={this.state.isSeniorEnabled}
                    isGWPDVEnabled={this.state.isGWPDVEnabled}
                    isZanthusDVEnabled={this.state.isZanthusDVEnabled}
                    onRemove={this.onRemove}
                    estacionamentoId={this.state.estacionamentoId} />
            </NavBar >
        )
    }
}

class ListarFormasPagamentos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            items: [],
            isLoading: 1
        }
    }

    componentDidMount() {
        this.loading()
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onRemove = (item) => {
        this.props.onRemove(item)
    }

    loading = async () => {
        this.setState({ isLoading: true })
        const methods = await getFormasPagamentos({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ isLoading: false })
        this.setState({ items: methods.sort((a, b) => a.tipo.localeCompare(b.tipo)) })
    }

    render() {
        return (
            <div className="card my-3">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Todas as formas de pagamento
                </div>
                {
                    this.state.items.length === 0 && !this.state.isLoading ?
                        <EmptyContent text='Nenhuma forma de pagamento cadastrada' />
                        :
                        !this.state.isLoading ?
                            <div className='table-responsive'>
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <td scope="col"><strong>Tipo</strong></td>
                                            <td scope="col"><strong>Nome</strong></td>
                                            <td scope="col" className="d-none d-sm-table-cell"><strong>Status</strong></td>
                                            <td scope="col" className="d-none d-sm-table-cell"><strong>Visibilidade</strong></td>
                                            {
                                                this.props.isSeniorEnabled &&
                                                <td scope="col" align='center' className="d-none d-sm-table-cell"><strong>Código ERP</strong></td>
                                            }
                                            {
                                                this.props.isGWPDVEnabled || this.props.isZanthusDVEnabled &&
                                                <td scope="col" align='center' className="d-none d-sm-table-cell"><strong>Código PDV</strong></td>
                                            }
                                            <td width="10"></td>
                                        </tr>
                                        {
                                            this.state.items.map((item, index) =>
                                                <tr key={index}>
                                                    <td>
                                                        <Tooltip title={item.id} placement="top">
                                                            <span>{item.tipo}</span>
                                                        </Tooltip>
                                                    </td>
                                                    <td>{item.nome}</td>
                                                    <td className="d-none d-sm-table-cell">{item.status}</td>
                                                    <td className="d-none d-sm-table-cell">{item.onlyWeb === true ? "Administrativo" : "App e Administrativo"}</td>
                                                    {
                                                        this.props.isSeniorEnabled &&
                                                        <td className="d-none d-sm-table-cell" align='center'>{takeIfIsNotNullOrEmpty(item.seniorCode) ?? "-"}</td>
                                                    }
                                                    {
                                                        this.props.isGWPDVEnabled || this.props.isZanthusDVEnabled &&
                                                        <td className="d-none d-sm-table-cell" align='center'>{takeIfIsNotNullOrEmpty(item.pdvCode) ?? "-"}</td>
                                                    }
                                                    <td>
                                                        <div className="btn-group">
                                                            {
                                                                this.props.onEdit &&
                                                                <button type="button" onClick={() => { this.onEdit(item) }} className="btn btn-secondary">
                                                                    <i className="fas fa-edit" />
                                                                </button>
                                                            }
                                                            {
                                                                this.props.onRemove &&
                                                                <button type="button" onClick={() => { this.onRemove(item) }} className="btn btn-danger">
                                                                    <i className="fas fa-trash" />
                                                                </button>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :
                            <Loading />
                }
            </div>
        )
    }
}

export { CriarFormasPagamentos, ListarFormasPagamentos }