import { getRevenda } from '../../config/auth';
import { Collection } from '../../shared/constants';
import { addDoc, getAllDocsByClientId, getAllDocsByParkId, updateDoc } from './collectionBaseWorker';

export const addPayment = async ({ clienteId, estacionamentoId, nome, tipo, status, revendaId }) => {
    const data = {
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        revendaId: revendaId ?? getRevenda()?.id,
        nome: nome,
        tipo: tipo,
        status: status,
        isDeleted: false
    }
    const doc = await addDoc({ collection: Collection.FORMAS_PAGAMENTOS, data: data })
    return doc.id;
}

export const getAllPaymentsByClientId = async ({ clienteId }) => {
    return getAllDocsByClientId({ collection: Collection.FORMAS_PAGAMENTOS, clienteId: clienteId })
}

export const getAllPaymentsByParkId = async ({ estacionamentoId }) => {
    return getAllDocsByParkId({ collection: Collection.FORMAS_PAGAMENTOS, estacionamentoId: estacionamentoId })
}

export const updatePaymentById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.FORMAS_PAGAMENTOS, id: id, data: data })
}