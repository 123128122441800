import { getRevenda } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docToItem, docsToItems } from '../transform.docs';
import { addDoc, deleteDoc, updateDoc } from './collectionBaseWorker';

export const addProfileFilled = async ({ clienteId, clienteNome, estacionamentoId, revendaId }) => {
    const data = {
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        nome: "Administrativo",
        cliente: clienteNome,
        createClient: true,
        removeMonthly: true,
        removeMonthlyVehicle: true,
        revendaId: revendaId ?? getRevenda()?.id,
        createProfile: true,
        createUser: true,
        createPlan: true,
        createProduct: true,
        createPartnership: true,
        accessWebSite: true,
        createPaymentTable: true,
        createWayPayment: true,
        habilitarDescontoManual: true,
        changeWayPayments: true,
        changeParkOfTicket: true,
        changeTypeVehicleOfTicket: true,
        changePaymentTableOfTicket: true,
        habilitarFecharCaixa: true,
        habilitarAbrirCaixa: true,
        habilitarPagamentoTicket: true,
        habilitarPagamentoMensalista: true,
        habilitarExtornoPagamento: true,
        habilitarCancelarTicket: true,
        notaFiscalVisualizar: true,
        fecharCaixaCego: false,
        notaFiscalExportar: true,
        notaFiscalConfigurar: true,
        habilitarRemoverSangria: true,
        habilitarRemoverMovimentacaoDeCaixa: true,
        habilitarEmissaoRelatorioCaixasFechados: true,
        notaPermitirEmitirNoAplicativo: true
    }
    const doc = await addDoc({ collection: Collection.PROFILES, data: data })
    return doc.id;
}

export const getAllProfilesByClientId = async ({ clientId }) => {
    const result = await firebase.firestore().collection(Collection.PROFILES).where(WhereField.CLIENTEID, '==', clientId).get()
    return docsToItems(result)
}

export const insertProfile = async ({ data }) => {
    await addDoc({ collection: Collection.PROFILES, data: data })
}

export const updateProfile = async ({ id, data }) => {
    await updateDoc({ collection: Collection.PROFILES, id: id, data: data })
}

export const deleteProfile = async ({ id }) => {
    await deleteDoc({ collection: WhereField.CLIENTEID, id: id })
}

export const getProfileById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.PROFILES).doc(id).get()
    return docToItem(result)
}