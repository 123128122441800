import React from 'react';
import { NavBar } from '../../../components/navbar'
import { goToNewWindow, toCurrency } from '../../../shared/utils';
import { getEstacionamentosSelecionados } from '../../../config/auth';
import { getAllPaymentsInPastDays } from './viewModel';
import Tooltip from '@mui/material/Tooltip';
import { AllParksSelect, EmptyContent, Loading } from '../../../components/common/commons';
import moment from 'moment';

class PagamentosResumo extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentosIds: getEstacionamentosSelecionados(),
            date: "7",
            isLoading: true,
            cards: [],
            items: []
        }
        this.loadData()
    }

    loadData = async () => {
        if (this.state.estacionamentosIds.length > 0) {
            this.setState({ isLoading: true })
            const payment = await getAllPaymentsInPastDays({ days: this.state.date, estacionamentosIds: this.state.estacionamentosIds })
            this.setState({ payment: payment })
            this.setState({
                tpv:
                {
                    type: "success",
                    title: "Transações totais",
                    total: payment.totals.tpv.total,
                    quantity: payment.totals.tpv.quantity,
                    average: (payment.totals.tpv.total / payment.totals.tpv.quantity)
                }
            })
            this.setState({
                cards: [
                    {
                        type: "success",
                        title: "Pagos",
                        total: payment.totals.payed.total,
                        quantity: payment.totals.payed.quantity
                    }, {
                        type: "warning",
                        title: "Pendente",
                        total: payment.totals.pending.total,
                        quantity: payment.totals.pending.quantity
                    }, {
                        type: "secondary",
                        title: "Mensalistas",
                        total: payment.totals.monthlyPayed.total,
                        quantity: payment.totals.monthlyPayed.quantity
                    }, {
                        type: "secondary",
                        title: "Tickets",
                        total: payment.totals.ticketPayed.total,
                        quantity: payment.totals.ticketPayed.quantity
                    }
                ],
                items: payment.items
            })
        } else {
            this.setState({
                cards: [],
                items: []
            })
        }
        this.setState({ isLoading: false })
    }

    changeParks = (parks) => {
        this.setState({ estacionamentosIds: parks }, () => {
            this.loadData()
        })
    }

    changeParks = (parks) => {
        this.setState({ estacionamentosIds: parks }, () => {
            this.loadData()
        })
    }

    changeDate = (e) => {
        const value = e.target.value
        if (value !== "custom") {
            this.setState({ date: value }, () => {
                this.loadData()
            })
        } else {
            this.setState({ date: value })
        }
    }

    showDetail = (item) => {
        goToNewWindow(item.path)
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='col-lg-5'>
                            <h4>Resumo</h4>
                            {
                                !this.state.isLoading && this.state.items.length > 0 &&
                                <h5> <span className='text-success'>Total {toCurrency(this.state.tpv?.total)}</span> | {this.state.tpv?.quantity} Cobranças | {toCurrency(this.state.tpv?.average)} Ticket médio</h5>
                            }
                        </div>
                        <div className="col-lg-7">
                            <div className='row d-flex flex-row-reverse'>
                                <div className='col-lg-3'>
                                    <strong>Período</strong>
                                    <select className='form-select' value={this.state.date} onChange={this.changeDate}>
                                        <option value="1">Hoje</option>
                                        <option value="7">7 dias</option>
                                        <option value="10">15 dias</option>
                                        <option value="30">30 dias</option>
                                        <option value="60">60 dias</option>
                                        {/* <option value="custom">Personalizado</option> */}
                                    </select>
                                </div>
                                <div className='col-lg-5'>
                                    <strong>Estabelecimentos</strong>
                                    <AllParksSelect onChange={this.changeParks} />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        !this.state.isLoading &&
                        <div className='mt-2 row row-cols-1 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-4'>
                            {
                                this.state.cards.map(e =>
                                    <div className="col mt-2">
                                        <div className={`card bg-${e.type} rounded-3`}>
                                            <div className="card-body p-2">
                                                <div className="d-flex align-items-stretch justify-content-between rounded-4">
                                                    <div className="w-50 p-3">
                                                        <h5 className="text-white"><strong>{e.title}</strong></h5>
                                                        <h5 className="text-white">{toCurrency(e.total)}</h5>
                                                    </div>
                                                    <div className="w-50 p-3">
                                                        <p className="mb-3 text-white text-end">
                                                            {e.quantity} <i className="bi bi-arrow-up" />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    }
                    <div className="col-lg-12 card my-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Todas as cobranças
                        </div>
                        {
                            this.state.isLoading ?
                                <div className="card-body">
                                    <Loading />
                                </div> :
                                <>
                                    {
                                        this.state.items.length === 0 ?
                                            <div className="card-body">
                                                <EmptyContent text="Nenhuma cobrança disponível" />
                                            </div>
                                            :
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td width={10}><strong>Tipo</strong></td>
                                                        <td width={10}><strong>Status</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Forma de pagamento</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='center'><strong>Data</strong></td>
                                                        <td className="d-none d-sm-table-cell" align='right'><strong>Valor</strong></td>
                                                        <td width={10}></td>
                                                    </tr>
                                                </thead>
                                                <tbody className='table-responsive'>
                                                    {
                                                        this.state.items.map((item, index) =>
                                                            <tr key={index}>
                                                                <td width={10} align='center'>
                                                                    {item.status === "pending" && <span className="badge text-bg-warning text-white w-100">Pendente</span>}
                                                                    {item.status === "payed" && <span className="badge text-bg-success w-100">Pago</span>}
                                                                    {item.status === "canceled" && <span className="badge text-bg-danger w-100">Cancelado</span>}
                                                                </td>
                                                                <td width={10} align='center'>
                                                                    {item.type === "monthly-charge" && <span className="badge text-bg-info text-white w-100">Cobrança de mensalista</span>}
                                                                    {item.type === "ticket" && <span className="badge text-bg-success w-100">Pagamento de ticket</span>}
                                                                </td>
                                                                <td align='center'>
                                                                    {item.paymentMethod?.type ?? "-"}
                                                                </td>
                                                                <td className="d-none d-sm-table-cell" align='center'>{moment(item.date.seconds * 1000).format("DD/MM/YYYY HH:mm")}</td>
                                                                <td className="d-none d-sm-table-cell" align='right'>
                                                                    {item.status === "pending" && <strong className='text-warning'>{toCurrency(item.value)}</strong>}
                                                                    {item.status === "payed" && <strong className='text-success'>{toCurrency(item.value)}</strong>}
                                                                    {item.status === "canceled" && <strong className='text-danger'>{toCurrency(item.value)}</strong>}
                                                                </td>
                                                                <td>
                                                                    <div className="btn-group">
                                                                        <Tooltip role="button" title="Visualizar" placement="top">
                                                                            <button type="button" onClick={() => { this.showDetail(item) }} className="btn btn-primary">
                                                                                <i className="fas fa-eye" />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                    }
                                </>
                        }
                    </div>
                </NavBar>
            </>
        )
    }
}

export { PagamentosResumo }