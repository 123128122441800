import firebase from '../../config/firebase';
import { docToItem, docsToItem, docsToItems } from '../transform.docs';
import { allTicketsByCashiers } from './ticket'
import Moment from 'moment';

export const getAllSelles = async ({ estacionamentosIds, startDate, endDate }) => {
    if (estacionamentosIds.length === 0) {
        return []
    }
    var newStartDate = Moment(startDate).add(-1, 'days').toDate()
    var newEndDate = Moment(endDate).add(1, 'days').toDate()
    const results = await firebase.firestore().collection('caixas')
        .where('status', '==', 'fechado')
        .where('openAt', '>=', newStartDate)
        .where('openAt', '<=', newEndDate)
        .where('estacionamentoId', 'in', estacionamentosIds).get()
    const items = docsToItems(results)
    return transformResult(items, estacionamentosIds)
}

export const getAllSellesByCashierByIds = async ({ estacionamentosIds, ids }) => {
    const cashiers = []
    for (const id of ids) {
        const result = await firebase.firestore().collection('caixas').doc(id).get()
        const doc = docToItem(result)
        if (estacionamentosIds.indexOf(doc.estacionamentoId)) {
            cashiers.push(doc)
        }
    }
    return transformResult(cashiers, estacionamentosIds)
}

const transformResult = async (cashiers, estacionamentosIds) => {
    if (cashiers.length === 0) {
        return { state: 'not-found' }
    }
    cashiers = cashiers.filter(e => e.closeAt).sort((a, b) => b.closeAt.toDate() - a.closeAt.toDate())
    var tickets = await allTicketsByCashiers({
        estacionamentosIds: estacionamentosIds,
        caixasIds: cashiers.map(e => e.id)
    })
    if (tickets.length === 0) {
        return { state: 'not-found' }
    }
    var allTickets = []
    var allProducts = []
    cashiers.forEach(cashier => {
        const t = allTicketsFromCashiers(tickets, cashier)
        allTickets.push(t)
        allProducts.push(allProductsFromTickets(t, cashier))
    })
    const allTicketsWithSpun = allTickets.flatMap(e => e).filter(e => e.isSpun === true)
    allTickets = allTickets.flatMap(e => e).filter(e => e.isSpun !== true).filter(e => (e.produtos ?? []).length > 0)
    allProducts = allProducts.flatMap(e => e).filter(e => e)
    if (allProducts.length > 0) {
        var valor = allProducts.map(e => e.valor * e.quantidade).reduce((a, b) => a + b)
        var valorCusto = 0.0
        var allCustos = allProducts.filter(e => e.valorCusto !== undefined).map(e => e.valorCusto * e.quantidade)
        if (allCustos.length !== 0) {
            valorCusto = allCustos.reduce((a, b) => a + b)
        }
        var lucroTotal = valor - valorCusto;
        var ticketMedio = valor / allTickets.length
        var porcentagem = ((valor - valorCusto) / valor) * 100
        const vDoPeriodo = vendasDoPeriodo(cashiers, allTickets)
        const fPagamentos = formasPagamentos(allTickets)
        const pMaisVendidos = produtosMaisVendidos(allProducts)
        const pVendasPorUsuarios = vendasPorUsuarios(allProducts)
        const result = {
            valor: valor,
            valorCusto: valorCusto,
            lucroTotal: lucroTotal,
            ticketMedio: ticketMedio,
            porcentagem: porcentagem,
            vendas: allTickets.length,
            items: allProducts.map(e => e.quantidade ?? 0).reduce((a, b) => a + b),
            caixas: cashiers,
            allTicketsWithSpun: allTicketsWithSpun,
            vendasDoPeriodo: vDoPeriodo,
            formasPagamentos: fPagamentos,
            vendasPorUsuarios: pVendasPorUsuarios,
            optionsVendasDoPeriodo: optionsVendasDoPeriodo(vDoPeriodo),
            seriesVendasDoPeriodo: seriesVendasDoPeriodo(vDoPeriodo),

            optionsProdutosMaisVendidos: optionsProdutosMaisVendidos(pMaisVendidos),
            seriesProdutosMaisVendidos: seriesProdutosMaisVendidos(pMaisVendidos),

            optionsFormasPagamento: optionsFormasPagamento(fPagamentos),
            seriesFormasPagamento: seriesFormasPagamento(fPagamentos),

            optionsVendasPorUsuarios: optionsVendasPorUsuarios(pVendasPorUsuarios),
            seriesVendasPorUsuarios: seriesVendasPorUsuarios(pVendasPorUsuarios),

            produtosMaisVendidos: pMaisVendidos,
            state: 'found'
        }
        return result
    } else {
        return { state: 'not-found' }
    }
}

const allTicketsFromCashiers = (tickets, caixa) => {
    if (tickets === undefined) {
        return []
    }
    return tickets.filter(e => e.paymentsLogged !== null && e.paymentsLogged.map(p => p.caixaId).includes(caixa.id))
}

const allProductsFromTickets = (tickets, caixa) => {
    if (tickets === undefined) {
        return []
    }
    return tickets.filter(e => e.isSpun !== true).flatMap(e => e.paymentsLogged).filter(e => e !== null && (e.canceled === undefined || e.canceled === false) && e.caixaId === caixa.id).map(e => e.resumo.products).flatMap(e => e)
}

const vendasDoPeriodo = (cashiers, tickets) => {
    var vendasDoPeriodo = []
    var datesOfCashiers = new Set(cashiers.map(e => Moment(e.closeAt.toDate()).format("DD/MM/YYYY")))
    datesOfCashiers.forEach(date => {
        var products = tickets.map(e => e.paymentsLogged).flatMap(e => e).filter(e => Moment(e.createAt.toDate()).format("DD/MM/YYYY") === date && (e.canceled === undefined || e.canceled === false)).map(e => e.resumo.products).flatMap(e => e).filter(e => e)
        if (products.length > 0) {
            var valor = products.map(e => (e.valor ?? 0) * e.quantidade).reduce((a, b) => a + b)
            vendasDoPeriodo.push({
                date: date,
                dateObject: Moment(date, 'DD/MM/YYYY').toDate(),
                valorVendido: valor
            })
        }
    })
    return vendasDoPeriodo.sort((a, b) => a.dateObject - b.dateObject)
}

const formasPagamentos = (tickets) => {
    const ticketFiltered = tickets.map(e => e.paymentsLogged).flatMap(e => e).filter(e => e.formaPagamento?.id !== undefined)
    var formasPagamentosGrouped = []
    var identifiers = new Set(ticketFiltered.map(e => e.formaPagamento.id))
    identifiers.forEach(identifier => {
        var ticketsPorFormaDePagamento = ticketFiltered.filter(e => e.formaPagamento?.id === identifier)
        const valorProdutos = ticketFiltered.filter(e => e.formaPagamento.id === identifier).flatMap(e => e).map(e => e.resumo.valorProdutos).reduce((a, b) => a + b)
        const valorServicos = ticketFiltered.filter(e => e.formaPagamento.id === identifier).flatMap(e => e).map(e => e.resumo.valorServicos).reduce((a, b) => a + b)
        formasPagamentosGrouped.push({
            nome: ticketsPorFormaDePagamento[0].formaPagamento.nome,
            total: (valorProdutos + valorServicos),
            quantidade: ticketFiltered.filter(e => e.formaPagamento.id === identifier).length
        })
    });
    return formasPagamentosGrouped.sort((a, b) => b.quantidade - a.quantidade)
}

const optionsVendasDoPeriodo = (vendasDoPeriodo) => {
    return {
        chart: {
            type: 'bar',
            animations: {
                enabled: true,
            }
        },
        fill: {
            colors: ['#6c757d']
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: 'center',
                },
            }
        },
        dataLabels: {
            enabled: false,
            formatter: function (value) {
                return 'R$' + value.toFixed(2);
            },
            style: {
                fontSize: '18px',
                colors: ["#fff"]
            }
        },
        tooltip: {
            enabled: true,
            formatter: function (value, opts) {
                // const totalInWeek = series[opts.seriesIndex].data[opts.dataPointIndex].total
                // if (parseInt(value) > 0) {
                //     const percent = `${(parseInt(value) / totalInWeek * 100).toFixed(2)}%`
                //     return `${value} - ${percent}`
                // }
                return value
            },
        },
        xaxis: {
            categories: vendasDoPeriodo.map(e => e.date),
            position: 'top'
        },
        yaxis: {
            labels: {
                show: true,
                formatter: function (value) {
                    return 'R$' + value.toFixed(2);
                }
            }
        }
    }
}

const seriesVendasDoPeriodo = (vendasDoPeriodo) => {
    return [{
        name: 'Inflation',
        data: vendasDoPeriodo.map(e => e.valorVendido)
    }]
}

const baseOptionsPie = () => {
    return {
        chart: {
            width: '100%',
            type: 'pie',
        },
        legend: {
            show: true,
            position: 'bottom'
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return 'R$' + value
                }
            },
        },
    }
}

const optionsProdutosMaisVendidos = (produtosVendidos) => {
    return {
        chart: {
            type: 'bar',
            height: '100%'
        },
        plotOptions: {
            bar: {
                horizontal: true,
            }
        },
        yaxis: {
            title: {
                text: undefined
            },
        },
        tooltip: {
            theme: 'dark',
            y: {
                formatter: function (value) {
                    return 'R$' + value.toFixed(2)
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        xaxis: {
            categories: produtosVendidos.produtosVendidos.map(e => e.quantidade + ' - ' + e.nome),
            labels: {
                formatter: function (value) {
                    return 'R$' + value.toFixed(2)
                }
            }
        }
    }
}

const seriesProdutosMaisVendidos = (products) => {
    var valores = products.produtosVendidos.map(e => e.valor)
    var valorCusto = products.produtosVendidos.map(e => e.valorCusto)
    return [
        {
            name: 'Valor de custo total',
            data: valorCusto
        },
        {
            name: 'Valor de venda total',
            data: valores
        }]
}

const optionsFormasPagamento = (formasPagamento) => {
    return {
        ...baseOptionsPie(),
        labels: formasPagamento.map(e => e.nome),
    }
}

const optionsVendasPorUsuarios = (users) => {
    return {
        ...baseOptionsPie(),
        labels: users.map(e => e.user.nome),
    }
}

const seriesFormasPagamento = (formasPagamento) => {
    return formasPagamento.map(e => parseFloat(e.total.toFixed(2)))
}

const seriesVendasPorUsuarios = (users) => {
    return users.map(e => parseFloat(e.valor.toFixed(2)))
}

const produtosMaisVendidos = (products) => {
    var productGrouped = []
    var identifiers = new Set(products.map(e => e.id))
    identifiers.forEach(identifier => {
        var product = products.filter(e => e.id === identifier)
        var quantidade = product.map(e => e.quantidade).reduce((a, b) => a + b)
        const valor = product[0].valor * quantidade
        const valorCusto = product[0].valorCusto === undefined ? null : (product[0].valorCusto * quantidade)

        productGrouped.push({
            quantidade: quantidade,
            nome: product[0].descricao,
            valor: valor,
            valorCusto: valorCusto,
            margemLucro: valor - valorCusto,
            category: product[0].category,
            subCategory: product[0].subCategory,
        })
    });
    return {
        produtosVendidos: productGrouped.sort((a, b) => b.quantidade > a.quantidade),
        produtosSemValorDeCusto: productGrouped.filter(e => e.valorCusto === null).length > 0
    }
}

const vendasPorUsuarios = (products) => {
    var usersGrouped = []
    var users = products.flatMap(e => e.usersSell).filter(e => e) ?? []
    if (users.length !== 0) {
        var identifiers = [...(new Set(users.map(e => e.user?.id ?? "")))]
        identifiers.filter(e => e !== "").forEach(identifier => {
            const userProducts = users.filter(e => e.user.id === identifier)
            const quantidade = userProducts.map(e => e.quantity).reduce((a, b) => a + b)
            const valor = userProducts.map(e => e.total ?? 0).reduce((a, b) => a + b)
            usersGrouped.push({
                user: userProducts[0].user,
                quantidade: quantidade,
                valor: valor
            })
        });
        return usersGrouped.sort((a, b) => b.quantidade > a.quantidade)
    }
    return []
}