import { ProductType, TicketStatus } from '../constants'
const { minutesBetween, minutesBetweenNow } = require('./utils.parking.calculate')

export const ticketAllPayments = ({ ticket }) => {
    return ticket.paymentsLogged?.filter(e => e.canceled == false).sort((a, b) => b.createAt.toDate() - a.createAt.toDate()) ?? []
}

export const ticketLastPayment = ({ ticket }) => {
    const payments = ticketAllPayments({ ticket: ticket })
    if (payments.length > 0) {
        return payments[payments.length - 1]
    }
    return null
}

export const ticketFirstPayment = ({ ticket }) => {
    const payments = ticketAllPayments({ ticket: ticket })
    if (payments.length > 0) {
        return ticketAllPayments({ ticket: ticket })[0]
    }
    return null
}

export const ticketTotalPermanence = ({ ticket, startDate, endDate }) => {
    if (ticket.status == TicketStatus.PAYED_AND_DELIVERED) {
        const lastPayment = ticketLastPayment({ ticket: ticket })
        return minutesBetween({ startDate: startDate, endDate: lastPayment?.createAt ?? new Date() })
    }
    if (endDate != null) {
        return minutesBetween({ startDate: startDate, endDate: endDate })
    }
    return minutesBetweenNow({ endDate: endDate })
}

export const ticketTotalProducts = ({ ticket }) => {
    return (ticket.products ?? []).filter(e => e.type == ProductType.SELL).map(e => parseFloat(e.value ?? 0) * parseInt(e.quantity ?? 0)).reduce((a, b) => a + b, 0)
}

export const ticketTotalServices = ({ ticket }) => {
    return (ticket.products ?? []).filter(e => e.type == ProductType.SERVICES).map(e => parseFloat(e.value ?? 0) * parseInt(e.quantity ?? 0)).reduce((a, b) => a + b, 0)
}

export const totalProductsAndServices = ({ ticket }) => {
    return ticketTotalProducts({ ticket: ticket }) + ticketTotalServices({ ticket: ticket })
}