import React from 'react'
import 'firebase/storage'
import NumberFormat from 'react-number-format';
import '../dashboard/index.css'
import { secondsToDescription, toCurrency } from '../../shared/utils';

class TotalsValorCobrado extends React.Component {

    constructor(props) {
        super(props)
        const payments = props.tickets.filter(e => e.status !== "Cancelado").flatMap(e => e.paymentsLogged ?? [])?.filter(e => e.canceled !== true)
        const discount = payments.filter(e => e !== null && (e.resumo?.descontoManual || e.resumo?.convenio)).map(e => e.resumo?.descontoManual?.discount ?? 0).reduce((a, b) => a + b, 0)
        const relationships = payments.filter(e => e !== null && (e.resumo?.descontoManual || e.resumo?.convenio)).map(e => e.resumo?.valorDesconto ?? 0).reduce((a, b) => a + b, 0)
        const total = payments.map(e => (e.resumo?.valorCobrado ?? 0)).reduce((a, b) => a + b, 0)
        this.state = {
            totalTickets: total,
            ticketMedio: total / props.tickets.length,
            length: props.tickets.length
        }
    }

    render() {
        return (
            <div className='col-lg-4'>
                <div className="card text-light bg-success mb-3">
                    <div className="card-body">
                        <h5 className="card-title text-center text-white">Valor cobrado</h5>
                        <h3 className="card-text text-center text-white">
                            {toCurrency(this.state.totalTickets)}
                        </h3>
                        <h5 className='monthly-card monthly-card-green'>
                            Ticket médio: {toCurrency(this.state.ticketMedio)}
                        </h5>
                        <div className="text-center">
                            <small className="card-text text-white">{this.state.length} Tickets</small>
                        </div>
                    </div>
                </div>
            </div>)
    }
}

class TotalsTickets extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            length: props.tickets.length,
            totalMensalistas: props.tickets.filter(e => e.monthly !== null).length
        }
    }

    render() {
        return (
            <div className='col-lg-4'>
                <div className="card text-light bg-primary mb-3">
                    <div className="card-body">
                        <h5 className="card-title text-center text-white">Tickets</h5>
                        <h3 className="card-text text-center text-white">
                            {this.state.length}
                        </h3>
                        {
                            this.state.totalMensalistas > 1 &&
                            <h5 className='monthly-card monthly-card-blue'>
                                {this.state.totalMensalistas} mensalistas
                            </h5>
                        }
                        <div className="text-center">
                            <small className="card-text text-white">Todos os tickets</small>
                        </div>
                    </div>
                </div>
            </div>)
    }
}

class TotalsConvenios extends React.Component {

    constructor(props) {
        super(props)
        const payments = props.tickets.filter(e => e.status !== "Cancelado").flatMap(e => e.paymentsLogged).filter(e => e).filter(e => (e.resumo?.descontoManual || e.resumo?.convenio))
        const discount = payments.map(e => e.resumo?.descontoManual?.discount ?? 0).reduce((a, b) => a + b, 0)
        const relationships = payments.map(e => e.resumo?.valorDesconto ?? 0).reduce((a, b) => a + b, 0)
        this.state = {
            length: payments.length,
            desconto: -(discount + relationships)
        }
    }

    render() {
        return (
            <>
                {
                    this.state.length > 0 &&
                    <div className='col-lg-4'>
                        <div div className="card text-light bg-danger mb-3">
                            <div className="card-body">
                                <h5 className="card-title text-center text-white">Convênios ou descontos</h5>
                                <h3 className="card-text text-center text-white">
                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.desconto} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                </h3>
                                {
                                    this.state.length > 1 &&
                                    <h5 className='monthly-card monthly-card-red'>
                                        {this.state.length} usados
                                    </h5>
                                }
                                <div className="text-center">
                                    <small className="card-text text-white">Convênios ou descontos</small>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

class TotalsCredenciados extends React.Component {

    constructor(props) {
        super(props)
        const payments = props.tickets.filter(e => e.accredited)
        this.state = {
            length: payments.length
        }
    }

    render() {
        return (
            <>
                {
                    this.state.length > 0 &&
                    <div className='col-lg-4'>
                        <div div className="card text-light bg-info mb-3">
                            <div className="card-body">
                                <h5 className="card-title text-center text-white">Credenciados</h5>
                                <h3 className="card-text text-center text-white">
                                    {this.state.length}
                                </h3>
                                <div className="text-center">
                                    <small className="card-text text-white">Total de credenciados</small>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>)
    }
}
class TotalsTicketsCanceled extends React.Component {

    constructor(props) {
        super(props)
        const ticketsCanceled = props.tickets.filter(e => e.status === "Cancelado")
        this.state = {
            length: ticketsCanceled.length
        }
    }

    render() {
        return (
            <>
                {
                    this.state.length > 0 &&
                    <div className='col-lg-4'>
                        <div div className="card text-light bg-danger mb-3">
                            <div className="card-body">
                                <h5 className="card-title text-center text-white">Cancelados</h5>
                                <h3 className="card-text text-center text-white">
                                    {this.state.length}
                                </h3>
                                <div className="text-center">
                                    <small className="card-text text-white">Total de tickets cancelados</small>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>)
    }
}

class TotalsPermanenciaMedia extends React.Component {

    constructor(props) {
        super(props)
        const ticketsCloseds = props.tickets.filter(e => e.closedAt)
        const seconds = this.sumOfsecondsPark(ticketsCloseds)
        const mediamMinutes = this.generateMedianPark(seconds, ticketsCloseds)
        const totalPermanence = this.generatePermanencePark(seconds)
        this.state = {
            mediamMinutes: secondsToDescription(mediamMinutes),
            totalPermanence: secondsToDescription(totalPermanence)
        }
    }

    sumOfsecondsPark = (tickets) => {
        if (tickets.length === 0) {
            return 0
        }
        const allSeconds = tickets.filter(e => e.closedAt).map(e => ((e.closedAt?.seconds ?? 0) - (e.createAt?.seconds ?? 0)))
        return allSeconds.reduce((a, b) => a + b, 0)
    }

    generateMedianPark = (seconds, tickets) => {
        const mediam = (seconds / tickets.length)
        return mediam
    }

    generatePermanencePark = (seconds) => {
        return seconds
    }

    render() {
        return (
            <div className='col-lg-4'>
                <div className="card text-light bg-info mb-3">
                    <div className="card-body">
                        <h5 className="card-title text-center text-white">Permanência média</h5>
                        <h3 className="card-text text-center text-white">
                            {this.state.mediamMinutes}
                        </h3>
                        <div className="text-center">
                            <small className="card-text text-white">Permanência total {this.state.totalPermanence}</small>
                        </div>
                    </div>
                </div>
            </div>)
    }
}


export { TotalsValorCobrado, TotalsTickets, TotalsConvenios, TotalsPermanenciaMedia, TotalsCredenciados, TotalsTicketsCanceled }