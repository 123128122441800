import { Collection } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { addDoc, getCollectionBy } from './collectionBaseWorker';

export const addQueue = async ({ data }) => {
    data.status = "in-queue"
    data.createAt = new Date()
    await addDoc({ collection: Collection.CALLBACK_QUEUE, data: data })
}

export const getAllQueuesByDate = async ({ days }) => {
    const date = new Date()
    date.setDate(date.getDate() - days)
    const docs = await getCollectionBy({ collection: Collection.CALLBACK_QUEUE }).where("createAt", ">=", date).orderBy("createAt", "desc").get()
    return docsToItems(docs)
}