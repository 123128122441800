import { getRevenda } from '../../config/auth';
import firebase from '../../config/firebase';
import Moment from 'moment';
import { docsToItem, docsToItems } from '../transform.docs';
import { addDoc, getAllDocsByCollection, getAllDocsByClientId, updateDoc } from './collectionBaseWorker';
import { Collection } from '../../shared/constants';

export const addLicenca = async ({ clienteId, estacionamentoId, revendaId }) => {
    const revenda = getRevenda()
    const data = {
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        revendaId: revendaId ?? revenda.id,
        vencimento: Moment().add(revenda?.diasDeTeste ?? 7, 'days').toDate()
    }
    const doc = await addDoc({ collection: Collection.LICENCE, data: data })
    return doc.id
}

export const getLicencas = async ({ clientId }) => {
    const docs = await getAllDocsByClientId({ collection: Collection.LICENCE, clienteId: clientId })
    return docs
}

export const getAllLicencasByRevendaId = async ({ revendaId }) => {
    const result = await firebase.firestore().collection('licencas').where('revendaId', '==', revendaId).get()
    return docsToItems(result)
}

export const getAllLicencas = async () => {
    const docs = await getAllDocsByCollection({ collection: Collection.LICENCE })
    return docs
}

export const getAllLicencasByClienteId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.LICENCE, clienteId: clienteId })
}

export const getLicencaByParkId = async ({ parkId }) => {
    const result = await firebase.firestore().collection('licencas').where('estacionamentoId', '==', parkId).get()
    return docsToItem(result)
}

export const getLastBillPayment = async ({ estacionamentoId }) => {
    try {
        const result = await firebase.firestore().collection('licencas-historico').where('estacionamentId', '==', estacionamentoId).get()
        if (result.empty) {
            return { success: true, data: result[0] }
        }
        var listHistorico = docsToItems(result)
        const list = listHistorico.sort((a, b) => b.buyAt.toDate() - a.buyAt.toDate()).filter(item => item.transaction?.idTransaction !== undefined || item.motivo !== undefined)
        if (list.length > 0) {
            return { success: true, data: list[0] }
        }
        return { success: false, data: null }
    } catch (err) {
        return { success: false, message: "Última cobrança não encontrada" }
    }
}

export const updateLicenceRevendaId = async ({ id, revendaId }) => {
    await updateDoc({ collection: Collection.LICENCE, id: id, data: { revendaId: revendaId } })
}

export const updateLicenceById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.LICENCE, id: id, data: data })
}