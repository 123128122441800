export const partnershipTransform = (dataIn) => {
    if (!dataIn) {
        return undefined
    }
    const dataOut = {
        id: dataIn.id,
        name: dataIn.nome,
        parkId: dataIn.estacionamentoId,
        clientId: dataIn.clienteId,
        tolerance: dataIn.tolerancia,
        keepDiscount: dataIn.manterDesconto === "Sim",
        type: dataIn.tipo === "Valor" ? "price" : dataIn.tipo === "Porcentagem" ? "percentage" : "progressive",
        progressives: dataIn.progressives,
        price: dataIn.valor,
        percentage: dataIn.porcentagem
    }
    return dataOut
}

export const partnershipesTransform = (datasIn) => {
    const datasOut = datasIn.map(e => {
        return module.exports.partnershipTransform(e)
    })
    return datasOut
}