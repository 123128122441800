import Moment from 'moment/moment';
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';

export const allTicketsByCashiers = async ({ caixasIds }) => {
    var tickets = [];
    try {
        const chunkSize = 10;
        for (let i = 0; i < caixasIds.length; i += chunkSize) {
            const cashiersSliced = caixasIds.slice(i, i + chunkSize);
            if (cashiersSliced.length > 0) {
                const results = await firebase.firestore().collection("ticket")
                    .where('caixasIds', 'array-contains-any', cashiersSliced)
                    .get()
                const items = docsToItems(results)
                tickets.push(items)
            }
        }
    } catch (error) {
    }
    const allTickets = tickets.flatMap(e => e)
    const ids = Array.from(new Set(allTickets.map((item) => item.id)))
    var newTickets = []
    ids.forEach(e => {
        const ticket = allTickets.filter(f => f.id === e)[0]
        newTickets.push(ticket)
        return newTickets
    })
    return newTickets
}

export const allTicketsByDates = async ({ estacionamentoId, startDate, endDate }) => {
    var newStartDate = Moment(startDate.toDate()).toDate()
    var newEndDate = Moment(endDate.toDate()).toDate()
    try {
        const results = await firebase.firestore().collection("ticket")
            .where("estacionamentoId", "==", estacionamentoId)
            .where('createAt', '>=', newStartDate)
            .where('createAt', '<=', newEndDate)
            .get()
        return docsToItems(results)
    } catch (error) {
    }
    return []
}