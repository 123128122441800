
import React from 'react'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { PDFFooterComponent, PDFHeaderComponent } from './pdf-shared-report';
import moment from 'moment';
import { isNullOrEmpty, takeIfIsNotNullOrEmpty, toCurrency } from '../utils';
import RubikMedium from '../../files/fonts/Rubik/Rubik-Medium.ttf';
import RubikRegular from '../../files/fonts/Rubik/Rubik-Regular.ttf';

Font.register({
    family: 'Rubik-Regular',
    fonts: [
        {
            src: RubikRegular
        }
    ],
})

Font.register({
    family: 'Rubik-Medium',
    fonts: [
        {
            src: RubikMedium
        }
    ],
})

const styles = StyleSheet.create({
    page: {
        padding: 10,
        backgroundColor: '#FFFFFF',
    },
    section: {
        fontSize: 20,
        fontWeight: "bold",
        fontFamily: 'Rubik-Medium',
        marginTop: 10
    },
    subSection: {
        fontSize: 15,
        fontWeight: "bold",
        fontFamily: 'Rubik-Medium',
        marginTop: 10
    },
    normal: {
        fontSize: 12,
        fontFamily: 'Rubik-Regular',
        marginTop: 5
    },
    small: {
        fontSize: 9
    },
    redColor: {
        color: "#FF0000"
    },
    table: {
        display: 'table',
        width: 'auto',
        borderColor: "#ececec",
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        margin: 'auto',
        flexDirection: 'row'
    },
    tableColHeader: {
        width: '25%',
        borderColor: "#ececec",
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        textAlign: "center",
        fontFamily: 'Rubik-Medium',
        borderTopWidth: 0,
        fontSize: 12,
        padding: 5
    },
    tableCol: {
        width: '25%',
        borderColor: "#ececec",
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        textAlign: "center",
        fontFamily: 'Rubik-Regular',
        borderTopWidth: 0,
        fontSize: 12,
        padding: 5
    },
    tableColLeft: {
        width: '25%',
        borderColor: "#ececec",
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 1,
        textAlign: "center",
        fontFamily: 'Rubik-Regular',
        borderTopWidth: 0,
        fontSize: 12,
        padding: 5
    },
    tableCell: {
        margin: 'auto',
        marginTop: 5,
        fontSize: 10
    },
    subtotal: {
        alignSelf: 'flex-end',
    },
    subtotal2: {
        fontSize: 15,
        alignSelf: 'flex-end',
    },
    left: {
        flexShrink: 1,
        flexGrow: 2,
    },
    right: {
        flexShrink: 1,
        flexGrow: 2,
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        flexGrow: 1,
    },
});

class MonthlyReportDocument extends React.Component {
    constructor(props) {
        super(props)
        const rows = props.mensalistas.map(e => {
            return {
                id: e.id,
                status: e.plano.dataFatura ? moment(e.plano.dataFatura.seconds * 1000).isAfter(new Date()) ? "Em dia" : "Pendente" : "Sem cobranças",
                name: e.nome,
                cellphone: e.celular ?? "-",
                plan: e.plano?.nome ?? "-",
                price: e.plano?.valor,
                slots: e.vagas ?? "1",
                dueDate: moment(e.plano?.dataFatura.toDate()).format("DD/MM/YYYY")
            }
        })
        this.state = {
            mensalistas: rows,
            mensalistasPagos: rows.filter(e => e.status === "Em dia"),
            mensalistasPendentes: rows.filter(e => e.status === "Pendente"),
            mensalistasExpirados: rows.filter(e => e.status === "Sem cobranças")
        }
    }

    render() {
        return (
            <Document>
                <Page size="A4" wrap={false} style={styles.page} orientation={"landscape"}>
                    <PDFHeaderComponent />
                    <MonthlyReportHeaderDocument mensalistas={this.state.mensalistas} />
                    <MonthlyReportByStatus title="Em dia" mensalistas={this.state.mensalistasPagos} />
                    <MonthlyReportByStatus title="Pendentes" mensalistas={this.state.mensalistasPendentes} />
                    <MonthlyReportByStatus title="Sem cobranças" mensalistas={this.state.mensalistasExpirados} />
                    <PDFFooterComponent />
                </Page>
            </Document>
        )
    }
}

class MonthlyReportCompletedDocument extends React.Component {
    constructor(props) {
        super(props)
        const rows = props.mensalistas.map(e => {
            return {
                id: e.id,
                status: e.plano.dataFatura ? moment(e.plano.dataFatura.seconds * 1000).isAfter(new Date()) ? "Em dia" : "Pendente" : "Sem cobranças",
                name: e.nome,
                cellphone: e.celular ?? "-",
                plan: e.plano?.nome ?? "-",
                price: e.plano?.valor,
                slots: e.vagas ?? "1",
                dueDate: !isNullOrEmpty(e.plano?.dataFatura) ? moment(e.plano?.dataFatura.toDate()).format("DD/MM") : "-"
            }
        })
        this.state = {
            mensalistas: rows,
            mensalistasPagos: rows.filter(e => e.status === "Em dia"),
            mensalistasPendentes: rows.filter(e => e.status === "Pendente"),
            mensalistasExpirados: rows.filter(e => e.status === "Sem cobranças"),
            changes: props.changes ?? [],
            vehicles: props.vehicles ?? []
        }
    }

    render() {
        return (
            <Document>
                <Page size="A4" wrap={false} style={styles.page} orientation={"landscape"}>
                    <PDFHeaderComponent />
                    <MonthlyReportHeaderDocument mensalistas={this.state.mensalistas} />
                    <MonthlyReportByStatusWithChanges
                        title="Em dia"
                        mensalistas={this.state.mensalistasPagos}
                        changes={this.state.changes}
                        vehicles={this.state.vehicles} />
                    <MonthlyReportByStatusWithChanges
                        title="Pendente"
                        mensalistas={this.state.mensalistasPendentes}
                        changes={this.state.changes}
                        vehicles={this.state.vehicles} />
                    <MonthlyReportByStatusWithChanges
                        title="Sem cobranças"
                        mensalistas={this.state.mensalistasExpirados}
                        changes={this.state.changes}
                        vehicles={this.state.vehicles} />
                    <PDFFooterComponent />
                </Page>
            </Document>
        )
    }
}

class MonthlyReportAccessCardsDocument extends React.Component {

    constructor(props) {
        super(props)
        const items = props.accessCards.sort((a, b) => -b.cardNumber.localeCompare(a.cardNumber)).filter(e => e.type === "monthly").map(e => {
            return {
                id: e.id,
                number: e.cardNumber,
                createdAt: moment(e.createdAt.toDate()).format("DD/MM/YYYY"),
                createdBy: e.createdBy,
                monthly: props.monthlies.filter(a => a.id === e.referenceId)[0],
                vehicles: props.vehicles.filter(b => b.cartao === e.cardNumber).map(e => e.placa).join(", ")
            }
        })
        this.state = {
            items: items,
            mensalistas: props.monthlies
        }
    }

    render() {
        return (
            <Document>
                <Page size="A4" wrap={false} style={styles.page} orientation={"landscape"}>
                    <PDFHeaderComponent />
                    <View style={styles.section}>
                        <Text style={[styles.section, { marginTop: 30 }]}>Cartões de acesso</Text>
                        <View style={[styles.table, { marginTop: 12 }]}>
                            <View style={styles.tableRow}>
                                <Text style={[styles.tableColHeader, { width: "33.33%" }]}>Cartão</Text>
                                <Text style={[styles.tableColHeader, { width: "33.33%" }]}>Mensalista</Text>
                                <Text style={[styles.tableColHeader, { width: "33.33%" }]}>Veículos</Text>
                            </View>
                        </View>
                        {
                            this.state.items.map((item, index) =>
                                <View style={styles.tableRow} key={`cartao_acesso_${index}`}>
                                    <Text style={[styles.tableColLeft, { width: "33.33%" }]}>{item.number}</Text>
                                    <Text style={[styles.tableCol, { width: "33.33%" }]}>{item.monthly?.nome ?? "-"}</Text>
                                    <Text style={[styles.tableCol, { width: "33.33%" }]}>{item.vehicles.length > 0 ? item.vehicles : "-"}</Text>
                                </View>
                            )
                        }
                    </View>
                </Page>
            </Document>
        )
    }
}
class MonthlyReportHeaderDocument extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mensalistas: props.mensalistas,
            total: props.mensalistas.map(e => e.price).reduce((a, b) => a + b, 0)
        }
    }

    render() {
        return (
            <View style={styles.section}>
                <Text style={[styles.section, { marginTop: 30 }]}>Relatório de mensalistas</Text>
                <Text style={[styles.subtotal, { textAlign: "left" }]}>{toCurrency(this.state.total)}</Text>
            </View>
        )
    }
}

class MonthlyReportByStatusWithChanges extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: props.title,
            mensalistas: props.mensalistas,
            changes: props.changes,
            vehicles: props.vehicles
        }
    }
    render() {
        return (
            <>
                {
                    this.state.mensalistas.length > 0 &&
                    <View style={styles.section}>
                        <Text style={[styles.section, { marginTop: 30 }]}>{this.state.title}</Text>
                        {
                            this.state.mensalistas.map((item, index) => <>
                                <View style={[styles.table, { marginTop: 12 }]}>
                                    <View style={styles.tableRow}>
                                        <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Nome</Text>
                                        <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Celular</Text>
                                        <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Plano</Text>
                                        <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Vagas</Text>
                                        <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Vencimento</Text>
                                        <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Valor Plano</Text>
                                    </View>
                                </View>
                                <View style={styles.tableRow} key={`mensalistas_${index}`}>
                                    <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.name}</Text>
                                    <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.cellphone}</Text>
                                    <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.plan}</Text>
                                    <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.slots}</Text>
                                    <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.dueDate}</Text>
                                    <Text style={[styles.tableCol, { width: "16.66%", textAlign: "right" }]}>{toCurrency(item.price)}</Text>
                                </View>
                                <View style={[styles.row, { marginTop: 6, width: "60.0%", marginLeft: "auto", marginRight: 0 }]}>
                                    <View style={[styles.left, { width: "30%", marginRight: 7 }]}>
                                        <View style={[styles.table]}>
                                            <View style={styles.tableRow}>
                                                <Text style={[styles.tableColHeader, { width: "50%" }]}>Placa</Text>
                                                <Text style={[styles.tableColHeader, { width: "50%" }]}>Marca</Text>
                                            </View>
                                            {
                                                this.state.vehicles.filter(e => e.mensalistaId === item.id).length > 0 ? <>
                                                    {
                                                        this.state.vehicles.filter(e => e.mensalistaId === item.id).map((vehicle, index) =>
                                                            <View style={styles.tableRow} key={`mensalistas_veiculos_${index}`}>
                                                                <Text style={[styles.tableCol, { width: "50%" }]}>{vehicle.placa}</Text>
                                                                <Text style={[styles.tableCol, { width: "50%" }]}>{vehicle.marca} {vehicle.modelo}</Text>
                                                            </View>
                                                        )
                                                    }
                                                </> :
                                                    <View style={styles.tableRow} key={`mensalistas_veiculos_${index}`}>
                                                        <Text style={[styles.tableCol, { width: "100%" }]}>Sem veículos</Text>
                                                    </View>
                                            }
                                        </View>
                                    </View>
                                    <View style={[styles.right, { width: "43.0%" }]}>
                                        <View style={[styles.table]}>
                                            <View style={styles.tableRow}>
                                                <Text style={[styles.tableColHeader, { width: "33%" }]}>Tipo</Text>
                                                <Text style={[styles.tableColHeader, { width: "33%" }]}>Datas</Text>
                                                <Text style={[styles.tableColHeader, { width: "33%" }]}>Pagamentos</Text>
                                            </View>
                                            {
                                                this.state.changes.filter(e => e.monthlyId === item.id).length > 0 ? <>
                                                    {
                                                        this.state.changes.filter(e => e.monthlyId === item.id).map((change, index) =>
                                                            <View style={styles.tableRow} key={`mensalistas_cobrancas_${index}`}>
                                                                <Text style={[styles.tableCol, { width: "33%" }]}>{change.payment?.formaPagamento?.nome ?? "-"}</Text>
                                                                <Text style={[styles.tableCol, { width: "33%" }]}>{change.changeDate ? moment(change.changeDate.seconds * 1000).format("DD/MM/YYYY") : "-"}</Text>
                                                                <Text style={[styles.tableCol, { width: "33%", textAlign: "right", color: "green" }]}>{toCurrency(change.netValue)}</Text>
                                                            </View>
                                                        )
                                                    }
                                                </> :
                                                    <View style={styles.tableRow} key={`mensalistas_cobrancas_${index}`}>
                                                        <Text style={[styles.tableCol, { width: "100%" }]}>Sem pagamentos</Text>
                                                    </View>
                                            }
                                        </View>
                                    </View>
                                </View>
                                <Text style={[styles.subtotal2, { textAlign: "right", marginBottom: 20, marginTop: 8 }]}>{toCurrency(this.state.changes.filter(e => e.monthlyId === item.id).map(e => e.netValue).reduce((a, b) => a + b, 0))}</Text>
                            </>
                            )
                        }
                    </View >
                }
            </>
        )
    }
}

class MonthlyReportByStatus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: props.title,
            mensalistas: props.mensalistas,
            total: props.mensalistas.map(e => e.price).reduce((a, b) => a + b, 0)
        }
    }
    render() {
        return (
            <>
                {
                    this.state.mensalistas.length > 0 &&
                    <View style={styles.section}>
                        <Text style={[styles.section, { marginTop: 20 }]}>{this.state.title}</Text>
                        <View style={[styles.table, { marginTop: 12 }]}>
                            <View style={styles.tableRow}>
                                <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Nome</Text>
                                <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Celular</Text>
                                <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Plano</Text>
                                <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Vagas</Text>
                                <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Vencimento</Text>
                                <Text style={[styles.tableColHeader, { width: "16.66%" }]}>Valor</Text>
                            </View>
                            {
                                this.state.mensalistas.map((item, index) =>
                                    <View style={styles.tableRow} key={`mensalistas_${index}`}>
                                        <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.name}</Text>
                                        <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.cellphone}</Text>
                                        <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.plan}</Text>
                                        <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.slots}</Text>
                                        <Text style={[styles.tableCol, { width: "16.66%" }]}>{item.dueDate}</Text>
                                        <Text style={[styles.tableCol, { width: "16.66%", textAlign: "right" }]}>{toCurrency(item.price)}</Text>
                                    </View>
                                )
                            }
                        </View>
                        <Text style={[styles.subtotal, { textAlign: "right" }]}>{toCurrency(this.state.total)}</Text>
                    </View>
                }
            </>
        )
    }
}

export { MonthlyReportDocument, MonthlyReportCompletedDocument, MonthlyReportAccessCardsDocument }