import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { docsToItem, docsToItems } from '../transform.docs';
import { getAllDocsByCollection, updateDoc } from './collectionBaseWorker';

export const getRemotesConfig = async () => {
    return getAllDocsByCollection({ collection: Collection.REMOTE_CONFIG })
}

export const getRemoteConfigByKey = async ({ key }) => {
    const result = await firebase.firestore().collection(Collection.REMOTE_CONFIG).where("key", "==", key).get()
    return docsToItem(result)
}

export const getAllRemoteConfig = async () => {
    const result = await firebase.firestore().collection(Collection.REMOTE_CONFIG).get()
    return docsToItems(result)
}

export const updateRemoteConfig = async ({ id, data }) => {
    await updateDoc({ collection: Collection.REMOTE_CONFIG, id: id, data: data })
}