import { Collection } from '../../shared/constants';
import { addDoc, deleteDoc, getAllDocsByCollection, updateDoc } from './collectionBaseWorker';

export const getAllBranchesAndModels = async () => {
    var results = await getAllDocsByCollection({ collection: Collection.VEHICLE_MODELS })
    return results.sort((a, b) => -b.value.localeCompare(a.value))
}

export const addBranchAndModel = async ({ data }) => {
    await addDoc({ collection: Collection.VEHICLE_MODELS, data: data })
}

export const deleteBranchAndModelById = async (item) => {
    await deleteDoc({ collection: Collection.VEHICLE_MODELS, id: item.id })
}

export const updateBranchAndModel = async (item) => {
    await updateDoc({ collection: Collection.VEHICLE_MODELS, id: item.id, data: item.data })
}