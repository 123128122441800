// const { getMomentBySession, getMomentFromTimeStampBySession } = require("../../shared/authentication/express-manager");
// const { getSessionByToken } = require("../../shared/session-manager");
// const { getPartnershipById } = require("../../workers/partnershipWorker");
// const { getPriceTableById, getCurrentPriceTableByDayAndTime } = require("../../workers/priceTableWorker");
// const { updateLastSimulateTicket, getTicketById } = require("../../workers/ticketWorker");
// const { transformPriceTable } = require("../../workers/transform/priceTableTransform");
// const { transformTicket } = require("../../workers/transform/ticketTransform");
// const { convertHoursToMinutes, nextHourMyMissingMinutes } = require("./utils.parking.calculate")
// const { ticketTotalPermanence, ticketTotalProducts, ticketTotalServices, totalProductsAndServices } = require("./utils.ticket")

import moment from 'moment'
import { getParameter } from '../../store/collections/parameterWorker'
import {
    totalProductsAndServices,
    ticketTotalPermanence,
    ticketTotalServices,
    ticketTotalProducts
} from './utils.ticket'
import { convertHoursToMinutes, nextHourMyMissingMinutes } from './utils.parking.calculate'
import { transformTicket } from '../transform/ticketTransform'
import { transformPriceTable } from '../transform/priceTableTransform'
import { partnershipTransform } from '../transform/partnershipTransform'
import { getMonthlyById } from '../../store/collections/mensalistaWorker'

export const calculateTotalByTicket = async ({ ticket, table, partnership, type, endDate }) => {
    if (!table) {
        return {
            success: false,
            message: "Informe a tabela de preço."
        }
    }
    const ticket2 = transformTicket(ticket)
    const table2 = transformPriceTable(table)
    const partnership2 = partnershipTransform(partnership)
    return privateCalculateTotal({
        startDate: ticket2.createdAt,
        endDate: endDate,
        ticket: ticket2,
        table: table2,
        partnership: partnership2,
        type: type
    })
}

const privateCalculateTotal = async ({ startDate, endDate, ticket, table, partnership, discount, type }) => {
    try {
        switch (type) {
            case "NORMAL_DELIVERY":
            case "EARLY_PAYMENT_NORMAL_DELIVERY":
            case "RECALCULATE_PAYMENT":
                return await normalParking({
                    ticket: ticket,
                    startDate: startDate,
                    endDate: endDate,
                    table: table,
                    partnership: partnership,
                    discount: discount,
                    type: type
                })
            case "EARLY_DELIVERY":
                return await earlyParking({
                    ticket: ticket,
                    startDate: startDate,
                    endDate: endDate,
                    partnership: partnership,
                    table: table,
                    discount: discount,
                    type: type
                })
            case "MONTHLY_DELIVERY":
                const parameters = await getParameter()
                return monthlyParking({
                    parameters: parameters,
                    ticket: ticket,
                    startDate: startDate,
                    endDate: endDate,
                    type: type
                })
            case "ACCREDITED_DELIVERY":
                return accreditedParking({
                    ticket: ticket,
                    startDate: startDate,
                    endDate: endDate,
                    type: type
                })
            case "SINGLE_SALE":
                return singleSale({
                    ticket: ticket,
                    type: type
                })
        }
    } catch (error) {
        return {
            success: false,
            message: error.message
        }
    }
}

const normalParking = async ({ ticket: ticket, startDate, endDate, table, partnership, discount, type }) => {
    var time = parkedTime({ ticket: ticket, table: table, startDate: startDate, endDate: endDate })
    var resume = {
        parkingValue: 0,
        productsValue: ticketTotalProducts({ ticket: ticket }),
        servicesValue: ticketTotalServices({ ticket: ticket }),
        totalValue: totalProductsAndServices({ ticket: ticket }),
        discountValue: 0,
        missingMinutes: time,
        startDate: startDate,
        endDate: endDate,
        permanence: ticketTotalPermanence({ ticket: ticket, startDate: startDate, endDate: endDate }),
        tolerance: table.tolerance,
        products: ticket.products,
        partnership: partnership,
        priceTableId: table.id,
        type: type
    }
    if (resume.missingMinutes <= 0) {
        return {
            success: true,
            data: resume
        }
    }
    const qtdByMaxPeriod = quantityByMaxPeriod({ resume: resume, table: table })
    if (qtdByMaxPeriod > 0) {
        calculateMaxPeriod({ resume: resume, table: table })
        if (table.chargeAdditionalHoursAfterMaximumPeriod === true) {
            applyAdditionalHours({ resume: resume, table: table })
        } else {
            calculateNormalPeriod({ resume: resume, table })
        }
    } else {
        calculateNormalPeriod({ resume: resume, table })
        if (resume.missingMinutes > 0) {
            applyAdditionalHours({ resume: resume, table: table })
            //Caso ultrapasse o valor do primeiro periodo máximo
            if (table.maximumPrice > 0) {
                if (resume.parkingValue > table.maximumPrice) {
                    resume.parkingValue = table.maximumPrice
                }
            }
        }
    }
    applyPartnership({ resume: resume, ticket: ticket, startDate: startDate, endDate: endDate, table: table, partnership })
    applyManualDiscount({ resume: resume, discount: discount, ticket: ticket })
    applyMaxPermanence({ resume: resume, table: table })
    applyMinimumPrices({ resume: resume, ticket: ticket })
    applyDiscountByPreviousPayments({ resume: resume, ticket: ticket })
    return {
        success: true,
        data: resume
    }
}

const earlyParking = async ({ ticket, startDate, endDate, partnership, table, discount, type }) => {
    if (table.maximumPrice == null) {
        throw new Error('Para fazer um pagamento adiantado é preciso ter um valor máximo cadastrado na tabela de preço');
    }
    var resume = {
        parkingValue: table.maximumPrice ?? 0,
        productsValue: ticketTotalProducts({ ticket: ticket }),
        servicesValue: ticketTotalServices({ ticket: ticket }),
        discountValue: 0,
        totalValue: (table.maximumPrice ?? 0) + totalProductsAndServices({ ticket: ticket }),
        startDate: startDate,
        endDate: endDate,
        permanence: ticketTotalPermanence({ ticket: ticket, startDate: startDate, endDate: endDate }),
        products: ticket.products,
        partnership: partnership,
        priceTableId: table.id,
        type: type
    }
    if (table.chargeEarlyValuePermanence) {
        const periods = allPeriods({ table: table })
        if (periods.length === 0) {
            resume.parkingValue = table.maximumPrice ?? 0
        } else {
            resume.parkingValue = periods[0].price ?? 0
        }
    }
    applyPartnership({ resume: resume, ticket: ticket, startDate: startDate, endDate: endDate, table: table, partnership: partnership })
    applyManualDiscount({ resume: resume, discount: discount, ticket: ticket })
    applyMaxPermanence({ resume: resume, table: table })
    applyMinimumPrices({ resume: resume, ticket: ticket })
    return {
        success: true,
        data: resume
    }
}

const singleSale = ({ ticket, type }) => {
    if ((ticket.products ?? []).length === 0) {
        throw new Error('Informe ao menos um produto de consumo ou serviço.');
    }
    return {
        success: true,
        data: {
            productsValue: ticketTotalProducts({ ticket: ticket }),
            servicesValue: ticketTotalServices({ ticket: ticket }),
            totalValue: totalProductsAndServices({ ticket: ticket }),
            products: ticket.products,
            type: type
        }
    }
}

const accreditedParking = ({ ticket, startDate, endDate, type }) => {
    if (!ticket.accredited) {
        throw new Error('Este ticket não esta associado a nenhum credenciado.');
    }
    var resume = {
        parkingValue: 0,
        productsValue: ticketTotalProducts({ ticket: ticket }),
        servicesValue: ticketTotalServices({ ticket: ticket }),
        discountValue: 0,
        totalValue: totalProductsAndServices({ ticket: ticket }),
        startDate: startDate,
        endDate: endDate,
        permanence: ticketTotalPermanence({ ticket: ticket, startDate: startDate, endDate: endDate }),
        type: type
    }
    return {
        success: true,
        data: resume
    }
}

const monthlyParking = async ({ parameters, ticket, startDate, endDate, type }) => {
    if (!ticket.monthly) {
        throw new Error('Este ticket não esta associado a nenhum mensalista.');
    }
    const monthly = await getMonthlyById(ticket.monthly.id)
    const limitDays = parseInt(parameters.tarifaAposVencimenoDias ?? 1)
    const date = moment(monthly.plano.dataFatura).add(limitDays, "days")
    if (date.isBefore(new Date())) {
        const dateFormatted = moment(monthly.plano.dataFatura).format("DD/MM/YYYY")
        throw new Error(`Este mensalista esta com o plano vencido desde ${dateFormatted}`);
    }
    var resume = {
        parkingValue: 0,
        productsValue: ticketTotalProducts({ ticket: ticket }),
        servicesValue: ticketTotalServices({ ticket: ticket }),
        discountValue: 0,
        totalValue: totalProductsAndServices({ ticket: ticket }),
        startDate: startDate,
        endDate: endDate,
        permanence: ticketTotalPermanence({ ticket: ticket, startDate: startDate, endDate: endDate }),
        type: type
    }
    return {
        success: true,
        data: resume
    }
}

const calculateMaxPeriodUntilFinish = ({ table, resume }) => {
    calculateMaxPeriod({ resume: resume, table: table })
}

const calculateMaxPeriod = ({ resume, table }) => {
    if (table.maximumPeriod == null || table.maximumPrice == null) {
        throw new Error('É preciso preencher o período máximo.');
    }
    const maxPeriodByMinutes = convertHoursToMinutes({ value: table.maximumPeriod })
    if (table.chargeAdditionalHoursAfterMaximumPeriod) {
        resume.parkingValue += table.maximumPrice ?? 0
        resume.missingMinutes -= maxPeriodByMinutes
        return
    } else {
        const qtdByMaxPeriod = quantityByMaxPeriod({ resume: resume, table: table })
        const period = convertHoursToMinutes({ value: table.maximumPeriod })
        if (resume.missingMinutes >= period) {
            resume.missingMinutes -= (qtdByMaxPeriod * period)
            resume.parkingValue += (qtdByMaxPeriod * table.maximumPrice)
        }
        if (resume.missingMinutes > 0 && mustToCalculateMaxPeriod({ table: table })) {
            resume.missingMinutes -= period
            resume.parkingValue += table.maximumPrice
        }
    }
}

const calculatePeriodItem = ({ resume, period }) => {
    resume.parkingValue += period.price
    resume.missingMinutes -= convertHoursToMinutes({ value: period.period })
}

const calculateNormalPeriod = ({ resume, table }) => {
    if ((table.periods ?? []).length === 0 || resume.missingMinutes == 0) {
        return
    }
    const aPeriods = allPeriods({ table: table })
    var period = aPeriods[0]
    const periods = aPeriods.filter(e => convertHoursToMinutes({ value: e.period }) >= resume.missingMinutes)
    if (periods.length == 0) {
        period = aPeriods[aPeriods.length - 1]
    } else {
        period = periods[0]
    }
    calculatePeriodItem({ resume: resume, period: period })
}

const applyAdditionalHours = async ({ resume, table }) => {
    if (table.chargeAdditionalHoursAfterMaximumPeriod == true && table.additionalHourPrice == undefined) {
        throw new Error('É preciso informar o permanence da hora adicional.');
    }
    const minutes = nextHourMyMissingMinutes({ resume: resume })
    if (minutes === null && ((table.additionalHourPrice ?? 0) === 0)) {
        return
    }
    const price = (minutes / 60) * (table.additionalHourPrice ?? 0)
    resume.parkingValue += price
    resume.missingMinutes -= minutes
}

const applyPartnership = ({ resume, ticket, startDate, endDate, table, partnership }) => {
    if (partnership) {
        const time = parkedTime({ ticket: ticket, table: table, startDate: startDate, endDate: endDate })
        const partnershipTolerance = convertHoursToMinutes({ value: partnership.tolerance })
        if (partnership.keepDiscount === true || (partnership.keepDiscount === false && partnershipTolerance >= time)) {
            if (partnership.type === "price") {
                if (partnership.price) {
                    resume.discountValue = (partnership.price ?? 0)
                    resume.totalValue = resume.parkingValue - (partnership.price ?? 0)
                } else {
                    throw new Error('É preciso informar o campo valor neste tipo de convênio.');
                }
            } else if (partnership.type == "percentage" && resume.parkingValue > 0) {
                if (partnership.percentage) {
                    const pricePercentage = (resume.parkingValue * parseFloat(partnership.percentage ?? 0)) / 100
                    resume.totalValue = resume.parkingValue - pricePercentage
                    resume.discountValue = resume.parkingValue - resume.totalValue
                } else {
                    throw new Error('É preciso informar o campo porcentagem neste tipo de convênio.');
                }
            } else if (partnership.type === "progressive" && resume.parkingValue > 0) {
                const progressives = partnership.progressives.filter(e => e.charge <= resume.parkingValue).sort((a, b) => b.charge - a.charge)
                if (progressives.length !== 0) {
                    const progressive = progressives[0]
                    if (progressive.typeProgresive == "Porcentagem") {
                        const pricePercentage = (resume.parkingValue * parseFloat(progressive.porcentage ?? 0.0)) / 100
                        resume.totalValue = resume.parkingValue - pricePercentage
                        resume.discountValue = resume.parkingValue - resume.totalValue
                    } else {
                        resume.discountValue = (progressive.discount ?? 0)
                        resume.totalValue = resume.parkingValue - (progressive.discount ?? 0)
                    }
                }
            }
            resume.partnership = partnership
            resume.totalValue += totalProductsAndServices({ ticket: ticket })
        }
    }
}

const applyManualDiscount = ({ resume, discount, ticket }) => {
    if (discount) {
        if (!discount.reason) {
            throw new Error('Informe o motivo do desconto.');
        }
        resume.discount = discount
        if (discount.discount >= resume.parkingValue) {
            resume.discount.discount = resume.parkingValue
            resume.discountValue += resume.parkingValue ?? 0
            resume.parkingValue = 0
        } else {
            resume.discountValue += discount.discount ?? 0
        }
        resume.totalValue -= ((discount.discount ?? 0) + totalProductsAndServices({ ticket: ticket }))
    }
}

const applyMaxPermanence = ({ resume, table }) => {
    const max = Math.abs(resume.missingMinutes ?? 0)
    const tolerance = convertHoursToMinutes({ value: table.tolerancePosPayment })
    resume.limitPermanenceInMin = (max + tolerance) ?? 0
    resume.limitPermanenceInDate = moment(resume.endDate).add(resume.limitPermanenceInMin, "minutes").format("YYYY-MM-DD[T]HH:mm:ss")
    resume.missingMinutes = undefined
}

const applyMinimumPrices = async ({ resume, ticket }) => {
    if (resume.parkingValue < 0) { resume.parkingValue = 0 }
    if (resume.discountValue > resume.parkingValue) {
        resume.totalValue = totalProductsAndServices({ ticket: ticket })
    } else {
        resume.totalValue = (resume.parkingValue - resume.discountValue + totalProductsAndServices({ ticket: ticket }))
    }
}

const applyDiscountByPreviousPayments = async ({ resume, ticket }) => {
    const payments = ticket.paymentsLogged?.filter(e => e.canceled === false) ?? []
    if (payments.length > 0) {
        const discount = payments.map(e => e.resume.discount).reduce((a, b) => a + b, 0)
        const parking = payments.map(e => e.resume.parking).reduce((a, b) => a + b, 0)
        const products = payments.map(e => e.resume.products).reduce((a, b) => a + b, 0)
        const services = payments.map(e => e.resume.services).reduce((a, b) => a + b, 0)
        const total = payments.map(e => e.resume.total).reduce((a, b) => a + b, 0)
        resume.totalValue -= total
        resume.discountValue -= discount
        resume.parkingValue -= parking
        resume.productsValue -= products
        resume.servicesValue -= services

        if (resume.totalValue < 0) { resume.totalValue = 0 }
        if (resume.discountValue < 0) { resume.discountValue = 0 }
        if (resume.parkingValue < 0) { resume.parkingValue = 0 }
        if (resume.productsValue < 0) { resume.productsValue = 0 }
        if (resume.servicesValue < 0) { resume.servicesValue = 0 }
    }
}

const parkedTime = ({ ticket, startDate, endDate, table }) => {
    const parkingMinutes = ticketTotalPermanence({ ticket: ticket, startDate: startDate, endDate: endDate })
    const tolerance = convertHoursToMinutes({ value: table.tolerance })
    if (tolerance >= parkingMinutes) {
        return 0
    }
    return (parkingMinutes - tolerance)
}

const allPeriods = ({ table }) => {
    return (table.periods ?? []).sort((a, b) => convertHoursToMinutes({ value: a.period }) - convertHoursToMinutes({ value: b.period }))
}

const quantityByMaxPeriod = ({ resume, table }) => {
    if (table.maximumPeriod == null || table.maximumPrice == null) {
        throw new Error('É preciso preencher o período máximo.');
    }
    const maxPeriodByMinutes = convertHoursToMinutes({ value: table.maximumPeriod })
    if (maxPeriodByMinutes == 0) {
        return 0
    }
    const quantity = parseInt(resume.missingMinutes / maxPeriodByMinutes)
    if (quantity == 0 && mustToCalculateMaxPeriod({ table: table })) {
        return 1
    }
    return parseInt(quantity)
}

const mustToCalculateMaxPeriod = ({ table }) => {
    if (table.maximumPeriod == null || table.maximumPrice == null) {
        throw new Error('É preciso preencher o período máximo.');
    }
    const maxPeriodByMinutes = convertHoursToMinutes({ value: table.maximumPeriod })
    if (maxPeriodByMinutes == 0) {
        return false
    }
    return (table.periods ?? []).length === 0 && !table.chargeAdditionalHoursAfterMaximumPeriod
}