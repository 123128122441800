import { Collection } from '../../shared/constants';
import { deleteDoc, getAllDocsByParkId, updateDoc } from './collectionBaseWorker';

export const getAllDevicesByParkId = async ({ estacionamentoId }) => {
    const docs = await getAllDocsByParkId({ collection: Collection.DEVICES, estacionamentoId: estacionamentoId })
    return docs
}

export const updateDevice = async ({ id, data }) => {
    data.lastUpdate = new Date()
    await updateDoc({ collection: Collection.DEVICES, id: id, data: data })
}

export const deleteDevice = async ({ id }) => {
    await deleteDoc({ collection: Collection.DEVICES, id: id })
}
