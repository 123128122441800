import React from 'react'
import { NavBar } from '../../components/navbar'
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario, getUsuarioCompact } from '../../config/auth'
import { Loading, ComboBoxCollection, HelpButton } from '../../components/common/commons'
import firebase from '../../config/firebase';
import 'firebase/storage';
import { EmptyContent } from '../../components/common/commons'
import { sendClickButton, sendError, sendLog, sendLogByUser } from '../../shared/analytics'
import NumberFormat from 'react-number-format';
import { saveLastUpdate } from '../../store/collections/lastUpdateWorker';
import Moment from 'moment';
import { getAllSectores, getAllSectoresByClientId } from '../../store/collections/setoresWorker'
import Select from 'react-select';
import { getAllUsersActiveByClientId } from '../../store/collections/userWorker';
import Tooltip from '@mui/material/Tooltip';
import { searchCEP } from '../../shared/cepWorker';
import { getAllRelationshipsByClientId } from '../../store/collections/relationshipWorker';
import { getAllParksByClientId } from '../../store/collections/parkWorker';
import { getLicencaByParkId } from '../../store/collections/licencaWorker';
import { getFirstHighlightPlan, getLicencePlanById } from '../../store/collections/planosLicencaWorker';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { ItemPlan } from '../licenca/my-licence';
import { reloadWithAlert, toastError, toastSuccess, toastWarning } from '../../shared/utils';
import { AlertSuccess, AlertWarning } from '../../shared/alert-manager';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { getAllLogsByUserId } from '../../store/collections/userLogsWorker';
import { addDoc } from '../../store/collections/collectionBaseWorker';
import { Collection } from '../../shared/constants';

const initialState = {
    clienteId: getCliente()?.id,
    estacionamentoId: getEstacionamento()?.id,
    perfilId: '',
    status: 'ACTIVE',
    isEdit: false,
    mostrarCamposOpcionais: false,
    email: '',
    nome: '',
    documento: '',
    celular: '',
    cep: '',
    codigo: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    complemento: '',
    setores: [],
    allSetores: [],
    relationships: [],
    allRelationships: [],
    parks: [],
    allParks: [],
}

class CriarUsuario extends React.Component {

    constructor(props) {
        super(props)
        this.state = initialState
        this._loadParks()
        this._loadSectors()
        this._loadRelationships()
    }

    _loadSectors = async () => {
        var allSetores = await getAllSectoresByClientId({ clienteId: this.state.clienteId })
        var mapped = allSetores.map(e => { return { value: e.id, label: e.nome } })
        this.setState({ allSetores: mapped })
    }

    _loadRelationships = async () => {
        var allRelationships = await getAllRelationshipsByClientId({ clienteId: this.state.clienteId })
        var mapped = allRelationships.map(e => { return { value: e.id, label: e.nome } })
        this.setState({ allRelationships: mapped })
    }

    _loadParks = async () => {
        var allParks = await getAllParksByClientId({ clientId: this.state.clienteId })
        var mapped = allParks.map(e => { return { value: e.id, label: e.nomeInterno ?? e.nome } })
        this.setState({ allParks: mapped })
    }

    onProfileSelected = (item) => {
        this.setState({ perfilId: item.id })
    }

    handleSetores = (selected) => {
        this.setState({ setores: selected })
    };

    handleRelationships = (selected) => {
        this.setState({ relationships: selected })
    };

    handleParks = (selected) => {
        this.setState({ parks: selected })
    };

    reset = () => {
        this.setState({ ...initialState })
    }

    onEditUser = (user) => {
        this.setState({ ...user })
        this.setState({ isEdit: true })
        this.setState({ codigo: user.codigo ?? '' })
        this.setState({ setores: user.setores?.length === 0 ? this.state.allSetores : user.setores ?? [] })
        this.setState({ relationships: user.relationships ?? [] })
        this.setState({ parks: user.estacionamentos?.length === 0 ? this.state.allParks : user.estacionamentos.map(e => { return { value: e.id, label: e.nome } }) ?? [] })
        this.setState({ mostrarCamposOpcionais: (user.endereco?.cep !== undefined && user.endereco?.cep !== '') })
        this.setState({ habilitarAlertasNoPagamentoDeUmMensalista: user.alertas?.habilitarAlertasNoPagamentoDeUmMensalista ?? false })
        this.setState({ habilitarAlertasNoPixGeradoDeUmMensalista: user.alertas?.habilitarAlertasNoPixGeradoDeUmMensalista ?? false })
        this.setState({ habilitarAlertasNaAberturaDeCaixa: user.alertas?.habilitarAlertasNaAberturaDeCaixa ?? false })
        this.setState({ habilitarAlertasNoCadastroDeUmMensalista: user.alertas?.habilitarAlertasNoCadastroDeUmMensalista ?? false })
        this.setState({ habilitarAlertasNoCadastroDeUmCredenciado: user.alertas?.habilitarAlertasNoCadastroDeUmCredenciado ?? false })
        this.setState({ habilitarAlertasNoFechamentoDeCaixa: user.alertas?.habilitarAlertasNoFechamentoDeCaixa ?? false })
        this.setState({ habilitarAlertasNaSolicitacaoDoVeiculo: user.alertas?.habilitarAlertasNaSolicitacaoDoVeiculo ?? false })
        this.setState({ habilitarAlertasNoRecebimentoDeMensagensNoWhatsApp: user.alertas?.habilitarAlertasNoRecebimentoDeMensagensNoWhatsApp ?? false })
        this.setState({ random: Math.random() })
        if (user.endereco !== undefined) {
            this.setState({ cep: user.endereco.cep })
            this.setState({ logradouro: user.endereco.logradouro })
            this.setState({ numero: user.endereco.numero })
            this.setState({ bairro: user.endereco.bairro })
            this.setState({ cidade: user.endereco.cidade })
            this.setState({ estado: user.endereco.estado })
            this.setState({ complemento: user.endereco.complemento })
        }
    }

    checkAllFields = async () => {
        if (this.state.perfilId === '') {
            toastWarning('Selecione um perfil de acesso para o usuário')
            return false
        }
        if (this.state.codigo === '' || this.state.nome === '' || this.state.email === '' || this.state.senha === '') {
            toastWarning('Preencha todos os campos obrigatórios do formulário.')
            return false
        }
        const result = await firebase.firestore().collection("usuarios").where("clienteId", "==", this.state.clienteId).where("codigo", "==", this.state.codigo).get()
        if (!result.empty && result.docs[0].data().email !== this.state.email) {
            toastWarning("Existe um usuário com este mesmo código de acesso. Escolha outro")
            return false
        }
        return true
        const licence = await getLicencaByParkId({ parkId: this.state.estacionamentoId })
        if (licence.plan) {
            const plan = await getLicencePlanById({ id: licence.plan.id })
            if (plan) {
                const allUsers = await getAllUsersActiveByClientId({ clienteId: this.state.clienteId })
                const usersByPlan = parseInt(plan.quantidadeUsuariosAtivos ?? 0)
                if (allUsers.length >= usersByPlan) {
                    const highlightPlan = await getFirstHighlightPlan()
                    const moreThanCurrent = parseInt(highlightPlan.quantidadeUsuariosAtivos ?? 0)
                    if (moreThanCurrent > usersByPlan) {
                        this.setState({ highlightPlan: highlightPlan })
                    }
                    this.setState({ messageMaximumUsersModal: `Você atingiu o limite máximo de ${usersByPlan} usuários ativos no seu plano atual, mas você pode assinar um novo plano.` })
                    const user = getUsuarioCompact()
                    sendLogByUser("Usuários", `O usuário ${user.nome} visualizou o modal de limite máximo de usuários: ${usersByPlan} permitidos e ${allUsers.length} atuais ativos.`)
                    this.setState({ showMaximumUsersModal: true })
                    return false
                } else {
                    if (this.state.perfilId === '') {
                        toastWarning('Selecione um perfil de acesso para o usuário')
                        return false
                    }
                    if (this.state.codigo === '' || this.state.nome === '' || this.state.email === '' || this.state.senha === '') {
                        toastWarning('Preencha todos os campos obrigatórios do formulário.')
                        return false
                    }
                    const result = await firebase.firestore().collection("usuarios").where("clienteId", "==", this.state.clienteId).where("codigo", "==", this.state.codigo).get()
                    if (!result.empty && result.docs[0].data().email !== this.state.email) {
                        toastWarning("Existe um usuário com este mesmo código de acesso. Escolha outro")
                        return false
                    }
                }
                return true
            }
        } else {
            const highlightPlan = await getFirstHighlightPlan()
            this.setState({ highlightPlan: highlightPlan })
            this.setState({ messageMaximumUsersModal: `É preciso ter um plano ativo para cadastrar mais de um usuário.` })
            const user = getUsuarioCompact()
            sendLogByUser("Usuários", `O usuário ${user.nome} visualizou o modal de contratação de planos para cadastro de usuários.`)
            this.setState({ showMaximumUsersModal: true })
            return false
        }
    }

    updateUsuario = async () => {
        if (!(await this.checkAllFields())) {
            return
        }
        await firebase.firestore().collection('usuarios').doc(this.state.id).update({
            nome: this.state.nome,
            status: this.state.status,
            perfilId: this.state.perfilId,
            celular: this.state.celular,
            documento: this.state.documento,
            estacionamentos: (this.state.parks?.length === 0 ? this.state.allParks : this.state.parks).map(e => { return { id: e.value, nome: e.label } }),
            codigo: this.state.codigo,
            alterAt: new Date(),
            alterBy: getUsuario().email,
            setores: this.state.setores,
            relationships: this.state.relationships,
            alertas: {
                habilitarAlertasNoCadastroDeUmMensalista: this.state.habilitarAlertasNoCadastroDeUmMensalista,
                habilitarAlertasNoPagamentoDeUmMensalista: this.state.habilitarAlertasNoPagamentoDeUmMensalista,
                habilitarAlertasNoPixGeradoDeUmMensalista: this.state.habilitarAlertasNoPixGeradoDeUmMensalista,
                habilitarAlertasNaAberturaDeCaixa: this.state.habilitarAlertasNaAberturaDeCaixa,
                habilitarAlertasNoFechamentoDeCaixa: this.state.habilitarAlertasNoFechamentoDeCaixa,
                habilitarAlertasNoCadastroDeUmCredenciado: this.state.habilitarAlertasNoCadastroDeUmCredenciado,
                habilitarAlertasNaSolicitacaoDoVeiculo: this.state.habilitarAlertasNaSolicitacaoDoVeiculo,
                habilitarAlertasNoRecebimentoDeMensagensNoWhatsApp: this.state.habilitarAlertasNoRecebimentoDeMensagensNoWhatsApp
            },
            endereco: {
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                complemento: this.state.complemento
            },
            isDeleted: false,
        })
        await saveLastUpdate()
        sendClickButton("Usuários", "Atualizar")
        reloadWithAlert('Usuário atualizado com sucesso!')
    }

    cadastrarUsuario = async () => {
        if (!(await this.checkAllFields())) {
            return
        }
        try {
            const resultUser = await firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.senha)
            const data = {
                usuarioId: resultUser.user.uid,
                nome: this.state.nome,
                email: this.state.email,
                celular: this.state.celular,
                documento: this.state.documento,
                codigo: this.state.codigo,
                status: this.state.status,
                clienteId: this.state.clienteId,
                isTermsUpdated: true,
                revendaId: getRevendaIdByPark(),
                perfilId: this.state.perfilId,
                estacionamentos: (this.state.parks?.length === 0 ? this.state.allParks : this.state.parks).map(e => { return { id: e.value, nome: e.label } }),
                createdAt: new Date(),
                createdBy: getUsuario().email,
                setores: this.state.setores,
                relationships: this.state.relationships,
                alertas: {
                    habilitarAlertasNoCadastroDeUmMensalista: this.state.habilitarAlertasNoCadastroDeUmMensalista,
                    habilitarAlertasNoPagamentoDeUmMensalista: this.state.habilitarAlertasNoPagamentoDeUmMensalista,
                    habilitarAlertasNaAberturaDeCaixa: this.state.habilitarAlertasNaAberturaDeCaixa,
                    habilitarAlertasNoFechamentoDeCaixa: this.state.habilitarAlertasNoFechamentoDeCaixa,
                    habilitarAlertasNoCadastroDeUmCredenciado: this.state.habilitarAlertasNoCadastroDeUmCredenciado,
                    habilitarAlertasNaSolicitacaoDoVeiculo: this.state.habilitarAlertasNaSolicitacaoDoVeiculo,
                    habilitarAlertasNoRecebimentoDeMensagensNoWhatsApp: this.state.habilitarAlertasNoRecebimentoDeMensagensNoWhatsApp
                },
                endereco: {
                    cep: this.state.cep,
                    logradouro: this.state.logradouro,
                    numero: this.state.numero,
                    bairro: this.state.bairro,
                    cidade: this.state.cidade,
                    estado: this.state.estado,
                    complemento: this.state.complemento
                },
                isDeleted: false,
            }
            await addDoc({ collection: Collection.USERS, data: data })
            await saveLastUpdate()
            sendClickButton("Usuários", "Cadastrar")
            reloadWithAlert('Usuário criado com sucesso!')
        } catch (error) {
            var message = error.message
            if (error.code === "auth/email-already-in-use") {
                message = "Este e-mail já esta sendo ou foi utilizado por outro usuário.\n\nEntre em contato com o suporte."
            } else if (error.code === "auth/invalid-email") {
                message = "Informe um e-mail válido."
            } else if (error.code === "auth/operation-not-allowed") {
                message = "Operação não aceita, tente novamente mais tarde."
            } else if (error.code === "auth/weak-password") {
                message = "Esta senha esta muito fraca, tente uma senha mais forte."
            } else if (error.code === "auth/email-already-exists") {
                message = "Já existe um usuário com este e-mail cadastrado."
            }
            toastError(message);
            sendError("Usuários", "Cadastrar: " + message)
        }
    }

    fetchCEP = async (value) => {
        this.setState({ cep: value })
        const result = await searchCEP(value)
        if (result) {
            this.setState({ cep: result.cep })
            this.setState({ logradouro: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ estado: result.uf })
            this.setState({ ibge: result.ibge })
        }
    }

    goToPlans = async () => {
        window.location.href = "/licenca/minha-licenca"
    }

    selectPlan = (plan) => {
        const user = getUsuarioCompact()
        sendClickButton("Usuários", `Usuário ${user.nome} tocou no botão para alterar plano por limite máximo de usuário.`)
        window.location.href = `/licenca/minha-licenca?type=plan&id=${plan.id}`
    }

    closeMaximumUserModal = () => {
        const user = getUsuarioCompact()
        sendClickButton("Usuários", `Usuário ${user.nome} fechou o modal de limite máximo de usuário.`)
        this.setState({ showMaximumUsersModal: false })
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Cadastrar usuário
                                    <HelpButton />
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className="col-lg-4">
                                                    <label>Status</label>
                                                    <select value={this.state.status && this.state.status} onChange={(e) => this.setState({ status: e.target.value })} className="form-select">
                                                        <option value='ACTIVE'>Ativo</option>
                                                        <option value='INACTIVE'>Inativo</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-4">
                                                    <ComboBoxCollection key={this.state.random}
                                                        id={this.state.perfilId}
                                                        title='Selecionar perfil'
                                                        collection='perfis'
                                                        parentField='clienteId'
                                                        parentValue={getCliente().id}
                                                        onSelected={this.onProfileSelected} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Código de acesso</label>
                                                    <input type="password" value={this.state.codigo && this.state.codigo} onChange={(e) => this.setState({ codigo: e.target.value })} className="form-control" />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label>Nome do operador</label>
                                                    <input type="text" value={this.state.nome && this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} className="form-control" />
                                                </div>
                                                <div className='col-lg-4'>
                                                    <label>E-mail</label>
                                                    <input type="email" disabled={this.state.isEdit} value={this.state.email && this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className="form-control" />
                                                </div>
                                                <div className='col-lg-4'>
                                                    <label>Senha</label>
                                                    {
                                                        this.state.isEdit === false ?
                                                            <input type="text" value={this.state.senha && this.state.senha} onChange={(e) => this.setState({ senha: e.target.value })} className="form-control" />
                                                            :
                                                            <input type="text" value="***************" disabled className="form-control" />
                                                    }
                                                </div>
                                                {
                                                    this.state.isEdit === true &&
                                                    <div className="col-lg-12 mt-3">
                                                        <div className="alert alert-secondary" >
                                                            Não é possível alterar o e-mail de login de nenhum usuário.
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.mostrarCamposOpcionais === false &&
                                                    <div className='col-lg-6 col-md-12 col-sm-12'>
                                                        <div className='mt-2'>
                                                            <button type="button" onClick={(e) => { this.setState({ mostrarCamposOpcionais: true }) }} className="btn btn-secondary btn-sm btn-block">
                                                                Mostrar os campos opcionais
                                                                <i className="fas fa-plus mx-3" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.mostrarCamposOpcionais === true &&
                                                    <>
                                                        <div className="row mt-3">
                                                            <div className="col-lg-4">
                                                                <label>WhatsApp</label>
                                                                <NumberFormat className="form-control" value={this.state.celular} mask='_' onChange={(e) => this.setState({ celular: e.target.value })} format="(##)#####-####" />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <label>CPF</label>
                                                                <NumberFormat className="form-control" mask='_' format={'###.###.###-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                                                            </div>
                                                        </div>
                                                        <div className="row my-3">
                                                            <div className="col-lg-3">
                                                                <label>CEP</label>
                                                                <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Logradouro</label>
                                                                <input type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} className="form-control" required />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Número</label>
                                                                <input type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} className="form-control" required />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-lg-3">
                                                                <label>Bairro</label>
                                                                <input type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} className="form-control" required />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Cidade</label>
                                                                <input type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} className="form-control" required />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Estado</label>
                                                                <input type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} className="form-control" required />
                                                            </div>
                                                            <div className="col-lg-3">
                                                                <label>Complemento</label>
                                                                <input type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} className="form-control" />
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <h5>Estabelecimentos</h5>
                                            <div className="col-lg-12">
                                                <Select
                                                    key={this.state.random}
                                                    isMulti
                                                    name="parks"
                                                    defaultValue={this.state.parks}
                                                    options={this.state.allParks}
                                                    onChange={this.handleParks}
                                                    className="basic-multi-select"
                                                    classNamePrefix="Selecionar os estabelecimentos" />
                                            </div>
                                            <div className='mt-2'>
                                                <h5>Convênios</h5>
                                                <div className="col-lg-12">
                                                    <Select
                                                        key={this.state.random}
                                                        isMulti
                                                        name="relationships"
                                                        defaultValue={this.state.relationships}
                                                        options={this.state.allRelationships}
                                                        onChange={this.handleRelationships}
                                                        className="basic-multi-select"
                                                        classNamePrefix="Selecionar os convênios" />
                                                </div>
                                            </div>
                                            <div className='mt-2'>
                                                <h5>Setores</h5>
                                                <div className="col-lg-12">
                                                    <Select
                                                        key={this.state.random}
                                                        isMulti
                                                        name="setores"
                                                        defaultValue={this.state.setores}
                                                        options={this.state.allSetores}
                                                        onChange={this.handleSetores}
                                                        className="basic-multi-select"
                                                        classNamePrefix="Selecionar os setores"
                                                    />
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <AlertWarning message="Caso queira que o usuário tenha acesso a todas as informações acima, basta não selecionar nenhum item." />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="btn-group">
                                        {
                                            this.state.isEdit &&
                                            <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                                        }
                                        <button type="button" onClick={this.state.isEdit ? this.updateUsuario : this.cadastrarUsuario} className="btn btn-primary">{this.state.isEdit ? 'Atualizar' : 'Cadastrar'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Mensageria
                                </div>
                                <div className="card-body">
                                    <AlertSuccess tag="Novidades" message="Receba mensagens de mudanças no sistema." />
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="habilitarAlertasNoFechamentoDeCaixa" checked={this.state.habilitarAlertasNoFechamentoDeCaixa} onChange={(e) => this.setState({ habilitarAlertasNoFechamentoDeCaixa: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="habilitarAlertasNoFechamentoDeCaixa">
                                            No fechamento de caixa.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="habilitarAlertasNaAberturaDeCaixa" checked={this.state.habilitarAlertasNaAberturaDeCaixa} onChange={(e) => this.setState({ habilitarAlertasNaAberturaDeCaixa: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="habilitarAlertasNaAberturaDeCaixa">
                                            Na abertura de um caixa.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="habilitarAlertasNoCadastroDeUmMensalista" checked={this.state.habilitarAlertasNoCadastroDeUmMensalista} onChange={(e) => this.setState({ habilitarAlertasNoCadastroDeUmMensalista: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="habilitarAlertasNoCadastroDeUmMensalista">
                                            No cadastro de um novo mensalista via formulário online.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="habilitarAlertasNoPixGeradoDeUmMensalista" checked={this.state.habilitarAlertasNoPixGeradoDeUmMensalista} onChange={(e) => this.setState({ habilitarAlertasNoPixGeradoDeUmMensalista: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="habilitarAlertasNoPixGeradoDeUmMensalista">
                                            Quando o mensalista gerar um pagamento em Pix.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="habilitarAlertasNoPagamentoDeUmMensalista" checked={this.state.habilitarAlertasNoPagamentoDeUmMensalista} onChange={(e) => this.setState({ habilitarAlertasNoPagamentoDeUmMensalista: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="habilitarAlertasNoPagamentoDeUmMensalista">
                                            No recebimento de um pagamento de mensalistas.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="habilitarAlertasNoCadastroDeUmCredenciado" checked={this.state.habilitarAlertasNoCadastroDeUmCredenciado} onChange={(e) => this.setState({ habilitarAlertasNoCadastroDeUmCredenciado: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="habilitarAlertasNoCadastroDeUmCredenciado">
                                            No cadastro de um novo credenciado via formulário online.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="habilitarAlertasNaSolicitacaoDoVeiculo" checked={this.state.habilitarAlertasNaSolicitacaoDoVeiculo} onChange={(e) => this.setState({ habilitarAlertasNaSolicitacaoDoVeiculo: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="habilitarAlertasNaSolicitacaoDoVeiculo">
                                            Na solicitação de um veículo pelo motorista.
                                        </label>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" id="habilitarAlertasNoRecebimentoDeMensagensNoWhatsApp" checked={this.state.habilitarAlertasNoRecebimentoDeMensagensNoWhatsApp} onChange={(e) => this.setState({ habilitarAlertasNoRecebimentoDeMensagensNoWhatsApp: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="habilitarAlertasNoRecebimentoDeMensagensNoWhatsApp">
                                            No recebimento de mensagens no WhatsApp de cobranças.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog maxWidth={"xs"} onClose={this.closeMaximumUserModal} fullWidth open={this.state.showMaximumUsersModal}>
                        <MuiDialogContent>
                            <div className='row text-center mt-3'>
                                <h5>{this.state.messageMaximumUsersModal}</h5>
                                {
                                    this.state.highlightPlan &&
                                    <div className='col-lg-12 mt-3'>
                                        <ItemPlan key={this.state.highlightPlan.id} plan={this.state.highlightPlan} onSelected={this.selectPlan} />
                                    </div>
                                }
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className="btn-group m-3">
                                <button onClick={this.closeMaximumUserModal} className="btn btn-secondary">Fechar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                    <ListarUsuarios onEdit={this.onEditUser} clienteId={this.state.clienteId} />
                </NavBar >
            </>
        )
    }
}

class ListarUsuarios extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            usuarios: [],
            isLoading: true,
            clienteId: props.clienteId,
            userLogs: []
        }
        this.loading()
    }

    loading = async () => {
        this.setState({ isLoading: true })
        const users = await getAllUsersActiveByClientId({ clienteId: this.props.clienteId })
        this.setState({ usuarios: users })
        this.setState({ isLoading: false })
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onRemove = async (item) => {
        if (this.state.usuarios.length === 1) {
            toastWarning("Não é possível remover o único usuário de login.");
        } else {
            var confirm = window.confirm('Deseja mesmo remover o usuário ' + item.nome + '?\n\nEste e-mail não poderá mais ser utilizado.');
            if (confirm) {
                firebase.firestore().collection('usuarios').doc(item.id).update({
                    codigo: null,
                    status: "INACTIVE"
                })
                this.loading()
                await saveLastUpdate()
            }
        }
    }

    onResetPassword = async (item) => {
        var confirm = window.confirm('Deseja mesmo enviar um e-mail para o usuário ' + item.nome + ' para resetar a senha?');
        if (confirm) {
            await firebase.auth().sendPasswordResetEmail(item.email)
            toastSuccess("Email enviado com sucesso!")
            this.loading()
        }
    }

    onHistory = async (item) => {
        this.setState({ isOpenModalHistory: true })
        this.setState({ isOpenModalHistoryLoading: true })
        this.setState({ currentUser: item })
        const userLogs = await getAllLogsByUserId({ usuarioId: item.id })
        this.setState({ userLogs: userLogs })
        this.setState({ isOpenModalHistoryLoading: false })
    }

    render() {
        return (
            <div className="card my-3">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Listagem de usuários cadastrados
                </div>
                {
                    this.state.usuarios.length === 0 && !this.state.isLoading ?
                        <EmptyContent text='Nenhum usuário cadastrado' />
                        :
                        !this.state.isLoading ?
                            <div className="row">
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td scope="col"><strong>Código</strong></td>
                                                <td scope="col"><strong>Nome</strong></td>
                                                <td scope="col" className="d-none d-sm-table-cell"><strong>E-mail</strong></td>
                                                <td scope="col" className="d-none d-sm-table-cell" align='center'><strong>Setores</strong></td>
                                                <td scope="col" className="d-none d-sm-table-cell" align='center'><strong>Convênios</strong></td>
                                                <td scope="col" className="d-none d-sm-table-cell" align='center'>
                                                    <Tooltip title="Última vez que o usuário acessou o aplicativo." placement="top">
                                                        <strong>
                                                            Acesso
                                                            <i role='button' className="fas fa-info-circle mx-2" />
                                                        </strong>
                                                    </Tooltip>
                                                </td>
                                                <td scope="col" className="d-none d-sm-table-cell" align='center'>
                                                    <Tooltip title="Última vez que o usuário atualizou as tabelas." placement="top">
                                                        <strong>
                                                            Atualização
                                                            <i role='button' className="fas fa-info-circle mx-2" />
                                                        </strong>
                                                    </Tooltip>
                                                </td>
                                                <td scope="col"></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.usuarios.map(usuario =>
                                                    <tr>
                                                        <td>
                                                            <Tooltip title={usuario.id} placement="top">
                                                                <span>{usuario.codigo === undefined ? "Não informado" : parseInt(usuario.codigo) ? usuario.codigo : "Secreto"}</span>
                                                            </Tooltip>
                                                        </td>
                                                        <td>{usuario.nome}</td>
                                                        <td className="d-none d-sm-table-cell">{usuario.email}</td>
                                                        <td className="d-none d-sm-table-cell" align='center'>{(usuario.setores ?? []).length === 0 ? "Todos" : usuario.setores.map(e => e.label).join(", ")}</td>
                                                        <td className="d-none d-sm-table-cell" align='center'>{(usuario.relationships ?? []).length === 0 ? "Todos" : usuario.relationships.map(e => e.label).join(", ")}</td>
                                                        <td className="d-none d-sm-table-cell" align='center' width={180}>
                                                            {
                                                                usuario.lastAccess !== undefined ?

                                                                    <span className="badge text-bg-success mx-2 w-100">
                                                                        {
                                                                            usuario.version !== "" &&
                                                                            <span> {usuario.version} | </span>
                                                                        }
                                                                        {usuario.flavor?.includes("pagSeguro") && ('PagSeguro')}
                                                                        {usuario.flavor?.includes("cielo") && ('Cielo')}
                                                                        {usuario.flavor?.includes("getnet") && ('GetNet')}
                                                                        {usuario.flavor?.includes("Stone") && ('Stone')}
                                                                        {usuario.flavor?.includes("safra") && ('Safra')}
                                                                        {usuario.flavor?.includes("store") && ('Google Play')}
                                                                        {usuario.flavor?.includes("rede") && ('Rede')}
                                                                        {usuario.flavor?.includes("site") && 'Site'}
                                                                        <span> | </span>
                                                                        <span>{Moment(usuario.lastAccess.toDate()).format(" DD/MM/YYYY [às] HH:mm A")}</span>
                                                                    </span> :
                                                                    <span className="badge text-bg-secondary mx-2 w-100">Nunca</span>
                                                            }
                                                        </td>
                                                        <td className="d-none d-sm-table-cell" align='center' width={180}>
                                                            {
                                                                usuario.lastUpdateTables ?
                                                                    <span className="badge text-bg-success mx-2 w-100">
                                                                        <span>{Moment(usuario.lastUpdateTables.toDate()).format("DD/MM/YYYY [às] HH:mm A")}</span>
                                                                    </span> :
                                                                    <span className="badge text-bg-secondary mx-2 w-100">Nunca</span>
                                                            }
                                                        </td>
                                                        <td width='10'>
                                                            <div className='btn-group'>
                                                                <Tooltip title="Histórico de uso" placement="top">
                                                                    <button className='btn btn-warning text-white' onClick={(e) => this.onHistory(usuario)}>
                                                                        <QueryStatsIcon />
                                                                    </button>
                                                                </Tooltip>
                                                                <button type="button" onClick={() => { this.onResetPassword(usuario) }} className="btn btn-warning text-white">
                                                                    <i className="fas fa-key" />
                                                                </button>
                                                                {
                                                                    this.props.onEdit &&
                                                                    <button type="button" onClick={() => { this.onEdit(usuario) }} className="btn btn-secondary">
                                                                        <i className="fas fa-edit" />
                                                                    </button>
                                                                }
                                                                <button type="button" onClick={() => { this.onRemove(usuario) }} className="btn btn-danger">
                                                                    <i className="fas fa-trash" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                    <Dialog onClose={(e) => this.setState({ isOpenModalHistory: false })} fullWidth maxWidth={"md"} open={this.state.isOpenModalHistory}>
                                        {
                                            this.state.isOpenModalHistoryLoading === true ? <Loading /> : <>
                                                <MuiDialogTitle className='text-center'>
                                                    Histórico de ações
                                                </MuiDialogTitle>
                                                <MuiDialogContent>
                                                    {
                                                        !this.state.userLogs.length === 0 ? <EmptyContent text="Nenhuma histórico de mudanças para esta usuário." /> :
                                                            <table className="table-striped table-responsive table">
                                                                <thead>
                                                                    <tr>
                                                                        <td><strong>Módulo</strong></td>
                                                                        <td><strong>Ação</strong></td>
                                                                        <td align='right'><strong>Data</strong></td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.userLogs.map((item, index) =>
                                                                        <tr key={index}>
                                                                            <td>{item.module}</td>
                                                                            <td>{item.message}</td>
                                                                            <td align='right'>{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss")}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                    }
                                                </MuiDialogContent>
                                                <MuiDialogActions>
                                                    <div className='btn-group m-3'>
                                                        <button onClick={(e) => this.setState({ isOpenModalHistory: false })} className='btn btn-secondary'>Fechar</button>
                                                    </div>
                                                </MuiDialogActions>
                                            </>
                                        }
                                    </Dialog>
                                </div>
                            </div>
                            :
                            <Loading />
                }
            </div>
        )
    }
}

export { CriarUsuario, ListarUsuarios }