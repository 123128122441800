import { getCliente } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { docsToItem, docsToItems } from '../transform.docs';
import { addDoc, deleteDoc } from './collectionBaseWorker';

export const getAllAccreditedTypes = async () => {
    var results = await firebase.firestore().collection(Collection.ACCREDITEDS_TYPES).where("clienteId", '==', getCliente().id).get()
    return docsToItems(results).sort((a, b) => a.name.localeCompare(b.name))
}

export const getAccreditedTypeByName = async (name) => {
    var results = await firebase.firestore().collection(Collection.ACCREDITEDS_TYPES).where("clienteId", '==', getCliente().id).where("name", "==", name).get()
    return docsToItem(results)
}

export const removeAccreditedType = async (id) => {
    await deleteDoc({ collection: Collection.ACCREDITEDS_TYPES, id: id })
}

export const saveAccreditedType = async (name) => {
    const data = {
        name: name,
        isDeleted: false,
    }
    await addDoc({ collection: Collection.ACCREDITEDS_TYPES, data: data })
}