import { getRevenda } from "../../config/auth"
import { Collection } from "../../shared/constants"
import Moment from 'moment';
import firebase from '../../config/firebase';
import { docsToItem, docsToItems } from "../transform.docs";
import { addDoc, deleteDoc, getAllDocsByRevendaId, getDocById, updateDoc } from "./collectionBaseWorker";
import { ca } from "date-fns/locale";

export const addMarketingBanner = async ({ data }) => {
    const doc = await addDoc({ collection: Collection.MARKETING_BANNER, data: data })
    return doc.id
}

export const getAllBannersByRevendaId = async ({ revendaId }) => {
    return await getAllDocsByRevendaId({ collection: Collection.MARKETING_BANNER, revendaId: revendaId })
}

export const getActivesBannersByRevendaId = async ({ revendaId }) => {
    const date = Moment().toDate()
    try {
        const result = await firebase.firestore().collection(Collection.MARKETING_BANNER)
            .where('revendaId', '==', revendaId)
            .get()
        return docsToItems(result).filter(e => Moment(e.startDate.seconds * 1000).isSameOrBefore(date) && Moment(e.endDate.seconds * 1000).isAfter(date))
    } catch (error) {
        return []
    }
}

export const addMarketingBannerTapped = async ({ bannerId, park, user }) => {
    const result = await getDocById({ collection: Collection.MARKETING_BANNER, id: bannerId })
    if (result) {
        result.taps += 1
        if (!result.parks) {
            result.parks = []
        }
        result.parks.push({
            park: park,
            user: user
        })
        await updateDoc({ collection: Collection.MARKETING_BANNER, id: bannerId, data: result })
    }
}

export const deleteMarketingBanner = async ({ id }) => {
    await deleteDoc({ collection: Collection.MARKETING_BANNER, id: id })
}

export const updateMarketingBannerById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.MARKETING_BANNER, id: id, data: data })
}