import firebase from '../../config/firebase';
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario, isAdministrador } from '../../config/auth'
import { addDoc, updateDoc } from './collectionBaseWorker';
import { Collection } from '../../shared/constants';

export const saveLastUpdate = async (estacionamentoId) => {
    const park = {
        id: estacionamentoId ?? getEstacionamento().id,
        clienteId: getCliente().id,
    }
    await saveLastUpdateByPark(park, true)
}

export const saveLastUpdateNoSkip = async () => {
    const park = {
        id: getEstacionamento().id,
        clienteId: getCliente().id,
    }
    await saveLastUpdateByPark(park, false)
}

export const saveLastUpdateByPark = async (park, canSkip) => {
    const estacionamentoId = park.id
    const clienteId = park.clienteId
    const user = isAdministrador() === false ? getUsuario() : { email: "automação@site.com.br", nome: "Automação" }
    const result = await firebase.firestore().collection(Collection.LAST_UPDATE).where('clienteId', '==', clienteId).where('estacionamentoId', '==', estacionamentoId).get()
    if (result.empty) {
        const data = {
            clienteId: clienteId,
            estacionamentoId: estacionamentoId,
            revendaId: getRevendaIdByPark(),
            park: park,
            lastUpdate: new Date(),
            canSkip: canSkip,
            alterBy: {
                email: user.email,
                name: user.nome
            }
        }
        await addDoc({ collection: Collection.LAST_UPDATE, data: data })
    } else {
        const data = {
            lastUpdate: new Date(),
            park: park,
            deviceIdentifier: null,
            canSkip: canSkip,
            alterBy: {
                email: user.email,
                name: user.nome
            }
        }
        await updateDoc({ collection: Collection.LAST_UPDATE, id: result.docs[0].id, data: data })
    }
}