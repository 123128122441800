import React from 'react';
import './index.css';
import 'firebase/auth';
import { fetchRevendaByHost } from '../../../shared/service/revendaService';
import { getURLFromURI } from '../../../store/filesWorker';

class NotaFiscalMunicipiosHomologados extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            logoAdministrativo: ""
        }
        this.loadRevenda()
    }

    loadRevenda = async () => {
        const revenda = await fetchRevendaByHost()
        if (revenda.logoAdministrativo) {
            const logoAdministrativo = await getURLFromURI(revenda.logoAdministrativo)
            this.setState({ logoAdministrativo: logoAdministrativo })
        }
        this.setState({ revenda: revenda })
        this.setState({ isPagginLoading: false })
    }

    render() {
        return (
            <div className='row'>
                <div className='col-lg-6'>
                    <div className="image-container my-3">
                        <img height='50' src={this.state.logoAdministrativo} />
                    </div>
                    <div className="login-content">
                        <div className='row' style={{ textAlign: "justify" }}>
                            <h3 style={{ color: this.state.revenda?.siteCor, textAlign: "center" }}><strong>Municípios homologados - Nota Fiscal de Serviço (NFS-e)</strong></h3> <p />
                            <h6>
                                São mais de 2.000 municípios homologados
                            </h6>
                        </div>
                        <div className='row'>
                            <div className='table-responsive'>
                                <table className="table table-striped">
                                    <thead>
                                        <th>Cidade</th>
                                        <th>Estado</th>
                                        <th>Autenticação</th>
                                    </thead>
                                    <tr><td>Epitaciolândia	</td><td> AC</td><td>Certificado A1</td></tr>
                                    <tr><td>Mâncio Lima	</td><td> AC</td><td>Certificado A1</td></tr>
                                    <tr><td>Rodrigues Alves	</td><td> AC</td><td>Certificado A1</td></tr>
                                    <tr><td>Senador Guiomard	</td><td> AC</td><td>Certificado A1</td></tr>
                                    <tr><td>Tarauacá	</td><td> AC</td><td>Certificado A1</td></tr>
                                    <tr><td>Cruzeiro do Sul	</td><td> AC</td><td>Certificado A1</td></tr>
                                    <tr><td>Arapiraca	</td><td> AL</td><td>Certificado A1</td></tr>
                                    <tr><td>Delmiro Gouveia	</td><td> AL</td><td>Certificado A1</td></tr>
                                    <tr><td>Maceió	</td><td> AL</td><td>Certificado A1</td></tr>
                                    <tr><td>Marechal Deodoro	</td><td> AL</td><td>Certificado A1</td></tr>
                                    <tr><td>Palmeira dos Índios	</td><td> AL</td><td>Certificado A1</td></tr>
                                    <tr><td>Penedo	</td><td> AL</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto de Pedras	</td><td> AL</td><td>Certificado A1</td></tr>
                                    <tr><td>Santana do Ipanema	</td><td> AL</td><td>Certificado A1</td></tr>
                                    <tr><td>Japaratinga	</td><td> AL</td><td>Certificado A1</td></tr>
                                    <tr><td>Barra de São Miguel	</td><td> AL</td><td>Logine senha</td></tr>
                                    <tr><td>Quebrangulo	</td><td> AL</td><td>Certificado A1</td></tr>
                                    <tr><td>Itacoatiara	</td><td> AM</td><td>Certificado A1</td></tr>
                                    <tr><td>Manacapuru	</td><td> AM</td><td>Certificado A1</td></tr>
                                    <tr><td>Manaus	</td><td> AM</td><td>Certificado A1</td></tr>
                                    <tr><td>Tefé	</td><td> AM</td><td>Certificado A1</td></tr>
                                    <tr><td>Apuí	</td><td> AM</td><td>Certificado A1</td></tr>
                                    <tr><td>Macapá	</td><td> AP</td><td>Certificado A1</td></tr>
                                    <tr><td>Oiapoque	</td><td> AP</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Grande	</td><td> AP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santana	</td><td> AP</td><td>Certificado A1</td></tr>
                                    <tr><td>Alagoinhas	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Alcobaça	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Amargosa	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Andorinha	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Aurelino Leal	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Baixa Grande	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Barra	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Barreiras	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Barrocas	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Jesus da Lapa	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Brumado	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Caculé	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Camacan	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Formoso	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Canavieiras	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Candeias	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Canudos	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Capim Grosso	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Casa Nova	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Castro Alves	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Catu	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Conceição do Coité	</td><td> BA</td><td>Logine senha</td></tr>
                                    <tr><td>Conceição do Jacuípe	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Cruz das Almas	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Dias d´Ávila	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Dom Basílio	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Entre Rios	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Esplanada	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Euclides da Cunha	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Feira de Santana	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Filadélfia	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Gentio do Ouro	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Governador Mangabeira	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Guanambi	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibotirama	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipiaú	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Irará	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Irecê	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itabela	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaberaba	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itabuna	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itacaré	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaeté	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itagi	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itamaraju	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itanhém	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapetinga	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itiúba	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Jacobina	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaguaquara	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaguarari	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Jequié	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Juazeiro	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Lapão	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Lençóis Livramento de Nossa Senhora</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Luís Eduardo Magalhães	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Mairi	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Maracás	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Mata de São João	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Mirangaba	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Mucuri	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Nordestina	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Soure	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Viçosa	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Paulo Afonso	</td><td> BA</td><td>Logine senha</td></tr>
                                    <tr><td>Pindaí	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Pindobaçu	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Pintadas	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Piritiba	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Pojuca	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Seguro	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Ruy Barbosa	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Salinas da Margarida	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Salvador	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Cruz Cabrália	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Antônio de Jesus	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Estêvão	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>São Desidério	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>São Félix do Coribe	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>São Sebastião do Passé	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Sapeaçu	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Sátiro Dias	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Saúde	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Senhor do Bonfim	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Serrinha	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Simões Filho	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Teixeira de Freitas	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Uauá	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Umburanas	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Utinga	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Valença	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Várzea da Roça	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Várzea do Poço	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Vera Cruz	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Vitória da Conquista	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Wenceslau Guimarães	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Soure	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Cruz das Almas	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Filadélfia	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Wenceslau Guimarães	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Wenceslau Guimarães	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Maragogipe	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaparica	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>São Gonçalo dos Campos	</td><td> BA</td><td>Certificado A1</td></tr>
                                    <tr><td>Aquiraz	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Aracati	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Acaraú	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Baturité	</td><td> CE</td><td>Logine senha</td></tr>
                                    <tr><td>Beberibe	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Camocim	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Canindé	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Caucaia	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Crateús	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Crato	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Cruz	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Eusébio	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Fortaleza	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaramiranga	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Iguatu	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapipoca	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Jijoca de Jericoacoara	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Juazeiro do Norte	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Limoeiro do Norte	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Maracanaú	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Maranguape	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Pacajus	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Quixadá	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Sobral	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Tianguá	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Trairi	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Acaraú	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Acaraú	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Maracanaú	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Acaraú	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Maracanaú	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Cascavel	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipueiras	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Quixeramobim	</td><td> CE</td><td>Certificado A1</td></tr>
                                    <tr><td>Brasília	</td><td> DF</td><td>Certificado A1</td></tr>
                                    <tr><td>Brasília de Minas	</td><td> DF</td><td>Certificado A1</td></tr>
                                    <tr><td>Afonso Cláudio	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Água Doce do Norte	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Alfredo Chaves	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Anchieta	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Aracruz	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Baixo Guandu	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Barra de São Francisco	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Bom Jesus do Norte	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Brejetuba	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Cachoeiro de Itapemirim	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Cariacica	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Castelo	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Colatina	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Ecoporanga	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Fundão	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaçuí	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Guarapari	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibiraçu	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Iúna	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>João Neiva	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Linhares	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Mantenópolis	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Marataízes	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Teresa	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>São Mateus	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>São Roque do Canaã	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Serra	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Vargem Alta	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Venda Nova do Imigrante	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Viana	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Vila Velha	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Vitória	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Maria de Jetibá	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Domingos Martins	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Iconha	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Marechal Floriano	</td><td> ES</td><td>Certificado A1</td></tr>
                                    <tr><td>Presidente Kennedy	</td><td> ES</td><td>Logine senha</td></tr>
                                    <tr><td>Abadiânia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Acreúna	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Águas Lindas de Goiás	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Anápolis	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Anicuns	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Aparecida de Goiânia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Aparecida do Rio Doce	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Aporé	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Bela Vista de Goiás	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Jardim de Goiás	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Jesus de Goiás	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Cabeceiras	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Caçu	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Caiapônia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Caldas Novas	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Campinorte	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Catalão	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Ceres	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Chapadão do Céu	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Cristalina	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Cromínia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Edealina	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Edéia	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Formosa	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Goianápolis	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Goianésia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Goiânia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Goianira	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Goiatuba	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Hidrolândia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Inhumas	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipameri	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Iporá	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaberaí	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Itajá	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Itarumã	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Itumbiara	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Jaraguá	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Jataí	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Lagoa Santa	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Luziânia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Mineiros	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Montividiu	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Morrinhos	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Nerópolis	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Nova Veneza	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Novo Gama	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Padre Bernardo	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Paranaiguara	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Piracanjuba	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Pirenópolis	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Planaltina	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Pontalina	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Porangatu	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Quirinópolis	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Rialma	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Quente	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Verde	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Sanclerlândia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Helena de Goiás	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Rita do Araguaia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Senador Canedo	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Silvania	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Trindade	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Uruaçu	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Valparaíso de Goiás	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Vianópolis	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Palmeiras de Goiás	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapuranga	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Adelândia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Água Limpa	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Alexânia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Araçu	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Aragarças	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Aragoiânia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Bonfinópolis	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Brazabrantes	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Cachoeira Alta	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Campestre de Goiás	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Cezarina	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Corumbá de Goiás	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Crixás	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Estrela do Norte	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Flores de Goiás	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Terezópolis de Goiás	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Heitoraí	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Itapirapuã	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Marzagão	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Monte Alegre de Goiás	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Mozarlândia	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Nova Crixás	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Ouro Verde de Goiás	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Piranhas	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Pires do Rio	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Rubiataba	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>São Miguel do Araguaia	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Goiás	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Jandaia	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Mambai	</td><td> GO</td><td>Certificado A1</td></tr>
                                    <tr><td>Mundo Novo	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Posse	</td><td> GO</td><td>Logine senha</td></tr>
                                    <tr><td>Bacabal	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Balsas	</td><td> MA</td><td>Logine senha</td></tr>
                                    <tr><td>Codó	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Estreito	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapecuru Mirim	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Paço do Lumiar	</td><td> MA</td><td>Logine senha</td></tr>
                                    <tr><td>Pedreiras	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Presidente Dutra	</td><td> MA</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Inês	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Luzia	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>São José de Ribamar	</td><td> MA</td><td>Logine senha</td></tr>
                                    <tr><td>São Luís	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Timon	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Açailândia	</td><td> MA</td><td>Logine senha</td></tr>
                                    <tr><td>Barreirinhas	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Brejo	</td><td> MA</td><td>Logine senha</td></tr>
                                    <tr><td>Buriti	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Chapadinha	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Coelho Neto	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Coroatá	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Cururupu	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Davinópolis	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itinga do Maranhão	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Lago da Pedra	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Pinheiro	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Franco	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Presidente Vargas	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>São Pedro da Água Branca	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Sítio Novo	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Tutóia	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Viana	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Vitória do Mearim	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Zé Doca	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Caxias	</td><td> MA</td><td>Logine senha</td></tr>
                                    <tr><td>Colinas	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Carolina	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Imperatriz	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Arari	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Helena	</td><td> MA</td><td>Certificado A1</td></tr>
                                    <tr><td>Abaeté	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Além Paraíba	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Alfenas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Alpinópolis	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Alterosa	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Andradas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Araguari	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Araxá	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Arceburgo	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Arcos	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Bambuí	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Bandeira do Sul	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Barão de Cocais	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Barbacena	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Belo Horizonte	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Belo Oriente	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Betim	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Boa Esperança	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Bocaina de Minas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Despacho	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Botelhos	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Brumadinho	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Buritis	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Caeté	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Camanducaia	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Cambuí	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Belo	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Campos Altos	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Capinópolis	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Carangola	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Caratinga	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Carmo da Cachoeira	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Carmo do Cajuru	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Carmo do Paranaíba	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Carmo do Rio Claro	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Cássia	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Cataguases	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Cláudio	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Conceição das Alagoas	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Confins	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Congonhas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Conselheiro Lafaiete	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Contagem	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Coqueiral	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Corinto	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Coromandel	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Coronel Fabriciano	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Córrego Fundo	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Curvelo	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Delta	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Divinópolis	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Dores do Indaiá	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Esmeraldas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Espera Feliz	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Estiva	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Extrema	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Formiga	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Frutal	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Governador Valadares	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Guanhães	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Guaranésia	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaxupé	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibiá	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Ibirité	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Igarapé	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Igaratinga	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipatinga	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Itabira	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Itabirito	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Itajubá	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapeva	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Itatiaiuçu	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaú de Minas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaúna	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Ituiutaba	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Iturama	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Jaboticatubas	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Jacutinga	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Janaúba	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Januária	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>João Monlevade	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>João Pinheiro	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Juiz de Fora	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Lagoa da Prata	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Lavras	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Leopoldina	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Luz	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Machado	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Manhuaçu	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Mariana	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Mário Campos	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Matias Barbosa	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Matozinhos	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Miraí	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Monte Alegre de Minas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Monte Carmelo	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Monte Santo de Minas	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Monte Sião	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Montes Claros	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Muriaé	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Nepomuceno	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Lima	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Resende	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Serrana	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Oliveira	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Ouro Branco	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Ouro Fino	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Ouro Preto	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Pará de Minas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Paracatu	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Paraguaçu	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Passos	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Patos de Minas	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Patrocínio	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Pedra Dourada	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Pedro Leopoldo	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Pequi	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Pirajuba	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Pirapora	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Piumhi	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Planura	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Poço Fundo	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Poços de Caldas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Pompéu	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Ponte Nova	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Porteirinha	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Pouso Alegre	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Prata	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Raul Soares	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Ribeirão das Neves	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Piracicaba	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Sabará	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Sabinópolis	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Sacramento	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Salinas	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Bárbara	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Luzia	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Rita do Sapucaí	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Vitória	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Santana do Paraíso	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Santana do Paraíso	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Antônio do Monte	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Santos Dumont	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São Gonçalo do Pará	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São Gotardo	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São João Evangelista	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São Joaquim de Bicas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São José da Lapa	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São Lourenço	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São Sebastião do Oeste	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São Sebastião do Paraíso	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Sapucaí-Mirim	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Sarzedo	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Serrania	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Sete Lagoas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Taiobeiras	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Tapira	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Teófilo Otoni	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Timóteo	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Três Corações	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Três Marias	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Três Pontas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Tupaciguara	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Ubá	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Uberaba	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Uberlândia	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Unaí	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Varginha	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Várzea da Palma	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Vazante	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Veríssimo	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Vespasiano	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Viçosa	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Piracema	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Piracema	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Piracema	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Piumhi	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Rita de Caldas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Aguanil	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Aimorés	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Alvinópolis	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Alvorada de Minas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Angelândia	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Araçuaí	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Areado	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Argirita	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Augusto de Lima	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Berizal	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Bocaiúva	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Jesus do Amparo	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Repouso	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Bonito de Minas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Borda da Mata	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Botumirim	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Brazópolis	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Bueno Brandão	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Cachoeira de Pajeú	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Cambuquira	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Capelinha	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Capitão Enéas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Carneirinho	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Caxambu	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Cedro do Abaeté	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Chalé	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Chapada Gaúcha	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Conceição das Pedras	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Córrego do Bom Jesus	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Dom Joaquim	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Elói Mendes	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Francisco Sá	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Fruta de Leite	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Glaucilândia	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibertioga	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Iguatama	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Indaiabira	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipaba	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Itamarandiba	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Itanhandu	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Itueta	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaíba	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Japonvar	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Jenipapo de Minas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>José Gonçalves de Minas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Ladainha	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Leandro Ferreira	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Leme do Prado	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Lima Duarte	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Limeira do Oeste	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Mar de Espanha	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Maria da Fé	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Mato Verde	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Montezuma	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Morada Nova de Minas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Morro da Garça	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Nanuque	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Porteirinha	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova União	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Paula Cândido	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Pedra Azul	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Pedras de Maria da Cruz	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Piranguinho	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Pitangui	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Pratápolis	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Manso	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Paranaíba	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Cruz de Salinas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Juliana	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Maria de Itabira	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>   Santo Antônio do Rio Abaixo</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São Domingos das Dores	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São Francisco de Sales	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São Gonçalo do Rio Abaixo	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São João Batista do Glória	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>São João da Lagoa	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Senador Cortes	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Senador Firmino	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Silvianópolis	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Taquaraçu de Minas	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Turmalina	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Urucânia	</td><td> MG</td><td>Logine senha</td></tr>
                                    <tr><td>Virgínia	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Lambari	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Divino	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Conquista	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Lagoa Santa	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Itinga	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Manga	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Lontra	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Jacinto	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Água Boa	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Dionísio	</td><td> MG</td><td>Certificado A1</td></tr>
                                    <tr><td>Amambai	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Anastacio	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Aparecida do Taboado	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Aquidauana	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Aral Moreira	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Batayporã	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Bonito	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Caarapó	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Grande	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Chapadão do Sul	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Coronel Sapucaia	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Corumbá	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Costa Rica	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Deodápolis	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Dois Irmãos do Buriti	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Douradina	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Dourados	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Eldorado	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Fátima do Sul	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Iguatemi	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaporã	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaquiraí	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ivinhema	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Juti	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Maracaju	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Miranda	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Mundo Novo	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Naviraí	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Alvorada do Sul	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Andradina	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Novo Horizonte do Sul	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Paranaíba	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ponta Porã	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Murtinho	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ribas do Rio Pardo	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Brilhante	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Verde de Mato Grosso	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Rita do Pardo	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Gabriel do Oeste	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Sidrolândia	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Taquarussu	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Três Lagoas	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Anaurilândia	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Bataguassu	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Sete Quedas	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Jardim	</td><td> MS</td><td>Certificado A1</td></tr>
                                    <tr><td>Água Boa	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Alta Floresta	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Alto Araguaia	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Alto Garças	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Apiacás	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Araputanga	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Arenápolis	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Barra do Garças	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Brasnorte	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Cáceres	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Novo do Parecis	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Verde	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Campos de Júlio	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Chapada dos Guimarães	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Colíder	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Comodoro	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Confresa	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Cotriguaçu	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Cuiabá	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Diamantino	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Guarantã do Norte	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Itanhangá	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Itiquira	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaciara	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Juara	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Juína	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Lambari D'Oeste	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Lucas do Rio Verde	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Matupá	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Mirassol d´Oeste	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Nobres	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Mutum	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Paranaíta	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Paranatinga	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Peixoto de Azevedo	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Pontes e Lacerda	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Primavera do Leste	</td><td> MT</td><td>Logine senha</td></tr>
                                    <tr><td>Querência	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Ribeirão Cascalheira	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Rondonópolis	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>São Félix do Araguaia	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>São José dosM Quatro arcos</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Sapezal	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Sinop	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Sorriso	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Tangará da Serra	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Tapurah	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Vale de São Domingos	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Várzea Grande	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Vila Bela da Santíssima Trindade</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Vila Rica	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Primavera do Leste	</td><td> MT</td><td>Logine senha</td></tr>
                                    <tr><td>Aripuanã	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaúba	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Canabrava do Norte	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Carlinda	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Cláudia	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Juscimeira	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Guarita	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Marilândia	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Nazaré	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Pedra Preta	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Planalto da Serra	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Poxoréu	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Rita do Trivelato	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Afonso	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>São José do Rio Claro	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>São Pedro da Cipa	</td><td> MT</td><td>Certificado A1</td></tr>
                                    <tr><td>Altamira	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Ananindeua	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Barcarena	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Belém	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Benevides	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Breu Branco	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Cametá	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Canaã dos Carajás	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Conceição do Araguaia	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Dom Eliseu	</td><td> PA</td><td>Logine senha</td></tr>
                                    <tr><td>Itaituba	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Itupiranga	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Juruti	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Mãe do Rio	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Marituba	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Moju	</td><td> PA</td><td>Logine senha</td></tr>
                                    <tr><td>Novo Progresso	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Paragominas	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Parauapebas	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Redenção	</td><td> PA</td><td>Logine senha</td></tr>
                                    <tr><td>Rio Maria	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Rondon do Pará	</td><td> PA</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Isabel do Pará	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Santarém	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>São Domingos do Araguaia	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Tailândia	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Tomé-Açu	</td><td> PA</td><td>Logine senha</td></tr>
                                    <tr><td>Tucumã	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Tucuruí	</td><td> PA</td><td>Logine senha</td></tr>
                                    <tr><td>Xinguara	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Alenquer	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Anapu	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Belterra	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Capitão Poço	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Cumaru do Norte	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Curionópolis	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Eldorado do Carajás	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipixuna do Pará	</td><td> PA</td><td>Logine senha</td></tr>
                                    <tr><td>Irituia	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Medicilândia	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Mocajuba	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Ipixuna	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Ourilândia do Norte	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Placas	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Ponta de Pedras	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto de Moz	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Salinópolis	</td><td> PA</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Luzia do Pará	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Senador José Porfírio	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Uruará	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Bragança	</td><td> PA</td><td>Logine senha</td></tr>
                                    <tr><td>Capanema	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Soure	</td><td> PA</td><td>Certificado A1</td></tr>
                                    <tr><td>Cabedelo	</td><td> PB</td><td>Certificado A1</td></tr>
                                    <tr><td>Cajazeiras	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Campina Grande	</td><td> PB</td><td>Certificado A1</td></tr>
                                    <tr><td>Esperança	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Guarabira	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Mamanguape	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Massaranduba	</td><td> PB</td><td>Certificado A1</td></tr>
                                    <tr><td>Patos	</td><td> PB</td><td>Certificado A1</td></tr>
                                    <tr><td>Solânea	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Sousa	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Alhandra	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Araçagi	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Areia de Baraúnas	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Baía da Traição	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Barra de Santa Rosa	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Bernardino Batista	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Borborema	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Caaporã	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Cachoeira dos Índios	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Cacimba de Dentro	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Cajazeirinhas	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Carrapateira	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Casserengue	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Condado	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Conde	</td><td> PB</td><td>Certificado A1</td></tr>
                                    <tr><td>Coremas	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Coxixola	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Cubati	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Cuité	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Cuité de Mamanguape	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Cuitegi	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Curral de Cima	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Damião	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Dona Inês	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Duas Estradas	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Frei Martinho	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Gurjão	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Itapororoca	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Itatuba	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Lastro	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Logradouro	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Marcação	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Mataraca	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Monte Horebe	</td><td> PB</td><td>Certificado A1</td></tr>
                                    <tr><td>Mulungu	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Nova Floresta	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Pilõezinhos	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Pirpirituba	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Pombal	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Princesa Isabel	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Riachão do Bacamarte	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Riacho dos Cavalos	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Rio Tinto	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Salgadinho	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Salgado de São Félix	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>São Domingos do Cariri	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>São José da Lagoa Tapada	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>São José de Piranhas	</td><td> PB</td><td>Certificado A1</td></tr>
                                    <tr><td>São Vicente do Seridó	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Serraria	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Tacima	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Uiraúna	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Vista Serrana	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Arara	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Belém	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Bom Jesus	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Conceição	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Ingá	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Jericó	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Lagoa	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Mari	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Picuí	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Pilar	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Cecília	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Cruz	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Luzia	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Santo André	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>São Domingos	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>São Francisco	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Sertãozinho	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Soledade	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Triunfo	</td><td> PB</td><td>Logine senha</td></tr>
                                    <tr><td>Abreu e Lima	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Arcoverde	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Belo Jardim	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Bezerros	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Cabo de Santo Agostinho	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Camaragibe	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Caruaru	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Catende	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Garanhuns	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Goiana	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Gravatá	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Igarassu	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipojuca	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Itambé	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaboatão dos Guararapes	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Lajedo	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Olinda	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Ouricuri	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Palmares	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Paulista	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Pesqueira	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Petrolina	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Recife	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Salgueiro	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Cruz do Capibaribe	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>São Bento do Una	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>São Lourenço da Mata	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Timbaúba	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Vitória de Santo Antão	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>São Bento do Una	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Brejo da Madre de Deus	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Condado	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Vertente do Lério	</td><td> PE</td><td>Logine senha</td></tr>
                                    <tr><td>Xexéu	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Cabrobó	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Trindade	</td><td> PE</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Jesus	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Cajueiro da Praia	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Floriano	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Parnaíba	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Picos	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Teresina	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Valença do Piauí	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Alagoinha do Piauí	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Batalha	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Demerval Lobão	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Francisco Macedo	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Lagoa do Barro do Piauí	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Luís Correia	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Padre Marcos	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>União	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Altos	</td><td> PI</td><td>Certificado A1</td></tr>
                                    <tr><td>Adrianópolis	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Agudos do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Almirante Tamandaré	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Alto Paraná	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Altônia	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ampére	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Andirá	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Apucarana	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Arapongas	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Arapoti	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Araruna	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Araucária	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Assaí	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Assis Chateaubriand	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Astorga	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Balsa Nova	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Bandeirantes	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Barracão	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Bela Vista da Caroba	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Bela Vista do Paraíso	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Bituruna	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Jesus do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Cafelândia	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Cambará	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Campina da Lagoa	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Campina Grande do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Largo	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Magro	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Mourão	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Candói	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Capanema	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Capanema	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Capitão Leônidas Marques	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Carambeí	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Cascavel	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Castro	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Cerro Azul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Chopinzinho	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Cianorte	</td><td> PR</td><td>Logine senha</td></tr>
                                    <tr><td>Cidade Gaúcha	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Clevelândia	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Colombo	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Colorado	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Corbélia	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Cornélio Procópio	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Coronel Vivida	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Cruz Machado	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Cruzeiro do Oeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Curitiba	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Dois Vizinhos	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Enéas Marques	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Engenheiro Beltrão	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Fazenda Rio Grande	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Fernandes Pinheiro	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Figueira	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Flor da Serra do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Francisco Beltrão	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>General Carneiro	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Goioerê	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaíra	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Guamiranga	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaraniaçu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Guarapuava	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaraqueçaba	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaratuba	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibaiti	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibiporã	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Icaraíma	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Imbituva	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipiranga	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Iporã	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Iracema do Oeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Irati	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Iretama	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaipulândia	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Itambé	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ivaí	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ivaiporã	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ivaté	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Jacarezinho	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaguapitã	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaguariaíva	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Jandaia do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Juranda	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Lapa	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Laranjeiras do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Lindoeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Loanda	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Londrina	</td><td> PR</td><td>Logine senha</td></tr>
                                    <tr><td>Mallet	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Mamborê	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Manoel Ribas	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Mandaguaçu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Mandaguari	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Mandirituba	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Mangueirinha	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Marechal Cândido Rondon	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Marialva	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Mariluz	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Maringá	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Marmeleiro	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Matelândia	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Matinhos	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Mauá da Serra	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Medianeira	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Mercedes	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Missal	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Morretes	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Munhoz de Melo	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Aurora	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Londrina	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Santa Rosa	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ortigueira	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ourizona	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Paiçandu	</td><td> PR</td><td>Logine senha</td></tr>
                                    <tr><td>Palmas	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Palmeira	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Palotina	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Paraíso do Norte	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Paranaguá	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Paranavaí	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Pato Bragado	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Pato Branco	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Paula Freitas	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Peabiru	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Pérola d´Oeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Piên	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Pinhais	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Piraí do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Piraquara	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Pitanga	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Planalto	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ponta Grossa	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Pontal do Paraná	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Porecatu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Rico	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Pranchita	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Presidente Castelo Branco	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Prudentópolis	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Quatro Barras	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Quatro Pontes	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Quedas do Iguaçu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Realeza	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Rebouças	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Renascença	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ribeirão Claro	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Azul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Branco do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Branco do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Negro	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Rolândia	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Roncador	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Salgado Filho	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Salto do Lontra	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Helena	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Maria do Oeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Tereza do Oeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Terezinha de Itaipu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Antônio da Platina	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Antônio do Sudoeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São João	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São João do Ivaí	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São Jorge do Ivaí	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São Jorge D'Oeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São Mateus do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São Miguel do Iguaçu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São Pedro do Iguaçu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São Sebastião da Amoreira	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Sarandi	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Sengés	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Siqueira Campos	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Telêmaco Borba	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Tibagi	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Tijucas do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Toledo	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Tomazina	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ubiratã	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Umuarama	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>União da Vitória	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Vera Cruz do Oeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Vitorino	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Wenceslau Braz	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Branco do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Esperança	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Florestópolis	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapejara d´Oeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Abatiá	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Anahy	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Antônio Olinto	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Barra do Jacaré	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Boa Ventura de São Roque	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Sucesso do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo do Tenente	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Cândido de Abreu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Cantagalo	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Céu Azul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Conselheiro Mairinck	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Contenda	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Coronel Domingos Soares	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Cruzeiro do Iguaçu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Curiúva	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Espigão Alto do Iguaçu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Formosa do Oeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Foz do Jordão	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Guapirama	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Inácio Martins	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaperuçu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Japira	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Jataizinho	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Jesuítas	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Joaquim Távora	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Jundiaí do Sul	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Leópolis	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Manfrinópolis	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Esperança do Sudoeste</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Laranjeiras	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Santa Bárbara	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Perobal	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Pinhal de São Bento	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Amazonas	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Barreiro	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Quinta do Sol	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Quitandinha	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ramilândia	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Rancho Alegre D'Oeste	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Reserva do Iguaçu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ribeirão do Pinhal	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Bonito do Iguaçu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Cecília do Pavão	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Lúcia	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Mariana	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São Jorge do Patrocínio	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São José das Palmeiras	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São Pedro do Ivaí	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>São Tomé	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Sapopema	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Saudade do Iguaçu	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Sertanópolis	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Sulina	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Teixeira Soares	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Terra Boa	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Três Barras do Paraná	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Uniflor	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Ventania	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Verê	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Pitangueiras	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Pérola	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Douradina	</td><td> PR</td><td>Certificado A1</td></tr>
                                    <tr><td>Angra dos Reis	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Araruama	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Areal	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Armação dos Buzios	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Arraial do Cabo	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Barra do Piraí	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Barra Mansa	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Belford Roxo	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Cabo Frio	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Cachoeiras de Macacu	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Campos dos Goytacazes	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Cardoso Moreira	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Casimiro de Abreu	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Duque de Caxias	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Engenheiro Paulo de Frontin</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaboraí	</td><td> RJ</td><td>Logine senha</td></tr>
                                    <tr><td>Itaguaí	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Italva	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaperuna	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Itatiaia	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Macaé	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Magé	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Mangaratiba	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Maricá	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Mendes	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Mesquita	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Miguel Pereira	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Nilópolis	</td><td> RJ</td><td>Logine senha</td></tr>
                                    <tr><td>Niterói	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Friburgo	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Iguaçu	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Paraíba do Sul	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Paraty	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Petrópolis	</td><td> RJ</td><td>Logine senha</td></tr>
                                    <tr><td>Pinheiral	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Real	</td><td> RJ</td><td>Logine senha</td></tr>
                                    <tr><td>Queimados	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Resende	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Bonito	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio das Flores	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio das Ostras	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio de Janeiro	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Maria Madalena	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Antônio de Pádua	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>São João da Barra	</td><td> RJ</td><td>Logine senha</td></tr>
                                    <tr><td>São João de Meriti	</td><td> RJ</td><td>Logine senha</td></tr>
                                    <tr><td>São José do Vale do Rio Preto</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>São Pedro da Aldeia	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Sapucaia	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Saquarema	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Seropédica	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Silva Jardim	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Tanguá	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Teresópolis	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Três Rios	</td><td> RJ</td><td>Logine senha</td></tr>
                                    <tr><td>Valença	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Vassouras	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Miracema	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Iguaba Grande	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Laje do Muriaé	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Piraí	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Porciúncula	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Quatis	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Varre-Sai	</td><td> RJ</td><td>Certificado A1</td></tr>
                                    <tr><td>Caicó	</td><td> RN</td><td>Certificado A1</td></tr>
                                    <tr><td>Currais Novos	</td><td> RN</td><td>Certificado A1</td></tr>
                                    <tr><td>Mossoró	</td><td> RN</td><td>Certificado A1</td></tr>
                                    <tr><td>Parelhas	</td><td> RN</td><td>Certificado A1</td></tr>
                                    <tr><td>ParnamirimUP	</td><td> RN</td><td>Logine senha</td></tr>
                                    <tr><td>São Gonçalo do Amarante	</td><td> RN</td><td>Certificado A1</td></tr>
                                    <tr><td>São José de Mipibu	</td><td> RN</td><td>Certificado A1</td></tr>
                                    <tr><td>Tibau do Sul	</td><td> RN</td><td>Certificado A1</td></tr>
                                    <tr><td>Açu	</td><td> RN</td><td>Certificado A1</td></tr>
                                    <tr><td>Luís Gomes	</td><td> RN</td><td>Logine senha</td></tr>
                                    <tr><td>Alta Floresta D´Oeste	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Ariquemes	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Cacoal	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Cerejeiras	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Chupinguaia	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Espigão D´Oeste	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaru	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Ji-Paraná	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Machadinho D´Oeste	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Brasilândia D´Oeste	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Ouro Preto do Oeste	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Rolim de Moura	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>São Francisco do Guaporé	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Urupá	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Vilhena	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Pimenta Bueno	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Alto Paraíso	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Colorado do Oeste	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Corumbiara	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Cujubim	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Guajará-Mirim	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova União	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Presidente Médici	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Vale do Anari	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Vale do Paraíso	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Buritis	</td><td> RO</td><td>Certificado A1</td></tr>
                                    <tr><td>Caracaraí	</td><td> RR</td><td>Certificado A1</td></tr>
                                    <tr><td>Mucajaí	</td><td> RR</td><td>Certificado A1</td></tr>
                                    <tr><td>Boa Vista	</td><td> RR</td><td>Certificado A1</td></tr>
                                    <tr><td>Água Santa	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Agudo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Alegrete	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Alpestre	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Alvorada	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ametista do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Anta Gorda	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Antônio Prado	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Arroio do Meio	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Arroio do Tigre	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Arroio dos Ratos	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Bagé	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Barão	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Barros Cassal	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Bento Gonçalves	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Princípio	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Boqueirão do Leão	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Cacequi	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Cachoeira do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Cachoeirinha	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Caibaté	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Camaquã	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Bom	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Novo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Candelária	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Cândido Godói	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Candiota	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Canela	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Canguçu	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Canoas	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Capão da Canoa	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Carazinho	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Carlos Barbosa	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Caxias do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Cerro Grande do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Cerro Largo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Charqueadas	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Constantina	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Crissiumal	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Cruz Alta	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Cruzeiro do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Dois Irmãos	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Dom Pedrito	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Eldorado do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Encantado	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Erechim	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Estância Velha	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Esteio	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Estrela	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Farroupilha	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Faxinal do Soturno	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Feliz	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Flores da Cunha	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Fontoura Xavier	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Fortaleza dos Valos	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Frederico Westphalen	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Garibaldi	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Getúlio Vargas	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Giruá	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Gramado	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Gravataí	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaíba	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Guapore	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Horizontina	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibiaçá	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibiraiaras	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibirubá	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Igrejinha	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ijuí	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Independência	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipê	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaqui	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ivoti	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaboticaba	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Jacutinga	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaguari	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Júlio de Castilhos	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Lagoa Vermelha	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Lajeado	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Marau	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Montenegro	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Morro Redondo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Não-me-toque	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Nonoai	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Esperança do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Petrópolis	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Prata	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Santa Rita	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Novo Hamburgo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Osório	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Palmeira das Missões	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Panambi	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Parobé	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Passo Fundo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Pedro Osório	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Pelotas	</td><td> RS</td><td>Logine senha</td></tr>
                                    <tr><td>Picada Café	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Pinheiro Machado	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Piratini	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Planalto	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Portão	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Alegre	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Presidente Lucena	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Quatro Irmãos	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Grande	</td><td> RS</td><td>Logine senha</td></tr>
                                    <tr><td>Rio Pardo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Rodeio Bonito	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Rolante	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Sananduva	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Cruz do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Maria	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Rosa	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Vitória do Palmar	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santana do Livramento	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santiago	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Ângelo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Antônio da Patrulha	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Augusto	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Cristo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Borja	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Gabriel	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Jerônimo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Leopoldo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Lourenço do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Luiz Gonzaga	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Marcos	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Martinho	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Pedro do Butiá	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Sebastião do Caí	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Sepé	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Sapiranga	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Sapucaia do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Seberi	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Serafina Corrêa	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Sertão Santana	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Sobradinho	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Soledade	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Tapejara	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Tapera	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Taquara	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Taquari	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Tenente Portela	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Teutônia	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Tio Hugo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Torres	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Tramandai	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Três Coroas	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Três de Maio	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Três Passos	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Trindade do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Triunfo	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Tucunduva	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Tupanciretã	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Uruguaiana	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Vacaria	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Venâncio Aires	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Veranópolis	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Viamão	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Victor Graeff	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Vila Lângaro	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Sapucaia do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Rosário do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Campestre da Serra	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Cambará do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São José do Ouro	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ajuricaba	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Alto Feliz	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Arroio do Sal	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Arroio Grande	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Arvorezinha	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Augusto Pestana	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Boa Vista do Buricá	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Caçapava do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Condor	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Doutor Maurício Cardoso	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Encruzilhada do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Entre-Ijuís	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ernestina	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Espumoso	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Fagundes Varela	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Formigueiro	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Garruchos	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Gaurama	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>General Câmara	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Glorinha	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaara	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaguarão	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Jóia	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Lagoa dos Três Cantos	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Minas do Leão	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Monte Belo do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Morro Reuter	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Muçum	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Muitos Capões	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Bassano	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Roma do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Novo Cabrais	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Palmares do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Pantano Grande	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Paverama	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Mauá	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Xavier	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Quaraí	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Quinze de Novembro	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Redentora	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Restinga Sêca	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Ronda Alta	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Clara do Sul	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Antônio das Missões	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Francisco de Assis	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São José do Inhacorá	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São José do Norte	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>São Miguel das Missões	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Severiano de Almeida	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Silveira Martins	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Tapes	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Terra de Areia	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Tuparendi	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Turuçu	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Vale do Sol	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Westfália	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Butiá	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Iraí	</td><td> RS</td><td>Certificado A1</td></tr>
                                    <tr><td>Abdon Batista	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Abelardo Luz	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Agrolândia	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Agronômica	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Água Doce	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Águas de Chapecó	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Águas Mornas	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Alfredo Wagner	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Alto Bela Vista	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Anita Garibaldi	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Anitápolis	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Antônio Carlos	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Apiúna	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Arabutã	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Araquari	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Araranguá	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Armazém	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Arvoredo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ascurra	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Atalanta	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Aurora	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Balneário Camboriú	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Balneário Gaivota	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Balneário Piçarras	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Balneário Rincão	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Barra Velha	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Bela Vista do Toldo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Belmonte	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Benedito Novo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Biguaçu	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Blumenau	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Jardim da Serra	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Jesus	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Bombinhas	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Braço do Norte	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Brusque	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Caçador	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Alegre	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Campo Erê	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Campos Novos	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Canelinha	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Canoinhas	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Capinzal	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Catanduvas	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Celso Ramos	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Chapadão do Lageado	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Chapecó	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Cocal do Sul	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Concórdia	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Cordilheira Alta	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Coronel Freitas	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Correia Pinto	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Corupá	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Criciúma	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Cunhataí	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Curitibanos	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Descanso	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Dionísio Cerqueira	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Dona Emma	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Doutor Pedrinho	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ermo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Erval Velho	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Faxinal dos Guedes	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Florianópolis	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Forquilhinhas	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Fraiburgo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Frei Rogério	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Garopaba	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Garuva	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Gaspar	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Grão Pará	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Gravatal	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Guabiruba	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaraciaba	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaramirim	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Guarujá do Sul	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Herval d´Oeste	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibiam	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibicaré	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibirama	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Içara	</td><td> SC</td><td>Logine senha</td></tr>
                                    <tr><td>Imaruí	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Imbituba	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Imbuia	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Amaro da Imperatriz	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Indaial	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipira	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Iporã do Oeste	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipuaçu	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipumirim	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Irani	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Itá	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaiópolis	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Itajaí	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapema	</td><td> SC</td><td>Logine senha</td></tr>
                                    <tr><td>Itapiranga	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapoá	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ituporanga	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Jacinto Machado	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaguaruna	</td><td> SC</td><td>Logine senha</td></tr>
                                    <tr><td>Jaraguá do Sul	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Joaçaba	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Joinville	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>José Boiteux	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Jupiá	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Lacerdópolis	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Lages	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Laguna	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Lajeado Grande	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Lauro Muller	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Lebon Régis	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Lindóia do Sul	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Lontras	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Luiz Alves	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Luzerna	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Macieira	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Mafra	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Major Gercino	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Major Vieira	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Maracajá	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Maravilha	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Marema	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Mondaí	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Monte Carlo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Monte Castelo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Morro da Fumaça	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Morro Grande	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Navegantes	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Erechim	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Trento	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Veneza	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Novo Horizonte	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Orleans	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Otacílio Costa	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ouro	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Paial	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Palhoça	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Palmitos	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Papanduva	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Passo de Torres	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Passos Maia	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Paulo Lopes	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Pedras Grandes	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Penha	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Peritiba	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Petrolândia	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Pinhalzinho	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Piratuba	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Planalto Alegre	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Pomerode	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ponte Alta	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ponte Alta do Norte	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Ponte Serrada	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Belo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto União	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Pouso Redondo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Presidente Getúlio	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Quilombo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Rancho Queimado	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio das Antas	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio do Campo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio do Oeste	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio do Sul	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio dos Cedros	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Negrinho	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Rodeio	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Saltinho	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Salto Veloso	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Sangão	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Cecília	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Helena	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Rosa de Lima	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Terezinha	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São Bento do Sul	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São Bonifácio	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São Carlos	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São Domingos	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São Francisco do Sul	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São João Batista	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São João do Itaperiú	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São João do Oeste	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São Joaquim	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São José	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São José do Cedro	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São José do Cerrito	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São Lourenço do Oeste	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São Ludgero	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São Martinho	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>São Miguel do Oeste	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Schroeder	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Seara	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Serra Alta	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Siderópolis	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Sombrio	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Taió	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Tangará	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Tijucas	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Timbó	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Três Barras	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Treviso	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Treze de Maio	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Treze Tílias	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Trombudo Central	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Tubarão	</td><td> SC</td><td>Logine senha</td></tr>
                                    <tr><td>Tunápolis	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Turvo	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Urussanga	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Vargeão	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Vargem Bonita	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Vidal Ramos	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Videira	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Witmarsum	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Xanxerê	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Xavantina	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Xaxim	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Zortéa	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Pinheiro Preto	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Capivari de Baixo	</td><td> SC</td><td>Logine senha</td></tr>
                                    <tr><td>Águas Frias	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Balneário Arroio do Silva	</td><td> SC</td><td>Logine senha</td></tr>
                                    <tr><td>Caibi	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Governador Celso Ramos	</td><td> SC</td><td>Logine senha</td></tr>
                                    <tr><td>Ilhota	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Itaberaba	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Pescaria Brava	</td><td> SC</td><td>Logine senha</td></tr>
                                    <tr><td>Riqueza	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Romelândia	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Saudades	</td><td> SC</td><td>Certificado A1</td></tr>
                                    <tr><td>Meleiro	</td><td> SC</td><td>Logine senha</td></tr>
                                    <tr><td>Aquidabã	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>Aracaju	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>Barra dos Coqueiros	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>Carmópolis	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>Estância	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>Itabaiana	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>Lagarto	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>Moita Bonita	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>Nossa Senhora da Glória	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>Nossa Senhora do Socorro	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>São Cristóvão	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>Tobias Barreto	</td><td> SE</td><td>Certificado A1</td></tr>
                                    <tr><td>Adamantina	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Águas de Lindóia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Aguaí	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Agudos	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Altinópolis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Alumínio	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Alvinlândia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Americana	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Américo de Campos	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Amparo	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Analândia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Andradina	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Aparecida	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Apiaí	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Araçariguama	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Araçatuba	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Araçoiaba da Serra	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Araraquara	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Arujá	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Assis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Atibaia	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Avaré	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Bady Bassitt	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Bálsamo	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Bariri	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Barra Bonita	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Barretos	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Barrinha	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Barueri	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Bastos	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Bauru	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Bebedouro	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Bertioga	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Bilac	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Birigui	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São João da Boa Vista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Boituva	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Botucatu	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Bragança Paulista	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Brodowski	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Brotas	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Buritama	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Cabrália Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Caçapava	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Cafelândia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Caieiras	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Cajobi	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Cajuru	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Campina do Monte Alegre	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Campinas	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Campos do Jordão	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Cândido Mota	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Cândido Rodrigues	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Capela do Alto	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Capivari	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Carapicuíba	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Casa Branca	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Catanduva	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Cedral	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Cerquilho	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Colina	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Colômbia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Conchal	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Cordeirópolis	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Corumbataí	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Cosmorama	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Cotia	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Cravinhos	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Cruzeiro	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Cubatão	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Cunha	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Diadema	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Divinolândia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Dois Córregos	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Dracena	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Duartina	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Embu das Artes	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Embu-Guaçu	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Engenheiro Coelho	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Espírito Santo do Pinhal	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Fernando Prestes	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Fernandópolis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ferraz de Vasconcelos	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Franca	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Franco da Rocha	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Garça	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Gavião Peixoto	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaiçara	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaimbê	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaíra	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guapiaçu	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guará	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guararapes	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guararema	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaratinguetá	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guariba	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Guarujá	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guarulhos	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guatapará	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Holambra	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Hortolândia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibaté	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibitinga	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Igarapava	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ilha Solteira	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Indaiatuba	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Indiana	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Indiaporã	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Inúbia Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ipuã	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itajobi	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itanhaém	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapecerica da Serra	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Itapetininga	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapeva	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Itapevi	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapira	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Itápolis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaquaquecetuba	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itatiba	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itu	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itupeva	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Ituverava	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Jaboticabal	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Jacareí	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaguariúna	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Jales	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Jambeiro	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Jandira	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Jardinópolis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Jaú	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>José Bonifácio	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Jundiaí	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Junqueirópolis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Laranjal Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Leme	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Lins	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Lorena	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Lucélia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Lutécia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Macatuba	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mairiporã	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Marília	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Martinópolis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Matão	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mauá	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Miguelópolis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mirandópolis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mirante do Paranapanema	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mirassol	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mirassolândia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mococa	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mogi das Cruzes	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mogi Guaçu	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Mogi Mirim	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mongaguá	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Monte Alto	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Monte Aprazível	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Monte Azul Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Monte Mor	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Morro Agudo	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Morungaba	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Nazaré Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Aliança	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Granada	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Odessa	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Novo Horizonte	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Olímpia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Oriente	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Orlândia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Osasco	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Ourinhos	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Palmares Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Palmital	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Panorama	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Paraguaçu Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Patrocínio Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Paulínia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Paulo de Faria	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pederneiras	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pedreira	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Penápolis	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Pereira Barreto	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Piedade	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Pilar do Sul	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pindamonhangaba	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Piracicaba	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pirajuí	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pirangi	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pirapora do Bom Jesus	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pirapozinho	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pirassununga	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Pitangueiras	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Poá	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Pompéia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pontal	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Feliz	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Ferreira	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Potirendaba	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pradópolis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Praia Grande	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Presidente Bernardes	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Presidente Epitácio	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Presidente Prudente	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Presidente Venceslau	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Promissão	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Quatá	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Queiroz	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Rancharia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Regente Feijó	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Registro	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ribeirão Bonito	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ribeirão Corrente	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ribeirão Pires	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ribeirão Preto	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio Claro	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Rio das Pedras	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Riolândia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Rosana	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Sabino	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Sales	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Sales Oliveira	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Salto de Pirapora	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Bárbara d´Oeste	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Cruz das Palmeiras	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Fé do Sul	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Isabel	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Santa Rita do Passa Quatro	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Rosa de Viterbo	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santana de Parnaíba	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Santo Anastácio	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo André	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Antônio de Posse	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santo Antônio do Pinhal	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santos	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São Bernardo do Campo	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São Caetano do Sul	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São Carlos	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Francisco Morato	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>São Joaquim da Barra	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São José do Rio Pardo	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São José do Rio Preto	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São José dos Campos	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São Manuel	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São Paulo	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São Pedro	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São Roque	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São Sebastião	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>São Vicente	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Serra Azul	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Serra Negra	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Serrana	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Sertãozinho	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Sorocaba	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Sud Mennucci	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Sumaré	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Suzano	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Taboão da Serra	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Tambaú	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Tanabi	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Taquaritinga	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Tatuí	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Taubaté	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Teodoro Sampaio	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Tremembé	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Tupã	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ubarana	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ubatuba	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Urânia	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Valinhos	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Valparaíso	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Vargem Grande do Sul	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Vargem Grande Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Várzea Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Vinhedo	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Viradouro	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Vista Alegre do Alto	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Votorantim	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Votuporanga	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Iracemápolis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Capão Bonito	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Bom Jesus dos Perdões	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São Miguel Arcanjo	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Boa Esperança do Sul	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Águas da Prata	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Altair	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Álvares Florence	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Álvares Machado	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Anhumas	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Arealva	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Balbinos	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Barra do Turvo	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Braúna	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Buritizal	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Cabreúva	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Cachoeira Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Catiguá	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Conchas	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Elias Fausto	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Elisiário	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Emilianópolis	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Estiva Gerbi	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Getulina	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaraçaí	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibirarema	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Igaratá	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ilha Comprida	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Iporanga	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapirapuã Paulista	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itapuí	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itirapina	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Jacupiranga	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Jarinu	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Meridiano	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mineiros do Tietê	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Mira Estrela	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Monteiro Lobato	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Nhandeara	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Nova Europa	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Nuporanga	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ouroeste	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pedra Bela	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Piacatu	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Pindorama	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Piratininga	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Poloni	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Rafard	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Rubiácea	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Cruz da Conceição	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Ernestina	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Santa Gertrudes	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>São Luiz do Paraitinga	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>São Sebastião da Grama	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Tabapuã	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Tuiuti	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Vargem	</td><td> SP</td><td>Logine senha</td></tr>
                                    <tr><td>Zacarias	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Ibirá	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Dumont	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaberá	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Itaju	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Charqueada	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Borá	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Buri	</td><td> SP</td><td>Certificado A1</td></tr>
                                    <tr><td>Araguaína	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Araguatins	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Augustinópolis	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Dianópolis	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Gurupi	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Lagoa da Confusão	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Paraíso do Tocantins	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Porto Nacional	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Colinas do Tocantins	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Guaraí	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Miranorte	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Palmas	</td><td> TO</td><td>Certificado A1</td></tr>
                                    <tr><td>Aragominas	</td><td> TO</td><td>Certificado A1</td></tr>
                                </table>
                            </div>
                            <h5>
                                Não encontrou o seu município? Realizamos a homologação. <br />Converse com um de nossos consultores <p />
                            </h5>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 pos-container' style={{ backgroundColor: this.state.revenda?.siteCor }}>
                    <div className="row">
                        <div className="promotion-content" style={{ backgroundColor: this.state.revenda?.siteCor }}>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export { NotaFiscalMunicipiosHomologados };