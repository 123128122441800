import { fetchFunction } from './function-request'
import Moment from 'moment';

export const allCharges = async () => {
    const option = {
        method: 'GET'
    };
    return await fetchFunction("/charge/all", option)
}

export const allChargesByDocument = async (document) => {
    const option = {
        method: 'GET'
    };
    return await fetchFunction(`/charge/all/${document}`, option)
}

export const chargeDetail = async (hash) => {
    const option = {
        method: 'GET'
    };
    return await fetchFunction(`/charge/detail/${hash}`, option)
}

export const chargeDelete = async (hash) => {
    const option = {
        method: 'DELETE'
    };
    return await fetchFunction(`/charge/delete/${hash}`, option)
}

export const createLicenceCharge = async ({
    parkId,
    licenceDate,
    amount,
    dueDate,
    notifyCustomer }) => {
    const json = {
        parkId: parkId,
        description: "Mensalidade",
        type: "MENSALIDADE",
        licence: {
            date: Moment(licenceDate).format("YYYY-MM-DD")
        },
        amount: amount,
        dueDate: Moment(dueDate).format("YYYY-MM-DD"),
        notifyCustomer: notifyCustomer
    }
    const option = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: json
    };
    return await fetchFunction("/charge/create", option)
}

export const createNormalCharge = async ({
    parkId,
    amount,
    dueDate,
    notifyCustomer }) => {
    const json = {
        parkId: parkId,
        description: "Cobrança avulsa",
        type: "COBRANCA_MANUAL",
        amount: amount,
        dueDate: Moment(dueDate).format("YYYY-MM-DD"),
        notifyCustomer: notifyCustomer
    }
    const option = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json'
        },
        body: json
    };
    return await fetchFunction("/charge/create", option)
}