import React from 'react'
import firebase from '../../config/firebase'
import MobileTimePicker from '@mui/lab/MobileTimePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getEstacionamento, getEstacionamentos, getRevenda, isAdministrador } from '../../config/auth'
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import './common.css'
import 'firebase/storage'
import 'firebase/storage'
import { sendLog, sendLogByUser } from '../../shared/analytics';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getParameterByParkId, updateParameterById } from '../../store/collections/parameterWorker';
import { getAllTicketsOpenByParkId } from '../../store/collections/ticketWorker';
import Select from 'react-select';
import { getAllParksByClientId } from '../../store/collections/parkWorker';
import { toastWarning } from '../../shared/utils';
import StaticDateTimePicker from '@mui/lab/StaticDateTimePicker';

class SFTimePicker extends React.Component {

    constructor(props) {
        super(props)
        this.state = { value: new Moment(new Date()) }
    }

    onChange = (e) => {
        this.props.onChange(e)
    }

    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <MobileTimePicker
                    label={this.props.label}
                    value={new Moment(this.props.value)}
                    onChange={this.onChange}
                    renderInput={(params) => <TextField {...params} size="small" className='btn-block' />}
                />
            </LocalizationProvider>
        )
    }
}

class SFDatePicker extends React.Component {

    constructor(props) {
        super(props)
        this.state = { value: new Moment(new Date()) }
    }

    onChange = (e) => {
        this.setState({ value: e })
        this.props.onChange(e)
    }

    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
                <MobileDatePicker
                    label={this.props.label}
                    inputFormat="dd/MM/yyyy"
                    value={this.state.value}
                    onChange={this.onChange}
                    renderInput={(params) => <TextField {...params} size="small" className='btn-block' />}
                />
            </LocalizationProvider>
        )
    }
}

class DatePickerDialog extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen: true,
            date: props.date ?? new Date(),
        }
    }

    onChange = (e) => {
        this.setState({ date: e })
        if (this.props.onChange) {
            this.props.onChange(e)
        }
    }

    onConfirm = () => {
        this.props.onConfirm(this.state.date)
        this.onClose()
    }

    onClose = (e) => {
        if (this.props.onClose) {
            this.props.onClose(e)
            this.setState({ isOpen: false })
        }
    }

    render() {
        return (
            <Dialog onClose={this.onClose} fullWidth maxWidth={"xs"} open={this.state.isOpen}>
                <MuiDialogContent>
                    <StaticDateTimePicker value={this.state.date} onChange={this.onChange} />
                </MuiDialogContent>
                <MuiDialogActions>
                    <div className='btn-group m-3'>
                        <button onClick={this.onClose} className='btn btn-secondary'>Fechar</button>
                        <button onClick={() => this.onConfirm()} className='btn btn-success'>Confirmar</button>
                    </div>
                </MuiDialogActions>
            </Dialog>
        )
    }
}

class Loading extends React.Component {

    render() {
        return (
            <div className='m-5 d-flex justify-content-center'>
                <div className="spinner-border">
                    <span className="sr-only" />
                </div>
            </div>
        )
    }
}

class EmptyContent extends React.Component {

    render() {
        return (
            <div className='col-lg-12'>
                <div className='m-5 d-flex justify-content-center'>
                    <div className='text-center'>
                        <h3>{this.props.text}</h3>
                    </div>
                </div>
            </div>
        )
    }
}

class ReloadContent extends React.Component {

    render() {
        return (
            <div className='m-5 d-flex justify-content-center'>
                <div className='text-center'>
                    <h3>{this.props.text}</h3>
                    <button text='Carregar' className='form-control btn-primary' />
                </div>
            </div>
        )
    }
}

var list = []

class ComboBoxCollection extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: '',
            value: '',
            list: []
        }
        this.loading()
    }

    onSelected = (e) => {
        this.setState({ id: e.target.value })
        let items = this.state.list.filter((item) => { return item.id === e.target.value })
        if (items.length > 0) {
            this.props.onSelected(items[0])
        }
    }

    onSelectOneItem = () => {
        if (this.state.list.length > 0) {
            this.props.onSelected(this.state.list[0])
        }
    }

    loading = async () => {
        var results = null
        if (this.props.parentField !== undefined) {
            results = await firebase.firestore().collection(this.props.collection).where(this.props.parentField, '==', this.props.parentValue).get()
        } else {
            results = await firebase.firestore().collection(this.props.collection).get()
        }
        list = []
        results.docs.forEach(doc => {
            list.push({
                ...doc.data(),
                id: doc.id
            })
        })
        this.setState({ list: list });
        this.setState({ id: (this.props.id !== null ? this.props.id : '') })
        this.onSelectOneItem()
    }

    render() {
        return (
            <>
                {
                    <>
                        <label>{this.props.title}</label>
                        <div className="input-group mb-3">
                            <select className="form-select" value={this.state.id && this.state.id} onChange={this.onSelected} required>
                                {
                                    this.props.hasEmpty === true &&
                                    <option key='1' value='0'>Todos</option>
                                }
                                {
                                    this.props.showSelectOption === true &&
                                    <option key='1' value=''>Selecione</option>
                                }
                                {
                                    this.state.list.map(item => <option key={item.id} value={item.id}>{item.nomeInterno ?? item.nome}</option>)
                                }
                            </select>
                        </div>
                    </>
                }
            </>)
    }
}

class SFSearchBox extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen: props.isOpen ?? false,
            isLoading: props.isLoading ?? false,
            options: props.options
        }
    }

    onFocus = () => {
        this.props.onFocus(this)
    }

    render() {
        return (
            <Autocomplete
                id="asynchronous-demo"
                open={this.props.isOpen}
                onOpen={(e) => this.setState({ isOpen: true })}
                onClose={(e) => this.setState({ isOpen: false })}
                onFocus={this.onFocus}
                isOptionEqualToValue={(option, value) => option.title === value.title}
                getOptionLabel={(option) => option.title}
                options={this.state.options}
                loading={this.state.isLoading}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small" className='btn-block'
                        label="Buscar"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {this.state.isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        )
    }
}

class FilterComponent extends React.Component {

    constructor(props) {
        super(props)
        const startDate = props.startDate ?? new Moment(new Date())
        const endDate = props.endDate ?? new Moment(new Date())
        this.state = {
            parks: [],
            startDate: startDate,
            endDate: endDate,
            days: 1
        }
    }

    componentDidMount = () => {
        // var days = queryString("past-days")
        // if (days !== null && parseInt(days) <= 60) {
        //     this.setState({ days: parseInt(days) }, () => {
        //         this.search()
        //     })
        // }
        // const startDate = this.props.startDate !== undefined ? new Moment(this.props.startDate) : new Moment(new Date())
        // const endDate = this.props.endDate !== undefined ? new Moment(this.props.endDate) : new Moment(new Date())
        // this.setState({ startDate: startDate })
        // this.setState({ endDate: endDate })
        // const from = queryString("from")
        // if (from) {
        //     this.setState({ isFromSearch: from === "search" })
        // }
    }

    handleChange = (event) => {
        this.setState({ days: event.target.value }, () => {
            this.search()
        })
    };

    handleParksChange = (newValue) => {
        this.setState({ parks: newValue })
    }

    handleStartDateChange = (newValue) => {
        this.setState({ startDate: newValue })
    }

    handleEndDateChange = (newValue) => {
        this.setState({ endDate: newValue })
    }

    search = () => {
        var date = new Date();
        date.setDate(date.getDate() - this.state.days);
        this.setState({ startDate: new Moment(date) })
        this.setState({ endDate: new Moment(new Date()) }, () => {
            this.applyFilter()
        })
    }

    applyFilter = () => {
        if (this.state.parks.length === 0) {
            toastWarning("Seleciona ao menos um estabelecimento.")
            return
        }
        var data = {
            estacionamentosIds: this.state.parks,
            startDate: this.state.startDate?.toDate(),
            endDate: this.state.endDate?.toDate(),
        }
        this.props.onApplyFilter(data)
    }

    onBack = () => {
        window.history.go(-1)
    }

    render() {
        return (
            <>
                <div className="card mb-4">
                    <div className="card-header d-flex align-items-center">
                        {
                            this.state.isFromSearch &&
                            <button type="button" onClick={() => { this.onBack() }} className="btn btn-sm btn-secondary">
                                <i className="fas fa-chevron-left mx-2" />
                                Voltar
                            </button>
                        }
                        <span className='mx-2'> Selecione o seu filtro </span>
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-lg-3'>
                                <strong>Estabelecimentos</strong>
                                <AllParksSelect onChange={this.handleParksChange} />
                            </div>
                            <div className='col-lg-2 my-4'>
                                <MobileDatePicker
                                    label="Data inicial"
                                    inputFormat="DD/MM/yyyy"
                                    value={this.state.startDate}
                                    onChange={this.handleStartDateChange}
                                    renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                />
                            </div>
                            <div className='col-lg-2 my-4'>
                                <MobileDatePicker
                                    label="Data final"
                                    inputFormat="DD/MM/yyyy"
                                    value={this.state.endDate}
                                    onChange={this.handleEndDateChange}
                                    renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                />
                            </div>
                            <div className='col-lg-3'>
                                <button type="button" className="btn btn-primary my-4" onClick={(e) => this.applyFilter()}>Aplicar filtro</button>
                            </div>
                        </div>
                        {this.props.children}
                    </div>
                </div>
            </>)
    }
}

class HelpButton extends React.Component {

    constructor(props) {
        super(props)
        const revenda = getRevenda()
        if (revenda.mostrarLinksDeAjuda === "false") {
            this.state = {
                items: null
            }
            return
        }
        var options = []
        options.push({
            path: "/cadastrar/usuario",
            items: [{
                link: "https://www.youtube.com/watch?v=FlIPkYM2J_I",
                title: "Cadastrar -> usuário"
            }]
        })
        options.push({
            path: "/cadastrar/perfil",
            items: [{
                link:
                    "https://www.youtube.com/watch?v=s6vGZQvPK_0",
                title: "Cadastrar -> perfil"
            }]
        })
        options.push({
            path: "/cadastrar/mensalista",
            items: [{
                link: "https://www.youtube.com/watch?v=43KSury6Ey4",
                title: "Cadastrar -> mensalista"
            }]
        })
        options.push({
            path: "/cadastrar/plano",
            items: [{
                link: "https://www.youtube.com/watch?v=CXMzfJ2HhMY",
                title: "Cadastrar -> plano"
            }]
        })
        options.push({
            path: "/cadastrar/convenio",
            items: [{
                link: "https://www.youtube.com/watch?v=FIlaLxlsif4",
                title: "Cadastrar -> convênio"
            }]
        })
        options.push({
            path: "/cadastrar/tabela-preco",
            items: [{
                link: "https://www.youtube.com/watch?v=-LO7a04yT74",
                title: "Cadastrar -> Tabela de preço"
            }]
        })
        options.push({
            path: "/cadastrar/produto",
            items: [{
                link: "https://www.youtube.com/watch?v=skUGY-W8VUQ&t=3s",
                title: "Cadastrar -> Produtos e serviços"
            }]
        })
        options.push({
            path: "/cadastrar/forma-pagamento",
            items: [{
                link: "https://www.youtube.com/watch?v=zHAmIdzOz5E",
                title: "Cadastrar -> Forma de pagamento"
            }]
        })
        options.push({
            path: "/cadastrar/setor",
            items: [{
                link: "https://www.youtube.com/watch?v=ZbGE7-lwR0Q",
                title: "Cadastrar -> Setor"
            }]
        })
        options.push({
            path: "/cadastrar/credenciado",
            items: [{
                link: "https://www.youtube.com/watch?v=X0D1XkyJASU",
                title: "Cadastrar -> Credenciado"
            }]
        })
        options.push({
            path: "/visualizar/mensalista",
            items: [{
                link: "https://www.youtube.com/watch?v=KqSzuz-Drdk",
                title: "Cadastrar -> Mensalista"
            }]
        })
        options.push({
            path: "/nota-fiscal/configurar",
            items: [{
                link: "https://www.youtube.com/watch?v=84II7EPnr0w",
                title: "Nota Fiscal -> Configurar"
            }]
        })
        options.push({
            path: "/licenca/minha-licenca",
            items: [{
                link: "https://www.youtube.com/watch?v=ALPILqqL3Yw",
                title: "Licença -> Minha Licença"
            }]
        })
        options.push({
            path: "/equipamentos/cameras-lpr",
            items: [{
                link: "https://www.youtube.com/watch?v=9BLCIdtTYDE",
                title: "Equipamentos -> Câmeras LPR"
            }]
        })
        options.push({
            path: "/buscar/mensalistas",
            items: [{
                link: "https://www.youtube.com/watch?v=VNYxNRdlZAk",
                title: "Buscar -> Mensalistas -> Exportar"
            }]
        })
        options.push({
            path: "/relatorios/caixas",
            items: [{
                link: "https://www.youtube.com/watch?v=eW1vTUYK6uc",
                title: "Relatório -> Caixas -> Exportar"
            }]
        })
        const selected = options.filter(e => window.location.href.indexOf(e.path) !== -1)
        if (selected.length > 0) {
            const item = selected[0]
            this.state = {
                items: item.items,
                quantity: item.items.length
            }
        } else {
            this.state = {
                items: null
            }
        }
    }

    onSelectedItem = (item) => {
        sendLogByUser("Ajuda", `Tocou no botão de ajuda '${item.title}'.`, item)
    }

    render() {
        return (<>
            {
                this.state.items &&
                <>
                    {
                        this.state.quantity === 1 &&
                        <a href={this.state.items[0].link} onClick={e => this.onSelectedItem(this.state.items[0])} target='_blank' className='btn btn-sm btn-danger'>
                            <i className="fab fa-youtube mx-2" />
                            Ajuda
                        </a>
                    }
                </>
            }
        </>)
    }
}

class ParkSettings extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            habilitarControlarVagas: false,
            habilitarCancela: false
        }
    }

    componentDidMount = async () => {
        await this.load()
    }

    load = async () => {
        const parkId = getEstacionamento()?.id
        const parameters = await getParameterByParkId({ estacionamentoId: parkId })
        if (parameters) {
            this.setState({ habilitarCancela: parameters.habilitarCancela })
            this.setState({ habilitarEmergenciaNasCancelas: parameters.habilitarEmergenciaNasCancelas })
            this.setState({ habilitarControlarVagasBloquearEntrada: parameters.habilitarControlarVagasBloquearEntrada })

            const items = await getAllTicketsOpenByParkId(parkId)
            var inPark = items.length
            var slots = parameters.habilitarControlarVagasQuantidade ?? 0
            let percentage = ((inPark / slots) * 100)
            if (percentage < 40) {
                this.setState({ classPercentagem: "badge text-bg-success mx-2" })
                this.setState({ classTitle: "Ocupação baixa" })
            } else if (percentage < 70) {
                this.setState({ classPercentagem: "badge text-bg-warning text-white mx-2" })
                this.setState({ classTitle: "Ocupação média" })
            } else {
                this.setState({ classPercentagem: "badge text-bg-danger text-white mx-2" })
                this.setState({ classTitle: "Ocupação alta" })
            }
            this.setState({ enableSlots: ((parameters.habilitarControlarVagas ?? false) && slots > 0) })
            this.setState({ slots: slots })
            this.setState({ inPark: inPark })
        }
    }

    showCancelaModal = () => {
        this.setState({ isCancelaModalOpen: true })
    }

    saveNewSettings = async () => {
        const parkId = getEstacionamento()?.id
        const parameters = await getParameterByParkId({ estacionamentoId: parkId })
        parameters.habilitarEmergenciaNasCancelas = this.state.habilitarEmergenciaNasCancelas
        parameters.habilitarControlarVagasBloquearEntrada = this.state.habilitarControlarVagasBloquearEntrada
        await updateParameterById({ id: parameters.id, data: parameters })
        this.setState({ isCancelaModalOpen: false })
    }

    render() {
        return (
            <>
                {/* {
                    this.state.habilitarCancela &&
                    <a className='text-white' role='button' onClick={this.showCancelaModal} >
                        <i className="fas fa-parking mx-2" />
                    </a>
                } */}
                {
                    this.state.enableSlots &&
                    <Tooltip title={this.state.classTitle} placement="bottom">
                        <span>
                            <span className={this.state.classPercentagem}>
                                {this.state.inPark} de {this.state.slots}
                            </span>
                        </span>
                    </Tooltip>
                }
                <Dialog onClose={(e) => this.setState({ isCancelaModalOpen: false })} fullWidth maxWidth={"xs"} open={this.state.isCancelaModalOpen}>
                    <MuiDialogTitle className='text-center'>
                        Configurações das cancelas
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className="form-check form-switch my-2">
                            <input className="form-check-input" id="habilitarEmergenciaNasCancelas" checked={this.state.habilitarEmergenciaNasCancelas} onChange={(e) => this.setState({ habilitarEmergenciaNasCancelas: e.target.checked })} type="checkbox" />
                            <label className="form-check-label" htmlFor="habilitarEmergenciaNasCancelas">
                                Permitir entrar todos os veículos sem validação.
                            </label>
                        </div>
                        {
                            !this.state.habilitarEmergenciaNasCancelas &&
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="habilitarControlarVagasBloquearEntrada2" checked={this.state.habilitarControlarVagasBloquearEntrada} onChange={(e) => this.setState({ habilitarControlarVagasBloquearEntrada: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="habilitarControlarVagasBloquearEntrada2">
                                    Bloquear entrada de veículos caso chegue no limite de vagas disponíveis.
                                </label>
                            </div>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isCancelaModalOpen: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={() => this.saveNewSettings()} className='btn btn-success'>Salvar alterações</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

class ClientButton extends React.Component {

    render() {
        return (
            <Tooltip title="Visualizar matriz" placement="top">
                <a href={`/revenda/cliente/${this.props.item?.clienteId}?e=${this.props.item?.estacionamentoId}`} rel="noopener noreferrer" className="btn btn-primary">
                    <i className="fas fa-eye" />
                </a>
            </Tooltip>
        )
    }
}

class AllParksSelect extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            random: Math.random()
        }
        this.loadAllParks()
    }

    loadAllParks = async () => {
        var parks = []
        const park = getEstacionamento()
        if (park) {
            if (isAdministrador()) {
                parks = await getAllParksByClientId({ clientId: park.clienteId })
            } else {
                parks = getEstacionamentos()
                if (!parks) {
                    parks = await getAllParksByClientId({ clientId: park.clienteId })
                }
            }
        } else {
            parks = []
        }
        var values = [{ label: "Todos", value: "Todos" }]
        for (const park of parks) {
            values.push({
                label: park.nomeInterno ?? park.nome,
                value: park.id
            })
        }
        this.setState({ parks: parks })
        const selecteds = localStorage.getItem('parksSelecteds')
        if (selecteds && selecteds !== "") {
            const selectedsParsed = JSON.parse(selecteds)
            this.emitOnChange(selectedsParsed)
            this.setState({ allSelecteds: selectedsParsed })
        }
        this.setState({ allValues: values })
        this.setState({ random: Math.random() })
    }

    emitOnChange = (selecteds) => {
        if (this.props.onChange) {
            if (selecteds.length > 0) {
                if (selecteds.map(e => e.label).indexOf("Todos") !== -1) {
                    const values = this.state.parks.map(e => e.id)
                    this.props.onChange(values)
                } else {
                    this.props.onChange(selecteds.map(e => e.value))
                }
            } else {
                this.props.onChange([])
            }
        }
    }

    handleValues = (selecteds) => {
        this.setState({ allSelecteds: selecteds })
        localStorage.setItem('parksSelecteds', JSON.stringify(selecteds))
        this.emitOnChange(selecteds)
    }

    render() {
        return (
            <Select
                key={this.state.random}
                isMulti
                defaultValue={this.state.allSelecteds}
                options={this.state.allValues}
                onChange={this.handleValues}
                className="basic-multi-select"
            />
        )
    }
}

export {
    Loading,
    EmptyContent,
    ReloadContent,
    ComboBoxCollection,
    SFTimePicker,
    SFDatePicker,
    SFSearchBox,
    FilterComponent,
    HelpButton,
    ClientButton,
    ParkSettings,
    AllParksSelect,
    DatePickerDialog
}