import { getPerfil, getRevendaIdByPark, getUsuarioCompact, isAdministrador } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docToItem, docsToItem, docsToItems } from '../transform.docs';
import { addDoc, updateDoc } from './collectionBaseWorker';
import { saveLastUpdateNoSkip } from './lastUpdateWorker';
import { getParameterByParkId } from './parameterWorker';
    
export const activeCashier = async ({ estacionamentoId }) => {
    const parameters = await getParameterByParkId({ estacionamentoId: estacionamentoId })
    if (parameters?.typeCashierOpened === "user" && !isAdministrador()) {
        const user = getUsuarioCompact()
        const result = await firebase.firestore().collection(Collection.CASHIER).where(WhereField.PARKID, "==", estacionamentoId).where("openBy.id", "==", user.id).where("status", "==", "aberto").orderBy("openAt", 'desc').limit(1).get()
        return docsToItem(result)
    } else {
        const result = await firebase.firestore().collection(Collection.CASHIER).where(WhereField.PARKID, "==", estacionamentoId).where("status", "==", "aberto").orderBy("openAt", 'desc').limit(1).get()
        return docsToItem(result)
    }
}

export const getAllActiveCashiers = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.CASHIER).where(WhereField.PARKID, "==", estacionamentoId).where("status", "==", "aberto").orderBy("openAt", 'desc').get()
    return docsToItems(result)
}

export const getCashierById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.CASHIER).doc(id).get()
    return docToItem(result)
}

export const getAllCashiersByAnalytics = async ({ limit }) => {
    const result = await firebase.firestore().collection(Collection.CASHIER).where("status", "==", "fechado").orderBy("closeAt", "desc").limit(limit).get()
    return docsToItems(result)
}

export const updateCashier = async (id, data) => {
    await updateDoc({ collection: Collection.CASHIER, id: id, data: data })
    await saveLastUpdateNoSkip()
}

export const updateCashierById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.CASHIER, id: id, data: data })
}

export const getCashierBySequence = async ({ estacionamentoId, sequence }) => {
    const result = await firebase.firestore().collection(Collection.CASHIER).where(WhereField.PARKID, "==", estacionamentoId).where("sequence", "==", sequence.padStart("7", "0")).get()
    return docsToItem(result)
}

export const getAllCashiers = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.CASHIER).where(WhereField.PARKID, "==", estacionamentoId).orderBy("openAt", 'desc').limit(100).get()
    return docsToItems(result).sort((a, b) => b.openAt?.toDate() - a.openAt?.toDate())
}

export const lastCashier = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.CASHIER).where(WhereField.PARKID, "==", estacionamentoId).orderBy("openAt", 'desc').limit(1).get()
    return docsToItem(result)
}

export const newCashierSequence = async ({ estacionamentoId }) => {
    var sequence = 0
    const lastCashierClosed = await lastCashier({ estacionamentoId: estacionamentoId })
    if (lastCashierClosed === null) {
        sequence = 1
    } else {
        const lastSequence = parseInt(lastCashierClosed.sequence ?? 1)
        sequence = lastSequence + 1
    }
    return sequence
}

export const createCashier = async ({ clienteId, estacionamentoId, valorInicial, currentUser }) => {
    const sequence = await newCashierSequence({ estacionamentoId: estacionamentoId })
    var newCashier = {
        version: "v3",
        sequence: sequence.toString().padStart(7, '0'),
        valorInicial: parseFloat(valorInicial),
        openAt: new Date(),
        openBy: {
            id: currentUser.id,
            nome: currentUser.nome,
            email: currentUser.email,
        },
        status: "aberto",
        openUserAllowed: null,
        retiradas: [],
        ticketsPayments: [],
        monthlyPayments: [],
        isDeleted: false,
    }
    const doc = await addDoc({ collection: Collection.CASHIER, data: newCashier })
    newCashier.id = doc.id
    return newCashier
}

export const closeCashier = async ({ cashier, trocoFinal, valorTotal, status, comment, tickets }) => {
    const profile = getPerfil()
    cashier.closeBy = getUsuarioCompact()
    cashier.closeAt = new Date()
    cashier.status = "fechado"
    cashier.valorTotal = valorTotal
    cashier.valorTrocoFinal = trocoFinal
    cashier.comentarioFinal = comment
    if (profile?.fecharCaixaCego === true) {
        cashier.statusFechamento = "Cego"
    } else {
        cashier.statusFechamento = status
    }
    cashier.metrics = { quantityTickets: tickets.length, tickets: tickets.map(e => e.ticket) }
    await updateDoc({ collection: Collection.CASHIER, id: cashier.id, data: cashier })
}

export const registerMonthlyPayment = async ({ cashierId, mensalista, formaPagamento, pricesPayment }) => {
    const payment = {
        monthlyId: mensalista.id,
        document: mensalista.documento,
        monthlyName: mensalista.nome,
        formaPagamento: formaPagamento,
        originPayment: "admin",
        resumo: pricesPayment
    }
    const cashier = await getCashierById({ id: cashierId })
    if (cashier !== undefined) {
        if (cashier.monthlyPayments === undefined) {
            cashier.monthlyPayments = []
        }
        cashier.monthlyPayments.push(payment)
        await updateMonthlyPayments({
            cashierId: cashier.id,
            monthlyPayments: cashier.monthlyPayments
        })
        return { status: true, message: "Pagamento registrado com sucesso!", caixa: cashier }
    }
    return { status: false, message: "Não foi possível registrar o pagamento." }
}

const updateMonthlyPayments = async ({ cashierId, monthlyPayments }) => {
    await updateDoc({
        collection: Collection.CASHIER, id: cashierId, data: {
            monthlyPayments: monthlyPayments
        }
    })
}
