import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { addDoc, getAllDocsByClientId, updateDoc } from './collectionBaseWorker';

export const getAllPlansByClientId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.PLANS, clienteId: clienteId })
}

export const addMonthlyPlan = async ({ clientId, estacionamentoId, vagasDisponiveis, valorMensal, nome, status, horarioInicio, horarioFim }) => {
    const data = {
        clienteId: clientId,
        estacionamentoId: estacionamentoId,
        nome: nome,
        horarioInicio: horarioInicio,
        horarioFim: horarioFim,
        vagasDisponiveis: vagasDisponiveis,
        valorMensal: valorMensal,
        status: status ?? "Ativo",
        isDeleted: false,
    }
    const doc = await addDoc({ collection: Collection.PLANS, data: data })
    return doc.id;
}

export const getPlanById = async (id) => {
    const result = await firebase.firestore().collection(Collection.PLANS).doc(id).get()
    return docsToItems(result)
}

export const getAllMonthlyPlans = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.PLANS).where(WhereField.PARKID, '==', estacionamentoId).get()
    return docsToItems(result)
}

export const updatePlanById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.PLANS, id: id, data: data })
}
