import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docToItem, docsToItems } from '../transform.docs';
import { addDoc, deleteDoc, getAllDocsByClientId, updateDoc } from './collectionBaseWorker';

export const addSector = async ({ data }) => {
    await addDoc({ collection: Collection.SECTORES, data: data })
}

export const updateSectorById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.SECTORES, id: id, data: data })
}

export const removeSectorById = async ({ id }) => {
    await deleteDoc({ collection: Collection.SECTORES, id: id })
}

export const getAllSectoresByParkId = async ({ estacionamentoId }) => {
    var results = await firebase.firestore().collection(Collection.SECTORES).where(WhereField.PARKID, '==', estacionamentoId).get()
    return docsToItems(results).sort((a, b) => a.nome.localeCompare(b.nome))
}

export const getSectorById = async ({ id }) => {
    var result = await firebase.firestore().collection(Collection.SECTORES).doc(id).get()
    return docToItem(result)
}

export const getAllSectoresByClientId = async ({ clienteId }) => {
    var results = await getAllDocsByClientId({ collection: Collection.SECTORES, clienteId: clienteId })
    return results.sort((a, b) => a.nome.localeCompare(b.nome))
}