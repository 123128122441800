import moment from 'moment';

export const convertHoursToMinutes = ({ value }) => {
    const permanence = parseInt(moment.duration((value ?? "00:00")).asMinutes())
    if (permanence < 0) {
        return 0
    }
    return permanence
}

export const minutesBetween = ({ startDate, endDate }) => {
    const startDateM = moment(startDate, "YYYY-MM-DD[T]HH:mm:ss", 'America/Sao_Paulo')
    const endDateM = moment(endDate ?? new Date(), "YYYY-MM-DD[T]HH:mm:ss", 'America/Sao_Paulo')
    var duration = moment.duration(endDateM.diff(startDateM));
    const permanence = parseInt(duration.asMinutes())
    if (permanence < 0) {
        return 0
    }
    return permanence
}

export const minutesBetweenNow = ({ startDate }) => {
    const endDate = moment(new Date()).format("YYYY-MM-DD[T]HH:mm:ss")
    return minutesBetween({ startDate: startDate, endDate: endDate })
}

export const hoursBy60Minutes = ({ missingMinutes }) => {
    return Array.from({ length: missingMinutes }, (_, i) => i * 60)
}

export const nextHourMyMissingMinutes = ({ resume }) => {
    const period = hoursBy60Minutes({ missingMinutes: resume.missingMinutes }).filter(e => e >= resume.missingMinutes)
    if (period.length > 0) {
        return period[0]
    }
    return 60
}