import React from 'react';
import firebase from '../../config/firebase';
import { NavBar } from '../../components/navbar'
import { getEstacionamento } from '../../config/auth'
import { queryString } from '../../shared/utils'
import { EmptyContent, Loading, FilterComponent } from '../../components/common/commons'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { sendScreenView } from '../../shared/analytics';
import { getAllMonthly } from '../../store/collections/mensalistaWorker';
import NumberFormat from 'react-number-format';
import Tooltip from '@mui/material/Tooltip';
import Chart from "react-apexcharts";

class ReportPerformance extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notFound: false,
            isLoading: false,
            estacionamentoId: getEstacionamento()?.id,
            random: Math.random(),
            tickets: [],
            resultado: null,
            allMonthlies: [],
            isOpenMonthliesSelectedModal: false,
            monthliesSelected: [],
            modalTitle: "",
            paymentHistory: [],
            loadingHistory: false,
            state: "normal",
            datesNoRepeat: null,
            grouped: null,
            isOpenPaymentsSelectedModal: false,
            agrouped: "dddd",
            allPaymentsWay: [],
            showPaymentCharts: true,
            groupedTable: []
        }
    }

    componentDidMount = async () => {
        this.findMountData()
        sendScreenView("Relatório", "Performance")
    }

    findMountData() {
        const estacionamentoId = queryString("e")
        const startDate = new Moment(queryString("startDate"))
        const endDate = new Moment(queryString("endDate"))
        if (estacionamentoId !== undefined && startDate.isValid() && endDate.isValid()) {
            this.setState({ estacionamentoId: estacionamentoId })
            this.setState({ startDate: startDate.toDate() })
            this.setState({ endDate: endDate.toDate() }, () => {
                this.loadPaymentHistory()
            })
        }
    }

    onApplyFilter = (item) => {
        this.setState({ startDate: item.startDate })
        this.setState({ endDate: item.endDate })
        this.setState({ estacionamentosIds: item.estacionamentosIds }, () => {
            this.loadPaymentHistory()
        })
    }

    render() {
        return (
            <>
                <NavBar>
                    <FilterComponent onApplyFilter={this.onApplyFilter} />
                    {
                        this.state.state === "loading" &&
                        <div className="card">
                            <div className="card-body">
                                <EmptyContent text="Carregando informações..." />
                            </div>
                        </div>
                    }
                    {
                        this.state.state === "empty" &&
                        <div className="card">
                            <div className="card-body">
                                <EmptyContent text="Nenhum pagamento encontrado com este filtro." />
                            </div>
                        </div>
                    }
                </NavBar>
            </>
        )
    }
}

export { ReportPerformance };