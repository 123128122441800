import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { docsToItem, docsToItems, docToItem } from '../transform.docs';
import { addDoc, updateDoc } from './collectionBaseWorker';

export const addRevenda = async ({ data }) => {
    const doc = await addDoc({ collection: Collection.RESALE, data: data })
    return doc.id;
}

export const getRevendaById = async ({ id }) => {
    if (id) {
        const result = await firebase.firestore().collection(Collection.RESALE).doc(id).get()
        return docToItem(result)
    }
    return null
}

export const updateRevenda = async ({ id, data }) => {
    await updateDoc({ collection: Collection.RESALE, id: id, data: data })
}

export const getRevendaByCode = async ({ code }) => {
    if (code) {
        const result = await firebase.firestore().collection(Collection.RESALE).where("codigo", "==", code).get()
        return docsToItem(result)
    }
    return null
}
export const getAllRevendas = async () => {
    const result = await firebase.firestore().collection(Collection.RESALE).get()
    return docsToItems(result)
}