import React from 'react';
import { NavBar } from '../../../components/navbar'
import { getCliente, getEstacionamento, getUsuario } from '../../../config/auth'
import { ComboBoxCollection } from '../../../components/common/commons'
import firebase from '../../../config/firebase';
import { EmptyContent } from '../../../components/common/commons'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { saveLastUpdate } from '../../../store/collections/lastUpdateWorker';
import { reloadWithAlert } from '../../../shared/utils';
import { updateDoc } from '../../../store/collections/collectionBaseWorker';
import { Collection } from '../../../shared/constants';

class RPSListar extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notFound: false,
            isLoading: true,
            serie: null,
            estacionamentoId: getEstacionamento()?.id,
            isShowEditingSerie: false,
            list: [],
            serieNome: '',
            serieSerie: '',
            serieId: '',
            serieSequencia: '',
            random: Math.random()
        }
    }

    componentDidMount = async () => {
        this.find()
    }

    onSelected = (serie) => {
        this.setState({ serieId: serie.id })
        this.setState({ serieNome: serie.deviceName })
        this.setState({ serieSerie: serie.serie })
        this.setState({ serieSequencia: serie.numero })
        this.setState({ isShowEditingSerie: true })
    }

    onEditSerie = async () => {
        const user = getUsuario()
        const data = {
            serie: this.state.serieSerie,
            numero: this.state.serieSequencia,
            deviceName: this.state.serieNome,
            alterAt: new Date(),
            alterBy: {
                email: user.email,
                id: user.usuarioId,
                nome: user.nome
            }
        }
        await updateDoc({ collection: Collection.NOTES_RPS_SERIES, id: this.state.serieId, data: data })
        await saveLastUpdate()
        reloadWithAlert("Configuração de serie de RPS atualizada com sucesso!")
    }

    hideEditingSerie = () => {
        this.setState({ isShowEditingSerie: false })
    }

    find = () => {
        this.setState({ isLoading: true });
        firebase.firestore().collection('notes-rps-series').where('estacionamentoId', '==', this.state.estacionamentoId).get().then(async (results) => {
            this.setState({ isLoading: false });
            if (results.docs.length === 0) {
                this.setState({ notFound: true });
                return
            }
            var list = []
            results.docs.forEach(doc => {
                list.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            this.setState({ list: list })
        }).catch(error => {
            this.setState({ isLoading: false });
            this.setState({ notFound: false });
        })
    }

    render() {
        return (
            <>
                <NavBar>
                    {
                        this.state.isLoading ?
                            <div className="card mb-4">
                                <div className="card-body py-3">
                                    <EmptyContent text="Carregando informações..." />
                                </div>
                            </div> :
                            this.state.notFound ?
                                <div className="card mb-4">
                                    <div className="card-body py-3">
                                        <EmptyContent text="Nenhuma configuração de serie de RPS foi localizada, faça login novamente no aplicativo." />
                                    </div>
                                </div> :
                                <div className="card mb-4">
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        Series
                                    </div>
                                    <div className="row">
                                        <div className='col-md-12'>
                                            <div className='table-responsive'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td width='150'><strong>Identificador único</strong></td>
                                                            <td><strong>Nome do aparelho / Setor</strong></td>
                                                            <td><strong>Serie</strong></td>
                                                            <td><strong>RPS Atual</strong></td>
                                                            <td></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.list.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td>{item.deviceIdentifier}</td>
                                                                    <td>{item.deviceName}</td>
                                                                    <td>{item.serie}</td>
                                                                    <td>{item.numero}</td>
                                                                    <td width='10'>
                                                                        <button type="button" onClick={(e) => { this.onSelected(item) }} className="btn btn-primary">
                                                                            <i className="fas fa-edit" />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <Dialog onClose={this.hideEditingSerie} fullWidth maxWidth='sm' open={this.state.isShowEditingSerie}>
                                        <MuiDialogTitle className='text-center'>
                                            Detalhes da configuração de serie
                                        </MuiDialogTitle>
                                        <MuiDialogContent>
                                            {
                                                <>
                                                    <div>
                                                        <label>Nome do aparelho</label>
                                                        <input type="text" value={this.state.serieNome && this.state.serieNome} onChange={(e) => this.setState({ serieNome: e.target.value })} className="form-control" />
                                                    </div>
                                                    <div>
                                                        <label>Serie</label>
                                                        <input type="text" value={this.state.serieSerie && this.state.serieSerie} onChange={(e) => this.setState({ serieSerie: e.target.value })} className="form-control" />
                                                    </div>
                                                    <div>
                                                        <label>RPS Atual</label>
                                                        <input type="text" value={this.state.serieSequencia && this.state.serieSequencia} onChange={(e) => this.setState({ serieSequencia: e.target.value })} className="form-control" />
                                                    </div>
                                                    <div className="alert alert-info my-3" role="alert">
                                                        Caso o estabelecimento esteja trabalhando em modo offline, será necessário atualizar as tabelas manualmente nas configurações do aplicativo.
                                                    </div>
                                                </>
                                            }
                                        </MuiDialogContent>
                                        {
                                            !this.state.isLoadingGenerateNote &&
                                            <MuiDialogActions>
                                                <button onClick={this.hideEditingSerie} className='btn btn-secondary'>Cancelar</button>
                                                <button onClick={this.onEditSerie} className='btn btn-primary'>Salvar informação</button>
                                            </MuiDialogActions>
                                        }
                                    </Dialog>
                                </div>
                    }
                </NavBar>
            </>)
    }
}

export { RPSListar };