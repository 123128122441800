import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { docToItem } from '../transform.docs';
import { updateDoc } from './collectionBaseWorker';

export const updatePrivateParametersRevenda = async ({ id, data }) => {
    await updateDoc({ collection: Collection.PRIVATE_PARAMETERS_REVENDA, id: id, data: data })
}

export const getPrivateParametersRevendaById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.PRIVATE_PARAMETERS_REVENDA).doc(id).get()
    return docToItem(result)
}