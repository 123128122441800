import { getUsuario } from '../config/auth'
import { updateDoc } from '../store/collections/collectionBaseWorker';
import { Collection } from './constants';

export const sendLastAccess = async () => {
    const usuario = getUsuario()
    if (usuario.administrativo === true) {
        return
    }
    const data = {
        lastAccess: new Date(),
        version: '',
        flavor: 'site'
    }
    await updateDoc({ collection: Collection.USERS, id: usuario.id, data: data })
}