
import firebase from '../config/firebase';
import { sendLog } from '../shared/analytics';
import { reloadWindow } from '../shared/utils';
import { getClient, getClientById } from '../store/collections/clienteWorker';
import { docToItem } from '../store/transform.docs';

//Administrador do sistema
export const isAdministrador = () => {
    const usuario = getUsuario()
    if (usuario === null) {
        return false
    }
    return usuario.administrativo === true
}

//Selfparking maker
export const isPlatformOwner = () => {
    const usuario = getUsuario()
    if (usuario === null) {
        return false
    }
    return usuario.platform === true || isOwner()
}

//Revendedor
export const isReseller = () => {
    const usuario = getUsuario()
    if (usuario === null) {
        return false
    }
    return usuario.revendedor === true || isOwner()
}

//Desenvolvedor
export const isDeveloper = () => {
    const usuario = getUsuario()
    if (usuario === null) {
        return false
    }
    return usuario.developer === true || isOwner()
}

export const isOwner = () => {
    const usuario = getUsuario()
    if (usuario === null) {
        return false
    }
    return usuario.owner === true
}

export const isLogado = () => {
    firebase.auth().onAuthStateChanged(user => {
        if (getUsuario() === null) {
            return
        }
        if (!user) {
            sendLog("Autenticacao", "deslogado por token expirado")
            clearSession()
            reloadWindow()
        }
    });
    return getUsuario() !== null
}

//Administrador do sistema
export const isTermsUpdated = () => {
    const usuario = getUsuario()
    if (usuario === null) {
        return false
    }
    return usuario.isTermsUpdated === true
}

export const getUsuario = () => {
    let sessao = getSessao()
    if (sessao !== null) {
        return sessao.usuario
    }
    return null
}

export const getRevendasAssociadas = () => {
    return getUsuario()?.revendasAssociadas ?? []
}

export const setRevenda = (revenda) => {
    const sessao = JSON.parse(localStorage.getItem('sessao'))
    if (sessao !== null) {
        sessao.sessao.revenda = revenda
        setSessao(sessao)
    }
}

export const getUsuarioCompact = () => {
    let user = getSessao()?.usuario
    if (isAdministrador()) {
        return {
            perfilId: user.perfilId,
            id: user.id,
            email: "suporte@site.com.br",
            nome: "Suporte"
        }
    } else {
        if (user) {
            return {
                id: user.id,
                nome: user.nome,
                email: user.email,
                perfilId: user.perfilId
            }
        }
    }
    return null
}

export const setUsuario = (user) => {
    const sessao = JSON.parse(localStorage.getItem('sessao'))
    if (sessao !== null) {
        sessao.sessao.usuario = user
        setSessao(sessao)
    }
}

export const setProfile = (profile) => {
    const sessao = JSON.parse(localStorage.getItem('sessao'))
    if (sessao !== null) {
        sessao.sessao.perfil = profile
        setSessao(sessao)
    }
}

export const getPerfil = () => {
    let sessao = getSessao()
    if (sessao !== null) {
        return sessao.perfil
    }
    return null
}

export const getSessao = () => {
    const sessao = JSON.parse(localStorage.getItem('sessao'))
    if (sessao !== null) {
        return sessao.sessao
    }
    return null
}

export const getEstacionamento = () => {
    const sessao = JSON.parse(localStorage.getItem('sessao'))
    if (sessao !== null) {
        return sessao.sessao.estacionamento
    }
    return null
}

export const getEstacionamentosSelecionados = () => {
    const selecteds = localStorage.getItem('parksSelecteds')
    if (selecteds && selecteds !== "") {
        return JSON.parse(selecteds)
    }
    return []
}

export const getEstacionamentoCompact = () => {
    const estacionamento = getEstacionamento()
    if (estacionamento) {
        return {
            id: estacionamento.id,
            celular: estacionamento.celular,
            documento: estacionamento.documento,
            horarios: estacionamento.horarios,
            nome: estacionamento.nome,
            endereco: estacionamento.endereco,
        }
    }
    return null
}

export const getLicencaAtual = () => {
    const sessao = JSON.parse(localStorage.getItem('sessao'))
    if (sessao !== null) {
        return sessao.sessao.licenca
    }
    return null
}

export const setLicencaAtual = (licenca) => {
    const sessao = JSON.parse(localStorage.getItem('sessao'))
    if (sessao !== null) {
        sessao.sessao.licenca = licenca
        setSessao(sessao)
    }
}

export const setEstacionamento = (estacionamento) => {
    const sessao = JSON.parse(localStorage.getItem('sessao'))
    if (sessao !== null) {
        sessao.sessao.estacionamento = estacionamento
        setSessao(sessao)
    }
}

export const getEstacionamentos = () => {
    const sessao = JSON.parse(localStorage.getItem('sessao'))
    if (sessao !== null) {
        return sessao.estacionamentos
    }
    return null
}

export const getRevendaIdByPark = () => {
    return getEstacionamento()?.revendaId
}

export const getRevenda = () => {
    const sessao = JSON.parse(localStorage.getItem('sessao'))
    if (sessao !== null) {
        return sessao.sessao.revenda
    }
    return null
}

export const getRevendaWhatsApp = () => {
    const revenda = getRevenda()
    return revenda.whatsAppSuporte?.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "") ?? ""
}

export const getRevendaLinkWhatsApp = () => {
    const phone = getRevendaWhatsApp()
    return `https://api.whatsapp.com/send?phone=55${phone}`
}

export const getMinimumVersions = () => {
    const minimumVersions = JSON.parse(localStorage.getItem('minimumVersions'))
    if (minimumVersions !== null) {
        return minimumVersions
    }
    return null
}

export const isApp = () => {
    return false
    // const value = localStorage.getItem('isApp')
    // return value === 'true'
}

export const clearSession = async () => {
    setSessao(null)
    setCliente(null)
    await firebase.auth().signOut()
}

export const setSessao = (sessao) => {
    if (sessao?.sessao?.cliente) {
        setCliente(sessao?.sessao?.cliente)
    }
    localStorage.setItem('sessao', JSON.stringify(sessao))
}

export const setUsersSuggestion = (user) => {
    localStorage.setItem('usersSuggestion', JSON.stringify(user))
}

export const getUsersSuggestion = () => {
    const usersSuggestion = JSON.parse(localStorage.getItem('usersSuggestion'))
    return usersSuggestion ?? []
}

export const getCliente = () => {
    const cliente = JSON.parse(localStorage.getItem('cliente'))
    if (cliente !== null) {
        return cliente
    }
    return { id: '', nome: '' }
}

export const setCliente = (cliente) => localStorage.setItem('cliente', JSON.stringify(cliente))

export const setIsApp = (isApp) => localStorage.setItem('isApp', isApp)

export const setMinimumVersions = (minimumVersions) => localStorage.setItem('minimumVersions', JSON.stringify(minimumVersions))

export const alterarEstacionamento = async (parkId) => {
    const doc = await firebase.firestore().collection('estacionamentos').doc(parkId).get()
    let estacionamento = docToItem(doc)
    const client = await getClientById({ id: estacionamento.clienteId })
    setCliente(client)
    setEstacionamento(estacionamento)
}