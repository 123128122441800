const INITIAL_STATE = { };

function usuarioReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case 'LOG_IN':
            return { ...state, sessao: action.sessao };
        case 'LOG_OUT':
            return { ...state, sessao: null };
        default: 
            return state;
    }
}

export default usuarioReducer;