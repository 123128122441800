import React from 'react'
import { DatePickerDialog, EmptyContent, Loading } from '../../components/common/commons.js'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getCliente, getEstacionamento, getEstacionamentoCompact, getRevendaIdByPark, getUsuario, getUsuarioCompact, isAdministrador } from '../../config/auth.js';
import { addTicket, getAllTicketsOpenByStatusAndParkId, getRandomNumberInTicketByParkId, getTicketById, getTicketByNumberAndParkId, getTicketByPlateAndParkId, removeTicketById } from "../../store/collections/ticketWorker"
import { ticketPermanenceFromNowDescription, ticketPermanenceFromNowDescriptionEdited, isValidDocument, isValidCPF, reloadWithAlert, reloadWindow, isValidatePlate, generateUUIDV7, takeIfIsNotNullOrEmpty, toCurrency, toastWarning, toastError, toastLoading, toastDismissLoading, toastSuccess, delay, isNullOrEmpty, valueByMaximunChars, normalizedField } from '../../shared/utils.js';
import { searchCEP } from '../../shared/cepWorker';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { addNewNote } from '../../store/collections/newNotesWorker';
import { updateTicket } from '../../store/collections/ticketWorker';
import { getFormasPagamentosAtivas } from '../../store/collections/formaPagamentoWorker.js';
import { getAllActivePaymentTables, getAllPaymentTables, getCurrentPriceTableByDayAndTime } from '../../store/collections/paymentTableWorker.js';
import NumberFormat from 'react-number-format';
import { activeCashier } from '../../store/collections/cashierWorker.js';
import { getMonthlyById } from '../../store/collections/mensalistaWorker.js';
import { urlTicketOnlineFromTicket } from '../../shared/url-manager.js';
import { ProductsSimplyList } from '../dashboard/dashboard-products.js';
import { AlertDanger, AlertInfo, AlertSecondary, AlertSuccess, AlertWarning } from '../../shared/alert-manager.js';
import { getParameterByParkId } from '../../store/collections/parameterWorker.js';
import { getTicketSettings } from '../../store/collections/ticketSettings.js';
import { getAllVeiculosByMonthlyIdAndAccessNumber, getVehicleByPlateAndClientId } from '../../store/collections/veiculosWorker.js';
import { getAccessCardByParkIdAndNumber } from '../../store/collections/accessCardWorker.js';
import { getPrismSettingsByParkId } from '../../store/collections/prismWorker.js';
import { getAllBranchesAndModels } from '../../store/collections/branchesAndModelsWorker.js';
import { getRemoteConfigByKey } from '../../store/collections/remoteConfigWorker.js';
import { getAllProducts } from '../../store/collections/productWorker.js';
import { getAllSectoresByParkId, getSectorById } from '../../store/collections/setoresWorker.js';
import { addOrUpdateContactInfo, getContactInfoByPlateAndClientId } from '../../store/collections/contactInfoWorker.js';
import { getAccreditedById, getAccreditedByPlateAndParkId } from '../../store/collections/accreditedWorker.js';
import ReactToPrint from 'react-to-print'
import { PrinterTicketComponent } from '../../shared/printer/printer-ticket.js';
import { QRCodeSVG } from 'qrcode.react';
import { getAllRelationshipsByParkId, getRelationshipById } from '../../store/collections/relationshipWorker.js';
import { calculateTotalByTicket } from '../../shared/calculator/parking.calculate.js';
import { getProfileById } from '../../store/collections/profileWorker.js';
import { NewNoteWidget } from '../nota-fiscal/novo/index'
import { TicketStatus } from '../../shared/constants.js';
import { TicketButton, UserPermissionButton } from '../operation/permission.js';
import { getAllPaymentsByParkId } from '../../store/collections/paymentWorker.js';
import { convertHoursToMinutes } from '../../shared/calculator/utils.parking.calculate.js';

class TicketSearchAndRegisterPaymentModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            ticket: props.ticket,
            state: "",
            title: props.title,
            isShowingModal: true,
            status: props.status,
            auxItems: [],
            items: []
        }
    }

    componentDidMount = () => {
        const ticket = this.state.ticket
        if (ticket) {
            this.onSelectedTicket(ticket)
        } else {
            this.setState({ state: "search" })
        }
    }

    onSelectedTicket = (item) => {
        this.setState({ ticketSelected: item })
        this.setState({ state: "payment" })
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                {
                    this.state.state === "search" && <TicketListModal
                        title={this.state.title}
                        status={["Pago e Estacionado", "Estacionado", "Pago e Aguardando"]}
                        onSelected={this.onSelectedTicket}
                        onClose={this.onClose} />
                }
                {
                    this.state.state === "payment" && <TicketRegisterPaymentModal
                        showCashiers={false}
                        ticket={this.state.ticketSelected}
                        title={this.state.title}
                        onClose={this.onClose} />
                }
            </>)
    }
}

class TicketRegisterPaymentModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            showCashiers: props.showCashiers,
            ticket: props.ticket,
            isShowingModal: true,
            status: props.status,
            deliveryDate: moment(),
            editingParkingValue: 0.0,
            editingProductValue: 0.0,
            editingServiceValue: 0.0,
            editingDiscountValue: 0.0,
            editingParkingTotalValue: 0.0,
            statusPaymentTicket: props.statusPaymentTicket ?? "Pago e Entregue",
            generatedTicket: props.generatedTicket,
            ticketFound: props.ticketFound,
            relationships: [],
            isMonthly: false,
            profile: null,
            parameter: null,
            isEditingDeliveryDate: false,
            isEditingLimitPermanenceDate: false,
            dayliesSelected: 0,
            allDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
            showDailyModal: false,
            limitPermanenceInDate: null
        }
    }

    componentDidMount = async () => {
        this.setState({ state: "loading" })

        const user = getUsuarioCompact()
        const profile = await getProfileById({ id: user.perfilId })
        this.setState({ profile: profile })
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ parameter: parameter })
        const monthlyId = this.state.ticket.monthly?.id
        if (monthlyId) {
            const monthly = await getMonthlyById(monthlyId)
            this.setState({ monthly: monthly })
            this.setState({ state: "fill" })
            const dueDate = moment(monthly.plano.dataFatura.toDate())
            const today = moment()
            if (dueDate.isSameOrAfter(today, "day")) {
                this.setState({ isMonthly: true })
                if ((this.state.ticket.produtos?.length ?? 0) > 0) {
                    this.loadTotalValues()
                }
            }
        } if (this.state.ticket.accredited) {
            this.setState({ isAccredited: true })
            if ((this.state.ticket.produtos?.length ?? 0) > 0) {
                this.loadTotalValues()
            }
        }
        const formasPagamentos = await getFormasPagamentosAtivas({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ formasPagamentos: formasPagamentos })
        var paymentTables = await getAllActivePaymentTables({ estacionamentoId: this.state.estacionamentoId })
        const tablesByType = paymentTables.filter(e => e.tipo.toLowerCase() === this.state.ticket.typeVehicler.toLowerCase())
        if (tablesByType.length !== 0) {
            this.setState({ paymentTables: tablesByType }, () => {
                const tableSelectedInCheckIn = this.state.ticket.tableSelectedInCheckIn
                if (tableSelectedInCheckIn) {
                    this.setState({ currentPaymentTable: tableSelectedInCheckIn })
                    this.changeTablePayment(tableSelectedInCheckIn?.id)
                } else if (tablesByType.length === 1) {
                    this.changeTablePayment(tablesByType[0].id)
                }
            })
        } else {
            this.setState({ paymentTables: paymentTables }, () => {
                const tableSelectedInCheckIn = this.state.ticket.tableSelectedInCheckIn
                if (tableSelectedInCheckIn) {
                    this.setState({ currentPaymentTable: tableSelectedInCheckIn })
                    this.changeTablePayment(tableSelectedInCheckIn?.id)
                }
            })
        }
        if (!this.state.currentPaymentTable) {
            this.setState({ showTotals: false })
            this.setState({ warningMessage: "É preciso informar a tabela de preço para este ticket." })
        }
        this.setState({ state: "fill" })
        this.loadRelationships()
    }

    loadRelationships = async () => {
        const user = getUsuario()
        var relationships = await getAllRelationshipsByParkId({ estacionamentoId: this.state.estacionamentoId })
        if (relationships.length === 0) {
            this.setState({ typeRelationshipButton: "empty" })
        } else if (isNullOrEmpty(user.relationships)) {
            this.setState({ typeRelationshipButton: "blocked" })
        } else {
            if (user.relationships?.length === relationships.length) {
                this.setState({ typeRelationshipButton: "fill" })
                this.setState({ relationships: relationships }, () => {
                    this.changeRelationship(this.state.ticket.relationshipApplied?.id)
                })
            } else {
                relationships = relationships.filter(e => user.relationships?.map(e => e.value).indexOf(e.id) !== -1)
                if (relationships.length > 0) {
                    this.setState({ relationships: relationships }, () => {
                        this.changeRelationship(this.state.ticket.relationshipApplied?.id)
                    })
                    this.setState({ typeRelationshipButton: "fill-user" })
                }
            }
        }
    }

    loadTotalValues = async () => {
        this.setState({ warningMessage: null })
        var type = ""
        if (this.state.isMonthly) {
            type = "MONTHLY_DELIVERY"
        } else if (this.state.ticket.accredited) {
            type = "ACCREDITED_DELIVERY"
        } else if (this.state.statusPaymentTicket === "Pago e Entregue") {
            type = "NORMAL_DELIVERY"
        } else {
            if (this.state.currentPaymentTable?.habilitarVendaDeDiaria) {
                type = "EARLY_PAYMENT_NORMAL_DELIVERY"
            } else {
                type = "EARLY_DELIVERY"
            }
        }
        const result = await calculateTotalByTicket({
            startDate: this.state.ticket.createAt.toDate(),
            endDate: this.state.deliveryDate,
            ticket: this.state.ticket,
            table: this.state.currentPaymentTable,
            partnership: this.state.relationshipSelected,
            type: type
        })
        if (result.success) {
            if (this.state.ticket.status === "Cancelado") {
                this.setState({ dangerMessage: "Este ticket esta cancelado." })
                this.setState({ state: "fill" })
                this.setState({ showTotals: false })
                return
            }
            if (this.state.ticket.status === "Pago e Entregue") {
                this.setState({ successMessage: "Este ticket já foi entregue." })
                this.setState({ state: "fill" })
                this.setState({ showTotals: false })
                return
            }
            this.setState({ showTotals: true })
            this.setState({ limitPermanenceInDate: moment(result.data.limitPermanenceInDate) })
            this.setState({ editingProductValue: result.data.productsValue })
            this.setState({ editingServiceValue: result.data.servicesValue })
            this.setState({ editingDiscountValue: result.data.discountValue })
            this.setState({ editingParkingValue: result.data.parkingValue })
            this.setState({ editingParkingTotalValue: result.data.totalValue })
            if (this.state.ticket.status === "Pago e Estacionado" && result.data.totalValue === 0) {
                this.setState({ successMessage: "Este ticket já teve pagamentos em outro momento." })
            }
            if (result.data.totalValue > 0) {
                this.setState({ isMonthly: false })
                this.setState({ isAccredited: false })
            }
        } else {
            this.setState({ showTotals: false })
            this.setState({ warningMessage: result.message })
        }
        this.setState({ state: "fill" })
    }

    changeTablePayment = async (value, update) => {
        const paymentTable = this.state.paymentTables.find(e => e.id === value)
        if (paymentTable) {
            this.setState({ currentPaymentTable: paymentTable })
            this.setState({ paymentTableSelectedId: value }, () => {
                this.loadTotalValues()
            })
            if (paymentTable) {
                this.setState({ buttonState: paymentTable.permitirPagamentoAntecipado ? "can-pay-in" : "out" })
            }
            // if (paymentTable.habilitarVendaDeDiaria) {
            //     this.setState({ dayliesSelected: 1 })
            //     const minutes = convertHoursToMinutes({ value: paymentTable.periodoMaximo ?? "00:00" })
            //     const totaMinutes = minutes * 1
            //     const newDeliveryDate = moment(this.state.ticket.createAt.seconds * 1000).add(totaMinutes, "minutes")
            //     this.setState({ deliveryDate: newDeliveryDate }, () => {
            //         this.loadTotalValues()
            //     })
            // } else {
            //     this.setState({ dayliesSelected: 0 })
            // }
            if (update) {
                this.saveTablePayment(paymentTable)
            }
        }
    }

    saveTablePayment = async (paymentTable) => {
        toastLoading("Alterando tabela de preço...")
        const ticket = this.state.ticket
        const last = ticket.tableSelectedInCheckIn
        ticket.tableChangesLogged = []
        ticket.tableSelectedInCheckIn = paymentTable
        const user = getUsuarioCompact()
        var message = ""
        if (last) {
            message = `Tabela de preço alterada de ${last.nome} para ${paymentTable.nome}`
        } else {
            message = `Tabela de preço adicionada ${paymentTable.nome}`
        }
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        ticket.changeLogged.push({
            message: message,
            createdAt: new Date(),
            createdBy: {
                id: user.id,
                nome: user.nome,
                email: user.email
            }
        })
        this.setState({ ticket: ticket })
        await updateTicket(ticket.id, ticket)
        await delay(500)
        toastDismissLoading()
        toastSuccess("Tabela de preço alterada com sucesso.")
    }

    changeDeliveryDate = (newValue) => {
        this.setState({ deliveryDate: newValue }, () => {
            this.loadTotalValues()
        })
    }

    changeLimitPermanenceDate = (newValue) => {
        if (newValue.isAfter(moment())) {
            this.setState({ limitPermanenceInDate: newValue })
        } else {
            toastWarning("Não é possível definir uma data máxima de saída menor que hoje.")
        }
    }

    changeRelationship = async (value) => {
        this.setState({ relationshipSelectedId: value })
        const relationshipSelected = this.state.relationships.filter(e => e.id === value)[0]
        this.setState({ relationshipSelected: relationshipSelected }, () => {
            this.loadTotalValues()
        })
    }

    showRelationshipByUser = async (user) => {
        if (isNullOrEmpty(user.relationships)) {
            toastWarning("Este usuário não possui convênios associados.")
        } else {
            this.setState({ relationshipAllowedByUser: user })
            var relationships = await getAllRelationshipsByParkId({ estacionamentoId: this.state.estacionamentoId })
            relationships = relationships.filter(e => user.relationships?.map(e => e.value).indexOf(e.id) !== -1)
            if (relationships.length > 0) {
                this.setState({ relationships: relationships })
                this.setState({ typeRelationshipButton: "fill-user" })
            } else {
                toastWarning("Este usuário não possui convênios associados para este estabelecimento.")
            }
        }
    }

    changeTicketStatus = async (e) => {
        this.setState({ statusPaymentTicket: e.target.value }, () => {
            this.loadTotalValues()
        })
    }

    confirmNewPayment = async (statusPaymentTicket) => {
        const cashier = await activeCashier({ estacionamentoId: this.state.estacionamentoId })
        if (!cashier) {
            toastWarning("É preciso ter um caixa aberto para executar esta ação.")
            return
        }
        if (moment(this.state.ticket.createAt.seconds * 1000) > this.state.deliveryDate) {
            toastWarning("O horário da saída não pode ser menor que o horário da entrada.")
            return
        }
        if (this.state.editingParkingTotalValue !== 0 && !this.state.isMonthly && !this.state.isAccredited && !this.state.paymentTableSelectedId) {
            toastWarning("Selecione a tabela de preço.")
            return
        }
        if (this.state.editingParkingTotalValue !== 0 && !this.state.isMonthly && !this.state.isAccredited && !this.state.formaDePagamentoSelecionada) {
            toastWarning("Selecione a forma de pagamento.")
            return
        }
        if (this.state.note) {
            if (this.state.note.tomador) {
                if (!isValidDocument(this.state.note.document)) {
                    toastWarning("Informe um documento válido.")
                    return
                }
                if (this.state.note.name === "") {
                    toastWarning("Informe o nome do tomador.")
                    return
                }
                if (this.state.note.cep === "" && this.state.parameter.habilitarEmissaoNotaCampoEnderecoObrigatorio && isValidCPF(this.state.note.document)) {
                    toastWarning("Informar o CEP é obrigatório!")
                    return
                }
                var isValidCep = await searchCEP(this.state.note.cep)
                if (isValidCep === null && this.state.note.cep !== "") {
                    toastWarning("Informe um cep válido!")
                    return
                }
                if (this.state.note.numero === "" && this.state.note.cep !== "") {
                    toastWarning("Informe o numero do endereço.")
                    return
                }
            }
        }
        var confirm = window.confirm(this.state.editingParkingTotalValue === 0 ? "Deseja mesmo entregar este veículo?" : `Deseja mesmo registrar este pagamento no ticket?`);
        if (confirm) {
            this.setState({ state: "loading" })
            const user = getUsuario()
            const paymentTable = this.state.paymentTables.filter(e => e.id === this.state.paymentTableSelectedId)[0]
            const formaPagamento = this.state.formasPagamentos.filter(e => e.id === this.state.formaDePagamentoSelecionada)[0]
            var ticket = this.state.ticket
            if (!ticket.changeLogged) {
                ticket.changeLogged = []
            }
            if (!ticket.statusLogged) {
                ticket.statusLogged = []
            }
            if (!ticket.paymentsLogged) {
                ticket.paymentsLogged = []
            }
            if (!ticket.caixasIds) {
                ticket.caixasIds = []
            }
            if (this.state.relationshipSelected && this.state.relationshipAllowedByUser) {
                const allowedUser = this.state.relationshipAllowedByUser
                ticket.changeLogged.push({
                    message: `Convênio ${this.state.relationshipSelected?.nome} atribuido com sucesso.`,
                    createdAt: new Date(),
                    createdBy: {
                        id: allowedUser.id,
                        nome: allowedUser.nome,
                        email: allowedUser.email
                    }
                })
            }
            if (this.state.dayliesSelected > 1) {
                ticket.changeLogged.push({
                    message: `Diarias adicionadas: ${this.state.dayliesSelected}`,
                    createdAt: new Date(),
                    createdBy: {
                        id: user.id,
                        nome: user.nome,
                        email: user.email
                    }
                })
            }
            ticket.changeLogged.push({
                message: `Pagamento registrado com sucesso: ${toCurrency(this.state.editingParkingTotalValue)}`,
                createdAt: new Date(),
                createdBy: {
                    id: user.id,
                    nome: user.nome,
                    email: user.email
                }
            })
            const userCompact = {
                id: user.id,
                nome: user.nome,
                perfilId: user.perfilId,
                email: user.email
            }
            var now = moment(this.state.ticket.createAt.toDate())
            var end = moment(new Date())
            var duration = moment.duration(end.diff(now)).asMinutes() ?? 0
            const payment = {
                caixaId: cashier.id,
                identifier: generateUUIDV7(),
                user: userCompact,
                formaPagamento: formaPagamento,
                resumo: {
                    valorEstacionamento: this.state.editingParkingValue,
                    valorProdutos: this.state.editingProductValue,
                    valorServicos: this.state.editingServiceValue,
                    valorDesconto: this.state.editingDiscountValue,
                    valorCobrado: this.state.editingParkingTotalValue,
                    startParking: this.state.ticket.createAt,
                    finishParking: new Date(),
                    permanencia: duration,
                    convenio: this.state.relationshipSelected,
                    permanenciaMaxima: end.toDate(),
                    products: this.state.ticket.produtos,
                    type: statusPaymentTicket === "Pago e Entregue" ? "NORMAL_DELIVERY" : "EARLY_DELIVERY",
                    tabelaPreco: paymentTable,
                    order: formaPagamento?.tipo && {
                        tipo: `ADMINISTRATIVO - ${formaPagamento.tipo?.toUpperCase()}`
                    },
                },
                createAt: new Date(),
                canceled: false,
                online: false
            }
            ticket.paymentsLogged.push(payment)
            ticket.statusLogged.push({
                status: statusPaymentTicket,
                createBy: userCompact,
                createAt: new Date(),
                currentCashier: cashier.id,
                canceled: false,
                online: false,
            })
            ticket.isDeleted = false
            ticket.caixasIds.push(cashier.id)
            ticket.status = statusPaymentTicket
            ticket.caixaFechado = this.state.caixaFechado
            if (this.state.currentPaymentTable?.habilitarVendaDeDiaria === true) {
                ticket.limitPermanenceEnabled = true
                ticket.limitPermanence = this.state.limitPermanenceInDate?.toDate()
            } else {
                ticket.limitPermanenceEnabled = false
            }
            if (statusPaymentTicket === "Pago e Entregue") {
                ticket.closedAt = this.state.deliveryDate.toDate()
                ticket.closedBy = userCompact.email
            }
            const newNote = await this.generateNote(ticket)
            ticket.newNote = newNote
            await updateTicket(ticket.id, ticket)
            this.setState({ state: "receipt" })
        }
    }

    fetchCEP = async (value) => {
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ cep: result.cep })
            this.setState({ logradouro: result.logradouro })
            this.setState({ complemento: result.complemento })
            this.setState({ bairro: result.bairro })
            this.setState({ cidade: result.localidade })
            this.setState({ estado: result.uf })
        }
    }

    generateNote = async (ticket) => {
        if (this.state.note) {
            const valor = this.state.editingParkingValue
            if (valor > 0) {
                const user = getUsuarioCompact()
                var data = {
                    revendaId: this.state.revendaId,
                    clienteId: this.state.clienteId,
                    estacionamentoId: this.state.estacionamentoId,
                    identifier: ticket.id,
                    type: "ticket",
                    createdBy: user,
                    createdAt: new Date(),
                    status: [{
                        createdAt: new Date(),
                        status: "NOTE_PROCESSING",
                        createdBy: user
                    }],
                    currentStatus: "NOTE_PROCESSING",
                    valor: valor,
                    issuer: this.state.note.tomador
                }
                if (this.state.note.tomador) {
                    data.document = this.state.note.document
                    data.name = this.state.note.name?.substring(0, 30)
                    data.cep = this.state.note.cep
                    data.numero = this.state.note.numero
                }
                if (this.state.note.cep) {
                    this.fetchCEP(this.state.note.cep)
                }
                const newNote = await addNewNote({ data: data })
                return newNote
            }
        }
    }

    reloadModal = () => {
        reloadWindow()
    }

    hideModal = async () => {
        if (this.state.state === "receipt") {
            reloadWindow()
        } else {
            if (this.state.ticketFound == false) {
                await removeTicketById(this.state.ticket.id)
            }
            this.setState({ isShowingModal: false })
            if (this.props.onClose) {
                this.props.onClose()
            }
        }
    }

    onChangeNewNoteForm = (data) => {
        this.setState({ note: data })
    }

    showDeliveryEditor = () => {
        this.setState({ isEditingDeliveryDate: true });
    }

    hideDeliveryEditor = () => {
        this.setState({ isEditingDeliveryDate: false });
    }

    confirmDeliveryDate = (date) => {
        this.setState({ deliveryDate: date }, () => {
            this.loadTotalValues()
        })
    }

    showVendaDeDiariaModal = () => {
        this.setState({ showDailyModal: true })
    }

    hideVendaDeDiariaModal = () => {
        this.setState({ showDailyModal: false })
    }

    confirmDailySelected = (daily) => {
        this.setState({ dayliesSelected: daily })
        this.setState({ showDailyModal: false })
        const minutes = convertHoursToMinutes({ value: this.state.currentPaymentTable?.periodoMaximo ?? "00:00" })
        const totaMinutes = minutes * parseInt(daily)
        const newDeliveryDate = moment(this.state.ticket.createAt.seconds * 1000).add(totaMinutes, "minutes")
        this.setState({ deliveryDate: newDeliveryDate }, () => {
            this.loadTotalValues()
        })
    }

    showLimitPermanenceModal = () => {
        this.setState({ isEditingLimitPermanenceDate: true })
    }

    hideLimitedPermanenceModal = () => {
        this.setState({ isEditingLimitPermanenceDate: false })
    }

    render() {
        return (
            <>
                <Dialog maxWidth='xs' fullWidth onClose={this.hideModal} open={this.state.isShowingModal}>
                    {
                        this.state.state !== "loading" &&
                        <MuiDialogTitle className='text-center'>
                            {
                                this.state.isMonthly ? <span>Entregar veículo</span> : <span>Registrar pagamento</span>
                            }
                        </MuiDialogTitle>
                    }
                    <MuiDialogContent>
                        {
                            this.state.state === "loading" &&
                            <div className='text-center'>
                                <Loading />
                                <h5>{this.state.messageLoading}</h5>
                            </div>
                        }
                        {
                            this.state.state === "fill" &&
                            <div className='col-lg-12'>
                                <div className='col-lg-12'>
                                    {
                                        this.state.ticket.monthly &&
                                        <a rel="noopener noreferrer" target="_blank" href={'/visualizar/mensalista/' + this.state.ticket.monthly.id}>
                                            <div className="alert alert-success">
                                                <button type="button" className="btn btn-success">
                                                    <i className="fas fa-calendar" />
                                                </button>
                                                <span className='mx-2'>
                                                    <strong>Mensalista: </strong>
                                                    {this.state.ticket.monthly?.nome}
                                                </span>
                                            </div>
                                        </a>
                                    }
                                    {
                                        this.state.warningMessage &&
                                        <div className="mt-2">
                                            <AlertWarning message={this.state.warningMessage} />
                                        </div>
                                    }
                                    {
                                        this.state.dangerMessage &&
                                        <div className="mt-2">
                                            <AlertDanger message={this.state.dangerMessage} />
                                        </div>
                                    }
                                    {
                                        this.state.successMessage &&
                                        <div className="mt-2">
                                            <AlertSuccess message={this.state.successMessage} />
                                        </div>
                                    }
                                    <div className='row'>
                                        <AlertSecondary tag="">
                                            <div>
                                                <strong>Ticket: </strong>
                                                <Tooltip title={this.state.ticket.id} placement="top">
                                                    <span>{this.state.ticket.ticket}</span>
                                                </Tooltip>
                                            </div>
                                            <div><strong>Entrada: </strong> {moment(this.state.ticket.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</div>
                                            <div className='row align-items-center'>
                                                <div className="col"><strong>Saída: </strong> {this.state.deliveryDate.format("DD/MM/YYYY [às ]HH:mm")}</div>
                                                {
                                                    (this.state.parameter.habilitarDataSaida || isAdministrador()) &&
                                                    <Tooltip title="Editar horário de saída" placement="top">
                                                        <button className="col-auto btn btn-secondary btn-sm text-white mx-2" onClick={this.showDeliveryEditor}>
                                                            <i className="fas fa-clock" />
                                                        </button>
                                                    </Tooltip>
                                                }
                                            </div>
                                            <div><strong>Permanência: </strong>
                                                {ticketPermanenceFromNowDescriptionEdited(this.state.ticket, this.state.deliveryDate)}
                                            </div>
                                            {
                                                this.state.limitPermanenceInDate &&
                                                <div>
                                                    {
                                                        <div className='row align-items-center'>
                                                            <div className="col"><strong>Permanência máxima: </strong> <br />{this.state.limitPermanenceInDate.format("DD/MM/YYYY [às ]HH:mm")}</div>
                                                            {
                                                                (this.state.parameter.habilitarDataSaida || isAdministrador()) &&
                                                                <UserPermissionButton permission="TICKET_EDIT_MAX_PERMANENCE" action={this.showLimitPermanenceModal} className='col-auto btn btn-secondary btn-sm text-white mx-2'>
                                                                    <Tooltip title="Editar permanência máxima" placement="top">
                                                                        <i className="fas fa-clock" />
                                                                    </Tooltip>
                                                                </UserPermissionButton>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </AlertSecondary>
                                        {
                                            (this.state.ticket.produtos?.length ?? 0) > 0 && <div>
                                                <strong>Produtos e serviços: </strong>
                                                <ProductsSimplyList showSellers={false} tickets={[this.state.ticket]} />
                                            </div>
                                        }
                                        {
                                            this.state.showTotals && <>
                                                {
                                                    this.state.editingProductValue > 0 &&
                                                    <div className='col-lg-6'>
                                                        <label>Produtos</label>
                                                        <h3 className='text-success'>
                                                            {toCurrency(this.state.editingProductValue)}
                                                        </h3>
                                                    </div>
                                                }
                                                {
                                                    this.state.editingServiceValue > 0 &&
                                                    <div className='col-lg-6'>
                                                        <label>Serviços</label>
                                                        <h3 className='text-success'>
                                                            {toCurrency(this.state.editingServiceValue)}
                                                        </h3>
                                                    </div>
                                                }
                                                {
                                                    this.state.editingParkingValue > 0 &&
                                                    <div className='col-lg-6'>
                                                        <label>Estacionamento</label>
                                                        <h3 className='text-success'>
                                                            {toCurrency(this.state.editingParkingValue)}
                                                        </h3>
                                                    </div>
                                                }
                                                {
                                                    this.state.editingDiscountValue > 0 &&
                                                    <div className='col-lg-6'>
                                                        <label>Desconto</label>
                                                        <h3 className='text-danger'>
                                                            -{toCurrency(this.state.editingDiscountValue)}
                                                        </h3>
                                                    </div>
                                                }
                                                {
                                                    !this.state.isMonthly &&
                                                    <div className='col-lg-6'>
                                                        <label>Total</label>
                                                        <h3 className='text-success'>
                                                            {toCurrency(this.state.editingParkingTotalValue)}
                                                        </h3>
                                                    </div>
                                                }

                                            </>
                                        }
                                    </div>
                                    {
                                        !this.state.isMonthly && !this.state.isAccredited && this.state.ticket.status === "Estacionado" && <>
                                            <div>
                                                <label>Tabela de preço</label>
                                                <div class="input-group mb-1">
                                                    <select className='form-select' value={this.state.paymentTableSelectedId} disabled={this.state.profile?.changePaymentTableOfTicket == false} onChange={(e) => this.changeTablePayment(e.target.value, true)}>
                                                        <option value=''>Selecione</option>
                                                        {
                                                            this.state.paymentTables?.map(item => { return <option value={item.id}>{item.nome}</option> })
                                                        }
                                                    </select>
                                                    {
                                                        this.state.currentPaymentTable?.habilitarVendaDeDiaria &&
                                                        <span class="input-group-btn text-center">
                                                            <button className="btn btn-secondary" onClick={(e) => this.showVendaDeDiariaModal()}>
                                                                {this.state.dayliesSelected} Diária
                                                            </button>
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                this.state.typeRelationshipButton == "blocked" &&
                                                <UserPermissionButton className="btn btn-secondary w-100 mt-2" permission="INFORM_RELATIONSHIP" action={(e) => this.showRelationshipByUser(e)}>
                                                    <i className="fas fa-handshake mx-2" />
                                                    Informar convênio
                                                </UserPermissionButton>
                                            }
                                            {
                                                this.state.typeRelationshipButton === "fill-user" &&
                                                <div>
                                                    <label>Convênio</label>
                                                    <div class="input-group mb-3">
                                                        <select className='form-control form-select' value={this.state.relationshipSelectedId} onChange={(e) => this.changeRelationship(e.target.value)}>
                                                            <option value=''>Selecione</option>
                                                            {
                                                                this.state.relationships?.map(item => { return <option value={item.id}>{item.nome}</option> })
                                                            }
                                                        </select>
                                                        <span class="input-group-btn">
                                                            <UserPermissionButton className="btn btn-secondary" permission="INFORM_RELATIONSHIP" action={(e) => this.showRelationshipByUser(e)}>
                                                                <i className="fas fa-handshake mx-2" />
                                                            </UserPermissionButton>
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.typeRelationshipButton == "fill" &&
                                                <>
                                                    <label>Convênio</label>
                                                    <select className='form-select' value={this.state.relationshipSelectedId} onChange={(e) => this.changeRelationship(e.target.value)}>
                                                        <option value=''>Selecione</option>
                                                        {
                                                            this.state.relationships?.map(item => { return <option value={item.id}>{item.nome}</option> })
                                                        }
                                                    </select>
                                                </>
                                            }
                                            <label>Forma de pagamento</label>
                                            <select className='form-select' value={this.state.formaDePagamentoSelecionada} onChange={(e) => this.setState({ formaDePagamentoSelecionada: e.target.value })}>
                                                <option value=''>Selecione</option>
                                                {
                                                    this.state.formasPagamentos?.map(item => { return <option value={item.id}>{item.nome === item.tipo ? item.nome : `${item.tipo} - ${item.nome}`}</option> })
                                                }
                                            </select>
                                        </>
                                    }
                                    {
                                        <div className='mt-3'>
                                            <NewNoteWidget
                                                screen="Pagamento de ticket"
                                                onChangeValue={this.onChangeNewNoteForm} />
                                        </div>
                                    }
                                    {
                                        isAdministrador() &&
                                        <AlertDanger tag="administradores">
                                            <div className="form-check form-switch mt-2">
                                                <input className="form-check-input text-success" id="caixaFechado" checked={this.state.caixaFechado} onChange={(e) => this.setState({ caixaFechado: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="caixaFechado">
                                                    Dar baixa no ticket
                                                </label>
                                            </div>
                                        </AlertDanger>
                                    }
                                </div>
                            </div>
                        }
                        {
                            this.state.state === "receipt" &&
                            <div className='col-lg-12 text-center'>
                                <QRCodeSVG value={urlTicketOnlineFromTicket(this.state.ticket, "payment-receipt")} size={250} />
                                <h4 className='text-success mt-3'>
                                    {
                                        this.state.isMonthly ?
                                            <span>Veículo entregue com sucesso.</span> :
                                            <span>Veículo pago com sucesso.</span>
                                    }
                                </h4>
                                <h6>O cliente pode escanear o QRCode acima para visualizar o comprovante.</h6>
                            </div>
                        }
                    </MuiDialogContent>
                    {
                        this.state.state !== "loading" &&
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                {
                                    this.state.ticket.status === "Cancelado" || this.state.ticket.status === "Pago e Entregue" &&
                                    <a href={`/buscar/ticket?id=${this.state.ticket?.id}`} className='btn btn-success' target='_blank'>
                                        Ver ticket
                                    </a>
                                }
                                {
                                    this.state.state === "receipt" && (this.state.ticket.status !== "Cancelado") &&
                                    <>
                                        <div style={{ display: "none" }}>
                                            <PrinterTicketComponent
                                                ticket={this.state.ticket}
                                                park={getEstacionamento()}
                                                ref={el => (this.componentRef = el)} />
                                        </div>
                                        <ReactToPrint
                                            trigger={() => {
                                                return <a href="#" className='btn btn-secondary'>Imprimir</a>
                                            }}
                                            content={() => this.componentRef}
                                        />
                                    </>
                                }
                                <button onClick={this.hideModal} className='btn btn-secondary'>Fechar</button>
                                {
                                    this.state.state !== "receipt" && !this.state.warningMessage && (this.state.ticket.status !== "Cancelado" && this.state.ticket.status !== "Pago e Entregue") &&
                                    <>
                                        {
                                            (this.state.isMonthly || this.state.isAccredited) ?
                                                <button onClick={(e) => { this.confirmNewPayment("Pago e Entregue") }} className='btn btn-success'>
                                                    Entregar veículo
                                                </button> : <>
                                                    <button onClick={(e) => { this.confirmNewPayment("Pago e Entregue") }} className='btn btn-success'>
                                                        {this.state.editingParkingTotalValue === 0 ? "Entregar" : "Pagar e entregar"}
                                                    </button>
                                                    {
                                                        this.state.ticket.status === "Estacionado" && this.state.buttonState === "can-pay-in" &&
                                                        <button onClick={(e) => { this.confirmNewPayment("Pago e Estacionado") }} className='btn btn-warning text-white'>
                                                            Pagar e manter estacionado
                                                        </button>
                                                    }
                                                </>
                                        }
                                    </>
                                }
                            </div>
                        </MuiDialogActions>
                    }
                </Dialog>
                <Dialog maxWidth='xs' fullWidth onClose={this.hideVendaDeDiariaModal} open={this.state.showDailyModal}>
                    <MuiDialogTitle className='text-center'>
                        <span>Ciclo da diária: {this.state.currentPaymentTable?.periodoMaximo ?? "0"}</span>
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        {
                            this.state.allDays.map(item => {
                                return <div className='col-lg-12 mt-3' key={item}>
                                    <button className='btn btn-secondary w-100 btn-bg' onClick={() => this.confirmDailySelected(item)}>
                                        {
                                            item === 1 && <span> 1 diária </span>
                                        }
                                        {
                                            item > 1 && <span> {item} diárias </span>
                                        }
                                    </button>
                                </div>
                            })
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={this.hideVendaDeDiariaModal} className='btn btn-secondary'>
                                Fechar
                            </button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                {
                    this.state.isEditingLimitPermanenceDate && <DatePickerDialog
                        onClose={this.hideLimitedPermanenceModal}
                        date={moment(this.state.limitPermanenceInDate).toDate()}
                        onConfirm={this.changeLimitPermanenceDate} />
                }
                {
                    this.state.isEditingDeliveryDate && <DatePickerDialog
                        onClose={this.hideDeliveryEditor}
                        date={this.state.deliveryDate?.toDate()}
                        onConfirm={this.confirmDeliveryDate} />
                }
            </>)
    }
}

class TicketListModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            isShowingModal: true,
            status: props.status,
            auxItems: [],
            items: []
        }
    }

    componentDidMount = async () => {
        this.setState({ state: "loading" })
        const items = await getAllTicketsOpenByStatusAndParkId({ status: this.state.status, parkId: this.state.estacionamentoId })
        this.setState({ items: items })
        this.setState({ auxItems: items })
        this.setState({ state: "fill" })
    }

    search = (e) => {
        const value = e.target.value.toUpperCase().replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        if (value !== "") {
            const items = this.state.items.filter(e => e.ticket?.includes(value) || e.placa?.toUpperCase().includes(value.toUpperCase()))
            this.setState({ items: items })
        } else {
            this.setState({ items: this.state.auxItems })
        }
    }

    onSelected = (item) => {
        if (this.props.onSelected) {
            this.props.onSelected(item)
        }
    }

    hideModal = () => {
        this.setState({ isShowingModal: false })
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    classByStatus = (item) => {
        if (item.status === "Estacionado") {
            return "col-lg-12 alert alert-primary"
        } else if (item.status === "Cancelado") {
            return "col-lg-12 alert alert-danger"
        } else if (item.status === "Pago e Aguardando") {
            return "col-lg-12 alert alert-warning"
        } else if (item.status === "Pago e Estacionado") {
            return "col-lg-12 alert alert-info"
        } else {
            return "col-lg-12 alert alert-success"
        }
    }

    classByStatusButton = (item) => {
        if (item.status === "Estacionado") {
            return "btn btn-primary my-2"
        } else if (item.status === "Cancelado") {
            return "btn btn-danger my-2"
        } else if (item.status === "Pago e Aguardando") {
            return "btn btn-warning my-2"
        } else if (item.status === "Pago e Estacionado") {
            return "btn btn-info text-white my-2"
        } else {
            return "btn btn-success my-2"
        }
    }

    render() {
        return (
            <>
                <Dialog onClose={this.hideModal} fullWidth maxWidth='sm' open={this.state.isShowingModal}>
                    {
                        this.state.state === "loading" && <Loading />
                    }
                    {
                        this.state.state === "fill" && <>
                            <MuiDialogTitle className='text-center'>
                                {this.state.title ?? "Selecione um veículo"}
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='col-lg-12 mb-3'>
                                    <input type="text" onChange={this.search} placeholder="Faça uma busca por placa ou número do ticket..." className="form-control form-control-lg text-center" />
                                </div>
                                {
                                    this.state.items.length === 0 && <EmptyContent text="Nenhum veículo encontrado." />
                                }
                                {
                                    this.state.items.map((item) =>
                                        <div key={item.id} className={this.classByStatus(item)} role='button' onClick={(e) => this.onSelected(item)}>
                                            <div className='col-lg-10 col-md-11 col-10 col-sm-10'>
                                                <div className='my-2'>
                                                    {item.status === 'Pago e Aguardando' && <span className="badge text-bg-primary">Este veículo esta pago e aguardando volta</span>}
                                                </div>
                                                {
                                                    item.type === "SALES" && <div><strong>Controle:</strong> {item.ticket}</div>
                                                }
                                                {
                                                    item.type !== "SALES" && <div><strong>Ticket:</strong> {item.ticket}</div>
                                                }
                                                {
                                                    item.placa &&
                                                    <div><strong>Placa:</strong> {item.placa}</div>
                                                }
                                                {
                                                    item.vehicler?.modelo &&
                                                    <div><strong>Modelo:</strong> {item.vehicler?.modelo}</div>
                                                }
                                                {
                                                    item.createAt &&
                                                    <>
                                                        <div><strong>Entrada:</strong> {moment(item.createAt.seconds * 1000).format("DD/MM/YYYY HH:mm")}</div>
                                                        <div><strong>Permanência:</strong> {ticketPermanenceFromNowDescription(item)}</div>
                                                    </>
                                                }
                                            </div>
                                            <TicketButton ticket={item} />
                                        </div>
                                    )
                                }
                            </MuiDialogContent>
                        </>
                    }
                </Dialog >
            </>
        )
    }
}

class TicketRegisterNewVehicleModal extends React.Component {

    constructor(props) {
        super(props)
        const park = getEstacionamento()
        this.state = {
            value: "",
            state: "fill",
            buttonState: "in",
            isShowingModal: true,
            estacionamentoId: park.id,
            clienteId: park.clienteId,
            revendaId: park.revendaId,
            paymentTableSelectedId: "",
            habilitarObrigatoriedadeModeloCorDoVeiculoRapida: false,
            habilitarObrigatoriedadeTabelaDePrecoPorVeiculo: false,
            currentDateChangeCalendarOpen: false,
            currentDate: moment(),
            currentDateChangedManually: false,
            habilitarDataEntrada: false,
            prismaEstacionamento: false,
            typePrismaEstacionamento: false,
            habilitarCampoContato: false,
            observacaoEstacionamento: false,
            setorEstacionamento: false,
            isSectorManuallyDefined: false,
            currentSector: null,
            ticketFound: false
        }
    }

    componentDidMount = async () => {
        this.showAlert("Preencha a placa do veículo ou o número do ticket.", "info")
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ parameter: parameter }, async () => {
            await this.loadPrismSettingsIfNeed()
            this.verifyFieldsObrigation()
        })
        const tables = await getAllPaymentTables({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ allTables: tables })

        this.loadCurrentTable()
        const currentCashier = await activeCashier({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ currentCashier: currentCashier })
    }

    loadCurrentTable = async () => {
        const table = await getCurrentPriceTableByDayAndTime({ parkId: this.state.estacionamentoId, type: this.state.currentVehicle?.type })
        this.setState({ currentTable: table })
    }

    hideModal = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    reloadModal = () => {
        reloadWindow()
    }

    searchByPlateOrTicket = async (e) => {
        const value = e.target.value.toUpperCase()
        this.setState({ ticketFound: false })
        this.setState({ value: value })
        var ticket = null
        if (parseInt(value) >= 6) {
            toastLoading()
            ticket = await getTicketByNumberAndParkId({ ticket: value, parkId: this.state.estacionamentoId })
        } else if (isValidatePlate(value)) {
            toastLoading()
            const ticketFound = await getTicketByPlateAndParkId({ plate: value, parkId: this.state.estacionamentoId })
            if (ticketFound && (ticketFound?.status !== TicketStatus.PAYED_AND_DELIVERED && ticketFound?.status !== TicketStatus.CANCELED)) {
                ticket = ticketFound
            }
        }
        if (ticket) {
            this.setState({ ticketFound: true })
            this.setState({ ticket: ticket })
            this.setState({ state: "payment" })
            this.setState({ isShowingModal: false })
        } else {
            if (value.length >= 6) {
                await this.verifyTicketSettings(value)
                await this.verifyMonthlyOrAccreditedVehicle(value)
                await this.loadContactInfoIfNeed(value)
            }
        }
        toastDismissLoading()
    }

    loadContactInfoIfNeed = async (value) => {
        if (this.state.setorEstacionamento) {
            const contactInfo = await getContactInfoByPlateAndClientId({ plate: value, clienteId: this.state.clienteId })
            if (contactInfo) {
                this.setState({ currentContactInfo: contactInfo })
            }
        }
    }

    verifyMonthlyOrAccreditedVehicle = async (value) => {
        if (isValidatePlate(value)) {
            this.setState({ state: "fill" })
            const vehicle = await getVehicleByPlateAndClientId({ clientId: this.state.clienteId, placa: value })
            if (vehicle) {
                this.updateVehicle(vehicle)
                if (vehicle?.mensalistaId) {
                    const monthly = await getMonthlyById(vehicle.mensalistaId)
                    this.setState({ currentMonthly: monthly })
                } else {
                    const accredited = await getAccreditedByPlateAndParkId({ plate: value, parkId: this.state.estacionamentoId })
                    if (accredited) {
                        this.updateVehicle(accredited.currentVehicle)
                        this.setState({ currentAccredited: accredited })
                        this.setState({ state: "fill" })
                    }
                }
            }
        } else if (this.state.habilitarCartaoParaAcesso) {
            const accessCard = await getAccessCardByParkIdAndNumber({ estacionamentoId: this.state.estacionamentoId, accessCard: value })
            if (accessCard) {
                this.setState({ state: "fill" })
                if (accessCard.type === "monthly") {
                    const monthly = await getMonthlyById(accessCard.referenceId)
                    this.setState({ currentMonthly: monthly })
                    const vehicles = await getAllVeiculosByMonthlyIdAndAccessNumber({ mensalistaId: accessCard.referenceId, accessCard: value })
                    if (vehicles.length === 1) {
                        this.updateVehicle(vehicles[0])
                    }
                } else if (accessCard.type === "accredited") {
                    const accredited = await getAccreditedById({ id: accessCard.referenceId })
                    if (accredited) {
                        this.setState({ currentAccredited: accredited })
                        if (accredited.veiculos.length === 1) {
                            this.updateVehicle(accredited.veiculos[0])
                        }
                    }
                }
            }
        }
    }

    updateVehicle = (vehicle) => {
        this.setState({ currentVehicle: vehicle }, () => {
            this.loadCurrentTable()
        })
    }

    loadPrismSettingsIfNeed = async () => {
        if (this.state.parameter.prismaEstacionamento === true) {
            const prismSettings = await getPrismSettingsByParkId({ estacionamentoId: this.state.estacionamentoId })
            if (prismSettings) {
                this.setState({ typePrismaEstacionamento: prismSettings.typePrismaEstacionamento })
                const maxSequencePrismaEstacionamento = parseInt(prismSettings.maxSequencePrismaEstacionamento ?? 0)
                const sequence = parseInt(prismSettings.sequencePrismaEstacionamento ?? 0)
                if (prismSettings.typePrismaEstacionamento === "sequence" || prismSettings.typePrismaEstacionamento === "sequence_unique") {
                    if (prismSettings.typePrismaEstacionamento === "sequence_unique") {
                        if (sequence > maxSequencePrismaEstacionamento) {
                            this.setState({ currentPrism: 1 })
                        } else {
                            this.setState({ currentPrism: sequence })

                        }
                    } else {
                        const sequenceUsing = prismSettings.sequenceUsing.map(e => parseInt(e.prism)).sort((a, b) => 1 - b)
                        if (sequenceUsing.length > 0) {
                            const end = parseInt(sequenceUsing[sequenceUsing.length - 1].prism)
                            for (let index = 1; index < end; index++) {
                                const notUsing = sequenceUsing.filter(e => e === index).length === 0
                                if (notUsing) {
                                    this.setState({ currentPrism: index })
                                } else {
                                    this.setState({ currentPrism: (end + 1) })
                                }
                            }
                        } else {
                            this.setState({ currentPrism: sequence })
                        }
                    }
                } else if (prismSettings.typePrismaEstacionamento === "stamp") {
                    if (sequence > maxSequencePrismaEstacionamento) {
                        this.setState({ currentPrism: 0 })
                    } else {
                        this.setState({ currentPrism: sequence })
                    }
                } else {
                    this.setState({ currentPrism: sequence })
                }
            }
        }
    }

    verifyTicketSettings = async (value) => {
        const settings = await getTicketSettings({ plate: value })
        if (settings?.allowToPark === "NAO_PERMITIR") {
            reloadWithAlert(`O administrador não permite que este veículo estacione no estabelecimento.\n\nMotivo: ${settings.allowToParkReason}`)
        } else if (settings?.allowToPark === "ALERTAR_OPERADOR") {
            toastError(`O administrador adicionou um alerta para este veículo.\n\nMensagem: ${settings.allowToParkReason}`)
        }
        this.setState({ currentSettings: settings })
    }

    verifyIfTicketExist = async (value) => {
        if (isValidatePlate(value)) {
            const ticket = await getTicketByPlateAndParkId({ plate: value, parkId: this.state.estacionamentoId })
            if (ticket && ticket?.status !== "Pago e Entregue") {
                this.setState({ ticket: ticket })
                this.setState({ state: "payment" })
                this.setState({ isShowingModal: false })
                return true
            }
        } else if (this.state.habilitarCartaoParaAcesso && value.length > 6) {
            const ticket = await getTicketByNumberAndParkId({ ticket: value, parkId: this.state.estacionamentoId })
            if (ticket) {
                this.setState({ ticket: ticket })
                this.setState({ state: "payment" })
                this.setState({ isShowingModal: false })
                return true
            }
        }
        return false
    }

    saveNewticket = async () => {
        if (!this.validateAllFields()) {
            return null
        }
        const date = this.state.currentDate.toDate()
        this.hideAlert()
        const estacionamento = getEstacionamentoCompact()
        const ticket = await getRandomNumberInTicketByParkId({ estacionamentoId: estacionamento.id })
        const user = getUsuarioCompact()
        const promotialMessage = await getRemoteConfigByKey({ key: "APP_MESSAGE_TICKET" })
        const system = `Web = ${window.navigator.userAgent}`
        const data = {
            version: "v6",
            revendaId: this.state.revendaId,
            clienteId: this.state.clienteId,
            estacionamento: {
                id: estacionamento.id,
                celular: estacionamento.celular,
                documento: estacionamento.documento,
                horarios: estacionamento.horarios,
                nome: estacionamento.nome,
                endereco: `${estacionamento.endereco.logradouro} - ${estacionamento.endereco.numero}`,
            },
            estacionamentoId: estacionamento.id,
            estacionamentoNome: estacionamento.nome,
            caixaFechado: false,
            ticket: ticket,
            placa: this.state.value,
            prisma: takeIfIsNotNullOrEmpty(String(this.state.currentPrism)),
            setor: this.state.currentSector?.nome,
            setorId: this.state.currentSector?.id,
            relationshipApplied: this.state.currentConvenio && {
                ...this.state.currentConvenio,
                appliedBy: user,
                appliedAt: new Date()
            },
            type: "TICKET",
            internalType: "TICKET",
            promotialMessage: promotialMessage.value,
            tableSelectedInCheckIn: this.state.currentTable,
            typeVehicler: this.state.currentVehicle?.type ?? this.state.currentTable?.tipo ?? "carro",
            status: "Estacionado",
            observation: takeIfIsNotNullOrEmpty(this.state.note),
            statusLogged: [
                {
                    status: "Estacionado",
                    createAt: date,
                    createBy: user,
                    currentCashier: this.state.currentCashier.id
                }
            ],
            produtos: this.state.products,
            interno: this.state.internalReview,
            monthly: this.state.currentMonthly,
            accredited: this.state.currentAccredited,
            vehicler: this.state.currentVehicle,
            createAt: date,
            fastIn: false,
            createBy: user.email,
            createByName: user.nome,
            system: {
                app: system,
                version: moment().format("DD/MM/YYYY [às] HH:mm:ss"),
            },
            settings: {
                printCustomMessage: this.state.currentSettings?.printCustomMessage,
                flavor: "Web"
            },
            updatedAt: date,
            deviceIdentifier: generateUUIDV7()
        }
        const monthly = this.state.currentMonthly
        if (monthly) {
            data.internalType = "MONTHLY"
            const dueDate = moment(monthly.plano.dataFatura.toDate())
            const today = moment()
            if (dueDate.isBefore(today, "day")) {
                data.monthlyMessage = this.state.parameter?.mensagemMensalistaInadimplentes
            }
        } else if (this.state.currentAccredited) {
            data.internalType = "ACCREDITED"
        }
        const id = await addTicket({ data: data })
        data.id = id
        const contactInfo = this.state.currentContactInfo
        if (contactInfo) {
            await addOrUpdateContactInfo({ data: contactInfo })
        }
        const ticketAdded = await getTicketById({ id: id })
        return ticketAdded
    }

    confirmVehicle = async () => {
        const ticket = await this.saveNewticket()
        if (ticket) {
            this.setState({ ticket: ticket })
            this.setState({ state: "ticket-added" })
        }
    }

    confirmPayInRequired = async () => {
        const ticket = await this.saveNewticket()
        if (ticket) {
            this.setState({ ticket: ticket })
            this.setState({ statusPaymentTicket: "Pago e Estacionado" })
            this.setState({ state: "payment" })
            this.setState({ isShowingModal: false })
        }
    }

    verifyFieldsObrigation = () => {
        this.setState({ habilitarObrigatoriedadeTabelaDePrecoPorVeiculo: this.state.parameter.habilitarObrigatoriedadeTabelaDePrecoPorVeiculo })
        this.setState({ habilitarObrigatoriedadeModeloCorDoVeiculoRapida: this.state.parameter.habilitarObrigatoriedadeModeloCorDoVeiculoRapida })
        this.setState({ prismaEstacionamento: this.state.parameter.prismaEstacionamento })
        this.setState({ habilitarCampoContato: this.state.parameter.habilitarCampoContato })
        this.setState({ observacaoEstacionamento: this.state.parameter.observacaoEstacionamento })
        this.setState({ setorEstacionamento: this.state.parameter.setorEstacionamento })
        this.setState({ habilitarDataEntrada: this.state.parameter.habilitarDataEntrada })
        this.setState({ habilitarCartaoParaAcesso: this.state.parameter.habilitarCartaoParaAcesso })
    }

    validateAllFields = () => {
        if (!isValidatePlate(this.state.value) && this.state.habilitarCartaoParaAcesso && (this.state.currentMonthly || this.state.currentAccredited)) {
            this.showAlert("Informe uma placa ou cartão de acesso válido.", "error")
            return false
        } else if (!isValidatePlate(this.state.value)) {
            this.showAlert("Informe uma placa válida.", "error")
            return false
        }
        /* if (this.state.parameter.habilitarObrigatoriedadeModeloCorDoVeiculoRapida === true && !this.state.currentVehicle) {
             this.showAlert("Informe a marca e modelo do veículo.", "error")
             return false
         }
         if (this.state.parameter.habilitarObrigatoriedadeTabelaDePrecoPorVeiculo && !this.state.currentTable) {
             this.showAlert("Informe a tabela de preço para o veículo.", "error")
             return false
         }*/
        if (this.state.parameter.prismaEstacionamento === true && !this.state.currentPrism) {
            this.showAlert("Informe o número do prisma.", "error")
            return false
        }
        return true
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    showAlert = (message, type) => {
        this.setState({ messageType: type })
        this.setState({ message: message })
    }

    hideAlert = () => {
        this.setState({ messageType: "" })
        this.setState({ message: "" })
    }

    changeTablePayment = async (e) => {
        const value = e.target.value
        this.setState({ paymentTableSelectedId: value })
        const currentTable = this.state.allTables.filter(e => e.id === value)[0]
        this.setState({ currentTable: currentTable })
        if (currentTable.permitirPagamentoAntecipado === true) {
            this.setState({ buttonState: "can-pay-in" })
        } else if (currentTable.obrigarPagamentoEntrada === true) {
            this.setState({ buttonState: "must-pay-in" })
        } else {
            this.setState({ buttonState: "in" })
        }
    }

    changeStateFormTo = (state) => {
        this.setState({ state: state })
    }

    onCloseForms = () => {
        this.changeStateFormTo("fill")
    }

    vehicleConfirmed = (vehicle) => {
        this.hideAlert()
        this.updateVehicle(vehicle)
    }

    paymentTableConfirmed = async (item) => {
        this.hideAlert()
        this.setState({ buttonState: item.obrigarPagamentoEntrada ? "must-pay-in" : item.permitirPagamentoAntecipado ? "can-pay-in" : "in" })
        this.setState({ currentTable: item })
        if (item.setorAssociadoId !== "") {
            const sector = await getSectorById({ id: item.setorAssociadoId })
            if (sector) {
                this.setState({ currentSector: sector })
            } else {
                if (!this.state.isSectorManuallyDefined) {
                    this.setState({ currentSector: null })
                }
            }
        } else {
            this.setState({ currentSector: null })
        }
        if (item.convenioAssociadoId !== "") {
            const convenio = await getRelationshipById({ id: item.convenioAssociadoId })
            if (convenio) {
                this.setState({ currentConvenio: convenio })
            }
        } else {
            this.setState({ currentConvenio: null })
        }
    }

    sectorConfirmed = (item) => {
        this.setState({ isSectorManuallyDefined: true })
        this.setState({ currentSector: item })
    }

    contactConfirmed = (item) => {
        this.setState({ currentContactInfo: item })
    }

    internalReviewConfirmed = (item) => {
        this.setState({ internalReview: item })
        this.setState({ internalReviewQuantity: item.map(e => e.quantidade).reduce((a, b) => a + b, 0) })
    }

    productsConfirmed = (item) => {
        this.setState({ products: item })
        this.setState({ productsQuantity: item.map(e => e.quantidade).reduce((a, b) => a + b, 0) })
    }

    noteConfirmed = (note) => {
        this.setState({ note: note })
    }

    showCurrentDateChangeCalendarOpen = () => {
        this.setState({ currentDateChangeCalendarOpen: true })
    }

    handleCurrentDateChange = (newValue) => {
        this.setState({ currentDateChangeCalendarOpen: false })
        this.setState({ currentDateChangedManually: true })
        this.setState({ currentDate: newValue })
    }

    render() {
        return (
            <>
                <Dialog maxWidth='xs' fullWidth open={this.state.isShowingModal}>
                    <MuiDialogTitle className='text-center'>
                        <span>Novo veículo</span>
                    </MuiDialogTitle>
                    <MuiDialogContent style={{ maxHeight: '600px' }}>
                        {
                            this.state.state === "fill" &&
                            <div>
                                {
                                    this.state.messageType === "error" && <AlertDanger message={this.state.message} />
                                }
                                {
                                    this.state.messageType === "info" && <AlertInfo message={this.state.message} />
                                }
                                {
                                    this.state.messageType === "success" && <AlertSuccess message={this.state.message} />
                                }
                                <div className='col-lg-12'>
                                    <input type="text" onChange={this.searchByPlateOrTicket} placeholder={this.state.habilitarCartaoParaAcesso ? 'Placa ou cartão de acesso' : 'Placa do veículo'} value={this.state.value} className="form-control form-control-lg text-center" />
                                </div>
                                <ul className="list-group mt-3">
                                    {
                                        this.state.currentMonthly &&
                                        <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button'>
                                            <div className="me-auto">
                                                <i className="fas fa-calendar-alt" />
                                                <span className='mx-2'>Mensalista</span>
                                            </div>
                                            <span className="badge bg-success rounded-pill">
                                                <a rel="noopener noreferrer" className='text-white' target="_blank" href={`/visualizar/mensalista/${this.state.currentMonthly.id}`}>
                                                    {this.state.currentMonthly?.nome}
                                                    <i className="fas fa-external-link-square-alt mx-1" />
                                                </a>
                                            </span>
                                        </li>
                                    }
                                    {
                                        this.state.currentAccredited &&
                                        <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button'>
                                            <div className="me-auto">
                                                <i className="fas fa-user-circle" />
                                                <span className='mx-2'>Credenciado</span>
                                            </div>
                                            <span className="badge bg-success rounded-pill">
                                                {this.state.currentAccredited?.nome}
                                                <i className="fas fa-external-link-square-alt mx-1" />
                                            </span>
                                        </li>
                                    }
                                    <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button' onClick={(e) => this.changeStateFormTo("vehicle")}>
                                        <div className="me-auto">
                                            <i className="fas fa-car-side" />
                                            <span className='mx-2'>Veículo</span>
                                        </div>
                                        {
                                            this.state.currentVehicle ?
                                                <span className="badge bg-success rounded-pill">
                                                    {this.state.currentVehicle?.marca} {this.state.currentVehicle?.modelo}
                                                    <i className="fas fa-edit mx-1" />
                                                </span>
                                                :
                                                <>
                                                    {
                                                        this.state.habilitarObrigatoriedadeModeloCorDoVeiculoRapida ?
                                                            <span className="badge bg-danger rounded-pill">Obrigatório</span> :
                                                            <span className="badge bg-secondary rounded-pill">Selecionar</span>
                                                    }
                                                </>
                                        }
                                    </li>
                                    <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button' onClick={(e) => this.changeStateFormTo("payment-table")}>
                                        <div className="me-auto">
                                            <i className="fas fa-money-bill-wave" />
                                            <span className='mx-2'>Tabela de preço</span>
                                        </div>
                                        {
                                            this.state.currentTable ?
                                                <span className="badge bg-success rounded-pill">
                                                    {`${this.state.currentTable?.nome} ${this.state.currentTable?.tipo}`}
                                                    <i className="fas fa-edit mx-1" />
                                                </span> :
                                                this.state.habilitarObrigatoriedadeTabelaDePrecoPorVeiculo ?
                                                    <span className="badge bg-danger rounded-pill">Obrigatório</span> :
                                                    <span className="badge bg-secondary rounded-pill">Selecionar</span>
                                        }
                                    </li>
                                    {
                                        (this.state.setorEstacionamento || this.state.currentSector) &&
                                        <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button' onClick={(e) => this.changeStateFormTo("sectors")}>
                                            <div className="me-auto">
                                                <i className="fas fa-building" />
                                                <span className='mx-2'>Setor</span>
                                            </div>
                                            {
                                                this.state.currentSector ?
                                                    <span className="badge bg-success rounded-pill">
                                                        {this.state.currentSector?.nome}
                                                        <i className="fas fa-edit mx-1" />
                                                    </span> :
                                                    <span className="badge bg-secondary rounded-pill">Selecionar</span>
                                            }
                                        </li>
                                    }
                                    {
                                        this.state.prismaEstacionamento &&
                                        <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button'>
                                            <div className="me-auto">
                                                <i className="fas fa-shield-alt" />
                                                <span className='mx-2'>Prisma</span>
                                            </div>
                                            {
                                                this.state.currentPrism ?
                                                    <span className="badge bg-success rounded-pill">
                                                        {this.state.currentPrism}
                                                        <i className="fas fa-edit mx-1" />
                                                    </span> :
                                                    <span className="badge bg-secondary rounded-pill">Selecionar</span>
                                            }
                                        </li>
                                    }
                                    {
                                        this.state.habilitarCampoContato &&
                                        <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button' onClick={(e) => this.changeStateFormTo("contact")}>
                                            <div className="me-auto">
                                                <i className="fas fa-address-book" />
                                                <span className='mx-2'>Dados para contato</span>
                                            </div>
                                            {
                                                this.state.currentContactInfo ?
                                                    <span className="badge bg-success rounded-pill">
                                                        {this.state.currentContactInfo?.phone}
                                                        <i className="fas fa-edit mx-1" />
                                                    </span> :
                                                    <span className="badge bg-secondary rounded-pill">Selecionar</span>
                                            }
                                        </li>
                                    }
                                    <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button' onClick={(e) => this.changeStateFormTo("products")}>
                                        <div className="me-auto">
                                            <i className="fas fa-hamburger" />
                                            <span className='mx-2'>Produtos e serviços</span>
                                        </div>
                                        {
                                            this.state.products ?
                                                <span className="badge bg-success rounded-pill">
                                                    {this.state.productsQuantity}
                                                    <i className="fas fa-edit mx-1" />
                                                </span> :
                                                <span className="badge bg-secondary rounded-pill">Selecionar</span>
                                        }
                                    </li>
                                    <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button' onClick={(e) => this.changeStateFormTo("internal-review")}>
                                        <div className="me-auto">
                                            <i className="fas fa-tasks" />
                                            <span className='mx-2'>Avaliação interna</span>
                                        </div>
                                        {
                                            this.state.internalReview ?
                                                <span className="badge bg-success rounded-pill">
                                                    {this.state.internalReviewQuantity}
                                                    <i className="fas fa-edit mx-1" />
                                                </span> :
                                                <span className="badge bg-secondary rounded-pill">Selecionar</span>
                                        }
                                    </li>
                                    {
                                        this.state.observacaoEstacionamento &&
                                        <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button' onClick={(e) => this.changeStateFormTo("note")}>
                                            <div className="me-auto">
                                                <i className="fas fa-clipboard" />
                                                <span className='mx-2'>Observação</span>
                                            </div>
                                            {
                                                !isNullOrEmpty(this.state.note) ?
                                                    <span className="badge bg-success rounded-pill">
                                                        {valueByMaximunChars(this.state.note, 20)}
                                                        <i className="fas fa-edit mx-1" />
                                                    </span> :
                                                    <span className="badge bg-secondary rounded-pill">Adicionar</span>
                                            }
                                        </li>
                                    }
                                    {/* {
                                        verificar depois
                                        this.state.habilitarDataEntrada &&
                                        <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button' onClick={(e) => this.showCurrentDateChangeCalendarOpen()}>
                                            <div className="me-auto">
                                                <i className="fas fa-clock" />
                                                <span className='mx-2'>Data e hora</span>
                                            </div>
                                            {/* {
                                                this.state.currentDateChangedManually ?
                                                    <span className="badge bg-success rounded-pill">
                                                        {this.state.currentDate?.format("DD/MM/YYYY [às] HH:mm A")}
                                                        <i className="fas fa-edit mx-1" />
                                                    </span> :
                                                    
                                            } 
                                            <MobileDateTimePicker
                                                open={this.state.currentDateChangeCalendarOpen}
                                                openTo="hours"
                                                value={this.state.currentDate}
                                                onAccept={this.handleCurrentDateChange}
                                                renderInput={(params) => <span className="badge bg-secondary rounded-pill" onClick={(e) => this.setState({ currentDateChangeCalendarOpen: true })}>Selecionar</span>}
                                            />
                                        </li>
                                     */}
                                    {/* <li style={{ minHeight: '50px' }} className="list-group-item d-flex justify-content-between align-items-center" role='button'>
                                        <div className="me-auto">
                                            <i className="fas fa-camera-retro" />
                                            <span className='mx-2'>Avaliação externa</span>
                                        </div>
                                        <span className="badge bg-secondary rounded-pill">Em breve</span>
                                    </li> */}
                                </ul>
                            </div>
                        }
                        {
                            this.state.state === "vehicle" && <TicketRegisterFindVehiclesModal
                                onConfirm={this.vehicleConfirmed}
                                onClose={this.onCloseForms} />
                        }
                        {
                            this.state.state === "payment-table" && <TicketRegisterFindPaymentTableModal
                                estacionamentoId={this.state.estacionamentoId}
                                onConfirm={this.paymentTableConfirmed}
                                onClose={this.onCloseForms} />
                        }
                        {
                            this.state.state === "sectors" && <TicketRegisterFindSectorsModal
                                estacionamentoId={this.state.estacionamentoId}
                                onConfirm={this.sectorConfirmed}
                                onClose={this.onCloseForms} />
                        }
                        {
                            this.state.state === "contact" && <TicketRegisterFindContactModal
                                estacionamentoId={this.state.estacionamentoId}
                                plate={this.state.value}
                                onConfirm={this.contactConfirmed}
                                onClose={this.onCloseForms} />
                        }
                        {
                            this.state.state === "internal-review" && <TicketRegisterFindInternalReviewModal
                                estacionamentoId={this.state.estacionamentoId}
                                items={this.state.internalReview}
                                onConfirm={this.internalReviewConfirmed}
                                onClose={this.onCloseForms} />
                        }
                        {
                            this.state.state === "products" && <TicketRegisterFindProductsModal
                                estacionamentoId={this.state.estacionamentoId}
                                items={this.state.products}
                                onConfirm={this.productsConfirmed}
                                onClose={this.onCloseForms} />
                        }
                        {
                            this.state.state === "note" && <TicketRegisterObservationModal
                                estacionamentoId={this.state.estacionamentoId}
                                note={this.state.note}
                                onConfirm={this.noteConfirmed}
                                onClose={this.onCloseForms} />
                        }
                        {
                            this.state.state === "ticket-added" && <TicketRegisterConfirmedModal
                                estacionamentoId={this.state.estacionamentoId}
                                ticket={this.state.ticket}
                                onClose={this.onCloseForms} />
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        {
                            this.state.state === "fill" &&
                            <div className='btn-group m-3'>
                                <button onClick={this.hideModal} className='btn btn-secondary'>
                                    Fechar
                                </button>
                                {
                                    this.state.buttonState === "in" &&
                                    <>
                                        <button onClick={this.confirmVehicle} className='btn btn-success'>
                                            Confirmar
                                        </button>
                                    </>
                                }
                                {
                                    this.state.buttonState === "can-pay-in" &&
                                    <>
                                        <button onClick={this.confirmPayInRequired} className='btn btn-warning text-white'>
                                            Pagamento antecipado
                                        </button>
                                        <button onClick={this.confirmVehicle} className='btn btn-success'>
                                            Confirmar
                                        </button>
                                    </>
                                }
                                {
                                    this.state.buttonState === "must-pay-in" &&
                                    <>
                                        <button onClick={this.confirmPayInRequired} className='btn btn-success'>
                                            Antecipar pagamento e confirmar
                                        </button>
                                    </>
                                }
                            </div>
                        }
                        {
                            this.state.state === "ticket-added" &&
                            <div className='btn-group m-3'>
                                <div style={{ display: "none" }}>
                                    <PrinterTicketComponent
                                        ticket={this.state.ticket}
                                        park={getEstacionamento()}
                                        ref={el => (this.componentRef = el)} />
                                </div>
                                <a href={`/buscar/ticket?id=${this.state.ticket?.id}`} className='btn btn-success' target='_blank'>
                                    Ver ticket
                                </a>
                                <ReactToPrint
                                    trigger={() => {
                                        return <a href="#" className='btn btn-secondary'>Imprimir</a>
                                    }}
                                    content={() => this.componentRef}
                                />
                                <button onClick={this.reloadModal} className='btn btn-secondary'>
                                    Fechar
                                </button>
                            </div>
                        }
                    </MuiDialogActions>
                </Dialog >
                {
                    this.state.state === "payment" && <TicketRegisterPaymentModal
                        showCashiers={false}
                        generatedTicket={true}
                        ticketFound={this.state.ticketFound}
                        statusPaymentTicket={this.state.statusPaymentTicket}
                        ticket={this.state.ticket}
                        onClose={this.onClose} />
                }
            </>)
    }
}

class TicketRegisterFindVehiclesModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            items: [],
            state: "vehicle", //color
            colors: [
                {
                    name: "Branco",
                    color: "#ffffff",
                    text: "#000"
                },
                {
                    name: "Prata",
                    color: "#bcbcbc",
                    text: "#FFFFFF"
                },
                {
                    name: "Preto",
                    color: "#000000",
                    text: "#FFFFFF"
                },
                {
                    name: "Cinza",
                    color: "#999999",
                    text: "#FFFFFF"
                },
                {
                    name: "Vermelho",
                    color: "#f44336",
                    text: "#FFFFFF"
                },
                {
                    name: "Azul",
                    color: "#0000ff",
                    text: "#FFFFFF"
                },
                {
                    name: "Amarelo",
                    color: "#ffd966",
                    text: "#000000"
                },
                {
                    name: "Verde",
                    color: "#008000",
                    text: "#FFFFFF"
                },
                {
                    name: "Vinho",
                    color: "#5e2129",
                    text: "#FFFFFF"
                },
                {
                    name: "Marrom",
                    color: "#a52a2a",
                    text: "#FFFFFF"
                },
                {
                    name: "Bege",
                    color: "#f5f5dc",
                    text: "#000"
                },
                {
                    name: "Laranja",
                    color: "#FFA500",
                    text: "#FFFFFF"
                },
                {
                    name: "Outros",
                    color: "#fff",
                    text: "#000"
                },
            ],
            isLoading: true
        }
    }

    componentDidMount = async () => {
        const items = await getAllBranchesAndModels()
        this.setState({ items: items })
        this.setState({ auxItems: items })
        this.setState({ isLoading: false })
    }

    search = (e) => {
        const value = e.target.value
        if (value !== "") {
            this.setState({ items: this.state.auxItems.filter(e => e.value.toLowerCase().indexOf(value.toLowerCase()) !== -1) })
        } else {
            this.setState({ items: this.state.auxItems })
        }
    }

    onConfirmColor = (item) => {
        const data = {
            marca: this.state.branch,
            modelo: this.state.model,
            type: this.state.type,
            cor: item.name
        }
        if (this.props.onConfirm) {
            this.props.onConfirm(data)
        }
        this.onClose()
    }

    onConfirmVehicle = (item) => {
        const items = item.value.split(" ")
        this.setState({ branch: items[0] })
        this.setState({ model: item.value.replace(items[0], "").trim() })
        this.setState({ type: item.type })
        this.setState({ state: "color" })
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading && <Loading />
                }
                {
                    !this.state.isLoading &&
                    <>
                        <div className="card-header d-flex align-items-center ">
                            <button type="button" onClick={() => { this.onClose() }} className="btn btn-sm btn-secondary">
                                <i className="fas fa-chevron-left mx-2" />
                            </button>
                            <h5 className='mt-1 mx-2'>Selecionar veículo</h5>
                        </div>
                        {
                            this.state.items.length === 0 && <EmptyContent text="Nenhum veículo encontrado com esta busca." />
                        }
                        {
                            this.state.state === "vehicle" && <>
                                {
                                    this.state.items.length > 0 && <>
                                        <div className='col-lg-12 mt-3'>
                                            <input type="text" placeholder='Digite a marca ou modelo do veículo...' onChange={this.search} value={this.state.value} className="form-control form-control-lg text-center" />
                                        </div>

                                        <ul className="list-group mt-3">
                                            {
                                                this.state.items.map((item) => {
                                                    return <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => this.onConfirmVehicle(item)} role='button'>
                                                        <div className="me-auto">
                                                            {item.type === "carro" ? item.value : `${item.type} - ${item.value}`}
                                                        </div>
                                                        <span className="badge bg-secondary rounded-pill">
                                                            Selecionar
                                                        </span>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </>
                                }
                            </>
                        }
                        {
                            this.state.state === "color" && <>
                                <ul className="list-group mt-3">
                                    {
                                        this.state.colors.map((item) => {
                                            return <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => this.onConfirmColor(item)} role='button'>
                                                <span style={{ backgroundColor: item.color, color: item.text }} className="badge rounded-pill">
                                                    {item.name.toUpperCase()}
                                                </span>
                                                <span className="badge bg-secondary rounded-pill">
                                                    Selecionar
                                                </span>
                                            </li>
                                        })
                                    }
                                </ul>
                            </>
                        }
                    </>
                }
            </>)
    }
}

class TicketRegisterFindPaymentTableModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: props.estacionamentoId,
            isAdministrador: isAdministrador(),
            items: [],
            isLoading: true
        }
    }

    componentDidMount = async () => {
        const items = await getAllActivePaymentTables({ estacionamentoId: this.state.estacionamentoId })
        const user = getUsuarioCompact()
        this.setState({
            items: items.filter(e => {
                if (this.state.isAdministrador) {
                    return true
                }
                if (!e.usersAllowed || e.usersAllowed?.length === 0) {
                    return true
                }
                if (e.usersAllowed.map(e => e.value).indexOf(user.id) !== -1) {
                    return true
                }
                return false
            })
        })
        this.setState({ isLoading: false })
    }

    onConfirm = (item) => {
        if (this.props.onConfirm) {
            this.props.onConfirm(item)
        }
        this.onClose()
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading && <Loading />
                }
                {
                    !this.state.isLoading &&
                    <>
                        <div className="card-header d-flex align-items-center ">
                            <button type="button" onClick={() => { this.onClose() }} className="btn btn-sm btn-secondary">
                                <i className="fas fa-chevron-left mx-2" />
                            </button>
                            <h5 className='mt-1 mx-2'>Selecionar a tabela de preço</h5>
                        </div>
                        {
                            this.state.items.length === 0 && <EmptyContent text="Nenhuma tabela de preço encontrada." />
                        }
                        {
                            this.state.items.length > 0 &&
                            <ul className="list-group mt-3">
                                {
                                    this.state.items.map((item) => {
                                        return <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => this.onConfirm(item)} role='button'>
                                            <div className="me-auto">
                                                {item.tipo.toUpperCase()} - {item.nome}
                                            </div>
                                            <span className="badge bg-secondary rounded-pill">
                                                Selecionar
                                            </span>
                                        </li>
                                    })
                                }
                            </ul>
                        }
                    </>
                }
            </>)
    }
}

class TicketPaymentMethodModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: props.estacionamentoId,
            isAdministrador: isAdministrador(),
            items: [],
            isLoading: true
        }
    }

    componentDidMount = async () => {
        const items = await getAllPaymentsByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ items: items.sort((a, b) => a.nome.localeCompare(b.nome)) })
        this.setState({ isLoading: false })
    }

    onConfirm = (item) => {
        if (this.props.onConfirm) {
            this.props.onConfirm(item)
        }
        this.onClose()
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading && <Loading />
                }
                {
                    !this.state.isLoading &&
                    <>
                        <div className="card-header d-flex align-items-center ">
                            <button type="button" onClick={() => { this.onClose() }} className="btn btn-sm btn-secondary">
                                <i className="fas fa-chevron-left mx-2" />
                            </button>
                            <h5 className='mt-1 mx-2'>Selecionar o meio de pagamento</h5>
                        </div>
                        {
                            this.state.items.length === 0 && <EmptyContent text="Nenhum meio de pagamento encontrado." />
                        }
                        {
                            this.state.items.length > 0 &&
                            <ul className="list-group mt-3">
                                {
                                    this.state.items.map((item) => {
                                        return <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => this.onConfirm(item)} role='button'>
                                            <div className="me-auto">
                                                {item.nome}
                                            </div>
                                            <span className="badge bg-secondary rounded-pill">
                                                Selecionar
                                            </span>
                                        </li>
                                    })
                                }
                            </ul>
                        }
                    </>
                }
            </>)
    }
}

class TicketRegisterFindSectorsModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: props.estacionamentoId,
            isAdministrador: isAdministrador(),
            items: [],
            isLoading: true
        }
    }

    componentDidMount = async () => {
        const items = await getAllSectoresByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    onConfirm = (item) => {
        if (this.props.onConfirm) {
            this.props.onConfirm(item)
        }
        this.onClose()
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading && <Loading />
                }
                {
                    !this.state.isLoading &&
                    <>
                        <div className="card-header d-flex align-items-center ">
                            <button type="button" onClick={() => { this.onClose() }} className="btn btn-sm btn-secondary">
                                <i className="fas fa-chevron-left mx-2" />
                            </button>
                            <h5 className='mt-1 mx-2'>Selecionar um setor</h5>
                        </div>
                        {
                            this.state.items.length === 0 && <EmptyContent text="Nenhum setor encontrado." />
                        }
                        {
                            this.state.items.length > 0 &&
                            <ul className="list-group mt-3">
                                {
                                    this.state.items.map((item) => {
                                        return <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => this.onConfirm(item)} role='button'>
                                            <div className="me-auto">
                                                {item.nome}
                                            </div>
                                            <span className="badge bg-secondary rounded-pill">
                                                Selecionar
                                            </span>
                                        </li>
                                    })
                                }
                            </ul>
                        }
                    </>
                }
            </>)
    }
}

class TicketRegisterFindContactModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            plate: props.plate,
            estacionamentoId: props.estacionamentoId,
            clienteId: getCliente()?.id,
            revendaId: getRevendaIdByPark()
        }
    }

    onConfirm = () => {
        if (!this.state.number) {
            toastWarning("Informe o número do celular.")
            return
        }
        const item = {
            revendaId: this.state.revendaId,
            clienteId: this.state.clienteId,
            estacionamentoId: this.state.estacionamentoId,
            plate: this.state.plate,
            nome: this.state.name,
            phone: this.state.number,
            email: this.state.email,
        }
        if (this.props.onConfirm) {
            this.props.onConfirm(item)
        }
        this.onClose()
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading && <Loading />
                }
                {
                    !this.state.isLoading &&
                    <>
                        <div className="card-header d-flex align-items-center ">
                            <button type="button" onClick={() => { this.onClose() }} className="btn btn-sm btn-secondary">
                                <i className="fas fa-chevron-left mx-2" />
                            </button>
                            <h5 className='mt-1 mx-2'>Informar os dados para contato</h5>
                        </div>
                        <button className='btn btn-secondary w-100 btn-bg mt-3' onClick={() => this.onConfirm()} >Confirmar</button>
                        <div className='row mt-2'>
                            <div className='col-lg-12'>
                                <label>Nome (Opcional)</label>
                                <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" required />
                            </div>
                            <div className='col-lg-12'>
                                <label>Celular</label>
                                <NumberFormat className="form-control" value={this.state.number} mask='_' onChange={(e) => this.setState({ number: e.target.value })} format="(##)#####-####" />
                            </div>
                            <div className='col-lg-12'>
                                <label>E-mail (Opcional)</label>
                                <input type="email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" required />
                            </div>
                        </div>
                    </>
                }
            </>)
    }
}

class TicketRegisterObservationModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            note: props.note,
            estacionamentoId: props.estacionamentoId,
            clienteId: getCliente()?.id,
            revendaId: getRevendaIdByPark()
        }
    }

    onConfirm = () => {
        if (isNullOrEmpty(this.state.note)) {
            toastWarning("Informe uma observação")
            return
        }
        if (this.props.onConfirm) {
            this.props.onConfirm(this.state.note)
        }
        this.onClose()
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                <div className="card-header d-flex align-items-center ">
                    <button type="button" onClick={() => { this.onClose() }} className="btn btn-sm btn-secondary">
                        <i className="fas fa-chevron-left mx-2" />
                    </button>
                    <h5 className='mt-1 mx-2'>Observações</h5>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-12'>
                        <label>Observações</label>
                        <textarea rows={6} onChange={(e) => this.setState({ note: e.target.value })} value={this.state.note} className="form-control" />
                    </div>
                </div>
                <button className='btn btn-secondary w-100 btn-bg mt-3' onClick={() => this.onConfirm()} >Confirmar</button>
            </>)
    }
}

class TicketRegisterNoteModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            document: normalizedField(props.note?.document),
            name: props.note?.name,
            email: props.note?.email,
            type: props.note?.noteType ?? "NFS-e",
            estacionamentoId: props.estacionamentoId,
            clienteId: getCliente()?.id,
            revendaId: getRevendaIdByPark()
        }
    }

    onConfirm = () => {
        if (isNullOrEmpty(this.state.document)) {
            toastWarning("Informe o documento.")
            return
        }
        if (!isValidDocument(this.state.document)) {
            toastWarning("Informe um documento válido.")
            return
        }
        if (this.props.onConfirm) {
            const user = getUsuarioCompact()
            var data = {
                revendaId: this.state.revendaId,
                clienteId: this.state.clienteId,
                estacionamentoId: this.state.estacionamentoId,
                type: "customizado",
                noteType: this.state.type,
                createdBy: user,
                createdAt: new Date(),
                email: this.state.email,
                name: this.state.name,
                document: this.state.document,
                status: [{
                    createdAt: new Date(),
                    status: "NOTE_PROCESSING",
                    createdBy: user
                }],
                currentStatus: "NOTE_PROCESSING",
            }
            this.props.onConfirm(data)
        }
        this.onClose()
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                <div className="card-header d-flex align-items-center ">
                    <button type="button" onClick={() => { this.onClose() }} className="btn btn-sm btn-secondary">
                        <i className="fas fa-chevron-left mx-2" />
                    </button>
                    <h5 className='mt-1 mx-2'>Nota fiscal</h5>
                </div>
                <div className='row mt-2'>
                    <div className='col-lg-12'>
                        <label>Documento (CPF ou CNPJ)</label>
                        <input type="text" onChange={(e) => this.setState({ document: e.target.value })} value={this.state.document} className="form-control" required />
                    </div>
                    <div className='col-lg-12'>
                        <label>Nome (Opcional)</label>
                        <input type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} className="form-control" required />
                    </div>
                    <div className='col-lg-12'>
                        <label>E-mail (Opcional)</label>
                        <input type="email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" required />
                    </div>
                    <div className='col-lg-12'>
                        <label>Tipo de nota</label>
                        <select className="form-select" value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })}>
                            <option value="NFS-e">NFS-e</option>
                            <option value="NFC-e">NFC-e</option>
                        </select>
                    </div>
                    {
                        this.state.type === "NFC-e" &&
                        <div className='col-lg-12 mt-3'>
                            <AlertWarning message="Garanta que todos os produtos de consumo estejam configurados corretamente para a emissção da NFC-e." />
                        </div>
                    }
                </div>
                <button className='btn btn-secondary w-100 btn-bg mt-3' onClick={() => this.onConfirm()} >Confirmar</button>
            </>)
    }
}

class TicketRegisterConfirmedModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            estacionamentoId: props.estacionamentoId,
            ticket: props.ticket
        }
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                <div className='col-lg-12 text-center'>
                    <QRCodeSVG value={urlTicketOnlineFromTicket(this.state.ticket, "added-receipt")} size={250} />
                    <h4 className='text-success mt-3'>
                        {
                            this.state.ticket.type === "SALES" ?
                                <span>Venda efetuada com sucesso.</span> :
                                <span>Veículo lançado com sucesso.</span>
                        }
                    </h4>
                    <h6>O cliente pode escanear o QRCode acima para visualizar o comprovante.</h6>
                </div>
            </>)
    }
}

class TicketRegisterFindInternalReviewModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            itemsSelected: props.items ?? [],
            estacionamentoId: props.estacionamentoId,
            isAdministrador: isAdministrador(),
            items: [],
            isLoading: true
        }
    }

    componentDidMount = async () => {
        const item = await getRemoteConfigByKey({ key: "APP_INTERNAL_REVIEWS" })
        const items = item.value.split(",").map(item => {
            const selected = this.state.itemsSelected.filter(e => e.nome === item)[0]
            return {
                nome: item,
                quantidade: selected?.quantidade ?? 0
            }
        })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    onConfirm = () => {
        const items = this.state.items.filter(e => e.quantidade > 0)
        if (this.props.onConfirm) {
            this.props.onConfirm(items)
        }
        this.onClose()
    }

    addItem = (index) => {
        this.state.items[index].quantidade += 1
        this.setState({ items: this.state.items })
    }

    removeItem = (index) => {
        this.state.items[index].quantidade -= 1
        if (this.state.items[index].quantidade < 0) {
            this.state.items[index].quantidade = 0
        }
        this.setState({ items: this.state.items })
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading && <Loading />
                }
                {
                    !this.state.isLoading &&
                    <>
                        <div className="card-header d-flex align-items-center ">
                            <button type="button" onClick={() => { this.onClose() }} className="btn btn-sm btn-secondary">
                                <i className="fas fa-chevron-left mx-2" />
                            </button>
                            <h5 className='mt-1 mx-2'>Selecionar avaliação interna</h5>
                        </div>
                        <button className='btn btn-secondary w-100 btn-bg mt-3' onClick={() => this.onConfirm()} >Confirmar</button>
                        {
                            this.state.items.length === 0 && <EmptyContent text="Nenhuma avaliação encontrada encontrada." />
                        }
                        {
                            this.state.items.length > 0 &&
                            <ul className="list-group mt-3">
                                {
                                    this.state.items.map((item, index) => {
                                        return <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <div className="me-auto">
                                                {item.nome}
                                            </div>
                                            <h2 role='button' onClick={(e) => this.removeItem(index)}>
                                                <i className="fas fa-minus-circle text-danger" />
                                            </h2>
                                            <h4 className='mx-2'>
                                                {item.quantidade}
                                            </h4>
                                            <h2 role='button' onClick={(e) => this.addItem(index)}>
                                                <i className="fas fa-plus-circle text-success" />
                                            </h2>
                                        </li>
                                    })
                                }
                            </ul>
                        }
                        <button className='btn btn-secondary w-100 btn-bg mt-3' onClick={() => this.onConfirm()} >Confirmar</button>
                    </>
                }
            </>)
    }
}

class TicketRegisterFindProductsModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            itemsSelected: props.items ?? [],
            estacionamentoId: props.estacionamentoId,
            isAdministrador: isAdministrador(),
            items: [],
            isLoading: true
        }
    }

    componentDidMount = async () => {
        const items = await getAllProducts({ parkId: this.state.estacionamentoId })
        const user = getUsuarioCompact()
        this.setState({
            items: items.filter(item => {
                const selected = this.state.itemsSelected.filter(e => e.descricao === item.descricao)[0]
                item.quantidade = selected?.quantidade ?? 0
                if (this.state.isAdministrador) {
                    return true
                }
                if (!item.usersAllowed || item.usersAllowed?.length === 0) {
                    return true
                }
                if (item.usersAllowed.map(e => e.value).indexOf(user.id) !== -1) {
                    return true
                }
                return false
            })
        })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    onConfirm = () => {
        const user = getUsuarioCompact()
        const items = this.state.items.filter(e => e.quantidade > 0).map(e => {
            return {
                ...e,
                usersSell: [{
                    user: user,
                    quantity: e.quantidade,
                    total: e.valor * e.quantidade
                }],
            }
        })
        if (this.props.onConfirm) {
            this.props.onConfirm(items)
        }
        this.onClose()
    }

    addItem = (index) => {
        this.state.items[index].quantidade += 1
        this.setState({ items: this.state.items })
    }

    removeItem = (index) => {
        this.state.items[index].quantidade -= 1
        if (this.state.items[index].quantidade < 0) {
            this.state.items[index].quantidade = 0
        }
        this.setState({ items: this.state.items })
    }

    onClose = () => {
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading && <Loading />
                }
                {
                    !this.state.isLoading &&
                    <>
                        <div className="card-header d-flex align-items-center ">
                            <button type="button" onClick={() => { this.onClose() }} className="btn btn-sm btn-secondary">
                                <i className="fas fa-chevron-left mx-2" />
                            </button>
                            <h5 className='mt-1 mx-2'>Selecionar produtos e serviços</h5>
                        </div>
                        <button className='btn btn-secondary w-100 btn-bg mt-3' onClick={() => this.onConfirm()} >Confirmar</button>
                        {
                            this.state.items.length === 0 && <EmptyContent text="Nenhum produto ou serviço encontrado." />
                        }
                        {
                            this.state.items.length > 0 &&
                            <ul className="list-group mt-3">
                                {
                                    this.state.items.map((item, index) => {
                                        return <li className="list-group-item d-flex justify-content-between align-items-center">
                                            <div className="me-auto">
                                                {toCurrency(item.valor)} - {item.descricao}
                                            </div>
                                            <h2 role='button' onClick={(e) => this.removeItem(index)}>
                                                <i className="fas fa-minus-circle text-danger" />
                                            </h2>
                                            <h4 className='mx-2'>
                                                {item.quantidade}
                                            </h4>
                                            <h2 role='button' onClick={(e) => this.addItem(index)}>
                                                <i className="fas fa-plus-circle text-success" />
                                            </h2>
                                        </li>
                                    })
                                }
                            </ul>
                        }
                        {
                            this.state.items.length > 8 &&
                            <button className='btn btn-secondary w-100 btn-bg mt-3' onClick={() => this.onConfirm()} >Confirmar</button>
                        }
                    </>
                }
            </>)
    }
}
export {
    TicketListModal,
    TicketSearchAndRegisterPaymentModal,
    TicketRegisterNewVehicleModal,
    TicketRegisterPaymentModal,
    TicketRegisterFindProductsModal,
    TicketRegisterObservationModal,
    TicketPaymentMethodModal,
    TicketRegisterNoteModal,
    TicketRegisterConfirmedModal
}