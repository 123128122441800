import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItem, docsToItems } from '../transform.docs';
import { addDoc, deleteDoc, updateDoc } from './collectionBaseWorker';

export const getAllCamerasLPRByParkId = async ({ estacionamentoId }) => {
    const results = await firebase.firestore().collection(Collection.CAMERAS_LPR)
        .where(WhereField.PARKID, '==', estacionamentoId).get()
    return docsToItems(results)
}

export const getCamerasLPRByIdentifierAndParkId = async ({ identifier, estacionamentoId }) => {
    const results = await firebase.firestore().collection(Collection.CAMERAS_LPR)
        .where(WhereField.PARKID, '==', estacionamentoId)
        .where(WhereField.IDENTIFIER, '==', identifier).get()
    return docsToItem(results)
}

export const addCameraLPR = async ({ data }) => {
    await addDoc({ collection: Collection.CAMERAS_LPR, data: data })
}

export const updateCameraLPR = async ({ id, data }) => {
    await updateDoc({ collection: Collection.CAMERAS_LPR, id: id, data: data })
}

export const deleteCameraLPR = async ({ id }) => {
    await deleteDoc({ collection: Collection.CAMERAS_LPR, id: id })
}
