import React from 'react';
import { isPlatformOwner, isDeveloper, isReseller, getRevendaIdByPark, getUsuario } from '../../config/auth'
import { sendScreenView } from '../../shared/analytics'

import { Link } from 'react-router-dom';
import './navbar.css';
import 'firebase/auth';
import 'firebase/storage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HttpsIcon from '@material-ui/icons/Https';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import CodeIcon from '@material-ui/icons/Code';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export const NavBarMenuRessales = props => {
    const [usuario] = React.useState(getUsuario());
    const [platformOpen, setPlatformOpen] = React.useState(false);
    const [revendaOpen, setRevendaOpen] = React.useState(false);
    const [financeiroOpen, setFinanceiro] = React.useState(false);
    const [analyticsOpen, setAnalytics] = React.useState(false);
    const [desenvolvimentoOpen, setDesenvolvimento] = React.useState(false);

    const [acessoRevendedorDashboard] = React.useState(usuario?.acessoRevendedorDashboard ?? true)
    const [acessoRevendedorCliente] = React.useState(usuario?.acessoRevendedorCliente ?? true)
    const [acessoRevendedorRevendedores] = React.useState(usuario?.acessoRevendedorRevendedores ?? true)
    const [acessoRevendedorCentralSuporte] = React.useState(usuario?.acessoRevendedorCentralSuporte ?? false)
    const [acessoRevendedorConfiguracao] = React.useState(usuario?.acessoRevendedorConfiguracao ?? true)
    const [acessoRevendedorChamados] = React.useState(usuario?.acessoRevendedorChamados ?? true)
    const [acessoRevendedorContratos] = React.useState(usuario?.acessoRevendedorContratos ?? true)
    const [acessoRevendedorRevenda] = React.useState(acessoRevendedorDashboard || acessoRevendedorCliente || acessoRevendedorRevendedores || acessoRevendedorConfiguracao || acessoRevendedorContratos || acessoRevendedorCentralSuporte)

    const [acessoRevendedorLogs] = React.useState(usuario?.acessoRevendedorLogs ?? false)
    const [acessoRevendedorConsumo] = React.useState(usuario?.acessoRevendedorConsumo ?? false)
    const [acessoRevendedorUsuarios] = React.useState(usuario?.acessoRevendedorUsuarios ?? true)
    const [acessoRevendedorCaixas] = React.useState(usuario?.acessoRevendedorCaixas ?? true)
    const [acessoRevendedorMensalistas] = React.useState(usuario?.acessoRevendedorMensalistas ?? true)
    const [acessoRevendedorAnalytics] = React.useState(acessoRevendedorUsuarios || acessoRevendedorCaixas || acessoRevendedorConsumo || acessoRevendedorMensalistas)

    const [acessoDesenvolvedor] = React.useState(isDeveloper() ?? false)
    const [acessoRevendedorVersaoMinima] = React.useState(usuario?.acessoRevendedorVersaoMinima ?? false)
    const [acessoRevendedorMarcasEModelos] = React.useState(usuario?.acessoRevendedorMarcasEModelos ?? false)
    const [acessoRevendedorAplicacoes] = React.useState(usuario?.acessoRevendedorAplicacoes ?? false)
    const [acessoRevendedorBackup] = React.useState(usuario?.acessoRevendedorBackup ?? false)
    const [acessoRevendedorDesenvolvedor] = React.useState(usuario?.developer || acessoRevendedorMarcasEModelos || acessoRevendedorVersaoMinima || acessoRevendedorAplicacoes || acessoRevendedorBackup)

    const [acessoRevendedorPlanos] = React.useState(usuario?.acessoRevendedorPlanos ?? true)
    const [acessoRevendedorAssinaturas] = React.useState(usuario?.acessoRevendedorAssinaturas ?? true)
    const [acessoRevendedorNotasFiscais] = React.useState(usuario?.acessoRevendedorNotasFiscais ?? true)
    const [acessoRevendedorFinanceiro] = React.useState(acessoRevendedorPlanos || acessoRevendedorAssinaturas || acessoRevendedorNotasFiscais)

    const togglePlatform = () => {
        setPlatformOpen(!platformOpen);
    }

    const toggleRevenda = () => {
        setRevendaOpen(!revendaOpen);
    }

    const toggleFinanceiro = () => {
        setFinanceiro(!financeiroOpen);
    }

    const toggleAnalytics = () => {
        setAnalytics(!analyticsOpen);
    }

    const toggleDesenvolvimento = () => {
        setDesenvolvimento(!desenvolvimentoOpen);
    }

    return (
        isReseller() &&
        <>
            {
                acessoRevendedorRevenda &&
                <List>
                    {
                        isPlatformOwner() && <>
                            <List>
                                <ListItem key={'Plataforma'} button onClick={togglePlatform}>
                                    <ListItemIcon> <AccountBalanceIcon /></ListItemIcon>
                                    <ListItemText primary='Plataforma' />
                                </ListItem>
                                {
                                    platformOpen && <>
                                        <Link to='/plataforma/revendas'>
                                            <ListItem button key='Revendas'>
                                                <ListItemText primary='Revendas' />
                                                <NavigateNextIcon />
                                            </ListItem>
                                        </Link>
                                    </>
                                }
                            </List>
                        </>
                    }
                    <ListItem key={'Revenda'} button onClick={toggleRevenda}>
                        <ListItemIcon> <HttpsIcon /></ListItemIcon>
                        <ListItemText primary='Revenda' />
                    </ListItem>
                    {
                        revendaOpen && <>
                            {
                                acessoRevendedorDashboard &&
                                <Link to='/revenda/home'>
                                    <ListItem button key='Dashboard-Revenda' onClick={(e) => sendScreenView("Revenda", "Dashboard")}>
                                        <ListItemText primary='Dashboard' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorCliente &&
                                <Link to='/revenda/cliente' onClick={(e) => sendScreenView("Revenda", "Clientes")}>
                                    <ListItem button key='Ticket'>
                                        <ListItemText primary='Clientes' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            <Link to='/revenda/marketing/banner'>
                                <ListItem button key='Promoções'>
                                    <ListItemText primary='Promoções' />
                                    <NavigateNextIcon />
                                </ListItem>
                            </Link>
                            {
                                acessoRevendedorRevendedores &&
                                <Link to='/revenda/revendedores' onClick={(e) => sendScreenView("Revenda", "Revendedores")}>
                                    <ListItem button key='Revendedores'>
                                        <ListItemText primary='Revendedores' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorChamados &&
                                <Link to='/revenda/chamados' onClick={(e) => sendScreenView("Revenda", "Chamados")}>
                                    <ListItem button key='Chamados'>
                                        <ListItemText primary='Chamados' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorContratos &&
                                <Link to='/revenda/contratos' onClick={(e) => sendScreenView("Revenda", "Contratos")}>
                                    <ListItem button key='Contratos'>
                                        <ListItemText primary='Contratos' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorCentralSuporte &&
                                <Link to='/revenda/central-suporte' onClick={(e) => sendScreenView("Revenda", "Centrais de suporte")}>
                                    <ListItem button key='Centrais de suporte'>
                                        <ListItemText primary='Centrais de suporte' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorConfiguracao &&
                                <Link to={`/revenda/detalhes/${getRevendaIdByPark()}`} onClick={(e) => sendScreenView("Revenda", "Configuração")}>
                                    <ListItem button key='Configuração'>
                                        <ListItemText primary='Configuração' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                        </>
                    }
                </List>
            }
            {
                acessoRevendedorAnalytics &&
                <List>
                    <ListItem key={'Analytics'} button onClick={toggleAnalytics}>
                        <ListItemIcon> <QueryStatsIcon /></ListItemIcon>
                        <ListItemText primary='Analytics' />
                    </ListItem>
                    {
                        analyticsOpen && <>
                            {
                                acessoRevendedorLogs &&
                                <Link to='/revenda/analytics/logs' onClick={(e) => sendScreenView("Analytics", "Logs")}>
                                    <ListItem button key='Logs'>
                                        <ListItemText primary='Logs' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorUsuarios &&
                                <Link to='/revenda/analytics/usuarios' onClick={(e) => sendScreenView("Analytics", "Usuários")}>
                                    <ListItem button key='Usuarios'>
                                        <ListItemText primary='Usuários' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorConsumo &&
                                <Link to='/revenda/analytics/consumo' onClick={(e) => sendScreenView("Analytics", "Consumo")}>
                                    <ListItem button key='Consumo'>
                                        <ListItemText primary='Consumo' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorCaixas &&
                                <Link to='/revenda/analytics/caixas' onClick={(e) => sendScreenView("Analytics", "Caixas")}>
                                    <ListItem button key='Caixas'>
                                        <ListItemText primary='Caixas' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorMensalistas &&
                                <Link to='/revenda/analytics/mensalistas' onClick={(e) => sendScreenView("Analytics", "Mensalistas")}>
                                    <ListItem button key='Mensalistas'>
                                        <ListItemText primary='Mensalistas' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                        </>
                    }
                </List>
            }
            {
                acessoRevendedorFinanceiro &&
                <List>
                    <ListItem key={'Financeiro'} button onClick={toggleFinanceiro}>
                        <ListItemIcon> <MonetizationOn /></ListItemIcon>
                        <ListItemText primary='Financeiro' />
                    </ListItem>
                    {
                        financeiroOpen && <>
                            {
                                isPlatformOwner() &&
                                <Link to='/revenda/financeiro/gerenciar-cobrancas' onClick={(e) => sendScreenView("Financeiro", "Gerenciar cobranças")}>
                                    <ListItem button key='Cobranças'>
                                        <ListItemText primary='Cobranças' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorPlanos &&
                                <Link to='/revenda/financeiro/gerenciar-planos' onClick={(e) => sendScreenView("Financeiro", "Gerenciar planos")}>
                                    <ListItem button key='Gerenciar Planos'>
                                        <ListItemText primary='Planos' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorAssinaturas &&
                                <Link to='/revenda/financeiro/gerenciar-assinaturas' onClick={(e) => sendScreenView("Financeiro", "Gerenciar assinaturas")}>
                                    <ListItem button key='Assinaturas'>
                                        <ListItemText primary='Assinaturas' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorNotasFiscais &&
                                <Link to='/revenda/financeiro/notas-fiscais' onClick={(e) => sendScreenView("Financeiro", "Notas fiscais")}>
                                    <ListItem button key='Notas fiscais'>
                                        <ListItemText primary='Notas fiscais' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                        </>
                    }
                </List>
            }
            {
                acessoRevendedorDesenvolvedor &&
                <List>
                    <ListItem key={'Desenvolvimento'} button onClick={toggleDesenvolvimento}>
                        <ListItemIcon> <CodeIcon /></ListItemIcon>
                        <ListItemText primary='Desenvolvimento' />
                    </ListItem>
                    {
                        desenvolvimentoOpen && <>
                            {
                                acessoDesenvolvedor && <>
                                    <Link to='/desenvolvimento/configuracoes-remotas' onClick={(e) => sendScreenView("Desenvolvimento", "Configurações remotas")}>
                                        <ListItem button key='Configurações remotas'>
                                            <ListItemText primary='Remote config' />
                                            <NavigateNextIcon />
                                        </ListItem>
                                    </Link>
                                    <Link to='/desenvolvimento/atualizacao' onClick={(e) => sendScreenView("Desenvolvimento", "Atualização")}>
                                        <ListItem button key='Atualização'>
                                            <ListItemText primary='Atualização' />
                                            <NavigateNextIcon />
                                        </ListItem>
                                    </Link>
                                    <Link to='/desenvolvimento/filas-de-callback' onClick={(e) => sendScreenView("Desenvolvimento", "Filas de callback")}>
                                        <ListItem button key='Filas'>
                                            <ListItemText primary='Filas' />
                                            <NavigateNextIcon />
                                        </ListItem>
                                    </Link>
                                    <Link to='/desenvolvimento/mensagens-whatsapp' onClick={(e) => sendScreenView("Desenvolvimento", "Mensagens do WhatsApp")}>
                                        <ListItem button key='WhatsApp'>
                                            <ListItemText primary='WhatsApp' />
                                            <NavigateNextIcon />
                                        </ListItem>
                                    </Link>
                                </>
                            }
                            {
                                acessoRevendedorVersaoMinima &&
                                <Link to='/desenvolvimento/versao-minima' onClick={(e) => sendScreenView("Desenvolvimento", "Versão miníma")}>
                                    <ListItem button key='Versao minima'>
                                        <ListItemText primary='Versão mínima' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorAplicacoes &&
                                <Link to='/desenvolvimento/aplicacoes' onClick={(e) => sendScreenView("Desenvolvimento", "Aplicações")}>
                                    <ListItem button key='Aplicações'>
                                        <ListItemText primary='Aplicações' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                            {
                                acessoRevendedorBackup &&
                                <Link to='/desenvolvimento/backups' onClick={(e) => sendScreenView("Desenvolvimento", "Backups")}>
                                    <ListItem button key='Backups'>
                                        <ListItemText primary='Backups' />
                                        <NavigateNextIcon />
                                    </ListItem>
                                </Link>
                            }
                        </>
                    }
                </List>
            }
        </>
    );
}