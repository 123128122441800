import { getUsuarioCompact } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { getResellerIdByEnv, isNullOrEmpty } from '../../shared/utils';
import { docToItem, docsToItem, docsToItems } from '../transform.docs';
import { addDoc, getAllDocsByClientId, updateDoc } from './collectionBaseWorker';

export const addUser = async ({ nome, email, phone, password, revendaId, isTermsUpdated }) => {
    try {
        const user = await firebase.auth().createUserWithEmailAndPassword(email, password)
        const data = {
            nome: nome,
            email: email.toLowerCase(),
            cellphone: phone,
            usuarioId: user.user.uid,
            status: "CREATED_ACCOUNT",
            codigo: "0001",
            isTermsUpdated: isTermsUpdated === true,
            revendaId: revendaId ?? getResellerIdByEnv(),
            downloadedApp: false,
            lastAccess: new Date(),
            isDeleted: false,
        }
        const doc = await addDoc({ collection: Collection.USERS, data: data })
        return { success: true, id: doc.id }
    } catch (error) {
        if (error.code === "auth/email-already-in-use") {
            return { success: false, message: "Este e-mail já esta sendo utilizado por outro usuário, caso seja você, tente fazer login." }
        } else if (error.code === "auth/invalid-email") {
            return { success: false, message: "Informe um e-mail válido." }
        } else if (error.code === "auth/operation-not-allowed") {
            return { success: false, message: "Operação não aceita, tente novamente mais tarde." }
        } else if (error.code === "auth/weak-password") {
            return { success: false, message: "Esta senha esta muito fraca, tente uma senha mais forte." }
        } else if (error.code === "auth/email-already-exists") {
            return { success: false, message: "Já existe um usuário com este e-mail cadastrado." }
        }
        return { success: false, message: "Não conseguimos efetuar esta ação. Por favor, tente novamente mais tarde." }
    }
}

export const updateUser = async ({ userId, clienteId, perfilId, estacionamentoId, parkName }) => {
    const data = {
        clienteId: clienteId,
        perfilId: perfilId,
        status: "ACTIVE",
        estacionamentos: [
            {
                id: estacionamentoId,
                nome: parkName,
            }
        ]
    }
    await updateDoc({ collection: Collection.USERS, id: userId, data: data })
}

export const updateUserById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.USERS, id: id, data: data })
}

export const updateUserDate = async ({ id, data }) => {
    await updateDoc({ collection: Collection.USERS, id: id, data: data })
}

export const updateRevendaId = async ({ userId, revendaId }) => {
    const data = {
        revendaId: revendaId
    }
    await updateDoc({ collection: Collection.USERS, id: userId, data: data })
}

export const getAllUsersByAssociatedRessales = async ({ associatedResales }) => {
    if (isNullOrEmpty(associatedResales)) {
        return []
    }
    const results = await firebase.firestore().collection(Collection.USERS).get()
    const items = docsToItems(results)
    return items.filter(e => associatedResales.map(e => e.value).includes(e.revendaId))
}

export const getAllUsersByRessaleId = async ({ ressaleId }) => {
    var results = await firebase.firestore().collection(Collection.USERS).where("revendaId", "==", ressaleId).get()
    return docsToItems(results)
}

export const getAllUsersByLimit = async ({ limit, associatedRessales }) => {
    if (isNullOrEmpty(associatedRessales)) {
        return []
    }
    const results = await firebase.firestore().collection(Collection.USERS).orderBy("lastAccess", "desc").where("revendaId", "in", associatedRessales.map(e => e.value)).limit(limit).get()
    return docsToItems(results)
}

export const getAllUsersActiveByClientId = async ({ clienteId }) => {
    const results = await firebase.firestore().collection(Collection.USERS).where('clienteId', '==', clienteId).get()
    return docsToItems(results).filter(e => e.status !== "INACTIVE" && e.revendedor !== true).sort((a, b) => a.nome.localeCompare(b.nome))
}

export const getAllUsersByRevendaId = async ({ revendaId }) => {
    const results = await firebase.firestore().collection(Collection.USERS).where("revendaId", "==", revendaId).get()
    return docsToItems(results)
}

export const getAllUsersByClienteId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.USERS, clienteId: clienteId })
}

export const getUseByAccessCode = async ({ clienteId, code }) => {
    const results = await firebase.firestore().collection(Collection.USERS).where("clienteId", "==", clienteId).where("codigo", "==", code).get()
    return docsToItem(results)
}

export const getUserById = async ({ id }) => {
    const results = await firebase.firestore().collection(Collection.USERS).doc(id).get()
    return docToItem(results)
}

export const getUserBySession = async () => {
    const user = getUsuarioCompact()
    if (user) {
        const results = await firebase.firestore().collection(Collection.USERS).doc(user.id).get()
        return docToItem(results)
    }
    return null
}

export const updateUserCodigo = async (id, codigo) => {
    const data = {
        codigo: codigo
    }
    await updateDoc({ collection: Collection.USERS, id: id, data: data })
}

export const getUser = async ({ email, password }) => {
    try {
        const userAuth = await firebase.auth().signInWithEmailAndPassword(email.toLowerCase(), password)
        const results = await firebase.firestore().collection(Collection.USERS).where('usuarioId', '==', userAuth.user.uid).get()
        const user = docsToItem(results)
        if (user) {
            return { success: true, data: user }
        }
        return { success: false }
    } catch (err) {
        console.log(err);
        if (err.code === "auth/user-not-found" || err.code === "auth/wrong-password") {
            return { success: false, message: "O e-mail ou senha não confere" }
        }
        return { success: false, message: "Não conseguimos efetuar esta ação. Por favor, tente novamente mais tarde." }
    }
}

export const updateDownloadedApp = async ({ userId, downloadedApp }) => {
    const data = {
        downloadedApp: downloadedApp,
    }
    await updateDoc({ collection: Collection.USERS, id: userId, data: data })
}

export const updateNotShowBannerDownloadedApp = async ({ userId, notShowBannerDownloadedApp }) => {
    const data = {
        notShowBannerDownloadedApp: notShowBannerDownloadedApp
    }
    await updateDoc({ collection: Collection.USERS, id: userId, data: data })
}

export const updateNotShowBannerNFE = async ({ userId, notShowBannerNFE }) => {
    const data = {
        notShowBannerNFE: notShowBannerNFE
    }
    await updateDoc({ collection: Collection.USERS, id: userId, data: data })
}

export const getAllRessellers = async ({ associatedResales }) => {
    const results = await firebase.firestore().collection(Collection.USERS).where("revendedor", "==", true).where("revendaId", "in", associatedResales.map(e => e.value)).get()
    return docsToItems(results)
}

export const updateUserToMainReseller = async ({ id, isMainResele }) => {
    const data = {
        revendedor: isMainResele,
        administrativo: isMainResele
    }
    await updateDoc({ collection: Collection.USERS, id: id, data: data })
}

export const updateUserToReseller = async ({ id, isRevendedor }) => {
    const data = {
        revendedor: isRevendedor
    }
    await updateDoc({ collection: Collection.USERS, id: id, data: data })
}

export const updateUserRevendaId = async ({ id, revendaId }) => {
    const data = {
        revendaId: revendaId
    }
    await updateDoc({ collection: Collection.USERS, id: id, data: data })
}

export const updateUserData = async ({ id, data }) => {
    await updateDoc({ collection: Collection.USERS, id: id, data: data })
}

export const getUsersByProfileId = async ({ profileId }) => {
    var result = await firebase.firestore().collection(Collection.USERS).where('perfilId', '==', profileId).get()
    return docsToItems(result)
}
