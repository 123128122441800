import React from 'react';
import './index.css';
import 'firebase/auth';
import { fetchRevendaByHost } from '../../../shared/service/revendaService';
import { getURLFromURI } from '../../../store/filesWorker';
import { getRevendaLinkWhatsApp } from '../../../config/auth';
import allFunctions from '../funcionalidades/files/funcoes.json';
import { isNullOrEmpty } from '../../../shared/utils';

class ComercialFuncionalidades extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            logoAdministrativo: "",
            items: allFunctions,
            auxItems: allFunctions,
            searchTerm: ""
        }
        console.log(allFunctions);
        this.loadRevenda()
    }

    loadRevenda = async () => {
        const revenda = await fetchRevendaByHost()
        if (revenda.logoAdministrativo) {
            const logoAdministrativo = await getURLFromURI(revenda.logoAdministrativo)
            this.setState({ logoAdministrativo: logoAdministrativo })
        }
        this.setState({ revenda: revenda })
        this.setState({ linkWhatsApp: getRevendaLinkWhatsApp() })
        this.setState({ isPagginLoading: false })
    }

    search = (f) => {
        const value = f.target.value.toLowerCase()
        this.setState({ searchTerm: value })
        if (!isNullOrEmpty(value)) {
            const items = this.state.auxItems.filter(e => e.section.toLowerCase().indexOf(value) !== -1 ||
                e.description.toLowerCase().indexOf(value) !== -1 ||
                e.items.filter(e => e.title.toLowerCase().indexOf(value) !== -1).length !== 0 ||
                e.items.filter(e => e.subItems.join(", ").toLowerCase().indexOf(value) !== -1).length !== 0)
            this.setState({ items: items })
        } else {
            this.setState({ items: this.state.auxItems })
        }
    }

    escapeRegExp = (str = '') => (
        str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')
    );

    Highlight = ({ search = '', children = '' }) => {
        const patt = new RegExp(`(${this.escapeRegExp(search)})`, 'i');
        const parts = String(children).split(patt);

        if (search) {
            return parts.map((part, index) => (
                patt.test(part) ? <mark key={index}>{part}</mark> : part
            ));
        } else {
            return children;
        }
    };

    render() {
        return (
            <div className='row'>
                <div className='col-lg-6'>
                    <div className="image-container my-3">
                        <img height='50' src={this.state.logoAdministrativo} />
                    </div>
                    <div className="login-content">
                        <h2 style={{ color: this.state.revenda?.siteCor }}><strong>Funcionalidades</strong></h2>
                        <h6>
                            Nesta página você terá acesso a listagens de todas as funcionalidades do sistema. <p />
                        </h6>
                        <div>
                            <input type="text" onChange={this.search} className='form-control form-control-lg' placeholder='Pesquise pela funcionalidade...' />
                        </div>
                        {
                            this.state.items.map(item =>
                                <>
                                    <h5 className='mt-3' style={{ color: this.state.revenda?.siteCor }}>
                                        <strong>
                                            <this.Highlight search={this.state.searchTerm}>{item.section}</this.Highlight>
                                        </strong></h5>
                                    <span>
                                        <this.Highlight search={this.state.searchTerm}>{item.description}</this.Highlight>
                                    </span>
                                    <div className='mt-2'>
                                        {
                                            item.items.map(subItem => <>
                                                <this.Highlight search={this.state.searchTerm}>{subItem.title}</this.Highlight> <br />
                                                {
                                                    !isNullOrEmpty(subItem.description) && <>
                                                        <this.Highlight search={this.state.searchTerm}><small>{subItem.description}</small></this.Highlight> <br />
                                                    </>
                                                }
                                                {
                                                    !isNullOrEmpty(subItem?.subItems) &&
                                                    <ul>
                                                        {
                                                            subItem?.subItems?.map(subItem =>
                                                                <li>
                                                                    <this.Highlight search={this.state.searchTerm}>{subItem}</this.Highlight>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                }
                                            </>
                                            )
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className='col-lg-6 pos-container' style={{ backgroundColor: this.state.revenda?.siteCor }}>
                    <div className="row">
                        <div className="promotion-content" style={{ backgroundColor: this.state.revenda?.siteCor }}>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export { ComercialFuncionalidades };