import { getEstacionamento, getRevenda, getRevendaIdByPark, getUsuario, getUsuarioCompact, isAdministrador } from "../../../config/auth";
import React from 'react';
import { addMarketingBanner, deleteMarketingBanner, getActivesBannersByRevendaId, getAllBannersByRevendaId, updateMarketingBannerById } from "../../../store/collections/marketingWorker";
import moment from "moment";
import { EmptyContent, Loading } from "../../../components/common/commons";
import { NavBar } from "../../../components/navbar";
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import { generateUUIDV7, goToNewWindow, isNullOrEmpty, reloadWithAlert, takeIfIsNotNullOrEmpty, toastDismissLoading, toastLoading, toastSuccess, toastWarning, valueByMaximunChars } from "../../../shared/utils";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { TextField } from "@mui/material";
import Moment from 'moment';
import { getAllRessellers } from "../../../store/collections/userWorker";
import Select from 'react-select';
import { getURLFromURI, uploadMarketingBanner } from "../../../store/filesWorker";
import { getParameterByParkId } from "../../../store/collections/parameterWorker";
import { getLicencaByParkId } from "../../../store/collections/licencaWorker";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { addQueue } from "../../../store/collections/queueWorker";
import { id } from "date-fns/locale";

class MarketingBanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            state: "loading",
            type: "banner",
            banner: null,
            random: Math.random(),
            revendaId: getRevendaIdByPark(),
            revenda: getRevenda(),
            banners: [],
            startDate: Moment(new Date()),
            endDate: Moment(new Date()),
            showIn: "",
            action: "internal",
            notify: "FALSE",
            actionValue: "",
            hiddenSelected: [],
            hidden: [
                {
                    value: "SEMPARAR_ATIVO",
                    label: "Sem parar ativo",
                },
                {
                    value: "CLIENTES_PAGANTES",
                    label: "Clientes pagantes",
                },
                {
                    value: "TAGGY_ATIVO",
                    label: "Taggy ativo",
                },
                {
                    value: "VELOE_ATIVO",
                    label: "Veloe ativo",
                },
                {
                    value: "CONECTCAR_ATIVO",
                    label: "ConectCar ativo",
                },
                {
                    value: "COBRANCA_MENSALISTA_ATIVO",
                    label: "Cobrança mensalista ativo",
                },
                {
                    value: "COBRANCA_TICKETS_ATIVO",
                    label: "Cobrança tickets ativo",
                }
            ],
            users: [],
        }
        this.load()
        this.loadRessalesrs()
    }

    load = async () => {
        this.setState({ state: "loading" })
        const banners = await getAllBannersByRevendaId({ revendaId: this.state.revendaId })
        if (banners.length > 0) {
            this.setState({ banners: banners })
            this.setState({ state: "fill" })
        } else {
            this.setState({ state: "empty" })
        }
    }

    loadRessalesrs = async () => {
        const revendasAssociadas = getUsuario().revendasAssociadas ?? []
        const users = await getAllRessellers({ associatedResales: revendasAssociadas })
        this.setState({ users: users.map(e => { return { value: e.id, label: e.nome } }) })
    }

    addBanner = async () => {
        const { title, type, startDate, endDate, notifiers, notify, file } = this.state
        if (!file) {
            toastWarning('Selecione um arquivo para o banner.')
            return
        }
        if (!this.validateFields()) {
            return
        }
        toastLoading("Salvando banner...")
        var url = this.state.url
        if (file) {
            const extension = file.type.split("/")[1]
            const bannerFile = await uploadMarketingBanner(this.state.revendaId, this.state.file, extension)
            url = await getURLFromURI(bannerFile)
        }
        const data = {
            title: title,
            type: type,
            showIn: isNullOrEmpty(this.state.showIn) ? [] : [this.state.showIn],
            action: this.state.action,
            actionValue: this.state.actionValue,
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
            revendaId: this.state.revendaId,
            notifiers: notifiers ?? [],
            notify: notify,
            hidden: this.state.hiddenSelected,
            tappers: [],
            url: url,
            taps: 0,
        }
        await addMarketingBanner({ data: data })
        this.hideBannerModal()
        toastDismissLoading()
        reloadWithAlert("Banner adicionado com sucesso!")
    }

    updateBanner = async () => {
        const { title, type, startDate, endDate, revendaId, notifiers, notify, file } = this.state
        if (!this.validateFields()) {
            return
        }
        toastLoading("Salvando banner...")
        var data = {
            title: title,
            type: type,
            showIn: isNullOrEmpty(this.state.showIn) ? [] : [this.state.showIn],
            action: this.state.action,
            actionValue: this.state.actionValue,
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
            revendaId: revendaId,
            notifiers: notifiers ?? [],
            notify: notify,
            hidden: this.state.hiddenSelected,
        }
        if (file) {
            const extension = file.type.split("/")[1]
            const bannerFile = await uploadMarketingBanner(revendaId, this.state.file, extension)
            const url = await getURLFromURI(bannerFile)
            data.url = url
        }
        await updateMarketingBannerById({ id: this.state.banner.id, data: data })
        this.hideBannerModal()
        toastDismissLoading()
        reloadWithAlert("Banner atualizado com sucesso!")
    }

    validateFields = () => {
        const { title, type, startDate, endDate, actionValue } = this.state
        if (isNullOrEmpty(title)) {
            toastWarning('O título não pode ser vazio.')
            return false
        }
        if (isNullOrEmpty(type)) {
            toastWarning('O tipo não pode ser vazio.')
            return false
        }
        if (isNullOrEmpty(startDate)) {
            toastWarning('A data de início não pode ser vazia.')
            return false
        }
        if (isNullOrEmpty(endDate)) {
            toastWarning('A data de fim não pode ser vazia.')
            return false
        }
        if (startDate.isAfter(endDate)) {
            toastWarning('A data de fim não pode ser maior que a data início.')
            return false
        }
        if (isNullOrEmpty(actionValue)) {
            toastWarning('Informe a navegação para o banner.')
            return false
        }
        return true
    }

    handleStartDate = (newValue) => {
        this.setState({ startDate: newValue })
    }

    handleEndDate = (newValue) => {
        this.setState({ endDate: newValue })
    }

    showBannerModal = () => {
        this.setState({ showBannerModal: true })
    }

    hideBannerModal = () => {
        this.setState({ showBannerModal: false })
    }

    handleFile = (e) => {
        this.setState({ file: e.target.files[0] })
        this.setState({ url: URL.createObjectURL(e.target.files[0]) })
    }

    handlerNotifiers = (selected) => {
        this.setState({ notifiers: selected })
    }

    handleHidden = (selected) => {
        this.setState({ hiddenSelected: selected })
    }

    onDelete = async (id) => {
        const confirm = window.confirm(`Deseja mesmo remover este banner?`)
        if (confirm) {
            toastLoading("Removendo banner...")
            await deleteMarketingBanner({ id: id })
            toastDismissLoading()
            toastSuccess("Banner removido com sucesso!")
            this.load()
        }
    }

    onEdit = (item) => {
        this.setState({ banner: item })
        this.setState({ ...item })
        this.setState({ startDate: Moment(item.startDate.seconds * 1000) })
        this.setState({ endDate: Moment(item.endDate.seconds * 1000) })
        this.setState({ random: Math.random() })
        this.setState({ hiddenSelected: item.hidden })
        this.setState({ showBannerModal: true })
    }

    onDuplicate = (item) => {
        this.setState({ ...item })
        this.setState({ startDate: Moment(item.startDate.seconds * 1000) })
        this.setState({ endDate: Moment(item.endDate.seconds * 1000) })
        this.setState({ random: Math.random() })
        this.setState({ hiddenSelected: item.hidden })
        this.setState({ showBannerModal: true })
    }

    hideBannerTappersModal = () => {
        this.setState({ showBannerTappersModal: false })
    }

    onDetails = (item) => {
        this.setState({ banner: item })
        this.setState({ showBannerTappersModal: true })
    }

    onRemoveTapper = async (index) => {
        const banner = this.state.banner
        banner.tappers.splice(index, 1)
        this.setState({ banner: banner })
        await updateMarketingBannerById({ id: banner.id, data: banner })
    }

    render() {
        return (
            <NavBar>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Banners e promoções
                        <span className='btn-group'>
                            <button type="button" onClick={(e) => this.showBannerModal(e)} className="btn btn-sm btn-primary">
                                <i className="fas fa-add mx-2" />
                                Criar banner
                            </button>
                        </span>
                    </div>
                    {
                        this.state.state === "loading" && <Loading />
                    }
                    {
                        this.state.state === "error" && <EmptyContent text="Ocorreu um erro ao carregar os banners" />
                    }
                    {
                        this.state.state === "empty" && <EmptyContent text="Nenhum banner cadastrado até o momento" />
                    }
                    {
                        this.state.state === "fill" &&
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" width={10}></th>
                                    <th scope="col" width={10}></th>
                                    <th scope="col" width={100}>Titulo</th>
                                    <th scope="col">Mostrar em</th>
                                    <th scope="col">Navegar para</th>
                                    <th scope="col" className="d-none d-sm-table-cell">Início</th>
                                    <th scope="col" className="d-none d-sm-table-cell">Fim</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.banners.map(item =>
                                        <tr key={item.id}>
                                            <td align='center' width={10}>
                                                {
                                                    Moment().isBefore(Moment(item.startDate.seconds * 1000)) &&
                                                    <span className="badge text-bg-secondary w-100">
                                                        Aguardando
                                                    </span>
                                                }
                                                {
                                                    Moment().isSameOrAfter(Moment(item.startDate.seconds * 1000)) && Moment().isBefore(Moment(item.endDate.seconds * 1000)) &&
                                                    <span className="badge text-bg-success w-100">
                                                        Em vigor
                                                    </span>
                                                }
                                                {
                                                    Moment().isAfter(Moment(item.endDate.seconds * 1000)) &&
                                                    <span className="badge text-bg-danger w-100">
                                                        Expirado
                                                    </span>
                                                }
                                            </td>
                                            <td align='center' width={10}>
                                                <span className="badge text-bg-success w-100">
                                                    {item.type}
                                                </span>
                                            </td>
                                            <td width={100}>{item.title}</td>
                                            <td>
                                                {
                                                    takeIfIsNotNullOrEmpty(item.showIn) ?? "Todas as páginas"
                                                }
                                            </td>
                                            <td>
                                                <Tooltip title={item.actionValue} placement="top">
                                                    <span>{valueByMaximunChars(item.actionValue, 50)}</span>
                                                </Tooltip>
                                            </td>
                                            <td className="d-none d-sm-table-cell">{moment(item.startDate.seconds * 1000).format("DD/MM/YYYY")}</td>
                                            <td className="d-none d-sm-table-cell">{moment(item.endDate.seconds * 1000).format("DD/MM/YYYY")}</td>
                                            <td width='10'>
                                                <div className="btn-group">
                                                    <Tooltip title="Histórico de acessos" placement="top">
                                                        <button className='btn btn-warning text-white' onClick={(e) => this.onDetails(item)}>
                                                            <QueryStatsIcon />
                                                        </button>
                                                    </Tooltip>
                                                    <Tooltip title="Duplicar" placement="top">
                                                        <button className="btn btn-warning text-white" onClick={(e) => this.onDuplicate(item)}>
                                                            <i className="fas fa-copy" />
                                                        </button>
                                                    </Tooltip>
                                                    <Tooltip title="Editar" placement="top">
                                                        <button className="btn btn-secondary" onClick={(e) => this.onEdit(item)}>
                                                            <i className="fas fa-edit" />
                                                        </button>
                                                    </Tooltip>
                                                    <Tooltip title="Abrir link" placement="top">
                                                        <a href={item.actionValue} target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                                                            <i className="fas fa-external-link-square-alt" />
                                                        </a>
                                                    </Tooltip>
                                                    <Tooltip title="Apagar banner" placement="top">
                                                        <button className="btn btn-danger" onClick={(e) => this.onDelete(item.id)}>
                                                            <i className="fas fa-trash" />
                                                        </button>
                                                    </Tooltip>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    }
                </div>
                <Dialog open={this.state.showBannerTappersModal} onClose={this.hideBannerTappersModal} fullWidth maxWidth='md'>
                    <MuiDialogTitle className="text-center">
                        Cliques
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        {
                            isNullOrEmpty(this.state.banner?.tappers) ?
                                <EmptyContent text="Nenhum clique até o momento." /> :
                                <div className="row">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td>Estabelecimento</td>
                                                <td>Operador</td>
                                                <td>E-mail</td>
                                                <td>Data</td>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.banner?.tappers?.map((item, index) =>
                                                    <tr key={item.id}>
                                                        <td>{item.estacionamentoNome}</td>
                                                        <td>{item.createdBy?.nome}</td>
                                                        <td>{item.createdBy?.email}</td>
                                                        <td>{moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</td>
                                                        <td width='10'>
                                                            <div className="btn-group">
                                                                <a href={`/revenda/cliente/${item.clienteId}?e=${item.estacionamentoId}`} rel="noopener noreferrer" className="btn btn-primary">
                                                                    <i className="fas fa-eye" />
                                                                </a>
                                                                <button type="button" onClick={(e) => this.onRemoveTapper(index)} className="btn btn-danger">
                                                                    <i className="fas fa-trash" />
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <button onClick={this.hideBannerTappersModal} className='btn btn-secondary m-3'>Fechar</button>
                    </MuiDialogActions>
                </Dialog>
                <Dialog open={this.state.showBannerModal} onClose={this.hideBannerModal} fullWidth maxWidth='lg'>
                    <MuiDialogTitle className="text-center">
                        {
                            this.state.banner?.id ?
                                <span>Editar banner</span> :
                                <span>Criar banner</span>
                        }
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="form-group mb-3">
                                    {
                                        this.state.url &&
                                        <img src={this.state.url} alt="Banner" style={{ borderRadius: "8px" }} className="img-fluid" />
                                    }
                                    <div className="mt-2">
                                        <input type="file" className="form-control-file" id="exampleFormControlFile1" onChange={this.handleFile} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="row mt-2">
                                    <div className="col-lg-4">
                                        <label htmlFor="type">Tipo</label>
                                        <select className="form-select" id="type" onChange={(e) => this.setState({ type: e.target.value })} value={this.state.type}>
                                            <option value={'banner'}>Banner</option>
                                            <option disabled>Modal</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-8">
                                        <label htmlFor="title">Título</label>
                                        <input type="text" className="form-control" id="title" onChange={(e) => this.setState({ title: e.target.value })} value={this.state.title} />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-6">
                                        <span>Data início</span> <br />
                                        <div>
                                            <MobileDatePicker
                                                label="-"
                                                inputFormat="DD/MM/yyyy"
                                                value={this.state.startDate}
                                                onChange={this.handleStartDate}
                                                renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <span>Data fim</span> <br />
                                        <div>
                                            <MobileDatePicker
                                                label="-"
                                                inputFormat="DD/MM/yyyy"
                                                value={this.state.endDate}
                                                onChange={this.handleEndDate}
                                                renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="type">Mostrar em</label>
                                    <select className="form-select" id="action" value={this.state.showIn} onChange={(e) => this.setState({ showIn: e.target.value })}>
                                        <option value={''}>Todas as páginas</option>
                                        <option value={'/home'}>/home</option>
                                        <option value={'/operacao/patio-ao-vivo'}>/operacao/patio-ao-vivo</option>
                                        <option value={'/licenca/minha-licenca'}>/licenca/minha-licenca</option>
                                        <option value={'/cadastrar/mensalista'}>/cadastrar/mensalista</option>
                                        <option value={'/visualizar/mensalista'}>/visualizar/mensalista</option>
                                        <option value={'/cadastrar/plano'}>/cadastrar/plano</option>
                                        <option value={'/cadastrar/produto'}>/cadastrar/produto</option>
                                        <option value={'/cadastrar/convenio'}>/cadastrar/convenio</option>
                                        <option value={'/cadastrar/perfil'}>/cadastrar/perfil</option>
                                        <option value={'/cadastrar/usuario'}>/cadastrar/usuario</option>
                                        <option value={'/cadastrar/forma-pagamento'}>/cadastrar/forma-pagamento</option>
                                        <option value={'/cadastrar/tabela-preco'}>/cadastrar/tabela-preco</option>
                                        <option value={'/cadastrar/setor'}>/cadastrar/setor</option>
                                        <option value={'/cadastrar/credenciado'}>/cadastrar/credenciado</option>
                                        <option value={'/buscar/ticket'}>/buscar/ticket</option>
                                        <option value={'/buscar/caixas'}>/buscar/caixas</option>
                                        <option value={'/buscar/mensalistas'}>/buscar/mensalistas</option>
                                        <option value={'/relatorios/vendas'}>/relatorios/vendas</option>
                                        <option value={'/relatorios/caixas'}>/relatorios/caixas</option>
                                        <option value={'/caixas/'}>/caixas</option>
                                        <option value={'/relatorios/mensalistas'}>/relatorios/mensalistas</option>
                                        <option value={'/relatorios/acessos'}>/relatorios/acessos</option>
                                        <option value={'/nota-fiscal/listar'}>/nota-fiscal/listar</option>
                                        <option value={'/nota-fiscal/configurar'}>/nota-fiscal/configurar'</option>
                                        <option value={'/configuracao/parametros'}>/configuracao/parametros</option>
                                        <option value={'/configuracao/dados-cadastrais'}>/configuracao/dados-cadastrais</option>
                                        <option value={'/configuracao/cobrancas'}>/configuracao/cobrancas</option>
                                        <option value={'/equipamentos/totens'}>/equipamentos/totens</option>
                                        <option value={'/equipamentos/cameras-lpr'}>/equipamentos/cameras-lpr</option>
                                        <option value={'/equipamentos/aparelhos'}>/equipamentos/aparelhos</option>
                                        <option value={'/conta/termos'}>/conta/termos</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="col-lg-12">
                                    <label htmlFor="type">Ocultar para clientes com:</label>
                                    <Select
                                        key={this.state.random}
                                        isMulti
                                        name="revendas"
                                        defaultValue={this.state.hiddenSelected}
                                        options={this.state.hidden}
                                        onChange={this.handleHidden}
                                        className="basic-multi-select"
                                        classNamePrefix="Selecionar as revendas associadas"
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="type">Notificar</label>
                                    <select className="form-select" id="type" onChange={(e) => this.setState({ notify: e.target.value })} value={this.state.notify}>
                                        <option value={"FALSE"}>Não</option>
                                        <option value={"TRUE"}>Sim</option>
                                    </select>
                                    {
                                        this.state.notify === "TRUE" &&
                                        <div className="mt-3">
                                            <Select
                                                key={this.state.random}
                                                isMulti
                                                name="revendas"
                                                defaultValue={this.state.notifiers}
                                                options={this.state.users}
                                                onChange={this.handlerNotifiers}
                                                className="basic-multi-select"
                                                classNamePrefix="Selecionar as revendas associadas"
                                            />
                                        </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <label htmlFor="action">Navegação</label>
                                    <select className="form-select" id="action" value={this.state.action} onChange={(e) => this.setState({ action: e.target.value })}>
                                        <option value={"internal"}>Página interna</option>
                                        <option value={"external"}>Página externa</option>
                                    </select>
                                </div>
                                {
                                    this.state.action === "external" &&
                                    <div className="input-group mt-3">
                                        <input type="text" className="form-control" value={this.state.actionValue} onChange={(e) => this.setState({ actionValue: e.target.value })} />
                                    </div>
                                }
                                {
                                    this.state.action === "internal" &&
                                    <div className="input-group mt-3">
                                        <span className="input-group-text" id="basic-addon3">{this.state.revenda?.siteAdmin}</span>
                                        <select className="form-select" id="action" value={this.state.actionValue} onChange={(e) => this.setState({ actionValue: e.target.value })}>
                                            <option value={''}>Selecionar</option>
                                            <option value={'/home'}>/home</option>
                                            <option value={'/operacao/patio-ao-vivo'}>/operacao/patio-ao-vivo</option>
                                            <option value={'/licenca/minha-licenca'}>/licenca/minha-licenca</option>
                                            <option value={'/cadastrar/mensalista'}>/cadastrar/mensalista</option>
                                            <option value={'/visualizar/mensalista'}>/visualizar/mensalista</option>
                                            <option value={'/cadastrar/plano'}>/cadastrar/plano</option>
                                            <option value={'/cadastrar/produto'}>/cadastrar/produto</option>
                                            <option value={'/cadastrar/convenio'}>/cadastrar/convenio</option>
                                            <option value={'/cadastrar/perfil'}>/cadastrar/perfil</option>
                                            <option value={'/cadastrar/usuario'}>/cadastrar/usuario</option>
                                            <option value={'/cadastrar/forma-pagamento'}>/cadastrar/forma-pagamento</option>
                                            <option value={'/cadastrar/tabela-preco'}>/cadastrar/tabela-preco</option>
                                            <option value={'/cadastrar/setor'}>/cadastrar/setor</option>
                                            <option value={'/cadastrar/credenciado'}>/cadastrar/credenciado</option>
                                            <option value={'/buscar/ticket'}>/buscar/ticket</option>
                                            <option value={'/buscar/caixas'}>/buscar/caixas</option>
                                            <option value={'/buscar/mensalistas'}>/buscar/mensalistas</option>
                                            <option value={'/relatorios/vendas'}>/relatorios/vendas</option>
                                            <option value={'/relatorios/caixas'}>/relatorios/caixas</option>
                                            <option value={'/caixas/'}>/caixas</option>
                                            <option value={'/relatorios/mensalistas'}>/relatorios/mensalistas</option>
                                            <option value={'/relatorios/acessos'}>/relatorios/acessos</option>
                                            <option value={'/nota-fiscal/listar'}>/nota-fiscal/listar</option>
                                            <option value={'/nota-fiscal/configurar'}>/nota-fiscal/configurar</option>
                                            <option value={'/configuracao/parametros'}>/configuracao/parametros</option>
                                            <option value={'/configuracao/dados-cadastrais'}>/configuracao/dados-cadastrais</option>
                                            <option value={'/configuracao/cobrancas'}>/configuracao/cobrancas</option>
                                            <option value={'/equipamentos/totens'}>/equipamentos/totens</option>
                                            <option value={'/equipamentos/cameras-lpr'}>/equipamentos/cameras-lpr</option>
                                            <option value={'/equipamentos/aparelhos'}>/equipamentos/aparelhos</option>
                                            <option value={'/conta/termos'}>/conta/termos</option>
                                        </select>
                                    </div>
                                }
                            </div>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className="btn-group m-3">
                            <button type="button" className="btn btn-secondary" onClick={this.hideBannerModal}>
                                Cancelar
                            </button>
                            {
                                this.state.banner?.id ?
                                    <button type="button" className="btn btn-success" onClick={this.updateBanner}>
                                        Atualizar
                                    </button> :
                                    <button type="button" className="btn btn-success" onClick={this.addBanner}>
                                        Adicionar
                                    </button>
                            }
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </NavBar>)
    }
}

class MarketingBannerActive extends React.Component {

    constructor(props) {
        super(props)
        const park = getEstacionamento()
        if (park) {
            this.state = {
                banner: null,
                clienteId: park.clienteId,
                revenda: getRevenda(),
                estacionamentoId: park.id,
                estacionamentoNome: park.nome,
            }
            const showBanner = (window.location.href.indexOf("desenvolvimento") === -1) && (window.location.href.indexOf("revenda") === -1 && window.location.href.indexOf("plataforma") === -1)
            if (showBanner) {
                this.load()
            }
        } else {
            this.state = {
                banner: null
            }
        }
    }

    load = async () => {
        var banners = await getActivesBannersByRevendaId({ revendaId: this.state.revenda?.id })
        if (banners.length > 0) {
            const parameters = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
            const licence = await getLicencaByParkId({ parkId: this.state.estacionamentoId })
            const features = {
                "CLIENTES_PAGANTES": !licence.status || licence.status === "FREE",
                "SEMPARAR_ATIVO": parameters.habilitarSemParar,
                "TAGGY_ATIVO": parameters.habilitarTaggy,
                "VELOE_ATIVO": parameters.habilitarVeloe,
                "CONECTCAR_ATIVO": parameters.habilitarConectCar,
                "COBRANCA_MENSALISTA_ATIVO": parameters.habilitarPagamentoMensalistasOnline,
                "COBRANCA_TICKETS_ATIVO": parameters.habilitarPagamentoTicketsOnline,
            }
            banners = banners.filter(e => e.hidden?.filter(f => features[f.value]).length === 0)
            const index = Math.floor(Math.random() * banners.length);
            const banner = banners[index]
            if (banner) {
                if (isNullOrEmpty(banner.showIn)) {
                    this.setState({ banner: banner })
                } else {
                    const showIn = banner.showIn.filter(e => e === window.location.pathname).length > 0
                    if (showIn) {
                        this.setState({ banner: banner })
                    }
                }
            }
        }
    }

    executeAction = async () => {
        const { banner } = this.state
        const url = banner?.actionValue
        if (url) {
            goToNewWindow(url)
        }
        if (isAdministrador()) {
            return
        }
        const user = getUsuarioCompact()
        const tappers = {
            id: generateUUIDV7(),
            clienteId: this.state.clienteId,
            estacionamentoId: this.state.estacionamentoId,
            estacionamentoNome: this.state.estacionamentoNome,
            createdBy: user,
            createdAt: new Date(),
        }
        banner.taps += 1
        banner.tappers.push(tappers)
        await updateMarketingBannerById({ id: banner.id, data: banner })
        if (banner.notify === "TRUE") {
            await addQueue({
                data: {
                    revendaId: this.state.revenda?.id,
                    clienteId: this.state.clienteId,
                    estacionamentoId: this.state.estacionamentoId,
                    createAt: new Date(),
                    origin: "Marketing",
                    type: "MARKETING_NOTIFY_TAP_IN_BANNER",
                    createdBy: user,
                    body: {
                        clienteId: this.state.clienteId,
                        estacionamentoId: this.state.estacionamentoId,
                        estacionamentoNome: this.state.estacionamentoNome,
                        index: banner.tappers.length - 1,
                        bannerId: banner.id,
                        user: user,
                    },
                }
            })
        }
    }

    render() {
        return (
            <>
                {
                    this.state.banner &&
                    <div className='col-lg-12 mb-3' role='button' onClick={this.executeAction} >
                        <img alt='Cobrança mensalista' style={{ width: "100%", height: "auto", borderRadius: "8px" }} src={this.state.banner.url} />
                    </div>
                }
            </>
        )
    }
}

export { MarketingBanner, MarketingBannerActive }