import React from 'react';
import { getPerfil, isAdministrador } from '../../config/auth'
import { sendScreenView } from '../../shared/analytics'

import { Link } from 'react-router-dom';
import './navbar.css';
import 'firebase/auth';
import 'firebase/storage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StoreIcon from '@mui/icons-material/Store'

export const NavBarMenuPublic = props => {
    const [perfil] = React.useState(getPerfil());
    const [administrativo] = React.useState(isAdministrador());

    const [pagamentosOpen, setPagamentos] = React.useState(false)

    const [acessoPagamentosResumo] = React.useState((perfil?.acessoPagamentosResumo ?? true))
    const [acessoPagamentos] = React.useState(administrativo || acessoPagamentosResumo)

    const togglePagamentos = () => {
        setPagamentos(!pagamentosOpen);
    }

    return (
        <></>
        // isAdministrador() &&
        // <>
        //     {
        //         acessoPagamentos &&
        //         <List>
        //             <ListItem key={'Pagamentos'} button onClick={togglePagamentos}>
        //                 <ListItemIcon> <StoreIcon /></ListItemIcon>
        //                 <ListItemText primary='Pagamentos' />
        //             </ListItem>
        //             {
        //                 pagamentosOpen && <>
        //                     {
        //                         acessoPagamentosResumo &&
        //                         <Link to='/pagamentos/resumo'>
        //                             <ListItem button key='Resumo' onClick={(e) => sendScreenView("Pagamentos", "Resumo")}>
        //                                 <ListItemText primary='Resumo' />
        //                                 <NavigateNextIcon />
        //                             </ListItem>
        //                         </Link>
        //                     }
        //                 </>
        //             }
        //         </List>
        //     }
        // </>
    );
}