import React, { useEffect } from 'react';
import {
  getCliente,
  getEstacionamento,
  clearSession,
  isAdministrador,
  isLogado,
  setEstacionamento,
  getLicencaAtual,
  isPlatformOwner,
  isDeveloper,
  isReseller,
  setLicencaAtual,
  setCliente,
  getUsuario,
  getPerfil,
  getRevenda,
  getUsuarioCompact,
  setUsuario,
  setProfile,
  setUsersSuggestion,
  setRevenda
} from '../../config/auth'
import { sendClickButton, sendScreenView } from '../../shared/analytics'
import { Toaster } from 'react-hot-toast';

import { Link } from 'react-router-dom';
import './navbar.css';
import 'firebase/auth';
import 'firebase/storage';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SearchIcon from '@material-ui/icons/Search';
import AssessmentIcon from '@material-ui/icons/Assessment';
import SettingsIcon from '@material-ui/icons/Settings';
import SchoolIcon from '@material-ui/icons/School';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import WhatsApp from '@material-ui/icons/WhatsApp';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import DescriptionIcon from '@mui/icons-material/Description';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { getAllClientsByRevendaIdAndStatus, getAllClientsByRevendedorId, getClientById } from '../../store/collections/clienteWorker';
import { getAllParksByClientId } from '../../store/collections/parkWorker';
import { fetchFunction } from '../../shared/function-request';
import { LicencaDashboard } from '../../view/licenca/my-licence';
import { goToWindow, queryString, reloadWindow, reloadWithAlert, toastDismissLoading, toastLoading, toastWarning } from '../../shared/utils';
import { Autocomplete, TextField } from '@mui/material';
import DvrIcon from '@mui/icons-material/Dvr';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { OperationRelationshipModal } from '../../view/operation/relationship';
import { OperationSingleNoteModal } from '../../view/operation/singlenote';
import { ParkSettings } from '../common/commons';
import { AlertDanger, AlertInfo, AlertWarning } from '../../shared/alert-manager';
import { NavBarMenuRessales } from './menu.private';
import { NavBarMenuPublic } from './menu.public';
import { getTicketByNumberAndParkId } from '../../store/collections/ticketWorker';
import { TicketRegisterPaymentModal } from '../../view/ticket';
import { OperationUserPermissionModal } from '../../view/operation/permission';
import { getProfileById } from '../../store/collections/profileWorker';
import { getAllUsersActiveByClientId, updateUserById } from '../../store/collections/userWorker';
import { getRevendaById } from '../../store/collections/revendaWorker';
import { getURLFromURI } from '../../store/filesWorker';
import { MarketingBannerActive } from '../../view/marketing/banner';

const drawerWidth = 260;

const getMainColor = () => {
  const client = getCliente()
  if (client?.tema?.personalizarAdministrativo) {
    return client.tema.corPrincipal ?? "#44377b"
  }
  return getRevenda()?.siteCor ?? "#44377b"
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: getMainColor(),
  color: 'white',
  ...(open ? {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  } : {
    backgroundColor: getMainColor(),
    color: 'white',
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const NavBar = props => {
  const [revenda] = React.useState(getRevenda());
  const [perfil] = React.useState(getPerfil());
  const [cliente] = React.useState(getCliente());
  const [usuario] = React.useState(getUsuario());
  const [administrativo] = React.useState(isAdministrador());
  const [cadastroOpen, setCadastroOpen] = React.useState(false);
  const [operarOpen, setOperarOpen] = React.useState(false);
  const [buscaOpen, setBuscaOpen] = React.useState(false);
  const [relatoriosOpen, setRelatoriosOpen] = React.useState(false);
  const [notasOpen, setNotasOpen] = React.useState(false);
  const [revendaOpen, setRevendaOpen] = React.useState(false);
  const [alterarClienteOpen, setAlterarClienteOpen] = React.useState(false);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [financeiroOpen, setFinanceiro] = React.useState(false);
  const [analyticsOpen, setAnalytics] = React.useState(false);
  const [desenvolvimentoOpen, setDesenvolvimento] = React.useState(false);
  const [gateOpen, setGate] = React.useState(false);
  const [crmOpen, setCRM] = React.useState(false);
  const [revendasAssociadas, setRevendasAssociadas] = React.useState([]);
  const [clientes, setClientes] = React.useState([]);
  const [revendaId, setRevendaId] = React.useState(null);
  const [estacionamentos, setEstacionamentos] = React.useState([]);
  const [clienteSelecionado, setClienteSelecionado] = React.useState(null);
  const [estacionamentoSelecionadoId, setEstacionamentoSelecionadoId] = React.useState('');
  const [ticketFoundByBarCode, setTicketFoundByBarCode] = React.useState(null);
  const [cancelaHabilitada, setCancelaHabilitada] = React.useState(false);

  const [acessoRevendedorDashboard] = React.useState(usuario?.acessoRevendedorDashboard ?? true)
  const [acessoRevendedorCliente] = React.useState(usuario?.acessoRevendedorCliente ?? true)
  const [acessoRevendedorRevendedores] = React.useState(usuario?.acessoRevendedorRevendedores ?? true)
  const [acessoRevendedorConfiguracao] = React.useState(usuario?.acessoRevendedorConfiguracao ?? true)
  const [acessoRevendedorChamados] = React.useState(usuario?.acessoRevendedorChamados ?? true)
  const [acessoRevendedorContratos] = React.useState(usuario?.acessoRevendedorContratos ?? true)
  const [acessoRevendedorRevenda] = React.useState(acessoRevendedorDashboard || acessoRevendedorCliente || acessoRevendedorRevendedores || acessoRevendedorConfiguracao || acessoRevendedorContratos)

  const [acessoDesenvolvedor] = React.useState(isDeveloper() ?? false)
  const [acessoRevendedorVersaoMinima] = React.useState(usuario?.acessoRevendedorVersaoMinima ?? false)
  const [acessoRevendedorMarcasEModelos] = React.useState(usuario?.acessoRevendedorMarcasEModelos ?? false)
  const [acessoRevendedorDesenvolvedor] = React.useState(usuario?.developer || acessoRevendedorMarcasEModelos || acessoRevendedorVersaoMinima)

  const [acessoRevendedorPlanos] = React.useState(usuario?.acessoRevendedorPlanos ?? true)
  const [acessoRevendedorAssinaturas] = React.useState(usuario?.acessoRevendedorAssinaturas ?? true)
  const [acessoRevendedorNotasFiscais] = React.useState(usuario?.acessoRevendedorNotasFiscais ?? true)
  const [acessoRevendedorFinanceiro] = React.useState(acessoRevendedorPlanos || acessoRevendedorAssinaturas || acessoRevendedorNotasFiscais)

  const [acessoHabilitarBuscaTickets] = React.useState(administrativo || (perfil?.acessoHabilitarBuscaTickets ?? true))
  const [acessoHabilitarBuscaCaixas] = React.useState(administrativo || (perfil?.acessoHabilitarBuscaCaixas ?? true))
  const [acessoHabilitarBuscaMensalista] = React.useState(administrativo || (perfil?.acessoHabilitarBuscaMensalistas ?? true))
  const [acessoHabilitarBusca] = React.useState(acessoHabilitarBuscaMensalista || acessoHabilitarBuscaTickets || acessoHabilitarBuscaCaixas)

  const [acessoHabilitarRelatorioVendas] = React.useState(administrativo || (perfil?.acessoHabilitarRelatorioVendas ?? true))
  const [acessoHabilitarRelatorioCaixas] = React.useState(administrativo || (perfil?.acessoHabilitarRelatorioCaixas ?? true))
  const [acessoHabilitarRelatorioMensalistas] = React.useState(administrativo || (perfil?.acessoHabilitarRelatorioMensalistas ?? true))
  const [acessoHabilitarRelatorioAcessos] = React.useState(administrativo || (perfil?.acessoHabilitarRelatorioAcessos ?? true))
  const [acessoHabilitarRelatorio] = React.useState(acessoHabilitarRelatorioVendas || acessoHabilitarRelatorioCaixas || acessoHabilitarRelatorioMensalistas || acessoHabilitarRelatorioAcessos)

  const [acessoHabilitarConfiguracoesParametro] = React.useState(administrativo || (perfil?.acessoHabilitarConfiguracoesParametros ?? true))
  const [acessoHabilitarConfiguracoesDadosCadastrais] = React.useState(administrativo || (perfil?.acessoHabilitarConfiguracoesDadosCadastrais ?? true))
  const [acessoHabilitarConfiguracoesCobrancas] = React.useState(administrativo || (perfil?.acessoHabilitarConfiguracoesCobrancas ?? true))
  const [acessoHabilitarConfiguracoesLicenca] = React.useState(administrativo || (perfil?.acessoHabilitarConfiguracoesLicenca ?? true))
  const [acessoHabilitarConfiguracoes] = React.useState(acessoHabilitarConfiguracoesParametro || acessoHabilitarConfiguracoesDadosCadastrais || acessoHabilitarConfiguracoesCobrancas)

  const [createClient] = React.useState(administrativo || (perfil?.createClient ?? true))
  const [createUser] = React.useState(administrativo || (perfil?.createUser ?? true))
  const [createProfile] = React.useState(administrativo || (perfil?.createProfile ?? true))
  const [createPlan] = React.useState(administrativo || (perfil?.createPlan ?? true))
  const [createProduct] = React.useState(administrativo || (perfil?.createProduct ?? true))
  const [createPartnership] = React.useState(administrativo || (perfil?.createPartnership ?? true))
  const [createPaymentTable] = React.useState(administrativo || (perfil?.createPaymentTable ?? true))
  const [createWayPayment] = React.useState(administrativo || (perfil?.createWayPayment ?? true))
  const [createSectors] = React.useState(administrativo || (perfil?.createSectors ?? true))
  const [createAccredited] = React.useState(administrativo || (perfil?.createAccredited ?? true))
  const [acessoHabilitarCadastros] = React.useState(createClient || createUser || createProfile || createPlan || createProduct || createPartnership || createPaymentTable || createWayPayment || createSectors || createAccredited)

  const [acessoHabilitarOperarAplicarConvenio] = React.useState(administrativo || (perfil?.acessoHabilitarOperarAplicarConvenio ?? true))
  const [acessoHabilitarOperarNotaAvulsa] = React.useState(administrativo || (perfil?.acessoHabilitarOperarNotaAvulsa ?? true))
  const [acessoHabilitarOperarPatioAoVivo] = React.useState(administrativo || (perfil?.acessoHabilitarOperarPatioAoVivo ?? true))
  const [acessoHabilitarOperar] = React.useState(acessoHabilitarOperarAplicarConvenio || acessoHabilitarOperarNotaAvulsa || acessoHabilitarOperarPatioAoVivo)
  const [acessoHabilitarOperarRelationshipModalOpen, setAcessoHabilitarOperarRelationshipModalOpen] = React.useState(false);
  const [acessoHabilitarOperarSingleNoteModalOpen, setAcessoHabilitarOperarSingleNoteModalOpen] = React.useState(false);
  const [acessoHabilitarOperarAlterarUsuarioModalOpen, setAcessoHabilitarOperarAlterarUsuarioModalOpen] = React.useState(false);
  const [habilitarEmissaoNotaV2, setHabilitarEmissaoNotaV2] = React.useState(true)
  const [acessoHabilitarNotaFiscalVerTodas] = React.useState(administrativo || (perfil?.acessoHabilitarNotaFiscalVerTodas ?? true))
  const [acessoHabilitarNotaFiscalSeries] = React.useState(administrativo || (perfil?.acessoHabilitarNotaFiscalSeries ?? true))
  const [acessoHabilitarNotaFiscalConfigurar] = React.useState(administrativo || (perfil?.acessoHabilitarNotaFiscalConfigurar ?? true))
  const [acessoHabilitarNotaFiscal] = React.useState(acessoHabilitarNotaFiscalVerTodas || acessoHabilitarNotaFiscalSeries || acessoHabilitarNotaFiscalConfigurar)

  const [acessoCancelaTotem] = React.useState(perfil?.acessoCancelaTotem ?? true)
  const [acessoCancelaLPR] = React.useState(perfil?.acessoCancelaLPR ?? true)
  const [acessoAparelhos] = React.useState(perfil?.acessoAparelhos ?? true)
  const [acessoCancela] = React.useState(administrativo || acessoCancelaTotem || acessoCancelaLPR || acessoAparelhos)

  const [acesssCRMContact] = React.useState(perfil?.acesssCRMContact ?? true)
  const [accessCRM] = React.useState(acesssCRMContact)

  const [mostrarLinksDeAjuda] = React.useState(revenda?.mostrarLinksDeAjuda === "true")
  const [linkAjuda] = React.useState(revenda?.youtube ?? "")

  useEffect(() => {
    if (isReseller() && isLogado() && getCliente().id === "") {
      toggleAlterarCliente()
    }
    document.title = revenda?.nome ?? ""
    loadCurrentSubscription()
    loadOpenModal()
    loadLogo()
    createNumberKeyPressListener()
    const user = getUsuario()
    setRevendasAssociadas(user.revendasAssociadas ?? [])
    setRevendaId(user.revendaId)
  }, []);

  const loadLogo = async () => {
    if (cliente?.tema?.personalizarAdministrativo && !cliente?.tema?.logoAdministrativoUrl) {
      const url = await getURLFromURI(cliente.tema.logo)
      cliente.tema.logoAdministrativoUrl = url
      setCliente(cliente)
    }
  }

  const carregarClientes = async (status, revendaId) => {
    if (isLogado()) {
      if (isReseller()) {
        var clientes = []
        const user = getUsuario()
        setRevendasAssociadas(user.revendasAssociadas ?? [])
        if (user.acessoRevendedorPermissao === "ASSOCIADOS") {
          clientes = await getAllClientsByRevendedorId({ revendedorId: user.id })
        } else {
          clientes = await getAllClientsByRevendaIdAndStatus({ status: status, revendaId: revendaId })
        }
        const items = clientes.map(e => {
          const name = e.nome
          return {
            firstLetter: name[0].toUpperCase(),
            id: e.id,
            nome: name
          }
        })
        setClientes(items)
      } else {
        setClienteSelecionado(getCliente())
      }
    }
  }

  const confirmAlterarCliente = async (goDetail) => {
    // const changes = await getAllChagesByClientId({ id: "dApDD0s48iT21DBbruT1" })
    // // .where("clienteId", '==', id)
    // console.log(changes.length);
    // for (let [index, change] of changes.entries()) {
    //   console.log((index + 1), change.id);
    //   await updateChage({ id: change.id, data: change })
    // }

    // for (const change of changes) {
    //   if (!(change.isDeleted === true)) {
    //     change.isDeleted = false
    //     console.log(change.id);
    //     await updateChage({ id: change.id, data: change })
    //   }
    // }
    // const charges = (await getAllChagesByClientId({ id: "N9xwt7x7SbkkFnajBc4M" })).filter(e => e.plan.dataFatura == undefined || e.plan.dataFatura === "04/10/2024")
    // console.log(charges.length);
    // for (const charge of charges) {
    //   console.log(charge.id);
    //   const date = charge.plan.dataFatura ? moment(charge.plan.dataFatura, 'DD/MM/YYYY').toDate() : moment().toDate()
    //   charge.plan.dataFatura = date
    //   await updateChage({ id: charge.id, data: charge })
    // }
    // const clients = await getAllMonthlyByClientId({clienteId: "N9xwt7x7SbkkFnajBc4M"}) 
    // console.log(clients.length);
    // for (var client of clients) {
    //   client.plano.dataFatura = new Date()
    //   await updateMonthly({ id: client.id, data: client })
    //   console.log(client.id)
    // }
    // const estacionamentoId = "DiBgZkFIZr9qomIzGvtM"
    // const vehicles = await getAllVeiculosByClientId({ clientId: "6WQZfFKmY8e6cbOTdQeq" })
    // for (const vehicle of vehicles.filter(e => e.mensalistaId)) {
    //   vehicle.estacionamentoId = estacionamentoId
    //   console.log(vehicle);
    //   await updateVehicleById({ id: vehicle.id, data: vehicle })
    // }

    // await definirIdEntidadesClientesMigration()
    const client = await getClientById({ id: clienteSelecionado.id })
    if (clienteSelecionado && estacionamentoSelecionadoId) {
      toastLoading("Alterando estabelecimento...")
      const user = getUsuario()
      if (user.revendaId !== revendaId) {
        user.revendaId = revendaId
        await updateUserById({ id: user.id, data: user })
        setUsuario(user)
        const revenda = await getRevendaById({ id: user.revendaId })
        if (!client?.tema?.personalizarAdministrativo) {
          const url = await getURLFromURI(revenda.logoAdministrativo)
          revenda.logoAdministrativoUrl = url
        }
        setRevenda(revenda)
      }
      if (client?.tema?.personalizarAdministrativo) {
        if (client.tema.logo) {
          const url = await getURLFromURI(client.tema.logo)
          client.tema.logoAdministrativoUrl = url
        }
      }
      setCliente(client)
      const result = await getAllParksByClientId({ clientId: clienteSelecionado.id })
      let estacionamento = result.filter(item => item.id === estacionamentoSelecionadoId)[0]
      setEstacionamento(estacionamento)
      localStorage.setItem('parksSelecteds', [])
      toastDismissLoading()
      if (goDetail) {
        window.location.href = `/revenda/cliente/${clienteSelecionado.id}`
      } else {
        reloadWindow()
      }
    } else {
      toastWarning("Selecione um estabelecimento")
    }
  }

  const closeAll = () => {
    setOpen(true);
    setCadastroOpen(false)
    setOperarOpen(false)
    setBuscaOpen(false)
    setRelatoriosOpen(false)
    setRevendaOpen(false)
    setNotasOpen(false)
    setSettingsOpen(false)
    setFinanceiro(false)
    setAnalytics(false)
    setDesenvolvimento(false)
    setGate(false)
    setCRM(false)
  }

  const toggleCadastro = () => {
    closeAll();
    setCadastroOpen(!cadastroOpen);
  };

  const toggleBusca = () => {
    closeAll();
    setBuscaOpen(!buscaOpen);
  };

  const toggleOperar = () => {
    closeAll();
    setOperarOpen(!operarOpen);
  };

  const toggleRelatorios = () => {
    closeAll();
    setRelatoriosOpen(!relatoriosOpen);
  };

  const toggleRevenda = () => {
    closeAll();
    setRevendaOpen(!revendaOpen);
  };

  const toggleFinanceiro = () => {
    closeAll();
    setFinanceiro(!financeiroOpen);
  };

  const toggleAnalytics = () => {
    closeAll();
    setAnalytics(!analyticsOpen);
  };

  const toggleDesenvolvimento = () => {
    closeAll();
    setDesenvolvimento(!desenvolvimentoOpen);
  };

  const toggleHabilitarOperarRelationshipModal = () => {
    setAcessoHabilitarOperarRelationshipModalOpen(!acessoHabilitarOperarRelationshipModalOpen);
  };

  const toggleHabilitarOperarSingleNoteModal = () => {
    setAcessoHabilitarOperarSingleNoteModalOpen(!acessoHabilitarOperarSingleNoteModalOpen);
  };

  const toggleHabilitarAlterarUsuarioModal = () => {
    setAcessoHabilitarOperarAlterarUsuarioModalOpen(!acessoHabilitarOperarAlterarUsuarioModalOpen);
  };

  const confirmChangeUser = async (user) => {
    setUsuario(user)
    const profile = await getProfileById({ id: user.perfilId })
    setProfile(profile)
    reloadWithAlert("Operador alterado com sucesso.")
  }

  const toggleAlterarCliente = () => {
    setAlterarClienteOpen(!alterarClienteOpen);
    carregarClientes('Ativo', getRevenda().id)
    const client = getCliente()
    if (client !== undefined) {
      carregarEstacionamentos(client.id)
      setClienteSelecionado(client)
    }
  };

  const loadCurrentSubscription = async () => {
    const estacionamento = getEstacionamento()
    if (estacionamento) {
      const option = {
        method: 'GET'
      }
      const result = await fetchFunction(`/newSubscription/current-licence`, option)
      if (result.data) {
        const licence = result.data
        setLicencaAtual(licence)
        parkLicenceFlag()
      }
    }
  }

  const loadOpenModal = async () => {
    const modal = queryString("show-modal")
    if (modal) {
      if (modal === "single-note") {
        toggleHabilitarOperarSingleNoteModal()
      } else if (modal === "relationship-apply") {
        toggleHabilitarOperarRelationshipModal()
      }
    }
  }

  const createNumberKeyPressListener = () => {
    var keys = []
    const keyDownCallback = function (e) {
      if ((e.key === "Enter" || e.key === "ArrowDown")) {
        var value = keys.join("")?.replaceAll("Ç", "//").replaceAll(";", "")
        if (value.indexOf("http") !== -1) {
          value = ("http" + (value.split("http")[1]))
          let params = new URLSearchParams(value)
          const ticket = params.get("t")
          if (ticket) {
            value = ticket
          }
        }
        if (value.startsWith("00")) {
          value = value.substring(2)
        }
        if (value.length >= 6) {
          loadTicket(value)
        }
        keys = []
      } else {
        const value = String(e.key)
        if (value !== "Shift") {
          keys.push(value)
        }
      }
    };
    document.addEventListener("keydown", keyDownCallback);
  }

  const loadTicket = async (value) => {
    const estacionamentoId = getEstacionamento()?.id
    const ticket = await getTicketByNumberAndParkId({ ticket: value, parkId: estacionamentoId })
    if (ticket) {
      setTicketFoundByBarCode(ticket)
    }
  }

  const onClosePaymentModal = () => {
    setTicketFoundByBarCode(null)
  }

  const loadSupport = () => {
    const client = getCliente()
    if (revenda?.tipoSuporte === "CONCESSIONARIO" && client.support) {
      const number = client.support.cellphone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "")
      return `https://api.whatsapp.com/send?phone=55${number}`
    } else if (revenda?.tipoSuporte !== "SELFPARKING" && revenda?.whatsAppSuporte) {
      const number = revenda.whatsAppSuporte.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "")
      return `https://api.whatsapp.com/send?phone=55${number}`
    } else {
      return "https://api.whatsapp.com/send?phone=5511984180090"
    }
  }

  const loadTermos = () => {
    return revenda.sitePrivacidade
  }

  const goCliente = () => {
    window.location.href = `/revenda/cliente/${getEstacionamento()?.clienteId}`
  }

  const alterarRevenda = (e, item) => {
    if (item) {
      const value = item.id
      if (value !== "") {
        setRevendaId(item.value)
        setClientes([])
        carregarClientes('Ativo', item.value)
      }
    } else {
      setRevendaId(null)
    }
  }

  const alterarCliente = (e, item) => {
    if (item) {
      const value = item.id
      if (value !== "") {
        let cliente = clientes.filter(item => item.id === value)[0]
        setEstacionamentoSelecionadoId(null)
        setClienteSelecionado(cliente)
        carregarEstacionamentos(cliente.id)
      }
    } else {
      setEstacionamentos([])
    }
  }

  const alterarEstabelecimento = (e, item) => {
    if (item) {
      const value = item.id
      if (value !== "") {
        setEstacionamentoSelecionadoId(value)
      }
    } else {
      setEstacionamentoSelecionadoId(null)
    }
  }

  const carregarEstacionamentos = async (clienteId) => {
    const user = getUsuario()
    var parks = await getAllParksByClientId({ clientId: clienteId })
    if (!user.revendedor) {
      parks = parks.filter(e => user.estacionamentos.map(i => i.id).indexOf(e.id) !== -1)
    }
    parks = parks.map(e => {
      return {
        id: e.id,
        nome: e.nomeInterno ?? e.nome
      }
    })
    setEstacionamentos(parks)
    if (parks.length >= 1) {
      const estacionamento = getEstacionamento()
      if (estacionamento?.clienteId === clienteId) {
        setEstacionamentoSelecionadoId(estacionamento.id)
      } else {
        setEstacionamentoSelecionadoId(parks[0].id)
        setEstacionamento(parks[0])
      }
    }
  }

  const toggleNotas = () => {
    closeAll();
    setNotasOpen(!notasOpen);
  }

  const toggleSettings = () => {
    closeAll();
    setSettingsOpen(!settingsOpen);
  }

  const toggleGate = () => {
    closeAll();
    setGate(!gateOpen);
  }

  const toggleCRM = () => {
    closeAll();
    setCRM(!crmOpen);
  }

  const logout = async () => {
    sendScreenView("Outros", "Sair")
    if ((getUsuario().revendasAssociadas ?? []).length === 0) {
      const users = await getAllUsersActiveByClientId({ clienteId: getCliente().id })
      setUsersSuggestion(users.map(e => {
        return {
          id: e.id,
          nome: e.nome,
          email: e.email,
        }
      }))
    } else {
      setUsersSuggestion([])
    }
    goToWindow("/")
    clearSession()
  }

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  }

  const handleDrawerClose = () => {
    setOpen(false);
  }

  const parkName = () => {
    const estacionamento = getEstacionamento()
    const isAdmin = isReseller()
    var name = "Selecionar"
    if (estacionamento?.nomeInterno) {
      name = estacionamento?.nomeInterno
    } else if (estacionamento !== undefined) {
      name = estacionamento?.nome
    }
    return <span>
      <Tooltip role="button" title="Alterar estabelecimento" placement="top">
        <a className='text-white' onClick={toggleAlterarCliente}>
          {name}
          <i className="fas fa-exchange mx-2" />
        </a>
      </Tooltip>
      {
        isAdmin &&
        <Tooltip role="button" title="Visualizar cliente" placement="top">
          <a className='text-white' onClick={goCliente}>
            <i className="fas fa-eye mx-2" />
          </a>
        </Tooltip>
      }
      <Tooltip role="button" title="Visualizar dashboard" placement="top">
        <a className='text-white' href='/home' >
          <i className="fas fa-house mx-2" />
        </a>
      </Tooltip>
      {
        isAdmin &&
        <Tooltip role="button" title="Visualizar parametros" placement="top">
          <a className='text-white' href='/configuracao/parametros' >
            <i className="fas fa-gear mx-2" />
          </a>
        </Tooltip>
      }
      {
        acessoCancelaTotem &&
        <Tooltip role="button" title="Visualizar totens" placement="top">
          <a className='text-white' href='/equipamentos/totens' >
            <i className="fas fa-parking mx-2" />
          </a>
        </Tooltip>
      }
      <ParkSettings />
    </span >
  };

  const parkChangeButton = () => {
    const name = parkName()
    var type = ""
    if (isPlatformOwner()) {
      type = "Diretoria"
    } else if (isReseller()) {
      type = "Revendedor"
    } else if (isDeveloper()) {
      type = "Desenvolvedor"
    }
    var userName = getUsuarioCompact()?.nome.split(" ")[0] ?? ""
    if (userName !== "") {
      userName = `${userName} | `
    }
    var logoAdministrativoUrl = ""
    if (cliente?.tema?.personalizarAdministrativo) {
      logoAdministrativoUrl = cliente.tema.logoAdministrativoUrl
    } else {
      logoAdministrativoUrl = revenda.logoAdministrativoUrl
    }
    if (type === "") {
      return <>
        <img alt={""} height='40' style={{ borderRadius: "5px" }} src={logoAdministrativoUrl} />
        <span className="text-white font-weight-bold mx-2"><strong>{userName}</strong> {name}</span>
      </>
    } else if (revenda) {
      return <>
        <img alt={""} height='40' style={{ borderRadius: "5px" }} src={logoAdministrativoUrl} />
        <span className="text-white font-weight-bold mx-2"><strong>{userName}</strong> | {name}</span>
      </>
    } else {
      return <>
        <img alt="" height='40' style={{ borderRadius: "5px" }} src={require('../../files/icon_app_white.png')} />
        <span className="text-white font-weight-bold mx-2">Self<strong>Parking</strong><strong>{userName}</strong> | {type} | {name}</span>
      </>
    }
  };

  const parkLicenceFlag = () => {
    const currentLicence = getLicencaAtual()
    if (currentLicence) {
      const status = currentLicence?.status?.toUpperCase()
      switch (status) {
        case "PAYED": return <span className="badge text-bg-success" role="img" aria-label='Ativa'>Ativa 🥳</span>
        case "PENDING": return <span className="badge text-bg-danger" role="img" aria-label='Pendente'>Pendente 🧐</span>
        case "GENERATED": return <span className="badge text-bg-warning text-white" role="img" aria-label='Pendente'>Aguardando ⌛</span>
      }
    }
    return <span className="badge text-bg-success">Assine 🤝</span>
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Toaster />
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 1, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          {parkChangeButton()}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <NavBarMenuRessales />
        <Divider />
        {
          acessoHabilitarConfiguracoesLicenca &&
          <List>
            <a href='/licenca/minha-licenca' >
              <ListItem button key={'Licença'}>
                <ListItemIcon> <AutoGraphIcon /></ListItemIcon>
                <ListItemText primary='Licença' />
                <ListItemIcon>
                  {parkLicenceFlag()}
                </ListItemIcon>
              </ListItem>
            </a>
          </List>
        }
        <NavBarMenuPublic />
        <List>
          <Link to='/home' onClick={(e) => sendScreenView("Menu", "Home")}>
            <ListItem button key={'Início'}>
              <ListItemIcon> <HomeIcon /></ListItemIcon>
              <ListItemText primary='Início' />
            </ListItem>
          </Link>
        </List>
        {
          acessoHabilitarCadastros &&
          <List>
            <ListItem key={'Cadastrar'} button onClick={toggleCadastro}>
              <ListItemIcon> <CreateNewFolderIcon /></ListItemIcon>
              <ListItemText primary='Cadastrar' />
            </ListItem>
            {
              cadastroOpen && open && <>
                {
                  createClient &&
                  <Link to='/cadastrar/mensalista' onClick={(e) => sendScreenView("Cadastrar", "Mensalistas")}>
                    <ListItem button key='Mensalistas'>
                      <ListItemText primary='Mensalistas' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  createPlan &&
                  <Link to='/cadastrar/plano' onClick={(e) => sendScreenView("Cadastrar", "Planos")}>
                    <ListItem button key='Planos'>
                      <ListItemText primary='Planos' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  createProduct &&
                  <Link to='/cadastrar/produto' onClick={(e) => sendScreenView("Cadastrar", "Produtos e serviços")}>
                    <ListItem button key='Produtos e serviços'>
                      <ListItemText primary='Produtos e serviços' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  createPartnership &&
                  <Link to='/cadastrar/convenio' onClick={(e) => sendScreenView("Cadastrar", "Convênio")}>
                    <ListItem button key='Convênios'>
                      <ListItemText primary='Convênios' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  createProfile &&
                  <Link to='/cadastrar/perfil' onClick={(e) => sendScreenView("Cadastrar", "Perfis de acesso")}>
                    <ListItem button key='Perfis de acesso'>
                      <ListItemText primary='Perfis de acesso' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  createUser &&
                  <Link to='/cadastrar/usuario' onClick={(e) => sendScreenView("Cadastrar", "Usuários")}>
                    <ListItem button key='Usuários'>
                      <ListItemText primary='Usuários' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  createWayPayment &&
                  <Link to='/cadastrar/forma-pagamento' onClick={(e) => sendScreenView("Cadastrar", "Formas de pagamento")}>
                    <ListItem button key='Formas de pagamento'>
                      <ListItemText primary='Formas de pagamento' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  createPaymentTable &&
                  <Link to='/cadastrar/tabela-preco' onClick={(e) => sendScreenView("Cadastrar", "Tabelas de preço")}>
                    <ListItem button key='Tabelas de preço'>
                      <ListItemText primary='Tabelas de preço' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  createSectors &&
                  <Link to='/cadastrar/setor' onClick={(e) => sendScreenView("Cadastrar", "Setores")}>
                    <ListItem button key='Setores'>
                      <ListItemText primary='Setores' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  createAccredited &&
                  <Link to='/cadastrar/credenciado' onClick={(e) => sendScreenView("Cadastrar", "Credenciados")}>
                    <ListItem button key='Credenciados'>
                      <ListItemText primary='Credenciados' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
              </>
            }
          </List>
        }
        {
          acessoHabilitarOperar &&
          <List>
            <ListItem key={'Buscar'} button onClick={toggleOperar}>
              <ListItemIcon> <DvrIcon /></ListItemIcon>
              <ListItemText primary='Operação' />
              <span className="badge text-bg-success">Novidade</span>
            </ListItem>
            {
              operarOpen && open && <>
                {acessoHabilitarOperarAplicarConvenio &&
                  <Link onClick={toggleHabilitarOperarRelationshipModal}>
                    <ListItem button key='AplicarConvenio' onClick={(e) => sendScreenView("Operar", "Aplicar convênio")}>
                      <ListItemText primary='Aplicar convênios' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {acessoHabilitarOperarNotaAvulsa &&
                  <Link onClick={toggleHabilitarOperarSingleNoteModal}>
                    <ListItem button key='NotaAvulsa' onClick={(e) => sendScreenView("Operar", "Emitir nota avulsa")}>
                      <ListItemText primary='Emitir nota avulsa' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {acessoHabilitarOperarPatioAoVivo &&
                  <Link to='/operacao/patio-ao-vivo'>
                    <ListItem button key='PatioAoVivo' onClick={(e) => sendScreenView("Operar", "Pátio ao vivo")}>
                      <ListItemText primary='Pátio ao vivo' />
                      <span className="badge text-bg-success">Novidade</span>
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                <Link onClick={toggleHabilitarAlterarUsuarioModal}>
                  <ListItem button key='AlterarUsuário' onClick={(e) => sendScreenView("Operar", "Alterar usuário")}>
                    <ListItemText primary='Alterar usuário' />
                    <NavigateNextIcon />
                  </ListItem>
                </Link>
              </>
            }
          </List>
        }
        {
          acessoHabilitarBusca &&
          <List>
            <ListItem key={'Buscar'} button onClick={toggleBusca}>
              <ListItemIcon> <SearchIcon /></ListItemIcon>
              <ListItemText primary='Buscar' />
            </ListItem>
            {
              buscaOpen && open && <>
                {acessoHabilitarBuscaTickets &&
                  <Link to='/buscar/ticket'>
                    <ListItem button key='Ticket' onClick={(e) => sendScreenView("Buscar", "Tickets")}>
                      <ListItemText primary='Ticket' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {acessoHabilitarBuscaCaixas &&
                  <Link to='/buscar/caixas'>
                    <ListItem button key='Caixas' onClick={(e) => sendScreenView("Buscar", "Caixas")}>
                      <ListItemText primary='Caixas' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  acessoHabilitarBuscaMensalista &&
                  <Link to='/buscar/mensalistas' onClick={(e) => sendScreenView("Buscar", "Mensalistas")}>
                    <ListItem button key='Mensalistas'>
                      <ListItemText primary='Mensalistas' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
              </>
            }
          </List>
        }
        {
          acessoHabilitarRelatorio &&
          <List>
            <ListItem key={'Relatorios'} button onClick={toggleRelatorios}>
              <ListItemIcon> <AssessmentIcon /></ListItemIcon>
              <ListItemText primary='Relatórios' />
            </ListItem>
            {
              relatoriosOpen && open && <>
                {
                  acessoHabilitarRelatorioVendas &&
                  <Link to='/relatorios/vendas' onClick={(e) => sendScreenView("Relatórios", "Vendas")}>
                    <ListItem button key='Vendas'>
                      <ListItemText primary='Vendas' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  acessoHabilitarRelatorioCaixas &&
                  <Link to='/relatorios/caixas' onClick={(e) => sendScreenView("Relatórios", "Caixas")}>
                    <ListItem button key='Caixas'>
                      <ListItemText primary='Caixas' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  acessoHabilitarRelatorioMensalistas &&
                  <Link to='/relatorios/mensalistas' onClick={(e) => sendScreenView("Relatórios", "Mensalistas")}>
                    <ListItem button key='Mensalistas'>
                      <ListItemText primary='Mensalistas' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  acessoHabilitarRelatorioAcessos &&
                  <Link to='/relatorios/acessos' onClick={(e) => sendScreenView("Relatórios", "Acessos")}>
                    <ListItem button key='Acessos'>
                      <ListItemText primary='Acessos' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
              </>
            }
          </List>
        }
        {acessoHabilitarNotaFiscal &&
          <List>
            <ListItem key={'Notas'} button onClick={toggleNotas}>
              <ListItemIcon> <AllInboxIcon /></ListItemIcon>
              <ListItemText primary='Nota fiscal' />
            </ListItem>
            {
              notasOpen && open && <>
                {
                  habilitarEmissaoNotaV2 && <>
                    {acessoHabilitarNotaFiscalVerTodas &&
                      <Link to='/nota-fiscal/listar' onClick={(e) => sendScreenView("Nota fiscal", "Listar")}>
                        <ListItem button key='Ver todas'>
                          <ListItemText primary='Ver todas' />
                          <NavigateNextIcon />
                        </ListItem>
                      </Link>
                    }
                    {acessoHabilitarNotaFiscalConfigurar &&
                      <Link to='/nota-fiscal/configurar' onClick={(e) => sendScreenView("Nota fiscal", "Configurar")}>
                        <ListItem button key='Configurar'>
                          <ListItemText primary='Configurar' />
                          <NavigateNextIcon />
                        </ListItem>
                      </Link>
                    }
                  </>
                }
                {
                  !habilitarEmissaoNotaV2 && <>
                    {acessoHabilitarNotaFiscalVerTodas &&
                      <Link to='/v1/nota-fiscal/listar' onClick={(e) => sendScreenView("Nota fiscal", "v1 - Listar")}>
                        <ListItem button key='Ver todas'>
                          <ListItemText primary='Ver todas' />
                          <NavigateNextIcon />
                        </ListItem>
                      </Link>
                    }
                    {acessoHabilitarNotaFiscalSeries &&
                      <Link to='/v1/nota-fiscal/visualizar-rps' onClick={(e) => sendScreenView("Nota fiscal", "v1 - Visualizar Series")}>
                        <ListItem button key='Visualizar Series'>
                          <ListItemText primary='Series' />
                          <NavigateNextIcon />
                        </ListItem>
                      </Link>
                    }
                    {acessoHabilitarNotaFiscalConfigurar &&
                      <Link to='/v1/nota-fiscal/configurar' onClick={(e) => sendScreenView("Nota fiscal", "v1 - Configurar")}>
                        <ListItem button key='Configurar'>
                          <ListItemText primary='Configurar' />
                          <NavigateNextIcon />
                        </ListItem>
                      </Link>
                    }
                  </>
                }
              </>
            }
          </List>
        }
        {
          acessoCancela &&
          <List>
            <ListItem key={'Equipamentos'} button onClick={toggleGate}>
              <ListItemIcon> <LocalParkingIcon /> </ListItemIcon>
              <ListItemText primary='Equipamentos' />
            </ListItem>
            {
              gateOpen && open && <>
                {
                  acessoCancelaTotem &&
                  <Link to='/equipamentos/totens' onClick={(e) => sendScreenView("Equipamentos", "Totens")}>
                    <ListItem button key='Totens'>
                      <ListItemText primary='Totens' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  acessoCancelaLPR &&
                  <Link to='/equipamentos/cameras-lpr' onClick={(e) => sendScreenView("Equipamentos", "Câmeras LPR")}>
                    <ListItem button key='Câmeras LPR'>
                      <ListItemText primary='Câmeras LPR' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  acessoAparelhos &&
                  <Link to='/equipamentos/aparelhos' onClick={(e) => sendScreenView("Equipamentos", "Aparelhos")}>
                    <ListItem button key='Aparelhos'>
                      <ListItemText primary='Aparelhos' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                <Link to='/equipamentos/tutoriais' target="_blank" onClick={(e) => sendScreenView("Equipamentos", "Tutoriais")}>
                  <ListItem button key='Tutoriais'>
                    <ListItemText primary='Tutoriais' />
                    <NavigateNextIcon />
                  </ListItem>
                </Link>
                <Link to='/comercial/funcionalidades' target="_blank" onClick={(e) => sendScreenView("Funcionalidades", "Funcionalidades")}>
                  <ListItem button key='Funcionalidades'>
                    <ListItemText primary='Funcionalidades' />
                    <span className="badge text-bg-success">Novidade</span>
                    <NavigateNextIcon />
                  </ListItem>
                </Link>
              </>
            }
          </List>
        }
        {
          acessoHabilitarConfiguracoes &&
          <List>
            <ListItem key={'Configuracoes'} button onClick={toggleSettings}>
              <ListItemIcon> <SettingsIcon /></ListItemIcon>
              <ListItemText primary='Configurações' />
            </ListItem>
            {
              settingsOpen && open && <>
                {
                  acessoHabilitarConfiguracoesParametro &&
                  <Link to='/configuracao/parametros' onClick={(e) => sendScreenView("Parametros", "Parâmetros")}>
                    <ListItem button key='Parametros'>
                      <ListItemText primary='Parâmetros' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  acessoHabilitarConfiguracoesCobrancas &&
                  <Link to='/configuracao/cobrancas' onClick={(e) => sendScreenView("Parametros", "Cobranças")}>
                    <ListItem button key='Cobrancas'>
                      <ListItemText primary='Cobranças' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
                {
                  acessoHabilitarConfiguracoesDadosCadastrais &&
                  <Link to='/configuracao/dados-cadastrais' onClick={(e) => sendScreenView("Parametros", "Dados Cadastrais")}>
                    <ListItem button key='Dados Cadastrais'>
                      <ListItemText primary='Dados cadastrais' />
                      <NavigateNextIcon />
                    </ListItem>
                  </Link>
                }
              </>
            }
          </List>
        }
        {
          mostrarLinksDeAjuda &&
          <List>
            <a href={linkAjuda} target='_blank' onClick={(e) => sendClickButton("Outros", "Treinamento e suporte")}>
              <ListItem button key={'Treinamento'}>
                <ListItemIcon> <SchoolIcon /></ListItemIcon>
                <ListItemText primary='Ajuda' />
                {
                  linkAjuda?.indexOf("youtube") !== -1 &&
                  <span className="badge text-bg-danger">YouTube</span>
                }
              </ListItem>
            </a>
          </List>
        }
        <Divider />
        {/* <List>
          <a href='https://wa.me/5511984180090?text=Tenho%20um%20estabelecimento%20para%20indicar' target="_blank">
            <ListItem button key={'IndiqueEGanhe'} onClick={(e) => sendClickButton("Outros", "Indique")}>
              <ListItemIcon> <CardGiftcard /></ListItemIcon>
              <ListItemText primary='Indique' />
            </ListItem>
          </a>
        </List> */}
        <List>
          <a href={loadSupport()} target="_blank">
            <ListItem button key={'Suporte'} onClick={(e) => sendClickButton("Outros", "Suporte")}>
              <ListItemIcon> <WhatsApp /></ListItemIcon>
              <ListItemText primary='Suporte' />
            </ListItem>
          </a>
        </List>
        <List>
          <a href={loadTermos()} target="_blank">
            <ListItem button key={'Termos de uso'} onClick={(e) => sendClickButton("Outros", "Termos de uso")}>
              <ListItemIcon> <DescriptionIcon /></ListItemIcon>
              <ListItemText primary='Termos de uso' />
            </ListItem>
          </a>
        </List>
        <Divider />
        <List>
          <Link to='/'>
            <ListItem button onClick={(e) => logout()} key={'Sair'}>
              <ListItemIcon> <ExitToAppIcon /></ListItemIcon>
              <ListItemText primary='Sair' />
            </ListItem>
          </Link>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <MarketingBannerActive />
        {
          acessoHabilitarConfiguracoesLicenca &&
          <LicencaDashboard />
        }
        {props.children}
      </Main>
      <Dialog maxWidth={"xs"} fullWidth onClose={toggleHabilitarOperarRelationshipModal} open={acessoHabilitarOperarRelationshipModalOpen}>
        <OperationRelationshipModal onClose={toggleHabilitarOperarRelationshipModal} />
      </Dialog>
      <Dialog maxWidth={"xs"} fullWidth onClose={toggleHabilitarOperarSingleNoteModal} open={acessoHabilitarOperarSingleNoteModalOpen}>
        <OperationSingleNoteModal onClose={toggleHabilitarOperarSingleNoteModal} />
      </Dialog>
      {
        acessoHabilitarOperarAlterarUsuarioModalOpen &&
        <OperationUserPermissionModal
          permission="CHANGE_USER"
          action={confirmChangeUser}
          onClose={toggleHabilitarAlterarUsuarioModal} />
      }
      <Dialog maxWidth={"xs"} open={alterarClienteOpen}>
        <MuiDialogTitle className='text-center'>
          Alterar estabelecimento
        </MuiDialogTitle>
        <MuiDialogContent>
          <div>
            {
              isReseller() &&
              <>
                <AlertWarning message="Todas as mudanças de cadastros feitas são refletidas no estabelecimento selecionado." />
                {
                  revendasAssociadas.length > 1 &&
                  <div className='mt-3'>
                    <Autocomplete
                      id="grouped-demo"
                      size="small"
                      onChange={alterarRevenda}
                      options={revendasAssociadas.sort((a, b) => -b.label.localeCompare(a.label))}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Revendas" />} />
                  </div>
                }
                <div className='mt-3'>
                  <Autocomplete
                    id="grouped-demo"
                    size="small"
                    onChange={alterarCliente}
                    options={clientes.sort((a, b) => -b.nome.localeCompare(a.nome))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.nome}
                    renderInput={(params) => <TextField {...params} label="Clientes" />} />
                </div>
              </>
            }
            {
              !isReseller() &&
              <Tooltip role="button" title={getEstacionamento()?.id} placement="top">
                <span>
                  <AlertInfo className='mt-3'>
                    Tem mais de um estacionamento ou lava-rápido? Nos mande uma mensagem no WhatsApp: <strong><a rel="noopener noreferrer" target="_blank" href={`https://api.whatsapp.com/send?phone=55${getRevenda()?.whatsAppSuporte?.replaceAll("(", "")?.replaceAll(")", "")?.replaceAll("-", "")?.replaceAll("_", "")}`}>{getRevenda()?.whatsAppSuporte}</a></strong>
                  </AlertInfo>
                </span>
              </Tooltip>
            }
            <div className='mt-3'>
              <Autocomplete
                id="grouped-demo"
                key={clienteSelecionado?.id}
                size="small"
                onChange={alterarEstabelecimento}
                options={estacionamentos.sort((a, b) => -b.nome.localeCompare(a.nome))}
                groupBy={(option) => option.firstLetter}
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => <TextField {...params} label="Estabelecimentos" />} />
            </div>
            {
              isPlatformOwner() &&
              <div className="mt-3" >
                <AlertDanger message="Você esta visualizando os clientes de todas das revendas existentes." />
              </div>
            }
          </div>
        </MuiDialogContent>
        <MuiDialogActions>
          <div className="btn-group m-3">
            <button onClick={(e) => setAlterarClienteOpen(false)} className="btn btn-secondary">Cancelar</button>
            <button onClick={(e) => confirmAlterarCliente(false)} className='btn btn-primary'>Confirmar</button>
            {
              isReseller() &&
              <button onClick={(e) => confirmAlterarCliente(true)} className='btn btn-success'>Confirmar e ver detalhes</button>
            }
          </div>
        </MuiDialogActions>
      </Dialog>
      {
        ticketFoundByBarCode &&
        <TicketRegisterPaymentModal
          showCashiers={false}
          ticket={ticketFoundByBarCode}
          title={"Entregar veículo"}
          onClose={onClosePaymentModal} />
      }
    </Box>
  )
}