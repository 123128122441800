import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItem } from '../transform.docs';
import { addDoc, getAllDocsByClientId, updateDoc } from './collectionBaseWorker';

export const getAllContactInfoByClientId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.CONTACT_INFO, clienteId: clienteId })
}

export const getContactInfoByPlateAndClientId = async ({ plate, clienteId }) => {
    const result = await firebase.firestore().collection(Collection.CONTACT_INFO).where(WhereField.CLIENTEID, '==', clienteId).where('plate', '==', plate.replaceAll("-").toUpperCase()).get()
    return docsToItem(result)
}

export const getContactInfoByPlateAndParkId = async ({ plate, estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.CONTACT_INFO).where(WhereField.PARKID, '==', estacionamentoId).where('plate', '==', plate.replaceAll("-").toUpperCase()).get()
    return docsToItem(result)
}

export const addOrUpdateContactInfo = async ({ data }) => {
    const exist = getContactInfoByPlateAndClientId({ plate: data.plate, clienteId: data.clienteId })
    if (!exist) {
        const doc = await addContactInfo({ data: data })
        return doc.id
    }
    return exist.id;
}

export const addContactInfo = async ({ data }) => {
    const doc = await addDoc({ collection: Collection.CONTACT_INFO, data: data })
    return doc.id;
}

export const updateContactInfoById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.CONTACT_INFO, id: id, data: data })
}