import { getRevendaIdByPark } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { docsToItems, docToItem } from '../transform.docs';
import { addDoc, deleteDoc, updateDoc } from './collectionBaseWorker';

export const getAllLicencePlan = async () => {
    const result = await firebase.firestore().collection(Collection.NEW_PLAN_LICENCE).where("revendaId", "==", getRevendaIdByPark()).get()
    return docsToItems(result).sort((a, b) => a.ordem - b.ordem)
}

export const getAllLicencePlansByAssociatedRessales = async ({ associatedResales }) => {
    const result = await firebase.firestore().collection(Collection.NEW_PLAN_LICENCE).get()
    const items = docsToItems(result)
    return items.filter(e => associatedResales.map(e => e.value).includes(e.revendaId)).sort((a, b) => b.revendaId.localeCompare(a.revendaId))
}

export const getAllPublicLicencePlan = async () => {
    const result = await firebase.firestore().collection(Collection.NEW_PLAN_LICENCE).where('status', '==', 'ativo').where('tipo', '==', 'publico').where("revendaId", "==", getRevendaIdByPark()).get()
    return docsToItems(result).sort((a, b) => a.ordem - b.ordem)
}

export const getFirstHighlightPlan = async () => {
    const result = await firebase.firestore().collection(Collection.NEW_PLAN_LICENCE).where('status', '==', 'ativo').where('tipo', '==', 'publico').where('destaque', '==', 'sim').where("revendaId", "==", getRevendaIdByPark()).get()
    return docsToItems(result).sort((a, b) => a.ordem - b.ordem)
}

export const getLicencePlanById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.NEW_PLAN_LICENCE).doc(id).get()
    return docToItem(result)
}

export const addLicencePlan = async ({ data }) => {
    const doc = await addDoc({ collection: Collection.NEW_PLAN_LICENCE, data: data })
    return doc.id;
}

export const updateLicencePlan = async ({ id, data }) => {
    await updateDoc({ collection: Collection.NEW_PLAN_LICENCE, id: id, data: data })
}

export const deleteLicencePlan = async ({ id }) => {
    await deleteDoc({ collection: Collection.NEW_PLAN_LICENCE, id: id })
}