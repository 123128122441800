import React from 'react';
import { NavBar } from '../../components/navbar'
import { getEstacionamento, getRevenda } from '../../config/auth'
import { documentMask, exportAndDownloadXLS, queryString, setPathWindow, toCurrency } from '../../shared/utils'
import { EmptyContent, FilterComponent } from '../../components/common/commons'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { sendClickButton, sendScreenView } from '../../shared/analytics';
import { getAllMonthly, getAllMonthlyByRangeWithChages, getMonthlyByParksIds } from '../../store/collections/mensalistaWorker';
import NumberFormat from 'react-number-format';
import { getAllChagesCurrentMonth, getAllChagesCurrentMonthByParksIds } from '../../store/collections/monthlyChagesWorker';
import { MonthlyChages } from '../mensalista/clientes/detalhes';
import '../dashboard/index.css'
import moment from 'moment';
import { AlertWarning } from '../../shared/alert-manager';

class ReportMonthly extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notFound: false,
            isLoading: false,
            estacionamentosIds: [],
            estacionamentoId: getEstacionamento()?.id,
            random: Math.random(),
            tickets: [],
            resultado: null,
            allMonthlies: [],
            isOpenMonthliesSelectedModal: false,
            chagesSelected: [],
            modalTitle: "",
            paymentHistory: [],
            loadingHistory: false,
            state: "normal",
            datesNoRepeat: null,
            grouped: null,
            isOpenPaymentsSelectedModal: false,
            agrouped: "dddd",
            allPaymentsWay: [],
            showPaymentCharts: true,
            groupedTable: [],
            allChages: [],
            allChagesMerged: [],
            auxAllMonthlies: [],
            monthliesFromRange: [],
            showModalChages: false,
            monthlySelectedId: "",
            fieldExportStatus: true,
            fieldExportNome: true,
            fieldExportSlots: true,
            fieldExportCellphone: true,
            fieldExportDocument: true,
            fieldExportPlanName: true,
            fieldExportPlanPriceName: true,
            fieldExportDueDate: true,
            fieldExportPrice: true,
            fieldExportDetail: true,
            exportFieldDelimiter: ",",
            estacionamentosIds: queryString("es") ?? [],
            startDate: Moment(queryString("startDate") ?? new Date()).format("DD/MM/YYYY"),
            endDate: Moment(queryString("endDate") ?? new Date()).format("DD/MM/YYYY"),
        }
    }

    componentDidMount = async () => {
        sendScreenView("Relatório", "Mensalistas")
    }

    findAllMonthlies = async () => {
        this.setState({ state: "loading" })
        const startDateURL = Moment(this.state.startDate).format("MM/DD/YYYY")
        const endDateURL = Moment(this.state.endDate).format("MM/DD/YYYY")
        setPathWindow('/relatorios/mensalistas?es=' + this.state.estacionamentosIds + "&startDate=" + startDateURL + "&endDate=" + endDateURL)

        var allChages = await getAllChagesCurrentMonthByParksIds({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            estacionamentosIds: this.state.estacionamentosIds
        })
        const allMothlies = await getMonthlyByParksIds({ estacionamentosIds: this.state.estacionamentosIds })
        for (const change of allChages) {
            const monthly = allMothlies.find(e => e.status !== "inativo" && e.id === change.monthlyId)
            change.monthly = monthly
        }
        this.setState({ allChages: allChages.filter(e => e.monthly) })
        if (allChages.length === 0) {
            this.setState({ state: "empty" })
        } else {
            this.setState({ state: "fill" })
        }
    }

    onApplyFilter = (item) => {
        this.setState({ startDate: item.startDate })
        this.setState({ endDate: item.endDate })
        this.setState({ estacionamentosIds: item.estacionamentosIds }, () => {
            this.findAllMonthlies()
        })
    }

    hideMonthlyExportFieldsModal = () => {
        this.setState({ isOpenMonthlyExportFieldsModal: false })
    }

    exportData = () => {
        const revenda = getRevenda()
        const rows = this.state.allChages.map(e => {
            var data = {}
            data.STATUS = e.status === "payed" ? "Pago" : e?.changeDate.toDate() > new Date() ? "Cobrança gerada" : "Pendente"
            if (this.state.fieldExportNome) {
                data.NOME = e.clienteNome
            }
            if (this.state.fieldExportCellphone) {
                data.CELULAR = e.monthly.celular ?? "-"
            }
            if (this.state.fieldExportDocument) {
                data.DOCUMENTO = documentMask(e.monthly.documento)
            }
            if (this.state.fieldExportDueDate) {
                data.VENCIMENTO = Moment(e?.changeDate.toDate()).format("DD/MM/YYYY")
            }
            if (this.state.fieldExportPlanName) {
                data.PLANO = e.plan?.nome ?? "-"
            }
            if (this.state.fieldExportPlanPriceName) {
                data.VALOR_PLANO = e.plan?.valor ?? "-"
            }
            if (this.state.fieldExportSlots) {
                data.VAGAS = e.monthly?.vagas ?? "1"
            }
            if (this.state.fieldExportPrice) {
                data.VALOR = (e?.netValue ?? 0).toFixed(2)
            }
            if (this.state.fieldExportDetail) {
                data.DETALHES = `${revenda.siteAdmin}/visualizar/mensalista/${e.monthlyId}`
            }
            return data
        })
        exportAndDownloadXLS(rows, `MENSALISTAS_EXPORTADOS`, this.state.exportFieldDelimiter)
        sendClickButton("Relatório", "Tocou em exportar dados dos mensalistas", null, JSON.stringify(rows))
    }

    handleAgrouped = (e) => {
        this.setState({ agrouped: e.target.value }, () => {
            this.applyGroupingDates()
        })
    }

    showPaymentsModal = () => {
        this.setState({ isOpenMonthliesSelectedModal: true })
        this.setState({ chagesSelected: this.state.paymentHistory.sort((a, b) => b.changeDate.toDate() - a.changeDate.toDate()) })
        this.setState({ modalTitle: "Dados analisados" })
    }

    hidePaymentModal = () => {
        this.setState({ isOpenPaymentsSelectedModal: false })
    }

    openPaymentHistory = (item) => {
        this.setState({ monthlySelectedId: item.id }, () => {
            this.setState({ showModalChages: true })
        })
    }

    closePaymentHistory = () => {
        this.setState({ showModalChages: false })
    }

    exportAllMonthly = () => {
        this.setState({ isOpenMonthlyExportFieldsModal: true })
    }

    render() {
        return (
            <>
                <NavBar>
                    <FilterComponent onApplyFilter={this.onApplyFilter} />
                    {
                        this.state.state === "loading" &&
                        <div className="col-lg-12 card my-3">
                            <EmptyContent text="Carregando informações..." />
                        </div>
                    }
                    {
                        this.state.state === "normal" &&
                        <div className="col-lg-12 card my-3">
                            <EmptyContent text="Selecione uma data e aplique o filtro." />
                        </div>
                    }
                    {
                        this.state.state === "empty" &&
                        <div className="col-lg-12 card my-3">
                            <EmptyContent text="Nenhum resultado encontrado com este filtro." />
                        </div>
                    }
                    {
                        this.state.state === "fill" &&
                        <>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <ReportMonthlyAllCard key={this.state.allChages.length + 1}
                                        chages={this.state.allChages} />
                                </div>
                                <div className='col-lg-4'>
                                    <ReportMonthlyProvidedCash key={this.state.allChages.length + 2}
                                        chages={this.state.allChages} />
                                </div>
                                <div className='col-lg-4'>
                                    <ReportMonthlyDefaulters key={this.state.allChages.length + 3}
                                        chages={this.state.allChages} />
                                </div>
                            </div>
                            <div className="col-lg-12 card my-3">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Mensalistas
                                    {
                                        this.state.allChages.length > 0 &&
                                        <div className='btn-group'>
                                            <button type="button" onClick={this.exportAllMonthly} className="btn btn-sm btn-success">
                                                <i className="fas fa-table mx-2" />
                                                Exportar
                                            </button>
                                        </div>
                                    }
                                </div>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <td className="d-none d-sm-table-cell" align='center' width={10}><strong>Status</strong></td>
                                            <td><strong>Nome</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Celular</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Documento</strong></td>
                                            <td align='center' width={10}><strong>Vencimento</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Plano</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Valor plano</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Vagas</strong></td>
                                            <td className="d-none d-sm-table-cell" align='center'><strong>Valor total</strong></td>
                                            <td width={10}></td>
                                        </tr>
                                    </thead>
                                    <tbody className='table-responsive'>
                                        {
                                            this.state.allChages.map(item =>
                                                <tr key={item.id}>
                                                    <td>
                                                        {
                                                            item.status == "payed" ?
                                                                <td align='center'>
                                                                    <span className='badge text-bg-success w-100'>Pago</span>
                                                                </td> :
                                                                <td align='center'>
                                                                    <span className='badge text-bg-danger w-100'>Pendente</span>
                                                                </td>
                                                        }
                                                    </td>
                                                    <td>{item.monthly.nome}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{item.monthly?.celular ?? "-"}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{documentMask(item.monthly?.documento)}</td>
                                                    <td align='center'>{Moment(item.changeDate.toDate()).format("DD/MM/YYYY")}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{item.monthly?.plano?.nome ?? "-"}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{toCurrency(item.monthly?.plano?.valor ?? 0)}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{item.monthly?.vagas ?? "1"}</td>
                                                    <td className="d-none d-sm-table-cell" align='center'>{toCurrency(item.netValue)}</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <Link to={'/visualizar/mensalista/' + item.monthly.id} target='_blank' className="btn btn-primary">
                                                                <i className="fas fa-eye" />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }
                    <Dialog onClose={this.hideMonthlyExportFieldsModal} maxWidth='xs' fullWidth open={this.state.isOpenMonthlyExportFieldsModal}>
                        <MuiDialogTitle className='text-center'>
                            Extrair relatório
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <AlertWarning message="Escolha quais dados deseja imprimir no relatório." />
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="fieldExportStatus" checked={this.state.fieldExportStatus} disabled type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportStatus">
                                    Status ( Pago, Pendente ou cobrança gerada )
                                </label>
                            </div>
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="fieldExportNome" checked={this.state.fieldExportNome} onChange={(e) => this.setState({ fieldExportNome: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportNome">
                                    Nome do mensalista
                                </label>
                            </div>
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="fieldExportCellphone" checked={this.state.fieldExportCellphone} onChange={(e) => this.setState({ fieldExportCellphone: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportCellphone">
                                    Celular
                                </label>
                            </div>
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="fieldExportDocument" checked={this.state.fieldExportDocument} onChange={(e) => this.setState({ fieldExportDocument: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportDocument">
                                    Documento
                                </label>
                            </div>
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="fieldExportDueDate" checked={this.state.fieldExportDueDate} onChange={(e) => this.setState({ fieldExportDueDate: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportDueDate">
                                    Data de vencimento
                                </label>
                            </div>
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="fieldExportSlots" checked={this.state.fieldExportSlots} onChange={(e) => this.setState({ fieldExportSlots: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportSlots">
                                    Vagas
                                </label>
                            </div>
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="fieldExportPlanName" checked={this.state.fieldExportPlanName} onChange={(e) => this.setState({ fieldExportPlanName: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportPlanName">
                                    Nome do plano
                                </label>
                            </div>
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="fieldExportPlanPriceName" checked={this.state.fieldExportPlanPriceName} onChange={(e) => this.setState({ fieldExportPlanPriceName: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportPlanPriceName">
                                    Valor do plano
                                </label>
                            </div>
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="fieldExportPrice" checked={this.state.fieldExportPrice} onChange={(e) => this.setState({ fieldExportPrice: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportPrice">
                                    Valor total
                                </label>
                            </div>
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="fieldExportDetail" checked={this.state.fieldExportDetail} onChange={(e) => this.setState({ fieldExportDetail: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="fieldExportDetail">
                                    Detalhes ( URL )
                                </label>
                            </div>
                            <label>Delimitador da planilha</label>
                            <select className="form-select" value={this.state.exportFieldDelimiter} onChange={(e) => this.setState({ exportFieldDelimiter: e.target.value })}>
                                <option value=",">Virgula ( , )</option>
                                <option value=";">Ponto e virgula ( ; )</option>
                            </select>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='m-3 btn-group'>
                                <button onClick={this.hideMonthlyExportFieldsModal} className='btn btn-secondary'>Fechar</button>
                                <button type="button" onClick={this.exportData} className="btn btn-success">
                                    Confirmar
                                </button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </NavBar >
            </>
        )
    }
}

class ReportMonthlyAllCard extends React.Component {

    constructor(props) {
        super(props)
        console.log(props.chages)
        this.state = {
            chages: props.chages,
            total: props.chages.map(e => e.netValue ?? 0).reduce((a, b) => a + b, 0),
        }
    }

    render() {
        return (
            <>
                <div className="card text-light bg-primary mb-3">
                    <div className="card-body">
                        <h5 className="card-title text-center text-white">Previstos</h5>
                        <h3 className="card-text text-center text-white">
                            {toCurrency(this.state.total)}
                        </h3>
                        <h5 className='monthly-card monthly-card-blue'>
                            <span className='mx-1'>
                                {this.state.chages.length}
                            </span>
                        </h5>
                        <div className="text-center">
                            <small className="card-text text-white">Cobranças previstas no período</small>
                        </div>
                    </div>
                </div>
            </>)
    }
}

class ReportMonthlyProvidedCash extends React.Component {

    constructor(props) {
        super(props)
        const defaulters = props.chages.filter(e => e.payment)
        var defaultersPercent = 0.0
        if (defaulters.length > 0 && props.chages.length > 0) {
            defaultersPercent = 100 - (((props.chages.length - defaulters.length) / props.chages.length) * 100)
        }
        this.state = {
            chages: props.chages,
            providedCashMonth: defaulters.length,
            defaultersPercent: defaultersPercent.toFixed(2),
            payed: props.chages.filter(e => e.payment).map(e => e.payment?.total ?? 0).reduce((a, b) => a + b, 0),
        }
    }

    render() {
        return (
            <>
                <div className="card text-light bg-success mb-3">
                    <div className="card-body">
                        <h5 className="card-title text-center text-white">Pagos</h5>
                        <h3 className="card-text text-center text-white">
                            {toCurrency(this.state.payed)}
                        </h3>
                        <h5 className='monthly-card monthly-card-green'>
                            <span className='mx-1'>
                                {this.state.providedCashMonth} / {this.state.defaultersPercent}%
                            </span>
                        </h5>
                        <div className="text-center">
                            <small className="card-text text-white">Pagos no período</small>
                        </div>
                    </div>
                </div>
            </>)
    }
}

class ReportMonthlyDefaulters extends React.Component {

    constructor(props) {
        super(props)
        const defaulters = props.chages.filter(e => !e.payment)

        var defaultersPercent = 0.0
        if (defaulters.length > 0 && props.chages.length > 0) {
            defaultersPercent = 100 - (((props.chages.length - defaulters.length) / props.chages.length) * 100)
        }
        this.state = {
            total: defaulters.map(e => e.netValue).reduce((a, b) => a + b, 0),
            chages: defaulters,
            defaultersTotal: defaulters.length,
            defaultersPercent: defaultersPercent.toFixed(2)
        }
    }

    render() {
        return (
            <>
                <div className="card text-light bg-danger mb-3">
                    <div className="card-body">
                        <h5 className="card-title text-center text-white">Pendentes</h5>
                        <h3 className="card-text text-center text-white">
                            {toCurrency(this.state.total)}
                        </h3>
                        <h5 className='monthly-card monthly-card-red'> {this.state.chages.length} / {this.state.defaultersPercent}% </h5>
                        {
                            this.state.chages.length === 0 &&
                            <div className="text-center">
                                <small className="card-text text-white">{
                                    <span>pagamentos Pendentes</span>
                                }</small>
                            </div>
                        }
                        {
                            this.state.chages.length === 1 &&
                            <div className="text-center">
                                <small className="card-text text-white">{
                                    this.state.chages.length !== 0 ? <span>{this.state.defaultersTotal} Pagamento Pendente</span> : <span>-</span>
                                }</small>
                            </div>
                        }
                        {
                            this.state.chages.length > 1 &&
                            <div className="text-center">
                                <small className="card-text text-white">
                                    {
                                        <span>{this.state.defaultersTotal} Pagamentos Pendentes</span>
                                    }
                                </small>
                            </div>
                        }
                    </div>
                </div>
            </>)
    }
}

class ReportMonthlyAllPayments extends React.Component {

    constructor(props) {
        super(props)
        const payments = props.payments.filter(e => e.payment)
        this.state = {
            total: payments.map(e => e.payment.total).reduce((a, b) => a + b, 0),
            quantity: payments.length
        }
    }

    render() {
        return (
            <>
                <div className="card text-light bg-success mb-3">
                    <div className="card-body">
                        <h5 className="card-title text-center text-white">Total de pagamentos</h5>
                        <h3 className="card-text text-center text-white">
                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.total} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                        </h3>
                        <div className="text-center">
                            {
                                this.state.quantity === 0 && <small className="card-text text-white">Nenhum registrados</small>
                            }
                            {
                                this.state.quantity === 1 && <small className="card-text text-white">{this.state.quantity} pagamento registrado</small>
                            }
                            {
                                this.state.quantity > 1 && <small className="card-text text-white">{this.state.quantity} pagamentos registrados</small>
                            }
                        </div>
                    </div>
                </div>
            </>)
    }
}

export { ReportMonthly };