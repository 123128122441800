import { add } from 'date-fns';
import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { addDoc, deleteDoc, updateDoc } from './collectionBaseWorker';

export const getAllSupportCentersByRessales = async ({ associatedResales }) => {
    var results = await firebase.firestore().collection(Collection.SUPPORT_CENTER).where("revendaId", "in", associatedResales.map(e => e.value)).get()
    return docsToItems(results)
}

export const addSupportCenter = async ({ data }) => {
    await addDoc({ collection: Collection.SUPPORT_CENTER, data: data })
}

export const updateSupportCenterById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.SUPPORT_CENTER, id: id, data: data })
}

export const removeSupportCenterById = async ({ id }) => {
    await deleteDoc({ collection: Collection.SUPPORT_CENTER, id: id })
}