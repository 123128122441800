
import React from 'react'
import { documentMask, isNullOrEmpty, takeIfIsNotNullOrEmpty, toCurrency, timestampToSeconds, toDateFormat } from '../utils'
import { getRevenda } from '../../config/auth'
import { urlTicketOnlineFromTicket } from '../../shared/url-manager.js';
import { QRCodeSVG } from 'qrcode.react';
import moment from 'moment'

class PrinterHeaderComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            park: props.park
        }
    }

    render() {
        return (
            <div >
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoNome && <>
                        {
                            this.state.parameters.tamanhoDoNomeDoEstacionamento === "grande" ?
                                <h5>{this.state.park.nome}</h5> :
                                <span>{this.state.park.nome}</span>
                        }
                    </>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoDocumento &&
                    <div>{documentMask(this.state.park.documento)}</div>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoEndereco &&
                    <div>{this.state.park.endereco?.logradouro}, {this.state.park.endereco?.numero}</div>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento &&
                    <div>{this.state.park.horarios}</div>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoCelular &&
                    <div>{this.state.park.celular}</div>
                }
            </div>
        )
    }
}

class MessageInTicketComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            position: props.position
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.parameters.habilitarMensagemImpressaNoTicket && this.state.position === this.state.parameters.mensagemLancamentoTicketLocal &&
                    <div>
                        {this.state.parameters.mensagemLancamentoTicket}
                    </div>
                }
            </div>
        )
    }
}

class BodyTicketInComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }
    render() {
        return (
            <div className='text-start mt-2'>
                {
                    this.state.ticket.paymentsLogged &&
                    <div className='text-center fs-6'>RECIBO DE PAGAMENTO</div>
                }
                {
                    <div className='text-center mb-1'><MessageInTicketComponent parameters={this.state.parameters} position={"comeco"} /></div>
                }
                {
                    this.state.ticket.type !== "SALES" && <>
                        {
                        }
                        {
                            <div>Entrada: {toDateFormat(this.state.ticket.createAt)}</div>
                        }
                        {
                            this.state.ticket.status === "Pago e Entregue" &&
                            <div>Saida: {toDateFormat(this.state.ticket.closedAt)} </div>
                        }
                        {
                            this.state.ticket.status === "Pago e Entregue" &&
                            <div>Permanência: {
                                (() => {
                                    const diff = timestampToSeconds(this.state.ticket.closedAt) - timestampToSeconds(this.state.ticket.createAt)
                                    if (diff < 60) {
                                        return "1 minuto";
                                    }
                                    const horas = Math.floor(diff / 3600);
                                    const minutos = Math.floor((diff % 3600) / 60);
                                    return horas > 0 ? `${horas} hora${horas !== 1 ? 's' : ''} e ${minutos} minuto${minutos !== 1 ? 's' : ''}` : `${minutos} minuto${minutos !== 1 ? 's' : ''}`;
                                })()
                            }</div>
                        }
                    </>
                }
                {
                    <div>Operador: {this.state.ticket.createByName}</div>
                }
                {
                    this.state.ticket.prisma?.length > 0 &&
                    <div>{this.state.parameters.descricaoPrisma + this.state.ticket.prisma}</div>
                }
                {
                    this.state.ticket.placa?.length > 0 &&
                    <div className='fs-6'>Placa: {this.state.ticket.placa}</div>
                }
                {
                    !this.state.parameters.habilitarTicketResumido &&
                    <>
                        {
                            this.state.ticket.setor &&
                            <div>Setor: {this.state.ticket.setor}</div>
                        }
                        {
                            this.state.ticket.vehicler && (
                                <div>
                                    {
                                        takeIfIsNotNullOrEmpty(this.state.ticket.vehicler.marca) &&
                                        <div>Marca: {this.state.ticket.vehicler.marca}<br /></div>
                                    }
                                    {
                                        takeIfIsNotNullOrEmpty(this.state.ticket.vehicler.modelo) &&
                                        <div>Modelo: {this.state.ticket.vehicler.modelo}<br /></div>
                                    }
                                    {
                                        takeIfIsNotNullOrEmpty(this.state.ticket.vehicler.cor) > 0 &&
                                        <div>Cor: {this.state.ticket.vehicler.cor}<br /></div>
                                    }
                                </div>
                            )
                        }
                    </>
                }
                {
                    this.state.ticket.tableSelectedInCheckIn && !this.state.parameters.habilitarTicketResumido &&
                    <div>Tabela: {this.state.ticket.tableSelectedInCheckIn.nome}</div>
                }
            </div>
        )
    }
}

class BodyMonthlyChargeComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            charge: props.charge
        }
    }
    render() {
        return (
            <div className='text-start mt-2'>
                {
                    <div className='text-center fs-6'>RECIBO DO MENSALISTA</div>
                }
                {
                    <>
                        <div>Cliente</div>
                        <div>{this.state.charge.clienteNome}</div>
                    </>
                }
                {
                    this.state.charge.newNote ?
                        <>
                            {
                                isNullOrEmpty(this.state.charge.newNote?.document) ?
                                    <div className='text-center fs-6'>Documento não informado</div> :
                                    <div>Documento: {documentMask(this.state.charge.newNote?.document)}</div>
                            }
                        </> : <>
                            {
                                this.state.parameters?.habilitarImprimirTituloDocumentoNaoFiscal === true && this.state.charge.newNote?.document != null &&
                                <div>Não é documento fiscal</div>
                            }
                        </>
                }
                {
                    <>
                        <div className='text-center fs-6'>PAGAMENTO</div>
                        <div className="row justify-content-between">
                            <div className="col-6">Data do pagamento</div>
                            <div className="col-6 text-end">{toDateFormat(this.state.charge.payment?.dataPagamento)}</div>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-6">Data do vencimento</div>
                            <div className="col-6 text-end">{toDateFormat(this.state.charge.payment?.dataFatura)}</div>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-6">Valor pago</div>
                            <div className="col-6 text-end">{toCurrency(this.state.charge.payment?.total ?? 0.0)}</div>
                        </div>
                    </>
                }
                {
                    <>
                        <div className='text-center fs-6'>PLANO</div>
                        <div className="row justify-content-between">
                            <div className="col-6">{this.state.charge.plan?.nome}</div>
                            <div className="col-6 text-end">Das {this.state.charge.plan?.horarioInicio} às {this.state.charge.plan?.horarioFim}</div>
                        </div>
                    </>
                }
                {
                    <>
                        <div className="text-center">VEÍCULOS ({this.state.charge.vehicle?.length ?? 0})</div>
                        <div className="row fw-bold text-start">
                            <div className="col-4 text-start">Placa</div>
                            <div className="col-4 text-center">Modelo</div>
                            <div className="col-4 text-end">Cor</div>
                        </div>
                        {
                            this.state.charge.vehicle?.map((vehicle, index) => (
                                <div key={index} className="row text-start">
                                    <div className="col-4 text-start">{takeIfIsNotNullOrEmpty(vehicle.placa) ?? "-"}</div>
                                    <div className="col-4 text-center">{takeIfIsNotNullOrEmpty(vehicle.modelo) ?? "-"}</div>
                                    <div className="col-4 text-end">{takeIfIsNotNullOrEmpty(vehicle.cor) ?? "-"}</div>
                                </div>
                            ))
                        }
                    </>
                }
            </div>
        )
    }
}

class PrinterPaymentsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ticket: props.ticket
        }
    }


    render() {
        return (
            <div>
                {
                    this.state.ticket.paymentsLogged?.length > 0 && (
                        <>
                            <div className='fs-6'>{this.state.ticket.paymentsLogged.length === 1 ? 'PAGAMENTO' : 'PAGAMENTOS'}</div>
                            {this.state.ticket.paymentsLogged.map(pagamento => (
                                <ul key={pagamento.id} className="text-start list-unstyled">
                                    <li>Data: {toDateFormat(pagamento.createAt)}</li>
                                    <li>Tipo: {pagamento.formaPagamento?.nome ?? "Não disponível"}</li>
                                    <li>Operador: {pagamento.user.nome}</li>
                                    <li>Valor: {toCurrency(pagamento.resumo.valorCobrado)}</li>
                                </ul>
                            ))}
                        </>
                    )
                }
            </div>
        )
    }
}

class PrinterInternalReviewComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.ticket.interno?.length > 0 && !this.state.parameters.habilitarTicketResumido &&
                    <div>Avaliações internas:
                        {
                            this.state.ticket.interno.map((item) => {
                                return <div> {item.quantidade} - {item.nome} </div>
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

class PrinterProductsComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }

    render() {
        var totalProdutos = 0
        return (
            <div className='text-start mt-2'>
                {
                    this.state.ticket.produtos?.length > 0 && (!this.state.parameters.habilitarTicketResumido || this.state.ticket.type === "SALES") &&
                    <div>
                        <div className='fs-6'>PRODUTOS</div>
                        {
                            this.state.ticket.produtos.map((item) => {
                                totalProdutos += (Number(item.quantidade) * Number(item.valor))
                                return <div> ({item.quantidade}x) {toCurrency(item.valor)} - {item.descricao} </div>
                            })
                        }
                        Total Produtos: {toCurrency(totalProdutos)}
                    </div>

                }
            </div>
        )
    }
}

class PrinterQRCodeComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }

    render() {
        return (
            <div className='mt-2'>
                {
                    this.state.parameters.imprimirTicketNoFormato === "QRCode" &&
                    <div>
                        <p>Aponte a sua câmera para o QRCode abaixo.</p>
                        <QRCodeSVG value={urlTicketOnlineFromTicket(this.state.ticket, "payment-receipt")} size={100} />
                    </div>
                }
                {
                    this.state.parameters.datahabilitarNumeroImpressoNoTicket &&
                    <div className='fs-1'>{this.state.ticket.ticket}</div>
                }
            </div>
        )
    }
}

class PrinterFooterComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            park: props.park,
            revenda: props.revenda
        }
    }

    render() {
        var revenda = getRevenda()
        var siteRevenda = revenda.site.split("/")[2]
        return (
            <div className="mt-2">
                {
                    <div>{siteRevenda}</div>
                }
                {
                    <div>
                        Impresso {new Date().toLocaleDateString('pt-BR')} às {new Date().toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                    </div>
                }
            </div>
        )
    }
}

class CashierReportTotalsDocument extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <div className="mt-2">
                <div className='text-start fs-6'>Faturamento</div>
                <table className="table table-bordered table-sm" style={{ borderColor: 'black' }}>
                    <thead>
                        <tr>
                            <th></th>
                            <th className="text-end">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="text-start col-6">Estacionamento</td>
                            <td className="text-end col-6">{toCurrency(this.state.resultado.totais.tickets.valorEstacionamento)}</td>
                        </tr>
                        <tr>
                            <td className="text-start">Mensalistas</td>
                            <td className="text-end">{toCurrency(this.state.resultado.totais.monthly)}</td>
                        </tr>
                        <tr>
                            <td className="text-start">Produtos e serviços</td>
                            <td className="text-end">{toCurrency(this.state.resultado.totais.tickets.valorProdutos)}</td>
                        </tr>
                        <tr>
                            <td className="text-start">Convênios</td>
                            <td className="text-end">{toCurrency(this.state.resultado.totais.convenios)}</td>
                        </tr>
                        <tr>
                            <td className="text-start">Descontos manuais</td>
                            <td className="text-end">{toCurrency(this.state.resultado.totais.descontoManual)}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th className="text-end" colSpan="2">
                                <div style={{ fontSize: '8px' }}>Valor Bruto: {toCurrency(this.state.resultado.totais.faturamento)}</div>
                                <div>Valor Líquido: {toCurrency(this.state.resultado.totais.faturamentoLiquido)}</div>
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        )
    }
}

class CashierReportFormasDePagamentosCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }
    render() {
        return (
            <>
                {
                    this.state.resultado?.totais?.tickets?.byWayPayment.length > 0 &&
                    <div>
                        <div className='text-start fs-6'>Formas de pagamentos</div>
                        <table className="table table-bordered table-sm" style={{ borderColor: 'black' }}>
                            <thead>
                                <tr>
                                    <th className="text-start">Tipo</th>
                                    <th className="text-end">Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.resultado?.totais?.tickets?.byWayPayment.map((item, index) =>
                                        <tr key={`way_payment_${index}`}>
                                            <td className="text-start">{item.wayPayment?.nome}</td>
                                            <td className="text-end">{toCurrency(item.total)}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                }
            </>
        )
    }
}

class CashierReportListagemMensalistasCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }
    render() {
        return (
            <>
                {
                    this.state.resultado.mensalistas.length > 0 &&
                    <div className="mt-2">
                        <div className='text-start fs-6'>Pagamentos de mensalistas</div>
                        <table className="table table-bordered table-sm" style={{ borderColor: 'black' }}>
                            <thead>
                                <tr style={{ fontSize: '10px' }}>
                                    <th className="text-center">Nome</th>
                                    <th className="text-center">Plano</th>
                                    <th className="text-center">Forma pag.</th>
                                    <th className="text-center">Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.resultado.mensalistas.map((item, index) => (
                                        <tr key={`row_${index}`}>
                                            <td>{(item.clienteNome ?? "-").substring(0, 10)}</td>
                                            <td>{item.plan?.nome ?? "-"}</td>
                                            <td>{(item.payment.formaPagamento?.nome ?? "-").substring(0, 10)}</td>
                                            <td>{toCurrency(item.payment?.total) ?? "-"}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className="text-end" colSpan="4">Total: {toCurrency(this.state.resultado.totais.monthly)}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                }
            </>
        )
    }
}

class CashierReportTabelasUtilizadasCaixas extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.tabelasUseds.length > 0 &&
                    <div className="mt-2">
                        <div className='text-start fs-6'>Tabelas de preços</div>
                        <table className="table table-bordered table-sm" style={{ borderColor: 'black' }}>
                            <thead>
                                <tr>
                                    <th className="text-center">Tipo</th>
                                    <th className="text-center">Nome</th>
                                    <th className="text-center">Vezes usada</th>
                                    <th className="text-center">Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.resultado.tabelasUseds.map((item, index) => (
                                        <tr key={`row_${index}`}>
                                            <td>{item.tableSelected.resumo.tabelaPreco.tipo.replace("_", " ").toUpperCase()}</td>
                                            <td>{item.tableSelected.resumo.tabelaPreco.nome.substring(0, 10)}</td>
                                            <td>{item.quantityUsed}</td>
                                            <td>{toCurrency(item.total)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className="text-end" colSpan="4">{toCurrency(this.state.resultado.totais.tickets.valorEstacionamento)}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                }
            </>
        );
    }
}

class CashierReportConveniosCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.convenios.length > 0 &&
                    <div className='mt-2'>
                        <div className='text-start fs-6'>Convênios</div>
                        <table className="table table-bordered table-sm" style={{ borderColor: 'black' }}>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Vezes usado</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.resultado.convenios.map((item, index) => (
                                        <tr key={`convenios_${index}`}>
                                            <td>{item.convenio.resumo.convenio.nome.substring(0, 10)}</td>
                                            <td>{item.quantityUsed}</td>
                                            <td>{toCurrency(item.total)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className='text-end' colSpan="3">{toCurrency(this.state.resultado.totais.convenios)}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                }
            </>
        )
    }
}

class CashierReportDescontosCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.descontoManual.length > 0 &&
                    <div className='mt-2'>
                        <div className='text-start fs-6'>Descontos manuais</div>
                        <table className='table table-bordered table-sm' style={{ borderColor: 'black' }}>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Responsável</th>
                                    <th>Motivo</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.resultado.descontoManual.map((item, index) => (
                                        <tr key={`descontos_manuais_${index}`}>
                                            <td>{moment(item.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</td>
                                            <td>{(item.descontoManual?.user?.nome ?? "-").substring(0, 10)}</td>
                                            <td>{(item.descontoManual?.reason ?? "-").substring(0, 10)}</td>
                                            <td>-R$ {item.descontoManual.discount.toFixed(2)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className='text-end' colSpan="4">-R$ {this.state.resultado.totais.descontoManual.toFixed(2)}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                }
            </>
        )
    }
}

class CashierReportCaixasAnalisados extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                <div className='mt-2'>
                    <div className='text-start fs-6'>Caixas analisados</div>
                    <table className='table table-bordered table-sm' style={{ borderColor: 'black' }}>
                        <thead>
                            <tr>
                                <th>Nº</th>
                                <th>Resp.</th>
                                <th>Aberto</th>
                                <th>Fechado</th>
                                <th>Bruto</th>
                                <th>Líquido</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.resultado.caixas.map((item, index) => (
                                    <tr key={`tabelas_usadas_${index}`}>
                                        <th>{item.sequence.replace(/^0+/, '')}</th>
                                        <th>{item.openBy.nome.split(' ')[0]}</th>
                                        <th>{moment(item.openAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</th>
                                        <th>{isNullOrEmpty(item.closeAt) ? "Caixa em aberto" : moment(item.closeAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</th>
                                        <th>{toCurrency(item.totais.faturamento)}</th>
                                        <th>{toCurrency(item.totais.faturamentoLiquido)}</th>
                                    </tr>
                                ))
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th className='text-end' colSpan="5">{toCurrency(this.state.resultado.totais.faturamento)}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </>
        )
    }
}

class CashierReportMovimentacoesCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }
    render() {
        return (
            <>
                {
                    this.state.resultado.movements.length > 0 &&
                    <div className='mt-2'>
                        <div className='text-start fs-6'>Movimentações</div>
                        <table className='table table-bordered table-sm' style={{ borderColor: 'black' }}>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Tipo</th>
                                    <th>Respon</th>
                                    <th>Obser</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.resultado.movements.map((item, index) => (
                                        <tr key={`movements_${index}`}>
                                            <td>{moment(item.createAt.seconds * 1000).format("DD/MM/YYYY")}</td>
                                            <td>{item.type}</td>
                                            <td>{(item.createdBy?.nome ?? "-").substring(0, 10)}</td>
                                            <td>{(item.comment ?? "-").substring(0, 10)}</td>
                                            <td>{toCurrency(item.price)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className='text-end' colSpan="6">
                                        <div>Entradas +{toCurrency(this.state.resultado.totais.movements.earn)}</div>
                                        <div>Saídas -{toCurrency(this.state.resultado.totais.movements.spends)}</div>
                                        <div>{toCurrency(this.state.resultado.totais.movements.total)}</div>
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                }
            </>
        )
    }
}

class CashierReportRetiradasCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }
    render() {
        return (
            <>
                {
                    this.state.resultado.retiradas.length > 0 &&
                    <div className='mt-2'>
                        <div className='text-start fs-6'>Retiradas ( sangrias )</div>
                        <table className='table table-bordered table-sm' style={{ borderColor: 'black' }}>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Responsável</th>
                                    <th>Observação</th>
                                    <th>Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.resultado.retiradas.map((item, index) => (
                                        <tr key={`retiradas_${index}`}>
                                            <td>{moment(item.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</td>
                                            <td>{item.user?.nome.substring(0, 10)}</td>
                                            <td>{(item.comment ?? "-").substring(0, 10)}</td>
                                            <td>{toCurrency(item.price)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className='text-end' colSpan="4">{toCurrency(this.state.resultado.totais.retiradas)}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                }
            </>
        )
    }
}

class CashierReportCancelamentosCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.ticketsCanceled.length > 0 &&
                    <div className='mt-2'>
                        <div className='text-start fs-6'>Tickets cancelados</div>
                        <table className='table table-bordered table-sm' style={{ borderColor: 'black' }}>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Placa</th>
                                    <th>Ticket</th>
                                    <th>Responsável</th>
                                    <th>Observação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.resultado.ticketsCanceled.map((item, index) => (
                                        <tr key={`retiradas_${index}`}>
                                            <td>{moment(item.statusLogged.filter(e => e.status === "Cancelado")[0].createAt.seconds * 1000).format("DD/MM/YYYY")}</td>
                                            <td>{item.placa ?? "-"}</td>
                                            <td>{item.ticket}</td>
                                            <td>{item.statusLogged.filter(e => e.status === "Cancelado")[0].createBy.nome ?? "-"}</td>
                                            <td>{item.statusLogged.filter(e => e.status === "Cancelado")[0].reason ?? "-"}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className='text-end' colSpan="5">{this.state.resultado.ticketsCanceled.length} tickets</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                }
            </>
        )
    }
}




export { PrinterHeaderComponent, BodyTicketInComponent, BodyMonthlyChargeComponent, PrinterPaymentsComponent, PrinterInternalReviewComponent, PrinterProductsComponent, PrinterFooterComponent, PrinterQRCodeComponent, MessageInTicketComponent, CashierReportTotalsDocument, CashierReportFormasDePagamentosCaixas, CashierReportListagemMensalistasCaixas, CashierReportTabelasUtilizadasCaixas, CashierReportConveniosCaixas, CashierReportDescontosCaixas, CashierReportCaixasAnalisados, CashierReportMovimentacoesCaixas, CashierReportRetiradasCaixas, CashierReportCancelamentosCaixas }