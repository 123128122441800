import { getRevenda, getUsuario } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { addDoc, deleteDoc, updateDoc } from './collectionBaseWorker';

export const addHelpdesk = async ({ platform, type, criticality, body, clienteId, estacionamentoId }) => {
    const revenda = getRevenda()
    const user = getUsuario()
    const data = {
        revendaId: revenda.id,
        revendaNome: revenda.nome,
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        criticality: criticality,
        platform: platform,
        type: type,
        body: body,
        user: {
            nome: user.nome,
            email: user.email
        },
        status: "CREATED",
        createdAt: new Date(),
        isDeleted: false,
    }
    const doc = await addDoc({ collection: Collection.HELPDESK, data: data })
    return doc.id
}

export const getAllHelpdeskByClientId = async ({ clientId }) => {
    const results = await firebase.firestore().collection(Collection.HELPDESK).where(WhereField.PARKID, "==", clientId).get()
    return docsToItems(results)
}

export const getAllHelpdeskByRevendaId = async ({ revendaId }) => {
    const results = await firebase.firestore().collection(Collection.HELPDESK).where(WhereField.PARKID, "==", revendaId).get()
    return docsToItems(results)
}

export const getAllHelpdesk = async () => {
    const results = await firebase.firestore().collection(Collection.HELPDESK).get()
    return docsToItems(results)
}

export const updateHelpdesk = async ({ id, data }) => {
    await updateDoc({ collection: Collection.HELPDESK, id: id, data: data })
}

export const deleteHelpdesk = async ({ id }) => {
    await deleteDoc({ collection: Collection.HELPDESK, id: id })
}
