import firebase from '../../config/firebase';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { addDoc, deleteDoc, getAllDocsByClientId, getAllDocsByParkId, updateDoc } from './collectionBaseWorker';

export const getAllMovementsCategoriesCashiersByClientId = async ({ clienteId }) => {
    const documents = await getAllDocsByClientId({ collection: Collection.MOVEMENTS_CATEGORIES_CASHIER, clienteId: clienteId })
    return documents
}

export const getAllMovementsCategoriesCashiers = async ({ estacionamentoId }) => {
    const documents = await getAllDocsByParkId({ collection: Collection.MOVEMENTS_CATEGORIES_CASHIER, estacionamentoId: estacionamentoId })
    return documents
}

export const getMovementsCategoryCashierByName = async ({ name, estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.MOVEMENTS_CATEGORIES_CASHIER).where(WhereField.NAME, "==", name).where(WhereField.PARKID, "==", estacionamentoId).get()
    return docsToItems(result)
}

export const createMovementCategoryCashier = async ({ data }) => {
    await addDoc({ collection: Collection.MOVEMENTS_CATEGORIES_CASHIER, data: data })
}

export const deleteMovementCategoryCashier = async ({ id }) => {
    await deleteDoc({ collection: Collection.MOVEMENTS_CATEGORIES_CASHIER, id: id })
}

export const updateMovementCategoryCashier = async ({ id, data }) => {
    await updateDoc({ collection: Collection.MOVEMENTS_CATEGORIES_CASHIER, id: id, data: data })
}