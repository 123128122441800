import React from 'react'
import { NavBar } from '../../components/navbar'
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario } from '../../config/auth'
import { Loading, EmptyContent, HelpButton } from '../../components/common/commons'
import { saveLastUpdate } from '../../store/collections/lastUpdateWorker';
import Select from 'react-select';
import 'firebase/auth';
import 'firebase/storage';
import { addSector, getAllSectoresByParkId, removeSectorById, updateSectorById } from '../../store/collections/setoresWorker';
import { reloadWithAlert, toastWarning } from '../../shared/utils';
import moment from 'moment';
import { sendLog, sendLogByUser } from '../../shared/analytics';
import { getAllPaymentTablesBySectorId } from '../../store/collections/paymentTableWorker';
import { AlertInfo } from '../../shared/alert-manager';

const initializeState = {
    id: null,
    estacionamentoId: getEstacionamento()?.id,
    clienteId: getCliente().id,
    revendaId: getRevendaIdByPark(),
    nome: '',
    excludedStatus: [],
    allStatus: [{ value: "Estacionado", label: "Estacionado" },
    { value: "Pago e Estacionado", label: "Pago e Estacionado" },
    { value: "Pago e Entregue", label: "Pago e Entregue" },
    { value: "Pago e Aguardando", label: "Pago e Aguardando" },
    { value: "Cancelado", label: "Cancelado" }],
    filtering: 'true',
    status: 'Ativo',
}

class CriarSetores extends React.Component {

    constructor(props) {
        super(props)
        this.state = initializeState
    }

    add = async () => {
        if (this.checkFields()) {
            toastWarning("Informe os campos obrigatórios")
            return
        }
        const user = getUsuario()
        const data = {
            clienteId: this.state.clienteId,
            revendaId: this.state.revendaId,
            estacionamentoId: this.state.estacionamentoId,
            nome: this.state.nome,
            status: this.state.status,
            vagas: this.state.vagas,
            filtering: this.state.filtering,
            excludedStatus: this.state.excludedStatus.map(e => e.value),
            createdAt: new Date(),
            createdBy: user.email
        }
        sendLogByUser("Setor", `Adicionou o setor ${data.nome}`, data)
        await addSector({ data: data })
        await saveLastUpdate()
        reloadWithAlert("Setor cadastrado com sucesso!")
    }

    onRemove = async (item) => {
        var confirm = window.confirm('Deseja mesmo remover este setor?');
        if (confirm) {
            const allTableAssociated = await getAllPaymentTablesBySectorId({ sectorId: item.id })
            if (allTableAssociated.length === 0) {
                removeSectorById({ id: item.id })
                sendLogByUser("Setor", `Removeu o setor ${item.nome}`, item)
                await saveLastUpdate()
                reloadWithAlert("Setor removido com sucesso.")
            } else {
                var names = allTableAssociated.map(e => e.nome + "\n")
                toastWarning("Não é possível remover um setor que esteja associado a uma ou mais Tabelas de preço, Você pode escolher desativar o setor ou remover a associação nas tabelas.\n\nTabelas de preço com este setor associado: \n\n" + names)
            }
        }
    }

    update = async () => {
        const user = getUsuario()
        const data = {
            estacionamentoId: this.state.estacionamentoId,
            nome: this.state.nome,
            status: this.state.status,
            filtering: this.state.filtering,
            vagas: this.state.vagas,
            excludedStatus: this.state.excludedStatus.map(e => e.value),
            alterAt: new Date(),
            alterBy: getUsuario().email
        }
        await updateSectorById({ id: this.state.id, data: data })
        sendLogByUser("Setor", `Atualizou o setor ${data.nome}`, data)
        await saveLastUpdate()
        reloadWithAlert("Setor atualizado com sucesso!")
    }

    reset = () => {
        this.setState({ ...initializeState })
    }

    onEdit = (item) => {
        this.setState({ nome: item.nome })
        this.setState({ status: item.status })
        this.setState({ vagas: item.vagas })
        this.setState({ filtering: item.filtering ?? "false" })
        this.setState({ excludedStatus: item.excludedStatus?.map(e => { return { value: e, label: e } }) } ?? [])
        this.setState({ id: item.id })
    }

    handleExcludedStatus = (selected) => {
        this.setState({ excludedStatus: selected })
    };

    checkFields = () => {
        return (this.state.estacionamentoId === undefined || this.state.nome === '')
    }

    onEstacionamentoSelected = (item) => {
        this.setState({ estacionamentoId: item.id })
        this.setState({ random: Math.random() })
    }

    render() {
        return (
            <NavBar>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Cadastrar setor
                        <HelpButton />
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className="col-lg-2">
                                <label>Status</label>
                                <select className='form-select' value={this.state.status && this.state.status} onChange={(e) => this.setState({ status: e.target.value })}>
                                    <option value='Ativo'>Ativo</option>
                                    <option value='Inativo'>Inativo</option>
                                </select>
                            </div>
                            <div className="col-lg-1">
                                <label>Vagas</label>
                                <input type="number" onChange={(e) => this.setState({ vagas: e.target.value })} value={this.state.vagas && this.state.vagas} className="form-control" required />
                            </div>
                            <div className="col-lg-3">
                                <label>Nome</label>
                                <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome && this.state.nome} className="form-control" required />
                            </div>
                            <div className="col-lg-2">
                                <label>Filtrável</label>
                                <select className='form-select' value={this.state.filtering && this.state.filtering} onChange={(e) => this.setState({ filtering: e.target.value })}>
                                    <option value='true'>Sim</option>
                                    <option value='false'>Não</option>
                                </select>
                                <div className='mt-2'>
                                    <AlertInfo message="Indica se este setor aparecerá na listagem dos tickets do pátio." />
                                </div>
                            </div>
                            <div className="col-lg-4">
                                {
                                    this.state.filtering === 'true' &&
                                    <>
                                        <label>Não contabilizar no total os status abaixo:</label>
                                        <Select
                                            isMulti
                                            name="excluded_status"
                                            value={this.state.excludedStatus}
                                            options={this.state.allStatus}
                                            onChange={this.handleExcludedStatus}
                                            className="basic-multi-select"
                                        />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='card-footer'>
                        <div className="btn-group">
                            {
                                this.state.id !== null && <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                            }
                            <button type="button" onClick={this.state.id !== null ? this.update : this.add} className="btn btn-primary">{this.state.id !== null ? 'Atualizar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <ListarSetores key={this.state.random} onEdit={this.onEdit} onRemove={this.onRemove} estacionamentoId={this.state.estacionamentoId} />
            </NavBar>
        )
    }
}

class ListarSetores extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            estacionamentoId: getEstacionamento()?.id,
            isLoading: true,
            items: []
        }
        this.loading()
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onRemove = (item) => {
        this.props.onRemove(item)
    }

    loading = async () => {
        this.setState({ isLoading: true })
        const items = await getAllSectoresByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    render() {
        return (
            <div className="card my-3">
                {
                    this.state.isLoading && <Loading />
                }
                {
                    this.state.items.length === 0 && !this.state.isLoading ? <EmptyContent text='Nenhum setor cadastrado' /> :
                        <>
                            {
                                !this.state.isLoading &&
                                <>
                                    <div className="card-header d-flex justify-content-between align-items-center">
                                        Todas os setores
                                    </div>
                                    <div className='row'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Nome</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Filtrável</th>
                                                        <th scope="col">Não contabilizar</th>
                                                        <th width="80"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items.map((item, index) =>
                                                            <tr key={index}>
                                                                <td>{item.nome}</td>
                                                                <td>{item.status}</td>
                                                                <td>{item.filtering === 'true' ? "Sim" : "Não"}</td>
                                                                <td>{item.excludedStatus?.join(', ') ?? "-"}</td>
                                                                <td>
                                                                    <div className="btn-group">
                                                                        {
                                                                            this.props.onEdit &&
                                                                            <button type="button" onClick={() => { this.onEdit(item) }} className="btn btn-secondary">
                                                                                <i className="fas fa-edit" />
                                                                            </button>
                                                                        }
                                                                        {
                                                                            this.props.onRemove &&
                                                                            <button type="button" onClick={() => { this.onRemove(item) }} className="btn btn-danger">
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            }
                        </>
                }
            </div>
        )
    }
}

export { CriarSetores, ListarSetores }