import React from 'react'
import 'firebase/auth';
import 'firebase/storage';
import { getEstacionamento } from '../../config/auth';
import Moment from 'moment';
import { isNullOrEmpty } from '../../shared/utils';
import { getParameterByParkId } from '../../store/collections/parameterWorker';
import { getContactInfoByPlateAndParkId } from '../../store/collections/contactInfoWorker';
import { getURLFromURI } from '../../store/filesWorker';

class ContactInfoCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            ticket: props.ticket,
            state: "hidden",
        }
        this.load()
    }

    load = async () => {
        if (!isNullOrEmpty(this.state.ticket.placa)) {
            const parameters = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
            if (parameters.habilitarCampoContato === true) {
                const contactInfo = await getContactInfoByPlateAndParkId({ plate: this.state.ticket.placa, estacionamentoId: this.state.estacionamentoId })
                if (contactInfo) {
                    console.log(contactInfo);
                    this.setState({ contactInfo: contactInfo })
                    this.setState({ state: "data" })
                    if (contactInfo.driverFacePicture) {
                        this.setState({ driverFacePictureUrl: "https://placehold.co/150x200" })
                        const driverFacePictureUrl = await getURLFromURI(contactInfo.driverFacePicture)
                        this.setState({ driverFacePictureUrl: driverFacePictureUrl })
                    }
                    if (contactInfo.driverLicencePicture) {
                        this.setState({ driverLicencePictureUrl: "https://placehold.co/150x200" })
                        const driverLicencePictureUrl = await getURLFromURI(contactInfo.driverLicencePicture)
                        this.setState({ driverLicencePictureUrl: driverLicencePictureUrl })
                    }
                }
            }
        }
    }

    render() {
        return (
            <>
                {
                    this.state.state === "data" &&
                    <div className="card text-white bg-success mb-3">
                        <div className="card-header">Dados do motorista</div>
                        <div className="card-body">
                            <h4 className="card-title">{this.state.contactInfo.nome}</h4>
                            {
                                !isNullOrEmpty(this.state.contactInfo.rg) &&
                                <><span className="card-text"><strong>RG:</strong> {this.state.contactInfo.rg}</span><br /> </>
                            }
                            {
                                !isNullOrEmpty(this.state.contactInfo.email) &&
                                <><span className="card-text"><strong>E-mail:</strong> {this.state.contactInfo.email}</span><br /> </>
                            }
                            {
                                !isNullOrEmpty(this.state.contactInfo.phone) &&
                                <><span className="card-text"><strong>Telefone:</strong> {this.state.contactInfo.phone}</span><br /> </>
                            }
                            {
                                !isNullOrEmpty(this.state.contactInfo.document) &&
                                <><span className="card-text"><strong>CPF:</strong> {this.state.contactInfo.document}</span><br /> </>
                            }
                            {
                                !isNullOrEmpty(this.state.contactInfo.company) &&
                                <><span className="card-text"><strong>Empresa:</strong> {this.state.contactInfo.company}</span><br /> </>
                            }
                            {
                                !isNullOrEmpty(this.state.contactInfo.department) &&
                                <><span className="card-text"><strong>Departamento:</strong> {this.state.contactInfo.department}</span><br /> </>
                            }
                            {
                                !isNullOrEmpty(this.state.contactInfo.landline) &&
                                <><span className="card-text"><strong>Telefone de contato:</strong> {this.state.contactInfo.landline}</span><br /> </>
                            }
                            {
                                !isNullOrEmpty(this.state.contactInfo.note) &&
                                <><span className="card-text"><strong>Observação:</strong> {this.state.contactInfo.note}</span><br /> </>
                            }
                            {
                                (this.state.driverFacePictureUrl || this.state.driverLicencePictureUrl) &&
                                <div className="row mt-3">
                                    {
                                        this.state.driverFacePictureUrl &&
                                        <div className="col-lg-6 col-6">
                                            <img style={{ borderRadius: "8px" }} className="w-100" alt="Foto do motorista" src={this.state.driverFacePictureUrl} />
                                        </div>
                                    }
                                    {
                                        this.state.driverLicencePictureUrl &&
                                        <div className="col-lg-6 col-6">
                                            <img className="w-100" style={{ borderRadius: "8px" }} alt="Foto da carteira" src={this.state.driverLicencePictureUrl} />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        {
                            !isNullOrEmpty(this.state.contactInfo.createdAt) && !isNullOrEmpty(this.state.contactInfo.createdBy) &&
                            <div className="card-footer">
                                <small className="card-text">{Moment(this.state.contactInfo.createdAt.seconds * 1000).format(`[Criado por ${this.state.contactInfo.createdBy.nome} em] DD/MM/YYYY [às] HH:mm:ss A`)}</small>
                            </div>
                        }
                    </div>
                }
            </>)
    }
}

export { ContactInfoCard };