import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { addDoc, deleteDoc, updateDoc } from './collectionBaseWorker';

export const getAllApplications = async () => {
    const result = await firebase.firestore().collection(Collection.APPLICATION).get()
    return docsToItems(result).sort((a, b) => a.name.localeCompare(b.name))
}

export const addApplication = async ({ data }) => {
    const doc = await addDoc({ collection: Collection.APPLICATION, data: data })
    return doc.id;
}

export const updateApplicationById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.APPLICATION, id: id, data: data })
}

export const deleteApplicationById = async ({ id }) => {
    await deleteDoc({ collection: Collection.APPLICATION, id: id })
}
