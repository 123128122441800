exports.NotesStatus = {
    RPS_CREATED: "RPS_CREATED",
    NOTE_GENERATED: "NOTE_GENERATED",
    RPS_CANCELED: "RPS_CANCELED",
    NOTE_REQUESTING_CANCELED: "NOTE_REQUESTING_CANCELED",
    NOTE_CANCELED: "NOTE_CANCELED",
    NOTE_GENERATED_ERROR: "NOTE_GENERATED_ERROR"
};

exports.ProductType = {
    SERVICES: "SERVICES",
    SELL: "SELL"
};

exports.CashierStatus = {
    OPEN: "aberto",
    CLOSE: "fechado"
};

exports.ClientStatus = {
    ACTIVE: "Ativo"
};

exports.Collection = {
    ANALYTICS: "analytics",
    ACCREDITEDS: "credenciados",
    ACCREDITEDS_TYPES: "credenciados-tipos",
    ACCESS_CARD: "accessCard",
    CLIENT: "clientes",
    NOTES: "notes",
    NOTA_FISCAL_CONFIGURACAO: "nota-fiscal-configuracao",
    CASHIER: "caixas",
    CAMERAS_LPR: "cameras-lpr",
    CAMERAS_LPR_LOGS: "cameras-lpr-logs",
    DEVICES: "devices",
    MOVEMENTS_CATEGORIES_CASHIER: "caixas-movimentacoes-categorias",
    PARK: "estacionamentos",
    PLANS: "planos",
    EQUIPMENTS: "equipamentos",
    PARAMETER: "parametros",
    PRIVATE_PARAMETER: "parametros-privados",
    PRIVATE_PARAMETERS_REVENDA: "parametros-privados-revenda",
    APPLICATION: "aplicacoes",
    REMOTE_CONFIG: "remote-config",
    TICKET: "ticket",
    TICKET_PAYMENT: "ticketPayment",
    PRODUCT: "produtos",
    PRODUCT_CATEGORIES: "produtos-categories",
    PRODUCT_SUB_CATEGORIES: "produtos-sub-categories",
    PRODUCT_INVENTORY_HISTORY: "produtos-estoque-historico",
    CHANGES: "cobrancas",
    PARTNERSHIP: "convenios",
    PRICE_TABLE: "tabela-precos",
    LICENCE: "licencas",
    LICENCE_HISTORY: "licencas-historico",
    LICENCE_PLANS: "planos-licenca",
    LAST_UPDATE: "last-update",
    QUEUE_MAILING: "queue-malling",
    NEW_PLAN_LICENCE: "planos-licenca-novo",
    NEW_PLAN_LICENCE_COUPON: "planos-licenca-novo-cupons",
    NEW_PLAN_LICENCE_CARDS: "licenca-novo-cartoes",
    NEW_SUBSCRIPTIONS_CLIENTS: "assinaturas-cliente-novo",
    USERS: "usuarios",
    RESALE: "revendas",
    VEHICLES: "veiculos",
    MONTHLIES: "mensalistas",
    MONTHLY_HISTORIES: "monthlyHistory",
    FORMAS_PAGAMENTOS: "formasPagamentos",
    SUBSCRIPTIONS_CHARGES: "subscriptions-changes",
    SUBSCRIPTION: "subscription",
    CARD_TOKENS: "card-tokens",
    POS: "pos",
    HELPDESK: "helpdesk",
    CONTACT_INFO: "contactInfo",
    NOTE_SETTINGS: "note-settings",
    NEW_NOTE: "newNotes",
    CALLBACK_QUEUE: "callback-queue",
    VEHICLE_MODELS: "vehicle-models",
    MESSAGES: "messages",
    CELLPHONE_VERIFY: "cellphone-verify",
    PRISM_SETTINGS: "prism-settings",
    PROFILES: "perfis",
    SECTORES: "setores",
    SUPPORT_CENTER: "support-center",
    TICKET_SETTINGS: "ticket-settings",
    USER_LOGS: "usuarios-logs",
    USER_TERMS: "usuarios-termos",
    NOTES_RPS_SERIES: "notes-rps-series",
    SUPPORT_ARTICLES: "support-articles",
    MARKETING_BANNER: "marketing-banner"
};

exports.WhereFieldValue = {
    ACTIVE: "Ativo",
    INACTIVE: "Inativo",
    TRUE: "true"
};

exports.WhereField = {
    ID: "id",
    STATUS: "status",
    PARK_ID: "estacionamento_id",
    PARKID: "estacionamentoId",
    PARKSIDS: "parksIds",
    EQUIPMENT_ID: "equipmentId",
    GROUP_ID: "groupId",
    X_API_KEY: "X_API_KEY",
    CLIENT_ID: "cliente_id",
    CLIENTEID: "clienteId",
    CARD_NUMBER: "cardNumber",
    CELULAR: "celular",
    CARD: "cartao",
    IDENTIFIER: "identifier",
    CAMERA_ID: "cameraId",
    CURRENT_STATUS: "currentStatus",
    REVENDAID: "revendaId",
    TRANSACTION_ID_TRANSACTION: "transaction.idTransaction",
    SUBSCRIPTION_ID_SUBSCRIPTION: "subscription.idSubscription",
    CLOSE_AT: "closeAt",
    CREATE_AT: "createAt",
    CREATED_AT: "createdAt",
    RESELLER: "revendedor",
    CODE: "codigo",
    PACKAGE_APP: "pacoteApp",
    PLACA: "placa",
    PLATE: "plate",
    DOCUMENT: "documento",
    TICKET: "ticket",
    TICKET_ID: "ticketId",
    TIPO: "tipo",
    PDVCODE: "pdvCode",
    TRANSACTION_ID: "idTransaction",
    HABILITAR_SEMPARAR: "habilitarSemParar",
    HABILITAR_PAGAMENTO_MENSALISTAS_ONLINE: "habilitarPagamentoMensalistasOnline",
    TYPE: "type",
    OPEN_AT: "openAt",
    MONTH: "month",
    YEAR: "year",
    CAIXA_FECHADO: "caixaFechado",
    NEW_NOTE_IDENTIFIER: "newNote.identifier",
    MONTHLY_ID: "monthlyId",
    MENSALISTA_ID: "mensalistaId",
    CHANGE_DATE: "changeDate",
    PENDING: "pending",
    PAYED: "payed",
    APPLICATION_ID: "applicationId",
    F360_SYNC_STATUS: "F360SyncStatus",
    ONLINE: "online",
    MESSAGE_ID: "messageId",
    UNIQUE_IDENTIFIER: "identificadorUnico",
    NAME: "name",
    LPR_IDENTIFIER: "lprIdentifier",
}

exports.TicketStatus = {
    CANCELED: "Cancelado",
    PARKING: "Estacionado",
    PAYED_AND_PARKING: "Pago e Estacionado",
    PAYED_AND_DELIVERED: "Pago e Entregue",
    PAYED_AND_WAITING_BACK: "Pago e Aguardando"
};

exports.TicketCommentStatus = {
    CANCELED: "CANCELADO",
    REVERSAL: "ESTORNADO"
};

exports.TicketTypeVehicle = {
    CAR: "carro",
    BIG_CAR: "carro_grande",
    MOTORCYCLE: "moto",
    BICYCLE: "bicicleta",
    BUS: "onibus",
    TRUNK: "caminhao",
    OTHERS: "outros",
    UNKNOWN: "nao_identificado"
};