import { getRevendaIdByPark } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { docToItem, docsToItem, docsToItems } from '../transform.docs';
import { addDoc, deleteDoc } from './collectionBaseWorker';

export const getAllCoupons = async () => {
    const result = await firebase.firestore().collection(Collection.NEW_PLAN_LICENCE_COUPON).where("revendaId", "==", getRevendaIdByPark()).get()
    return docsToItems(result)
}

export const getCouponByIdentifier = async ({ identifier }) => {
    const result = await firebase.firestore().collection(Collection.NEW_PLAN_LICENCE_COUPON).where("identifier", "==", identifier).get()
    return docsToItem(result)
}

export const getCouponById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.NEW_PLAN_LICENCE_COUPON).doc(id).get()
    return docToItem(result)
}

export const addCoupon = async (data) => {
    data.revendaId = getRevendaIdByPark()
    const doc = await addDoc({ collection: Collection.NEW_PLAN_LICENCE_COUPON, data: data })
    return doc.id;
}

export const deleteCouponById = async ({ id }) => {
    await deleteDoc({ collection: Collection.NEW_PLAN_LICENCE_COUPON, id: id })
}