import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { docToItem } from '../transform.docs';
import { deleteDoc } from './collectionBaseWorker';

export const getNewSubscriptionById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.NEW_SUBSCRIPTIONS_CLIENTS).doc(id).get()
    return docToItem(result)
}

export const deleteNewSubscriptionItem = async ({ id }) => {
    await deleteDoc({ collection: Collection.NEW_SUBSCRIPTIONS_CLIENTS, id: id })
}