import React from 'react';
import './login.css';
import firebase from '../../config/firebase';
import 'firebase/auth';
import { Redirect } from 'react-router-dom';
import { setSessao, isLogado, isAdministrador, setMinimumVersions, getUsuarioCompact, setUsuario, getUsersSuggestion } from '../../config/auth'
import { sendLastAccess } from '../../shared/user-collection';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import NumberFormat from 'react-number-format';
import { createAccount, doLogin, sendDownloadApp } from '../../view-model/conta/create-account-view-model'
import { sendError } from '../../shared/analytics';
import { getResellerIdByEnv, goToWindow, queryString, reloadWindow, searchIpInfo, toastError, toastWarning } from '../../shared/utils';
import { fetchRevendaByHost } from '../../shared/service/revendaService';
import { getURLFromURI } from '../../store/filesWorker';
import { getLastTerms } from '../../store/collections/temsWorker';
import { addTermsRead } from '../../store/collections/userTermsWorker';
import { getUserById, updateUserDate } from '../../store/collections/userWorker';
import { Toaster } from 'react-hot-toast';

class FazerLogin extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isPagginLoading: true,
            isLoading: false,
            progress: 0,
            usuario: '',
            senha: '',
            from: '',
            showDownload: false,
            redirect_to: null,
            usersSuggestion: [],
            message: ''
        }
        var email = queryString('email')
        var from = queryString('from')
        var name = queryString('name')
        var redirect_to = queryString('redirect_to')
        this.setState({ usuario: email ?? "" })
        this.setState({ name: name })
        this.setState({ redirect_to: redirect_to })
        this.setState({ from: from })
        this.loadRevenda()
    }

    loadRevenda = async () => {
        const revenda = await fetchRevendaByHost()
        if (revenda.logoAdministrativo) {
            const logoAdministrativo = await getURLFromURI(revenda.logoAdministrativo)
            this.setState({ logoAdministrativo: logoAdministrativo })
        }
        const usersSuggestion = getUsersSuggestion()
        this.setState({ usersSuggestion: usersSuggestion })
        this.setState({ revenda: revenda })
        this.setState({ isPagginLoading: false })
    }

    goToRedirect = () => {
        var from = queryString('from')
        var redirect_to = queryString('redirect_to')
        if (redirect_to !== null && from !== null) {
            window.location.href = redirect_to + '?from=' + from
            return false
        }
        if (redirect_to !== null) {
            window.location.href = redirect_to
            return false
        }
        return true
    }

    userLogged = async (sessao) => {
        await sendLastAccess()

        if (sessao.usuario.administrativo) {
            const results = await firebase.firestore().collection('remote-config').where("key", "==", "NEW_MINIMUM_VERSION").get()
            var versions = JSON.parse(results.docs[0].data()["value"])
            setMinimumVersions(versions)
        }
        if (sessao.usuario.isTermsUpdated) {
            window.location.href = "/conta/termos"
        } else if (this.goToRedirect()) {
            reloadWindow()
        }
    }

    verifyEnter = (event) => {
        if (event.key === 'Enter') {
            this.doLogin()
        }
    }

    doLogin = async () => {
        this.setState({ isLoading: true })
        this.setState({ message: '' })
        doLogin({ email: this.state.usuario, password: this.state.senha }, status => {
            this.setState({ progress: status.progress })
            this.setState({ isLoading: true })
        }, message => {
            if (message !== '') {
                toastError(message)
                this.setState({ isLoading: false })
                sendError("Login", `${this.state.usuario} | ${message}`)
            }
        }, sessao => {
            setSessao({ sessao: sessao })
            this.userLogged(sessao)
        })
    }

    changeUser = (e) => {
        if (e.target.value === "Email") {
            this.setState({ usuario: "" })
            this.setState({ senha: "" })
            this.setState({ usersSuggestion: [] })
        } else {
            this.setState({ usuario: e.target.value })
        }
    }

    render() {
        return (
            <>
                {(isLogado() && this.goToRedirect()) && <Redirect to='/home' />}
                {(isAdministrador()) && <Redirect to='/revenda/home' />}
                <Toaster />
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="image-container my-3">
                            <img height='50' src={this.state.logoAdministrativo} />
                        </div>
                        <div className="row">
                            <div className="login-content d-flex align-items-center ">
                                <form className="mx-auto col-md-7 col-sm-10">
                                    {
                                        this.state.from !== 'app' &&
                                        <h3>Acesse sua conta</h3>
                                    }
                                    {
                                        this.state.from === 'app' ?
                                            <>
                                                <h2>Olá, {this.state.name}</h2>
                                                <div className='alert alert-secondary text-center'>
                                                    Por motivos de segurança, vamos precisar que <strong>informe novamente</strong> a sua senha!
                                                </div>
                                            </> :
                                            <>
                                                {
                                                    this.state.usersSuggestion.length > 0 ?
                                                        <>
                                                            <select className="form-select my-2" value={this.state.usuario} onChange={(e) => this.changeUser(e)}>
                                                                <option value="">Selecionar operador</option>
                                                                {
                                                                    this.state.usersSuggestion.map(e => {
                                                                        return <option key={e.id} value={e.email}>{e.nome}</option>
                                                                    })
                                                                }
                                                                <option disabled>----------</option>
                                                                <option value="Email">Login por e-mail</option>
                                                            </select>
                                                        </> :
                                                        <input type="email" value={this.state.usuario} onChange={(e) => this.setState({ usuario: e.target.value })} onKeyPress={this.verifyEnter} className="form-control my-2" placeholder="E-mail" required />
                                                }
                                            </>
                                    }
                                    <input type="password" value={this.state.senha} onChange={(e) => this.setState({ senha: e.target.value })} onKeyPress={this.verifyEnter} className="form-control my-2" placeholder="Senha" required />
                                    <div className="my-2">
                                        {
                                            this.state.message !== '' && <span><strong>Oops!</strong> {this.state.message}</span>
                                        }
                                    </div>
                                    {
                                        this.state.isLoading === false ?
                                            <>
                                                <div className='my-4'>
                                                    <button onClick={this.doLogin} className="btn text-white btn-lg" style={{ backgroundColor: this.state.revenda?.siteCor }} type="button">Continuar</button>
                                                </div>
                                                {

                                                    this.state.from !== 'app' &&
                                                    <>
                                                        <div className="divider my-3" style={{ "border": `0.5px solid ${this.state.revenda?.siteCor}` }} />
                                                        <p>Ainda não é cliente <strong>{this.state.revenda?.nome}</strong>?</p>
                                                        <a href="/conta/criar-conta">
                                                            <button className="btn text-white" style={{ backgroundColor: this.state.revenda?.siteCor }} type="button">Crie sua conta</button>
                                                        </a>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                <div className={"divider-" + this.state.progress + " my-3"} style={{ backgroundColor: this.state.revenda?.siteCor }} />
                                                <div className='d-flex text-purple my-4'>
                                                    <div className="spinner-border">
                                                        <span className="sr-only" />
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                    <RightSideLogin />
                </div>
                <Dialog onClose={(e) => { this.setState({ showDownload: false }) }} maxWidth='xs' open={this.state.showDownload}>
                    <MuiDialogTitle className='text-center'>
                        Atenção
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <h6 className='text-center'>Percebemos que você começou a criar uma conta, mas não finalizou. Por favor, abra o nosso aplicativo e termine o seu cadastro.</h6>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='col-lg-12 text-center'>
                            <a href="https://play.google.com/store/apps/details?id=com.selfparking.app" rel="noopener noreferrer" target="_blank">
                                <img width='200' alt='Baixar aplicativo' src={require('../../files/icons/google-play-badge.png')} />
                            </a>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

class RightSideLogin extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isPagginLoading: true
        }
    }

    componentDidMount = async () => {
        const revenda = await fetchRevendaByHost()
        this.setState({ revenda: revenda })
        this.setState({ whatsAppSuporte: this.state.revenda?.whatsAppSuporte?.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "") })
        this.setState({ isPagginLoading: false })
    }

    render() {
        return (
            <>
                {
                    !this.state.isPagginLoading &&
                    <div className='col-lg-6 pos-container' style={{ backgroundColor: this.state.revenda?.siteCor }}>
                        <div className="row">
                            <div className="promotion-content" style={{ backgroundColor: this.state.revenda?.siteCor }}>
                                <div className="row">
                                    <span className='span-item'><h4 className='h2-item'>Aproveite o máximo - <strong>{this.state.revenda?.nome}</strong>!</h4> <p />Tenha o nosso aplicativo diretamente no seu POS de pagamentos, faça entrada do veículo, pagamento e impressão do ticket em um só lugar.</span>
                                </div>
                                <div className="row">
                                    {
                                        this.state.revenda?.posCielo &&
                                        <div className="col-lg-6 pos-cielo">
                                            <a href={`https://wa.me/55${this.state.whatsAppSuporte}`} rel="noopener noreferrer" target="_blank">
                                                <img alt='POS Cielo' src={require('../../files/pos/cielo.png')} />
                                            </a>
                                        </div>
                                    }
                                    {
                                        this.state.revenda?.posStone &&
                                        <div className="col-lg-6 pos-stone">
                                            <a href={`https://wa.me/55${this.state.whatsAppSuporte}`} rel="noopener noreferrer" target="_blank">
                                                <img alt='POS Stone' src={require('../../files/pos/stone.png')} />
                                            </a>
                                        </div>
                                    }
                                    {
                                        this.state.revenda?.posSafra &&
                                        <div className="col-lg-6 pos-safra">
                                            <a href={`https://wa.me/55${this.state.whatsAppSuporte}`} rel="noopener noreferrer" target="_blank">
                                                <img alt='POS Safra' src={require('../../files/pos/safra.png')} />
                                            </a>
                                        </div>
                                    }
                                    {
                                        this.state.revenda?.posRede &&
                                        <div className="col-lg-6 pos-rede">
                                            <a href={`https://wa.me/55${this.state.whatsAppSuporte}`} rel="noopener noreferrer" target="_blank">
                                                <img alt='POS Rede' src={require('../../files/pos/rede.png')} />
                                            </a>
                                        </div>
                                    }
                                    {
                                        this.state.revenda?.posGetnet &&
                                        <div className="col-lg-6 pos-getnet">
                                            <a href={`https://wa.me/55${this.state.whatsAppSuporte}`} rel="noopener noreferrer" target="_blank">
                                                <img alt='POS GetNet' src={require('../../files/pos/getnet.png')} />
                                            </a>
                                        </div>
                                    }
                                    {
                                        this.state.revenda?.posPagSeguro &&
                                        <div className="col-lg-6 pos-pagseguro">
                                            <a href={`https://wa.me/55${this.state.whatsAppSuporte}`} rel="noopener noreferrer" target="_blank">
                                                <img alt='POS PagSeguro' src={require('../../files/pos/pagseguro.png')} />
                                            </a>
                                        </div>
                                    }
                                </div>
                                <div className='row'>
                                    <small className='small-item'>Caso tenha alguma dúvida ou precise de ajuda para configurar o <strong>{this.state.revenda?.nome}</strong> no seu POS nos mande uma mensagem no nosso WhatsApp: <strong><a rel="noopener noreferrer" target="_blank" className='a-item' href={`https://api.whatsapp.com/send?phone=55${this.state.revenda?.whatsAppSuporte.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll("_", "")}`}>{this.state.revenda?.whatsAppSuporte?.replaceAll("_", "")}</a></strong> <p /><p /> Esta perdido? Conheça mais sobre o <strong>{this.state.revenda?.nome}</strong> clicando aqui: <strong><a rel="noopener noreferrer" target="_blank" className='a-item' href={this.state.revenda?.site}>{this.state.revenda?.site?.replaceAll("https://", "")}</a></strong></small>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }
}

class CriarConta extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: 'normal',
            email: '',
            password: '',
            name: 'Representante',
            phone: '',
            park: '',
            document: '',
            message: '',
            status: '',
            comoConheceu: 'Google',
            smsState: 'normal',
            smsMessage: '',
            showResselesCode: false
        }
        const code = queryString("code")
        if (code) {
            this.setState({ resaleCode: code })
            this.setState({ showResselesCode: true })
        }
        this.loadRevenda()
    }

    loadRevenda = async () => {
        const revenda = await fetchRevendaByHost()
        if (revenda.logoAdministrativo) {
            const logoAdministrativo = await getURLFromURI(revenda.logoAdministrativo)
            this.setState({ logoAdministrativo: logoAdministrativo })
            this.setState({ revendaId: revenda.id })
        }
        this.setState({ revenda: revenda })
        this.setState({ isPagginLoading: false })
    }

    verifyEnter = (event) => {
        if (event.key === 'Enter') {
            this.doCreate()
        }
    }

    doCreate = async () => {
        this.setState({ state: 'loading' })
        this.setState({ isCreatingAccount: true })
        await createAccount({
            nome: this.state.park,
            email: this.state.email,
            phone: this.state.phone,
            park: this.state.park,
            document: this.state.document,
            password: this.state.password,
            revendaId: this.state.revendaId,
            comoConheceu: this.state.comoConheceu,
            resaleCode: this.state.resaleCode,
        }, status => {
            this.setState({ state: 'creating-account' })
            this.setState({ status: status.text })
            this.setState({ progress: status.progress })
        }, error => {
            toastError(error)
            this.setState({ message: "" })
            this.setState({ state: 'normal' })
        }, completed => {
            this.execLogin()
        })
    }

    userLogged = async () => {
        await sendLastAccess()
    }

    showResselesCode = () => {
        this.setState({ showResselesCode: true })
    }

    execLogin = async () => {
        doLogin({ email: this.state.email, password: this.state.password }, status => {
            this.setState({ isLoading: true })
        }, message => {
        }, sessao => {
            setSessao({ sessao: sessao })
            this.userLogged()
            this.acceptTerms()
            window.location.href = "/conta/bem-vindo"
        })
    }

    acceptTerms = async () => {
        const revendaId = getResellerIdByEnv()
        const terms = await getLastTerms({ revendaId: revendaId })
        const info = await searchIpInfo()
        await addTermsRead({
            termsId: terms?.id,
            info: info
        })
    }

    render() {
        return (
            <>
                <Toaster />
                {(isLogado()) && <Redirect to='/home' />}
                {(isAdministrador()) && <Redirect to='/revenda/home' />}
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="image-container my-3">
                            <img height='50' src={this.state.logoAdministrativo} />
                        </div>
                        <div className="row">
                            <div className="login-content d-flex align-items-center ">
                                <form className="mx-auto col-md-9 col-sm-10">
                                    {
                                        this.state.state === 'normal' &&
                                        <>
                                            <h3>Crie sua conta</h3>
                                            <h6 className='text-secondary'>Somos um sistema que vai te ajudar a gerênciar melhor o seu <strong>estacionamentos, valets ou lava-rápidos.</strong></h6>
                                            <label className="my-2">E-mail</label>
                                            <input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className="form-control" />
                                            <label className="my-2">Senha</label>
                                            <input type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} className="form-control" />
                                            <label className="my-2">Celular</label>
                                            <NumberFormat className="form-control" value={this.state.phone} mask='_' onChange={(e) => this.setState({ phone: e.target.value })} format="(##)#####-####" />
                                            <label className="my-2">Nome do estabelecimento</label>
                                            <input type="text" value={this.state.park} onChange={(e) => this.setState({ park: e.target.value })} className="form-control" />
                                            <label className="my-2">CNPJ ou CPF</label>
                                            <input type="text" value={this.state.document} onChange={(e) => this.setState({ document: e.target.value })} onKeyPress={this.verifyEnter} className="form-control" />
                                            <label className="my-2">Como nos conheceu?</label>
                                            <select className='form-select' value={this.state.comoConheceu} onChange={(e) => this.setState({ comoConheceu: e.target.value })}>
                                                <option value="">Selecione</option>
                                                <option value="Google">Google</option>
                                                <option value="Facebook">Facebook</option>
                                                <option value="Instagram">Instagram</option>
                                                <option value="Indicação de amigo">Indicação de amigo</option>
                                                <option value="Rádio">Rádio</option>
                                                <option value="Outros">Outros</option>
                                            </select>
                                            {
                                                process.env.REACT_APP_REVENDA_ID === "yDDlIMYGASii6Scv1Un8" && <>
                                                    {
                                                        this.state.showResselesCode ? <>
                                                            <label className="my-2">Informe o código da revenda</label>
                                                            <input type="text" maxLength={6} value={this.state.resaleCode} onChange={(e) => this.setState({ resaleCode: e.target.value })} onKeyPress={this.verifyEnter} className="form-control" />
                                                        </> :
                                                            <div className='mt-2' role='button' onClick={this.showResselesCode} >
                                                                <strong className="text-purple" >Eu tenho um código de revenda!</strong>
                                                                <i className="fas fa-gift text-purple mx-2" />
                                                            </div>
                                                    }
                                                </>
                                            }
                                            <div className="my-2 text-warning">
                                                {
                                                    this.state.message !== '' && <span><strong>Oops!</strong> {this.state.message}</span>
                                                }
                                            </div>
                                            <a href={this.state.revenda?.sitePrivacidade} target="_blank">
                                                <span>Clicando no botão abaixo você aceita nosso <strong>termo de uso</strong> e <strong>política de privacidade</strong>.</span>
                                            </a>
                                            <div className='my-4'>
                                                <button onClick={this.doCreate} style={{ backgroundColor: this.state.revenda?.siteCor }} className="btn text-white btn-lg" type="button">Criar uma conta grátis!</button>
                                            </div>
                                            <div className="divider my-3" style={{ "border": `0.5px solid ${this.state.revenda?.siteCor}` }} />
                                            <a href="/">
                                                <button className="btn btn-light" type="button">Voltar</button>
                                            </a>
                                        </>
                                    }
                                    {
                                        this.state.state === 'creating-account' &&
                                        <>
                                            <h3>Aguarde</h3> <p />
                                            <span>{this.state.status}</span>
                                            <div className={"divider-" + this.state.progress + " my-3"} style={{ backgroundColor: this.state.revenda?.siteCor }} />
                                            <div className='d-flex text-purple my-4'>
                                                <div className="spinner-border">
                                                    <span className="sr-only" />
                                                </div>
                                            </div>
                                        </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                    <RightSideLogin />
                </div>
                <Dialog onClose={(e) => { this.setState({ showDownload: false }) }} maxWidth='xs' open={this.state.showDownload}>
                    <MuiDialogTitle className='text-center'>
                        Atenção
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <h6 className='text-center'>Percebemos que você começou a criar uma conta, mas não finalizou. Por favor, abra o nosso aplicativo e termine o seu cadastro.</h6>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='col-lg-12 text-center'>
                            <a href="https://play.google.com/store/apps/details?id=com.selfparking.app" rel="noopener noreferrer" target="_blank">
                                <img width='200' alt='Baixar aplicativo' src={require('../../files/icons/google-play-badge.png')} />
                            </a>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

class ContaCriada extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            smsState: 'normal'
        }
        this.loadRevenda()
    }

    sendWhatsApp = async () => {
        this.setState({ smsState: "normal" })
        await sendDownloadApp({
            phone: this.state.phone
        }, loading => {
            this.setState({ smsState: "loading" })
        }, message => {
            this.setState({ smsState: "success" })
            this.setState({ smsMessage: message })
        })
    }

    loadRevenda = async () => {
        const revenda = await fetchRevendaByHost()
        if (revenda.logoAdministrativo) {
            const logoAdministrativo = await getURLFromURI(revenda.logoAdministrativo)
            this.setState({ logoAdministrativo: logoAdministrativo })
        }
        this.setState({ revenda: revenda })
        this.setState({ isPagginLoading: false })
    }

    doLogin = () => {
        window.location.href = "/home"
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="image-container my-3">
                            <img width='80' height='80' src={this.state.logoAdministrativo} />
                        </div>
                        <div className="row">
                            <div className="login-content d-flex align-items-center ">
                                <form className="mx-auto col-md-7 col-sm-10">
                                    <div className='my-4'>
                                        <h4>Bem vindo ao <strong>{this.state.revenda?.nome}</strong></h4>
                                        <span>Em breve entraremos em contato para te ajudar a configurar o seu estabelecimento do seu jeito.</span> <p />
                                    </div>
                                    {
                                        this.state.revenda?.posGooglePlay && <>
                                            {
                                                this.state.smsState === "normal" && <>
                                                    <span>Se preferir você pode receber o aplicativo via WhatsApp:</span>
                                                    <div className="row my-2">
                                                        <div className='col-md-9 col-lg-8 col-sm-12'>
                                                            <NumberFormat className="form-control" value={this.state.phone} mask='_' onChange={(e) => this.setState({ phone: e.target.value })} format="(##)#####-####" />
                                                            <button onClick={this.sendWhatsApp} className="btn btn-purple my-2" style={{ backgroundColor: this.state.revenda?.siteCor }} type="button">Receber aplicativo</button>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                this.state.smsState === "loading" && <>
                                                    <div className='d-flex text-purple my-4'>
                                                        <div className="spinner-border">
                                                            <span className="sr-only" />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {
                                                this.state.smsState === "success" && <>
                                                    <div className="my-2">
                                                        <h6 className="text-purple">{this.state.smsMessage}</h6>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }
                                    <div className='my-4'>
                                        <a href="/">
                                            <button className="btn btn-light" onClick={this.doLogin} type="button">Acessar</button>
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <RightSideLogin />
                </div>
            </>
        )
    }
}

class TermsUpdated extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            isPagginLoading: true,
            isTermsAccepted: false,
            message: undefined
        }
        this.load()
    }

    load = async () => {
        this.loadRevenda()
        this.loadTermsToSign()
    }

    loadRevenda = async () => {
        const revenda = await fetchRevendaByHost()
        if (revenda.logoAdministrativo) {
            const logoAdministrativo = await getURLFromURI(revenda.logoAdministrativo)
            this.setState({ logoAdministrativo: logoAdministrativo })
            this.setState({ revenda: revenda })
        }
    }

    loadTermsToSign = async () => {
        const userCompact = getUsuarioCompact()
        if (userCompact) {
            const user = await getUserById({ id: userCompact.id })
            this.setState({ user: user })
            if (!user.isTermsUpdated) {
                window.location.href = "/home"
            } else {
                this.setState({ name: user.nome })
            }
        } else {
            window.location.href = "/home"
        }
    }

    acceptTerms = async () => {
        this.setState({ message: undefined })
        if (!this.state.isTermsAccepted) {
            this.setState({ message: 'Você precisa confirmar que leu e esta de acordo com os nossos termos de uso e política de privacidade acima.' })
        } else {
            this.setState({ isLoading: true })
            const revendaId = getResellerIdByEnv()
            const currentUser = this.state.user
            currentUser.isTermsUpdated = false
            const terms = await getLastTerms({ revendaId: revendaId })
            const info = await searchIpInfo()
            await addTermsRead({
                termsId: terms?.id,
                info: info
            })
            await updateUserDate({ id: currentUser.id, data: currentUser })
            setUsuario(currentUser)
            window.location.href = "/home"
        }
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="image-container my-3">
                            <img height='50' src={this.state.logoAdministrativo} />
                        </div>
                        <div className="row">
                            <div className="login-content d-flex align-items-center">
                                <form className="mx-auto col-md-10 col-sm-10">
                                    <h2>Olá, {this.state.name}</h2>
                                    <div className='alert alert-warning'>
                                        Antes de continuarmos você deve ler os nossos termos de uso e a nossa política de privacidade.<p />
                                        <a href={this.state.revenda?.sitePrivacidade} target="_blank"><strong>Termos de uso</strong></a><br />
                                        <a href={this.state.revenda?.sitePrivacidade} target="_blank"><strong>Política de Privacidade</strong></a>
                                    </div>
                                    {
                                        this.state.message &&
                                        <div className='alert alert-danger mt-2'>
                                            {this.state.message}
                                        </div>
                                    }
                                    <div className="form-check form-switch my-2">
                                        <input className="form-check-input" id="isTermsAccepted" checked={this.state.isTermsAccepted} onChange={(e) => this.setState({ isTermsAccepted: e.target.checked })} type="checkbox" />
                                        <label className="form-check-label" htmlFor="isTermsAccepted">
                                            Confirmo que estou de acordo com os termos lidos acima.
                                        </label>
                                    </div>
                                    {
                                        this.state.isLoading === false ?
                                            <>
                                                <div className='my-4'>
                                                    <button onClick={this.acceptTerms} className="btn btn-light" type="button">Continuar</button>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className={"divider-" + this.state.progress + " my-3"} style={{ backgroundColor: this.state.revenda?.siteCor }} />
                                                <div className='d-flex text-purple my-4'>
                                                    <div className="spinner-border">
                                                        <span className="sr-only" />
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                    <RightSideLogin />
                </div>
            </>
        )
    }
}

class EncerrarConta extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            state: 'normal'
        }
        this.loadRevenda()
    }

    loadRevenda = async () => {
        const revenda = await fetchRevendaByHost()
        if (revenda.logoAdministrativo) {
            const logoAdministrativo = await getURLFromURI(revenda.logoAdministrativo)
            // emailTemplateGenerico
            this.setState({ logoAdministrativo: logoAdministrativo })
        }
        this.setState({ revenda: revenda })
        this.setState({ isPagginLoading: false })
    }

    doRemove = async () => {
        if (!this.state.email || !this.state.document) {
            toastWarning("Informe o e-mail e documento da conta.")
            return
        }
        this.setState({ state: 'loading' })
        const body = {
            to: "parceiros@selfparking.com.br",
            template_id: this.state.revenda.emailTemplateGenerico,
            data: {
                title: "Encerramento de conta",
                email: this.state.email,
                document: this.state.document
            }
        }
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: body
        };
        // const result = await fetchFunction("/sendEmail", options)
        // if (result.success) {
        toastWarning("Seu pedido de encerramento de conta foi registrado com sucesso, nossa equipe irá executar as tratativas.")
        goToWindow("/")
        // } else {
        //     reloadWithAlert("Tente novamente")
        // }
    }

    render() {
        return (
            <>
                {(isLogado()) && <Redirect to='/home' />}
                {(isAdministrador()) && <Redirect to='/revenda/home' />}
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className="image-container my-3">
                            <img height='50' src={this.state.logoAdministrativo} />
                        </div>
                        <div className="row">
                            <div className="login-content d-flex align-items-center ">
                                <form className="mx-auto col-md-9 col-sm-10">
                                    {
                                        this.state.state === 'normal' &&
                                        <>
                                            <h3>Remover conta</h3>
                                            <h6 className='text-secondary'>Somos um sistema que vai te ajudar a gerênciar melhor o seu estacionamentos, valets ou lava-rápidos.</h6>
                                            <h6 className='text-secondary'>Deseja mesmo encerrar a sua conta e remover os dados de todos os estabelecimentos?</h6>
                                            <label className="my-2">E-mail</label>
                                            <input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} className="form-control" />
                                            <label className="my-2">Documento</label>
                                            <input type="text" value={this.state.document} onChange={(e) => this.setState({ document: e.target.value })} className="form-control" />
                                            <div className='my-4'>
                                                <button onClick={this.doRemove} style={{ backgroundColor: this.state.revenda?.siteCor }} className="btn text-white btn-lg" type="button">Remover minha conta!</button>
                                            </div>
                                            <div className="divider my-3" style={{ "border": `0.5px solid ${this.state.revenda?.siteCor}` }} />
                                            <a href="/">
                                                <button className="btn btn-light" type="button">Voltar</button>
                                            </a>
                                        </>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                    <RightSideLogin />
                </div>
                <Dialog onClose={(e) => { this.setState({ showDownload: false }) }} maxWidth='xs' open={this.state.showDownload}>
                    <MuiDialogTitle className='text-center'>
                        Atenção
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <h6 className='text-center'>Percebemos que você começou a criar uma conta, mas não finalizou. Por favor, abra o nosso aplicativo e termine o seu cadastro.</h6>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='col-lg-12 text-center'>
                            <a href="https://play.google.com/store/apps/details?id=com.selfparking.app" rel="noopener noreferrer" target="_blank">
                                <img width='200' alt='Baixar aplicativo' src={require('../../files/icons/google-play-badge.png')} />
                            </a>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

export { FazerLogin, CriarConta, ContaCriada, TermsUpdated, RightSideLogin, EncerrarConta };