import firebase from '../../config/firebase';
import { getUsuarioCompact } from '../../config/auth';
import { docsToItem, docsToItems, docToItem } from '../transform.docs';
import { addDoc, deleteDoc, updateDoc } from './collectionBaseWorker';
import { Collection } from '../../shared/constants';

export const getAllAccessCardsByClientId = async ({ clienteId }) => {
    const result = await firebase.firestore().collection(Collection.ACCESS_CARD).where('clienteId', '==', clienteId).get()
    return docsToItems(result)
}

export const getAllAccessCardsByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.ACCESS_CARD).where('estacionamentoId', '==', estacionamentoId).get()
    return docsToItems(result)
}

export const getAccessCardById = async ({ id }) => {
    const result = await firebase.firestore().collection(Collection.ACCESS_CARD).doc(id).get()
    return docToItem(result)
}

export const getAllAccessCardsByReferenceId = async ({ referenceId, estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.ACCESS_CARD).where('estacionamentoId', '==', estacionamentoId).where('referenceId', '==', referenceId).get()
    return docsToItems(result)
}

export const getAccessCardByParkIdAndNumber = async ({ estacionamentoId, accessCard }) => {
    const result = await firebase.firestore().collection(Collection.ACCESS_CARD).where('estacionamentoId', '==', estacionamentoId).where('cardNumber', '==', parseInt(accessCard).toString()).get()
    return docsToItem(result)
}

export const getAllAccessCardsOfAccreditedByParkId = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.ACCESS_CARD).where('estacionamentoId', '==', estacionamentoId).where('type', '==', "accredited").get()
    return docsToItems(result)
}

export const addAccessCard = async ({ clienteId, estacionamentoId, referenceId, type, accessCard }) => {
    const data = {
        clienteId: clienteId,
        estacionamentoId: estacionamentoId,
        referenceId: referenceId,
        type: type,
        cardNumber: parseInt(accessCard).toString(),
        createdAt: new Date(),
        createdBy: getUsuarioCompact(),
        isDeleted: false,
    }
    const doc = await addDoc({ collection: Collection.ACCESS_CARD, data: data })
    return doc.id;
}

export const updateAccessCard = async ({ id, data }) => {
    await updateDoc({ collection: Collection.ACCESS_CARD, id: id, data: data })
}

export const removeAccessCard = async ({ id }) => {
    var data = await getAccessCardById({ id: id })
    data.isDeleted = true
    await deleteDoc({ collection: Collection.ACCESS_CARD, id: id })
}
