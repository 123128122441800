import React from 'react'
import { NavBar } from '../../components/navbar'
import firebase from '../../config/firebase';
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario, isAdministrador } from '../../config/auth'
import { EmptyContent } from '../../components/common/commons'
import { Loading } from '../../components/common/commons'
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import VideoPlayer from 'react-video-js-player';
import { Link } from 'react-router-dom';
import { sendClickButton } from '../../shared/analytics'
import Moment from 'moment';
import 'firebase/auth';
import 'firebase/storage';
import { copyLinkAndShare, toastError, toastSuccess, toastWarning } from '../../shared/utils';
import { Collection } from '../../shared/constants';
import { addDoc } from '../../store/collections/collectionBaseWorker';

const initialState = {
    id: null,
    nome: '',
    descricao: '',
    status: true,
    categorias: '',
    caminhoPDF: '',
    caminhoCelular: '',
    caminhoAdministrativo: '',
    analytics: [],
    articleSelected: null,
    isOpenAnalytics: false,
    random: Math.random()
};

class CriarSupportArticle extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    reset = () => {
        this.setState({ ...initialState, random: Math.random() })
    }

    updateSupport = () => {
        if (this.checkFields()) {
            toastWarning("Preencha o nome")
            return
        }
        firebase.firestore().collection('support-articles').doc(this.state.id).update({
            nome: this.state.nome,
            descricao: this.state.descricao,
            status: this.state.status,
            categorias: this.state.categorias,
            caminhoPDF: this.state.caminhoPDF !== undefined ? this.state.caminhoPDF : "",
            caminhoCelular: this.state.caminhoCelular !== undefined ? this.state.caminhoCelular : "",
            caminhoAdministrativo: this.state.caminhoAdministrativo !== undefined ? this.state.caminhoAdministrativo : "",
            alteredAt: new Date(),
            alteredBy: getUsuario().email
        }).then(() => {
            toastSuccess("Artigo atualizado com sucesso!")
            this.reset()
        }).catch(function (error) {
            toastError(error.message);
        });
    }

    addSupport = async () => {
        if (this.checkFields()) {
            toastSuccess("Preencha o nome")
            return
        }
        const data = {
            revendaId: getRevendaIdByPark(),
            nome: this.state.nome,
            descricao: this.state.descricao,
            status: this.state.status,
            categorias: this.state.categorias,
            caminhoPDF: this.state.caminhoPDF !== undefined ? this.state.caminhoPDF : "",
            caminhoCelular: this.state.caminhoCelular !== undefined ? this.state.caminhoCelular : "",
            caminhoAdministrativo: this.state.caminhoAdministrativo !== undefined ? this.state.caminhoAdministrativo : "",
            analytics: [],
            createdAt: new Date(),
            createdBy: getUsuario().email,
            isDeleted: false,
        }
        await addDoc({ collection: Collection.SUPPORT_ARTICLES, data: data })
        toastWarning("Artigo cadastrado com sucesso!")
        this.reset()
    }

    onEdit = (item) => {
        this.setState({ id: item.id })
        this.setState({ nome: item.nome })
        this.setState({ descricao: item.descricao })
        this.setState({ status: item.status })
        this.setState({ categorias: item.categorias })
        this.setState({ caminhoPDF: item.caminhoPDF })
        this.setState({ caminhoCelular: item.caminhoCelular })
        this.setState({ caminhoAdministrativo: item.caminhoAdministrativo })
    }

    onDuplicate = (item) => {
        this.setState({ id: null })
        this.setState({ nome: item.nome })
        this.setState({ descricao: item.descricao })
        this.setState({ status: item.status })
        this.setState({ categorias: item.categorias })
        this.setState({ caminhoPDF: item.caminhoPDF })
        this.setState({ caminhoCelular: item.caminhoCelular })
        this.setState({ caminhoAdministrativo: item.caminhoAdministrativo })
    }

    showAnalytics = (item) => {
        this.setState({ articleSelected: item })
        this.setState({ analytics: item.analytics === undefined ? [] : item.analytics })
        this.setState({ isOpenAnalytics: true })
    }

    closeAnalytics = () => {
        this.setState({ isOpenAnalytics: false })
    }

    checkFields = () => {
        return (this.state.nome === '')
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Cadastrar artigo de suporte
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className='row'>
                                                <div className="col-lg-12">
                                                    <label>Nome</label>
                                                    <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Status</label>
                                                    <select value={this.state.status && this.state.status} onChange={(e) => this.setState({ status: e.target.value })} className="form-control">
                                                        <option value={true}>Ativo</option>
                                                        <option value={false}>Inativo</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Categorias</label>
                                                    <input type="text" onChange={(e) => this.setState({ categorias: e.target.value })} value={this.state.categorias} className="form-control" />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Descrição</label>
                                                    <textarea rows='5' onChange={(e) => this.setState({ descricao: e.target.value })} value={this.state.descricao} className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className="col-lg-12">
                                                <label>Artigo em PDF</label>
                                                <input type="text" onChange={(e) => this.setState({ caminhoPDF: e.target.value })} value={this.state.caminhoPDF} className="form-control" />
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Vídeo celular</label>
                                                <input type="text" onChange={(e) => this.setState({ caminhoCelular: e.target.value })} value={this.state.caminhoCelular} className="form-control" />
                                            </div>
                                            <div className="col-lg-12">
                                                <label>Vídeo administrativo</label>
                                                <input type="text" onChange={(e) => this.setState({ caminhoAdministrativo: e.target.value })} value={this.state.caminhoAdministrativo} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card-footer'>
                                    <div className='btn-group'>
                                        <button type="button" onClick={(e) => { this.state.id ? this.updateSupport() : this.addSupport() }} className="btn btn-primary">{this.state.id ? 'Atualizar' : 'Cadastrar'}</button>
                                        {
                                            this.state.id && <button type="button" onClick={this.reset} className="btn btn-default">Cancelar</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <ListarSupportArticle key={this.state.random} estacionamentoId={this.state.estacionamentoId} onEdit={this.onEdit} onDuplicate={this.onDuplicate} onAnalytics={this.showAnalytics} />
                        </div>
                    </div>
                    <Dialog onClose={this.closeAnalytics} maxWidth='lg' open={this.state.isOpenAnalytics}>
                        <MuiDialogContent>
                            {
                                this.state.analytics.length === 0 &&
                                <EmptyContent text="Nenhum métrica capturada até o momento." />
                            }
                            {
                                this.state.analytics.length !== 0 &&
                                <div className='row'>
                                    <div className='col-lg-12 text-center my-3'>
                                        <h3>Métricas do artigo '{this.state.articleSelected.nome}'</h3>
                                        <div className='row'>
                                            <div className='col-lg-2'>
                                                <div>
                                                    <h1>{this.state.analytics.filter(e => e.metrica === "like").length}</h1>
                                                </div>
                                                <div>
                                                    <h1 className="badge text-bg-success btn-block">Curtiu</h1>
                                                </div>
                                            </div>
                                            <div className='col-lg-2'>
                                                <div>
                                                    <h1>{this.state.analytics.filter(e => e.metrica === "dislike").length}</h1>
                                                </div>
                                                <div>
                                                    <h1 className="badge text-bg-danger btn-block">Não curtiu</h1>
                                                </div>
                                            </div>
                                            <div className='col-lg-2'>
                                                <div>
                                                    <h1>{this.state.analytics.filter(e => e.metrica === "shared").length}</h1>
                                                </div>
                                                <div>
                                                    <h1 className="badge text-bg-success btn-block">Compartilhou</h1>
                                                </div>
                                            </div>
                                            <div className='col-lg-2'>
                                                <div>
                                                    <h1>{this.state.analytics.filter(e => e.metrica === "view-pdf").length}</h1>
                                                </div>
                                                <div>
                                                    <h1 className="badge text-bg-warning btn-block">Visualizou o PDF</h1>
                                                </div>
                                            </div>
                                            <div className='col-lg-2'>
                                                <div>
                                                    <h1>{this.state.analytics.filter(e => e.metrica === "view-link").length}</h1>
                                                </div>
                                                <div>
                                                    <h1 className="badge text-bg-warning btn-block">Visualizou o link</h1>
                                                </div>
                                            </div>
                                            <div className='col-lg-2'>
                                                <div>
                                                    <h1>{this.state.analytics.filter(e => e.metrica === "view-cell-video").length}</h1>
                                                </div>
                                                <div>
                                                    <h1 className="badge text-bg-warning btn-block">Visualizou o celular</h1>
                                                </div>
                                            </div>
                                            <div className='col-lg-2'>
                                                <div>
                                                    <h1>{this.state.analytics.filter(e => e.metrica === "view-adm-video").length}</h1>
                                                </div>
                                                <div>
                                                    <h1 className="badge text-bg-warning btn-block">Visualizou o administrativo</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-12'>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Estacionamento</th>
                                                    <th scope="col">Usuário</th>
                                                    <th scope="col">E-mail</th>
                                                    <th scope="col">Data</th>
                                                    <th scope="col">Métrica</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.analytics.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{item.estacionamentoNome}</td>
                                                            <td>{item.user.email}</td>
                                                            <td>{item.user.nome}</td>
                                                            <td>{Moment(item.createdAt.toDate()).format("DD/MM/YYYY HH:mm")}</td>
                                                            <td>
                                                                {item.metrica === 'like' && <span className="badge text-bg-secondary btn-block">Curtiu</span>}
                                                                {item.metrica === 'shared' && <span className="badge text-bg-secondary btn-block">Compartilhou</span>}
                                                                {item.metrica === 'dislike' && <span className="badge text-bg-secondary btn-block">Não curtiu</span>}
                                                                {item.metrica === 'view-pdf' && <span className="badge text-bg-secondary btn-block">Visualizou o PDF</span>}
                                                                {item.metrica === 'view-link' && <span className="badge text-bg-secondary btn-block">Visualizou o link</span>}
                                                                {item.metrica === 'view-cell-video' && <span className="badge text-bg-secondary btn-block">Visualizou o celular</span>}
                                                                {item.metrica === 'view-adm-video' && <span className="badge text-bg-secondary btn-block">Visualizou o administrativo</span>}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <button onClick={this.closeAnalytics} className='btn btn-info'>Fechar</button>
                        </MuiDialogActions>
                    </Dialog>
                </NavBar>
            </>
        )
    }
}

class AnalyticsSupportArticle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            analytics: [],
            loading: 1
        }
    }

    componentDidMount() {
        this.loading()
    }

    loading = () => {
        this.setState({ loading: 1 })
        // .orderBy("lastAccess", "desc")
        firebase.firestore().collection('support-articles').limit(5).get().then(async (results) => {
            this.setState({ loading: 0 })
            var list = [];
            results.docs.forEach(doc => {
                list.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            const items = list.flatMap(e => e.analytics).filter((item, index) => item !== undefined && index < 7)
            this.setState({ analytics: items })
        }).catch(error => {
            toastError(error.message)
            this.setState({ loading: 0 })
        })
    }

    render() {
        return (
            <div className="col-lg-12 card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Interação em artigos
                </div>
                <div className="card-body py-3">
                    {
                        this.state.loading === 0 && this.state.analytics.length === 0 &&
                        <EmptyContent text="Nenhuma interação até o momento" />
                    }
                    {
                        (this.state.loading === 0 && this.state.analytics.length !== 0) &&
                        <div className='row'>
                            <div className='table-responsive'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col">Estacionamento</th>
                                            <th scope="col">Usuário</th>
                                            <th scope="col">Data</th>
                                            <th scope="col">Métrica</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.analytics.map((item, index) =>
                                                <tr key={index}>
                                                    <Link to={'/treinamento-online?artigo=' + item.articleId}>
                                                        <button type="button" className="btn btn-warning text-white my-2">
                                                            <i className="fas fa-eye" />
                                                        </button>
                                                    </Link>
                                                    <td>{item.estacionamentoNome}</td>
                                                    <td>{item.user.nome}</td>
                                                    <td>{Moment(item.createdAt.toDate()).format("DD/MM/YYYY HH:mm")}</td>
                                                    <td>
                                                        {item.metrica === 'like' && <span className="badge text-bg-secondary btn-block">Curtiu</span>}
                                                        {item.metrica === 'shared' && <span className="badge text-bg-secondary btn-block">Compartilhou</span>}
                                                        {item.metrica === 'dislike' && <span className="badge text-bg-secondary btn-block">Não curtiu</span>}
                                                        {item.metrica === 'view-pdf' && <span className="badge text-bg-secondary btn-block">Visualizou o PDF</span>}
                                                        {item.metrica === 'view-link' && <span className="badge text-bg-secondary btn-block">Visualizou o link</span>}
                                                        {item.metrica === 'view-cell-video' && <span className="badge text-bg-secondary btn-block">Visualizou o celular</span>}
                                                        {item.metrica === 'view-adm-video' && <span className="badge text-bg-secondary btn-block">Visualizou o administrativo</span>}

                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        this.state.loading === 1 &&
                        <Loading />
                    }
                </div>
            </div>
        )
    }
}

class ListarSupportArticle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            loading: 1
        }
        this.loading()
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onDuplicate = (item) => {
        this.props.onDuplicate(item)
    }

    onAnalytics = (item) => {
        this.props.onAnalytics(item)
    }

    share = (item) => {
        const url = window.location.host + "/treinamento-online?artigo=" + item.id
        copyLinkAndShare({
            title: item.nome,
            message: item.nome,
            url: url
        })
    }

    loading = () => {
        this.setState({ loading: 1 })
        firebase.firestore().collection('support-articles').get().then(async (results) => {
            this.setState({ loading: 0 })
            var list = [];
            results.docs.forEach(doc => {
                list.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            this.setState({ list: list });
        }).catch(error => {
            toastError(error.message)
            this.setState({ loading: 0 })
        })
    }

    render() {
        return (
            <div className="col-lg-12 card mb-4">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Artigos de suporte
                </div>
                <div className="card-body">
                    {
                        this.state.loading === 0 ?
                            <div className='row'>
                                <div className='table-responsive'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nome</th>
                                                <th scope="col">Categorias</th>
                                                <th scope="col">Status</th>
                                                <th scope="col" width="80"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.list.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>{item.nome}</td>
                                                        <td>
                                                            {
                                                                item.categorias.split(',').map(item => <span className="badge text-bg-primary mx-1">{item}</span>)
                                                            }
                                                        </td>
                                                        <td>{item.status ? 'Ativo' : 'Inativo'}</td>
                                                        <td>
                                                            <div className='btn-group'>
                                                                <button type="button" onClick={() => { this.share(item) }} className="btn btn-success">
                                                                    <i className="fas fa-share-alt" />
                                                                </button>
                                                                {
                                                                    this.props.onDuplicate &&
                                                                    <button type="button" onClick={() => { this.onDuplicate(item) }} className="btn btn-warning text-white">
                                                                        <i className="fas fa-copy" />
                                                                    </button>
                                                                }
                                                                {
                                                                    this.props.onEdit &&
                                                                    <button type="button" onClick={() => { this.onEdit(item) }} className="btn btn-secondary">
                                                                        <i className="fas fa-edit" />
                                                                    </button>
                                                                }
                                                                {
                                                                    this.props.onAnalytics &&
                                                                    <>
                                                                        {
                                                                            (item.analytics !== undefined && item.analytics?.length !== 0) &&
                                                                            <button type="button" onClick={() => { this.onAnalytics(item) }} className="btn btn-success">
                                                                                <i className="fas fa-chart-pie" />
                                                                            </button>
                                                                        }
                                                                        {
                                                                            (item.analytics === undefined) &&
                                                                            <button type="button" className="btn btn-secondary">
                                                                                <i className="fas fa-chart-pie" />
                                                                            </button>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <Loading />
                    }
                </div>
            </div>
        )
    }
}

class ListarTreinamento extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            auxList: [],
            isOpenCellVideoModal: false,
            isOpenAdmVideoModal: false,
            searchId: null,
            search: '',
            videoSelected: '',
            loading: 1
        }
        this.loading()
    }

    componentDidMount = () => {
        var searchId = this.queryArtigle()
        if (searchId !== null) {
            this.setState({ searchId: searchId })
        }
    }

    queryArtigle = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        return params.get('artigo');
    }

    openPDF = (item) => {
        window.open(item.caminhoPDF, '_blank').focus();
        sendClickButton("Artigo", `Visualizou o PDF: ${item.nome}`)
        this._saveStatus(item, 'view-pdf')
    }

    openCellVideoModal = (item) => {
        this.setState({ videoSelected: item.caminhoCelular })
        this.setState({ isOpenCellVideoModal: true })
        sendClickButton("Artigo", `Visualizou o celular: ${item.nome}`)
        this._saveStatus(item, 'view-cell-video')
    }

    closeCellVideoModal = () => {
        this.setState({ isOpenCellVideoModal: false })
    }

    openAdmVideoModal = (item) => {
        this.setState({ videoSelected: item.caminhoAdministrativo })
        this.setState({ isOpenAdmVideoModal: true })
        sendClickButton("Artigo", `Visualizou o vídeo: ${item.nome}`)
        this._saveStatus(item, 'view-adm-video')
    }

    closeAdmVideoModal = () => {
        this.setState({ isOpenAdmVideoModal: false })
    }

    loading = () => {
        this.setState({ loading: 1 })
        // .where('status', '==', true)
        firebase.firestore().collection('support-articles').get().then(async (results) => {
            this.setState({ loading: 0 })
            var list = [];
            results.docs.forEach(doc => {
                list.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            this.setState({ list: list });
            this.setState({ auxList: list });
            this.searchById()
        }).catch(error => {
            this.setState({ loading: 0 })
        })
    }

    searchById = () => {
        if (this.state.searchId !== null) {
            var filtered = this.state.auxList.filter(e => e.id === this.state.searchId)
            if (filtered.length > 0) {
                const item = filtered[0]
                this.setState({ search: item.nome })
                this.setState({ list: filtered });
                sendClickButton("Artigo", `Visualizou o PDF: ${item.nome}`)
                this._saveStatus(item, 'view-pdf')
            } else {
                toastWarning("Artigo não encontrado.")
            }
        }
    }

    search = (value) => {
        this.setState({ search: value })
        const list = this.state.auxList
        if (value === '') {
            this.setState({ list: list });
        } else {
            var filtered = list.filter(e => e.nome.includes(value) || e.categorias.includes(value) || e.descricao.includes(value))
            this.setState({ list: filtered });
        }
    }

    share = (item) => {
        const url = window.location.host + "/treinamento-online?artigo=" + item.id
        copyLinkAndShare({
            title: item.nome,
            message: item.nome,
            url: url
        })
        sendClickButton("Artigo", "Compartilhou")
        this._saveStatus(item, 'shared')
    }

    like = (item) => {
        sendClickButton("Artigo", "Curtiu")
        this._saveStatus(item, 'like')
    }

    deslike = (item) => {
        sendClickButton("Artigo", "Não curtiu")
        this._saveStatus(item, 'dislike')
    }

    _saveStatus = (item, metrica) => {
        if (isAdministrador()) {
            return
        }
        if (item.analytics === undefined) {
            item.analytics = []
        }
        const estacionamento = getEstacionamento()
        const cliente = getCliente()
        const user = getUsuario()
        item.analytics.push({
            articleId: item.id,
            clientId: estacionamento.clienteId,
            clientNome: cliente.nome,
            estacionamentoId: estacionamento.id,
            estacionamentoNome: estacionamento.nome,
            user: {
                nome: user.nome,
                email: user.email
            },
            metrica: metrica,
            createdAt: new Date()
        })
        this._update(item)
    }

    _update = (item) => {
        firebase.firestore().collection('support-articles').doc(item.id).update({
            analytics: item.analytics
        })
    }

    _quantity = (item, metrica) => {
        if (item.analytics === undefined) {
            return 0
        }
        return item.analytics.filter(e => e.metrica === metrica).length
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="card mb-4">
                        <div className="card-body py-3">
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <h4>Buscar artigo</h4>
                                    <input type='search' placeholder='Busque por titulo, descrição ou categoria' onChange={(e) => this.search(e.target.value)} value={this.state.search} className='form-control' />
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        (this.state.loading === 0 && this.state.list.length === 0) &&
                        <div className="card mb-4">
                            <div className="card-body py-3">
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <EmptyContent text="Nenhum artigo encontrado." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.loading === 1 &&
                        <div className="card mb-4">
                            <div className="card-body py-3">
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <Loading />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.list.map((item, index) =>
                            <>
                                <div className="card mb-4">
                                    <div className="card-body py-3">
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div key={index} className='my-3'>
                                                            <h5>{item.nome}</h5>
                                                            <label>{item.descricao}</label> <p />
                                                            {
                                                                item.categorias.split(',').map(item => <span className="badge text-bg-primary mx-1">{item}</span>)
                                                            }
                                                        </div>
                                                        <div className='btn-group  my-2'>
                                                            {
                                                                (item.caminhoPDF !== undefined && item.caminhoPDF !== '') &&
                                                                <button className="btn btn-primary btn-block" type="button" onClick={(e) => this.openPDF(item)}>Passo a passo no artigo</button>
                                                            }
                                                            {
                                                                (item.caminhoCelular !== undefined && item.caminhoCelular !== '') &&
                                                                <button className="btn btn-success btn-block" type="button" onClick={(e) => this.openCellVideoModal(item)}>Passo a passo no celular</button>
                                                            }
                                                            {
                                                                (item.caminhoAdministrativo !== undefined && item.caminhoAdministrativo !== '') &&
                                                                <button className="btn btn-info btn-block" type="button" onClick={(e) => this.openAdmVideoModal(item)}>Passo a passo no portal</button>
                                                            }
                                                        </div>
                                                        <h6 className='my-3'>Compartilhe e aproveite para falar o que achou do artigo:</h6>
                                                        <div className='btn-group'>
                                                            <button type="button" onClick={() => { this.share(item) }} className="btn btn-info">
                                                                <i className="fas fa-share-alt" /> <span className="badge text-bg-light">{this._quantity(item, 'shared')}</span>
                                                            </button>
                                                            <button type="button" onClick={() => { this.like(item) }} className="btn btn-success">
                                                                <i className="fas fa-thumbs-up" /> <span className="badge text-bg-light">{this._quantity(item, 'like')}</span>
                                                            </button>
                                                            <button type="button" onClick={() => { this.deslike(item) }} className="btn btn-danger">
                                                                <i className="fas fa-thumbs-down" /> <span className="badge text-bg-light">{this._quantity(item, 'dislike')}</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }
                    <Dialog onClose={this.closeCellVideoModal} fullWidth open={this.state.isOpenCellVideoModal}>
                        <MuiDialogContent>
                            <VideoPlayer
                                controls={true}
                                src={this.state.videoSelected}
                                width="320"
                                height="620"
                            />
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <button onClick={this.closeCellVideoModal} className='btn btn-info'>Fechar</button>
                        </MuiDialogActions>
                    </Dialog>
                    <Dialog onClose={this.closeAdmVideoModal} fullWidth open={this.state.isOpenAdmVideoModal}>
                        <MuiDialogContent>
                            <VideoPlayer
                                controls={true}
                                src={this.state.videoSelected}
                                width="550"
                                height="520"
                            />
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <button onClick={this.closeAdmVideoModal} className='btn btn-info'>Fechar</button>
                        </MuiDialogActions>
                    </Dialog>
                </NavBar>
            </>
        )
    }
}

export { CriarSupportArticle, ListarSupportArticle, ListarTreinamento, AnalyticsSupportArticle };