import React from 'react'
import { NavBar } from '../../components/navbar'
import { ClientButton, EmptyContent, Loading } from '../../components/common/commons'
import firebase from '../../config/firebase';
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getLastLogs } from '../../store/collections/analyticsWorker'
import 'firebase/auth';
import 'firebase/storage';
import { getRevendaIdByPark, isAdministrador, isPlatformOwner } from '../../config/auth';
import { getAllParks, getAllParksByRessaleId } from '../../store/collections/parkWorker';
import Tooltip from '@mui/material/Tooltip';
import { exportAndDownloadTxt, isJSON } from '../../shared/utils';
import { sendLogByUser } from '../../shared/analytics';
import { AlertSecondary } from '../../shared/alert-manager';
import ReactJson from 'react-json-view'
import { docsToItems } from '../../store/transform.docs';

class ListarAnalytics extends React.Component {

    render() {
        return (
            <>
                <NavBar>
                    <ListarAnalyticsBody props={this.props} />
                </NavBar>
            </>)
    }
}

class SimpleListarAnalytics extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            limit: 50,
            isLoading: true
        }
        this.load()
    }

    load = async () => {
        const results = await getLastLogs({ limit: 5 })
        this.setState({ items: results.filter(e => e.type === "error") })
        this.setState({ isLoading: false });
    }

    changeLimit = (e) => {
        this.setState({ limit: parseInt(e.target.value) }, () => {
            this.load()
        })
    }

    render() {
        return (
            <>
                {
                    this.state.isLoading === true ?
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                Últimos logs
                            </div>
                            <div className="card-body py-3">
                                <Loading />
                            </div>
                        </div> :
                        this.state.items.length === 0 ?
                            <div className="card mb-4">
                                <div className="card-body py-3">
                                    <EmptyContent text="Nenhum evento foi localizada com esse filtro" />
                                </div>
                            </div> :
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Últimos logs
                                    <div className='col-lg-3'>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <select className='form-select form-select-sm' value={this.state.limit} onChange={this.changeLimit}>
                                                    <option value={5}>5 registros</option>
                                                    <option value={10}>10 registros</option>
                                                    <option value={15}>15 registros</option>
                                                    <option value={20}>20 registros</option>
                                                    <option value={25}>25 registros</option>
                                                    <option value={50}>50 registros</option>
                                                    <option value={100}>100 registros</option>
                                                    <option value={200}>200 registros</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td className="d-none d-sm-table-cell"><strong>Fonte</strong></td>
                                                <td className="d-none d-sm-table-cell"><strong>Origem</strong></td>
                                                <td><strong>Evento</strong></td>
                                                <td className="d-none d-sm-table-cell"><strong>Data</strong></td>
                                                <td className="d-none d-sm-table-cell"></td>
                                                <td width={10}></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.items.map((item, index) =>
                                                    <tr key={index}>
                                                        <td className="d-none d-sm-table-cell">{item.estacionamentoNome}</td>
                                                        <td className="d-none d-sm-table-cell" >{item.screen}</td>
                                                        <td>{item.event}</td>
                                                        <td className="d-none d-sm-table-cell">{Moment(item.createdAt.toDate()).format("DD/MM/YYYY HH:mm")}</td>
                                                        <td className="d-none d-sm-table-cell">
                                                            {item.type === 'screenView' && <h6><span className="badge text-bg-primary text-white w-100">Visualização de tela</span></h6>}
                                                            {item.type === 'click' && <h6><span className="badge text-bg-info text-white w-100">Clique no botão</span></h6>}
                                                            {item.type === 'log' && <h6><span className="badge text-bg-success text-white w-100">Log do sistema</span></h6>}
                                                            {item.type === 'error' && <h6><span className="badge text-bg-danger text-white w-100">Log do sistema</span></h6>}
                                                        </td>
                                                        <td>
                                                            <div className="btn-group">
                                                                {
                                                                    item.origin !== "site-ticket" &&
                                                                    <ClientButton item={item} />
                                                                }
                                                                {
                                                                    item.origin === "site-ticket" &&
                                                                    <a href={'/buscar/ticket?id=' + item.user.nome} target='_blank' rel="noopener noreferrer" className="btn btn-warning text-white">
                                                                        <i className="fas fa-ticket-alt" />
                                                                    </a>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                }
            </>)
    }
}

class ListarAnalyticsBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            limit: 100,
            type: '',
            screen: '',
            event: '',
            days: props.days ?? 7,
            isShowLog: false,
            estacionamentoId: props.estacionamentoId,
            revendaId: getRevendaIdByPark(),
            clienteId: props.clienteId,
            fonts: [],
            log: '',
            parks: [{
                nome: "Aguarde..."
            }],
            items: [],
            state: "loading"
        }
        this.loadParks()
        this.load()
    }

    load = async () => {
        this.setState({ state: "loading" });
        var query = firebase.firestore().collection('analytics')
        if (this.state.estacionamentoId && this.state.estacionamentoId !== "") {
            query = query.where('estacionamentoId', '==', this.state.estacionamentoId)
        }
        if (this.state.clienteId) {
            query = query.where('clienteId', '==', this.state.clienteId)
        }
        if (this.state.type !== '') {
            query = query.where('type', '==', this.state.type)
        }
        if (this.state.screen !== '') {
            query = query.where('screen', '==', this.state.screen)
        }
        const docs = await query.limit(this.state.limit).orderBy("createdAt", "desc").get()
        const items = docsToItems(docs)
        if (items.length === 0) {
            this.setState({ state: "empty" });
        } else {
            if (isAdministrador()) {
                this.setState({ items: items })
            } else {
                this.setState({ items: items.filter(e => e.internal === false) })
            }
            const newFonts = [...new Set(items.map(e => e.screen))]
            if (this.state.fonts.length <= newFonts.length) {
                this.setState({ fonts: newFonts })
            }
            this.setState({ state: "data" });
        }
    }

    loadParks = async () => {
        if (!this.state.clienteId) {
            if (isPlatformOwner()) {
                var parks = await getAllParks()
                this.setState({ parks: parks })
            } else {
                var parks = await getAllParksByRessaleId({ id: this.state.revendaId })
                this.setState({ parks: parks })
            }
        }
    }

    handleParkSelected = (event) => {
        this.setState({ estacionamentoId: event.target.value }, () => {
            this.load()
        })
    }

    handleLimit = (event) => {
        this.setState({ limit: event.target.value }, () => {
            this.load()
        })
    };

    handleUserEmail = (event) => {
        this.setState({ userEmail: event.target.value }, () => {
            this.load()
        })
    };

    handleFontSelected = (event) => {
        this.setState({ fontSelected: event.target.value }, () => {
            this.load()
        })
    };

    handleScreen = (e) => {
        this.setState({ screen: e.target.value }, () => {
            this.load()
        })
    }

    handleType = (e) => {
        this.setState({ type: e.target.value }, () => {
            this.load()
        })
    }

    showLog = (log) => {
        if (log) {
            try {
                var ugly = log.replaceAll(`\\`, ``).replaceAll(`\"`, `"`);
                ugly = ugly.substring(1).slice(0, -1);
                var obj = JSON.parse(ugly);
                var pretty = JSON.stringify(obj, undefined, 4);
                this.setState({ log: pretty })
                this.setState({ isShowLog: true })
            } catch (error) {
                var pretty = JSON.stringify(log, undefined, 4);
                this.setState({ log: pretty })
                this.setState({ isShowLog: true })
            }
        }
    }

    exportLogs = async (e) => {
        e.preventDefault()
        sendLogByUser("Busca", "exportou os logs de uso.")
        const json = this.state.items.map(e => {
            return {
                ...e,
                json: JSON.stringify(e.json?.toString()?.replaceAll(`\\`, ``), undefined, 4),
                createdAt: Moment(e.createdAt.toDate()).format("DD/MM/YYYY HH:mm:ss")
            }
        })
        await exportAndDownloadTxt(json, `LOGS_EXPORTADOS_${Moment().format("DD/MM/YYYY_HH:mm:ss")}`)
    }

    render() {
        return (
            <>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Selecione o seu filtro
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-lg-2'>
                                <label>Registros</label>
                                <select className="form-select" value={this.state.limit} onChange={this.handleLimit}>
                                    <option value={100}>100</option>
                                    <option value={200}>200</option>
                                    <option value={300}>300</option>
                                    {
                                        isAdministrador() &&
                                        <>
                                            <option value={400}>400</option>
                                            <option value={600}>600</option>
                                            <option value={800}>800</option>
                                            <option value={1000}>1.000</option>
                                        </>
                                    }
                                </select>
                            </div>
                            {
                                !this.state.clienteId &&
                                <div className='col-lg-3'>
                                    <label>Estabelecimento</label>
                                    <select className="form-select" value={this.state.estacionamentoId} onChange={this.handleParkSelected}>
                                        <option value=''>Automação - Backend</option>
                                        {
                                            this.state.parks.map(e => <option value={e.id}>{e.nome}</option>)
                                        }
                                    </select>
                                </div>
                            }
                            <div className='col-lg-3'>
                                <label>Origem</label>
                                <select className="form-select" value={this.state.screen} onChange={this.handleScreen}>
                                    <option value=''>Todas</option>
                                    {
                                        this.state.fonts.map(e => <option value={e}>{e}</option>)
                                    }
                                </select>
                            </div>
                            <div className='col-lg-2'>
                                <label>Tipo</label>
                                <select className="form-select" value={this.state.type} onChange={this.handleType}>
                                    <option value=''>Todos</option>
                                    <option value='screenView'>Visualização de tela</option>
                                    <option value='click'>Cliques em botões</option>
                                    <option value='log'>Informação do sistema</option>
                                    <option value='error'>Erros do sistema</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.state === "loading" && <div className='card mb-4'>
                        <Loading />
                    </div>
                }
                {
                    this.state.state === "empty" && <div className='card mb-4'>
                        <EmptyContent text="Nenhum evento foi localizado com esse filtro." />
                    </div>
                }
                {
                    this.state.state === "data" &&
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Resultados de logs
                            {
                                isAdministrador() &&
                                <div className='btn-group'>
                                    <button type="button" onClick={this.exportLogs} className="btn btn-sm btn-secondary">
                                        <i className="fas fa-cloud-download-alt mx-2" />
                                        Baixar logs
                                    </button>
                                </div>
                            }
                        </div>
                        {
                            this.state.items.length > 0 ?
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td className="d-none d-sm-table-cell" align='center' width={10}><strong>Operador</strong></td>
                                                {
                                                    !this.state.clienteId &&
                                                    <td className="d-none d-sm-table-cell"><strong>Estacionamento</strong></td>
                                                }
                                                <td className="d-none d-sm-table-cell" width={10}><strong>Origem</strong></td>
                                                <td><strong>Evento</strong></td>
                                                <td><strong>Data</strong></td>
                                                <td className="d-none d-sm-table-cell"></td>
                                                {
                                                    !this.state.clienteId &&
                                                    <td width={10}></td>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.items.map((item, index) =>
                                                    <tr key={index}>
                                                        <td className="d-none d-sm-table-cell" align='center' >{item.createdBy?.nome ?? "-"}</td>
                                                        {
                                                            !this.state.clienteId &&
                                                            <Tooltip title={item.id} placement="top">
                                                                <td className="d-none d-sm-table-cell" >{item.estacionamentoNome}</td>
                                                            </Tooltip>
                                                        }
                                                        <td className="d-none d-sm-table-cell" >{item.screen}</td>
                                                        <td>{item.event}</td>
                                                        <td>{Moment(item.createdAt.toDate()).format("DD/MM/YYYY HH:mm:ss")}</td>
                                                        <td className="d-none d-sm-table-cell" width={10}>
                                                            {item.type === 'screenView' && <h6><span className="badge text-bg-primary text-white w-100">Visualização de tela</span></h6>}
                                                            {item.type === 'click' && <h6><span className="badge text-bg-info text-white w-100">Clique no botão</span></h6>}
                                                            {item.type === 'log' && <h6><span className="badge text-bg-success text-white w-100">Log do sistema</span></h6>}
                                                            {item.type === 'error' && <h6><span className="badge text-bg-danger text-white w-100">Log do sistema</span></h6>}
                                                        </td>
                                                        <td align='right'>
                                                            <div className='btn-group'>
                                                                {
                                                                    item.json &&
                                                                    <button onClick={(e) => this.showLog(item.json)} className="btn btn-secondary">
                                                                        <i className="fas fa-clipboard-list" />
                                                                    </button>
                                                                }
                                                                {
                                                                    !this.state.clienteId &&
                                                                    <>
                                                                        {
                                                                            item.origin === "site-ticket" &&
                                                                            <a href={'/buscar/ticket?id=' + item.user.nome} target='_blank' rel="noopener noreferrer" className="btn btn-warning text-white">
                                                                                <i className="fas fa-ticket-alt" />
                                                                            </a>
                                                                        }
                                                                        {
                                                                            !this.state.clienteId &&
                                                                            <ClientButton item={item} />
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div> : <EmptyContent text="Nenhum log encontrado" />
                        }
                        <Dialog maxWidth="md" fullWidth onClose={(e) => this.setState({ isShowLog: false })} open={this.state.isShowLog}>
                            <MuiDialogTitle className='text-center'>
                                Detalhes do log
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <AlertSecondary tag="">
                                    <div className='mt-2'>
                                        {
                                            isJSON(this.state.log) ?
                                                <ReactJson name={false} displayDataTypes={false} src={JSON.parse(this.state.log)} enableClipboard={false} /> :
                                                <span>{this.state.log}</span>
                                        }
                                    </div>
                                </AlertSecondary>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='m-3'>
                                    <button onClick={(e) => this.setState({ isShowLog: false })} className='btn btn-secondary'>Fechar</button>
                                </div>
                            </MuiDialogActions>
                        </Dialog>
                    </div>
                }
            </>)
    }
}

export { ListarAnalytics, SimpleListarAnalytics, ListarAnalyticsBody };