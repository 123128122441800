import React from 'react'
import { NavBar } from '../../components/navbar'
import { ClientButton, Loading } from '../../components/common/commons'
import Moment from 'moment';
import 'firebase/auth';
import 'firebase/storage';
import { getAllUsersByAssociatedRessales } from '../../store/collections/userWorker';
import { isNullOrEmpty } from '../../shared/utils';
import { getUsuario } from '../../config/auth';

class AnalyticsLogin extends React.Component {

    render() {
        return (
            <>
                <NavBar>
                    <AnalyticsLoginBody props={this.props} />
                </NavBar>
            </>)
    }
}

class AnalyticsLoginBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            typeAccess: "ACESSOU",
            today: Moment(new Date()).format("DDMMYYYY"),
            yesterday: Moment(new Date()).add(-1, 'days').format("DDMMYYYY"),
            days2: Moment(new Date()).add(-2, 'days').format("DDMMYYYY"),
            days3: Moment(new Date()).add(-3, 'days').format("DDMMYYYY"),
            days4: Moment(new Date()).add(-4, 'days').format("DDMMYYYY"),
            days5: Moment(new Date()).add(-5, 'days').format("DDMMYYYY"),
            accessToday: 0,
            accessTodayPercent: 0,
            accessYesterday: 0,
            accessYesterdayPercent: 0,
            accessDays2: 0,
            accessDays2Percent: 0,
            accessMoreThan2Days: 0,
            accessMoreThan2DaysPercent: 0,
            isLoading: true,
            adquirente: ""
        }
        this.load()
    }

    load = async () => {
        var items = await getAllUsersByAssociatedRessales({ associatedResales: getUsuario().revendasAssociadas ?? [] })
        switch (this.state.typeAccess) {
            case "ACESSOU": items = items.filter(e => e.lastAccess !== undefined); break
            case "NUNCA-ACESSOU": items = items.filter(e => e.lastAccess === undefined); break
        }
        if (!isNullOrEmpty(this.state.adquirente)) {
            items = items.filter(e => e.flavor?.toLowerCase().includes(this.state.adquirente.toLowerCase()) === true)
        }
        this.setState({ items: items.filter(e => e.revendedor !== true).sort((a, b) => (b.lastAccess?.toDate() ?? new Date()) - (a.lastAccess?.toDate() ?? new Date())) })
        var accessToday = items.filter(e => e.lastAccess !== undefined).filter(e => Moment(e.lastAccess.toDate()).format("DDMMYYYY") === this.state.today).length
        var accessYesterday = items.filter(e => e.lastAccess !== undefined).filter(e => Moment(e.lastAccess.toDate()).format("DDMMYYYY") === this.state.yesterday).length
        var accessDays2 = items.filter(e => e.lastAccess !== undefined).filter(e => Moment(e.lastAccess.toDate()).format("DDMMYYYY") === this.state.days2).length
        this.setState({ accessToday: accessToday })
        this.setState({ accessTodayPercent: (accessToday / items.length * 100).toFixed(0) })
        this.setState({ accessYesterday: accessYesterday })
        this.setState({ accessYesterdayPercent: (accessYesterday / items.length * 100).toFixed(0) })
        this.setState({ accessDays2: accessDays2 })
        this.setState({ accessDays2Percent: (accessDays2 / items.length * 100).toFixed(0) })
        this.setState({ accessMoreThan2Days: items.length - (accessToday - accessYesterday - accessDays2) })
        this.setState({ accessMoreThan2DaysPercent: ((items.length - (accessToday + accessYesterday + accessDays2)) / items.length * 100).toFixed(0) })
        this.setState({ isLoading: false });
    }

    handlerType = (e) => {
        this.setState({ typeAccess: e.target.value }, () => {
            this.load()
        })
    }

    handlerAdquirente = (e) => {
        this.setState({ adquirente: e.target.value }, () => {
            this.load()
        })
    }

    render() {
        return (
            <>
                {
                    !this.state.isLoading &&
                    <div className='col-lg-12'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <div className="card text-light bg-success mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Hoje</h5>
                                        <h3 className="card-text text-center text-white">
                                            {this.state.accessToday}
                                        </h3>
                                        <div className='text-center'>
                                            <small className="card-text text-white">
                                                {this.state.accessTodayPercent}%
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="card text-light bg-info mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Ontem</h5>
                                        <h3 className="card-text text-center text-white">
                                            {this.state.accessYesterday}
                                        </h3>
                                        <div className='text-center'>
                                            <small className="card-text text-white">
                                                {this.state.accessYesterdayPercent}%
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="card text-light bg-warning mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Há 2 dias</h5>
                                        <h3 className="card-text text-center text-white">
                                            {this.state.accessDays2}
                                        </h3>
                                        <div className='text-center'>
                                            <small className="card-text text-white">
                                                {this.state.accessDays2Percent}%
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="card text-light bg-danger mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Acima de 2 dias</h5>
                                        <h3 className="card-text text-center text-white">
                                            {this.state.accessMoreThan2Days}
                                        </h3>
                                        <div className='text-center'>
                                            <small className="card-text text-white">
                                                {this.state.accessMoreThan2DaysPercent}%
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Login de usuários
                        <div className='col-lg-5'>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <select className='form-select form-select-sm' value={this.state.adquirente} onChange={this.handlerAdquirente}>
                                        <option value="">Selecionar Adquirente</option>
                                        <option value="pagSeguro">PagSeguro</option>
                                        <option value="cielo">Cielo</option>
                                        <option value="getnet">GetNet</option>
                                        <option value="stone">Stone</option>
                                        <option value="safra">Safra</option>
                                        <option value="store">Google Play</option>
                                        <option value="rede">Rede</option>
                                        <option value="site">Site</option>
                                    </select>
                                </div>
                                <div className='col-lg-6'>
                                    <select className='form-select form-select-sm' value={this.state.typeAccess} onChange={this.handlerType}>
                                        <option value="TODOS">Todos</option>
                                        <option value="ACESSOU">Já acessou</option>
                                        <option value="NUNCA-ACESSOU">Nunca acessou</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.isLoading ? <Loading /> :
                            <div className="row">
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col" width={100}>Estabelecimento</th>
                                                <th scope="col" className="d-none d-sm-table-cell">Nome</th>
                                                <th scope="col" className="d-none d-sm-table-cell">E-mail</th>
                                                <th scope="col">Celular</th>
                                                <th scope="col" className="d-none d-sm-table-cell" align='center' width={240}>Último acesso</th>
                                                <th scope="col" className="d-none d-sm-table-cell" align='center'>Sistema</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.items.map(usuario =>
                                                    <tr>
                                                        <td align='center' width={10}>
                                                            {
                                                                usuario.lastAccess &&
                                                                <>
                                                                    {
                                                                        Moment(usuario.lastAccess.toDate()).format("DDMMYYYY") === this.state.today && <span className="badge text-bg-success w-100">Hoje</span>
                                                                    }
                                                                    {
                                                                        Moment(usuario.lastAccess.toDate()).format("DDMMYYYY") === this.state.yesterday && <span className="badge text-bg-info w-100">Ontem</span>
                                                                    }
                                                                    {
                                                                        Moment(usuario.lastAccess.toDate()).format("DDMMYYYY") === this.state.days2 && <span className="badge text-bg-warning w-100">Há 2 dias</span>
                                                                    }
                                                                    {
                                                                        Moment(usuario.lastAccess.toDate()).format("DDMMYYYY") === this.state.days3 && <span className="badge text-bg-warning w-100">Há 3 dias</span>
                                                                    }
                                                                    {
                                                                        Moment(usuario.lastAccess.toDate()).format("DDMMYYYY") === this.state.days4 && <span className="badge text-bg-danger w-100">Ha 4 dias</span>
                                                                    }
                                                                    {
                                                                        Moment(usuario.lastAccess.toDate()).format("DDMMYYYY") === this.state.days5 && <span className="badge text-bg-danger w-100">Ha 5 dias</span>
                                                                    }
                                                                </>
                                                            }
                                                        </td>
                                                        <td width={100}>{usuario.estacionamentos?.map(e => e.nome).join(" ")}</td>
                                                        <td className="d-none d-sm-table-cell">{usuario.nome}</td>
                                                        <td className="d-none d-sm-table-cell" >{usuario.email}</td>
                                                        <td>{usuario.celular ?? "Não informado"}</td>
                                                        <td className="d-none d-sm-table-cell" align='center' width={240}>
                                                            {
                                                                usuario.lastAccess !== undefined ?
                                                                    <span>{Moment(usuario.lastAccess.toDate()).format(" DD/MM/YYYY [às] HH:mm A")}</span>
                                                                    :
                                                                    <span className="badge text-bg-warning mx-2 w-100">Nunca</span>
                                                            }
                                                        </td>
                                                        <td className="d-none d-sm-table-cell" align='center' width={180}>
                                                            {
                                                                usuario.lastAccess !== undefined ?
                                                                    <span className="badge text-bg-success mx-2 w-100">
                                                                        {
                                                                            usuario.version ?
                                                                                <span> {usuario.version} </span> :
                                                                                <span> Site </span>
                                                                        }
                                                                        {usuario.flavor?.includes("pagSeguro") && ('PagSeguro')}
                                                                        {usuario.flavor?.includes("cielo") && ('Cielo')}
                                                                        {usuario.flavor?.includes("getnet") && ('GetNet')}
                                                                        {usuario.flavor?.includes("Stone") && ('Stone')}
                                                                        {usuario.flavor?.includes("safra") && ('Safra')}
                                                                        {usuario.flavor?.includes("store") && ('Google Play')}
                                                                        {usuario.flavor?.includes("rede") && ('Rede')}
                                                                        {usuario.flavor?.includes("site") && ''}
                                                                    </span> :
                                                                    <span className="badge text-bg-warning mx-2 w-100">-</span>
                                                            }
                                                        </td>
                                                        <td width='10'>
                                                            <ClientButton item={usuario} />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                </div>
            </>)
    }
}

export { AnalyticsLogin };