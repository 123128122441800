import { getRevenda } from '../../config/auth';
import { addDoc } from './collectionBaseWorker';

export const addVerify = async ({ clientId, nome, email, phone, comoConheceu, revendaId }) => {

    await addDoc({
        collection: 'cellphone_verify', data: {
            name: nome,
            email: email,
            phone: phone,
            createdAt: new Date(),
            comoConheceu: comoConheceu,
            platform: 'site',
            lastStatus: 'PARK_CREATED',
            lastDateStatus: new Date(),
            revendaId: revendaId ?? getRevenda()?.id,
            status: [{
                status: 'PARK_CREATED', createdAt: new Date()
            }],
            clientId: clientId,
            completedAt: new Date(),
            isDeleted: false,
        }
    })
}