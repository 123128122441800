import { getCliente, getUsuarioCompact } from '../../config/auth';
import firebase from '../../config/firebase';
import { Collection } from '../../shared/constants';
import { generateUUIDV7, getResellerIdByEnv } from '../../shared/utils';
import { docsToItems } from '../transform.docs';
import { addDoc } from './collectionBaseWorker';

export const addTermsRead = async ({ termsId, info }) => {
    const user = getUsuarioCompact()
    const cliente = getCliente()
    const data = {
        revendaId: getResellerIdByEnv(),
        clienteId: cliente.id,
        createdBy: user,
        createdAt: new Date(),
        termsId: termsId,
        guid: generateUUIDV7(),
        info: info,
        isDeleted: false,
    }
    await addDoc({ collection: Collection.USER_TERMS, data: data })
}

export const getTermsByRevendaId = async ({ revendaId }) => {
    const results = await firebase.firestore().collection(Collection.USER_TERMS).where("revendaId", "==", revendaId).get()
    return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
}

export const getAllTerms = async () => {
    const results = await firebase.firestore().collection(Collection.USER_TERMS).get()
    return docsToItems(results).sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
}