
export const searchCEP = async (value) => {
    const cep = String(value).replaceAll(' ', '').replaceAll("-", "")
    if (cep.length === 8) {
        const url = "https://viacep.com.br/ws/" + cep + "/json/"
        return new Promise(function (resolve, reject) {
            fetch(url)
                .then(response => response.json())
                .then(result => {
                    const data = {
                        cidade: result.localidade,
                        estado: result.uf,
                        ...result
                    }
                    resolve(data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
    return null
}