import React from 'react'
import { Button } from 'react-bootstrap';
import { getEstacionamento } from '../../config/auth'
import { NavBar } from '../../components/navbar'
import { Loading } from '../../components/common/commons'
import 'firebase/storage'
import { sendClickButton } from '../../shared/analytics';
import { queryString, toastWarning } from '../../shared/utils'
import { getCashierBySequence } from '../../store/collections/cashierWorker';
import { AlertSuccess } from '../../shared/alert-manager';

class CashierSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            sequence: ""
        }
    }

    componentDidMount = async () => {
        const sequence = queryString("sequence")
        if (sequence !== null) {
            this.setState({ sequence: sequence }, () => {
                this.search()
            })
        }
    }

    search = async () => {
        const sequence = this.state.sequence
        if (sequence === "") {
            toastWarning("Informe o número do caixa.")
            return
        }
        sendClickButton("Buscar", "Caixa: " + sequence)
        this.setState({ isLoading: true })
        const cashier = await getCashierBySequence({ estacionamentoId: this.state.estacionamentoId, sequence: sequence })
        this.setState({ isLoading: false })
        if (cashier) {
            window.location.href = `/caixas/${cashier.id}?from=search`
        } else {
            toastWarning("Caixa não encontrado.")
        }
    }


    verifyEnter = (event) => {
        if (event.key === 'Enter') {
            this.search()
        }
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="row">
                        <div className='col-lg-3 mb-4'>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Buscar caixa
                                </div>
                                <div className="card-body">
                                    <AlertSuccess message="Você pode buscar um caixa pelo número sequêncial, exemplo: 40, 320, 400 e etc." tag="Novidade" />
                                    {
                                        !this.state.isLoading ? <>
                                            <div className="row">
                                                <div className='col-lg-12'>
                                                    <label>Número do caixa</label>
                                                    <input type="text" value={this.state.sequence} onChange={(e) => { this.setState({ sequence: e.target.value }) }} onKeyPress={this.verifyEnter} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12 my-3">
                                                    <div className="btn-group">
                                                        <Button className='btn btn-primary' onClick={this.search} onKeyPress={this.verifyEnter} variant="default">Buscar</Button>
                                                        <a href='/relatorios/caixas?from=search' className='btn btn-secondary' variant="default">Busca avançada</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </> :
                                            <Loading />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </NavBar >
            </>
        )
    }
}

export { CashierSearch };