import React from 'react';
import { NavBar } from '../../components/navbar'
import { queryString, setPathWindow, toastDismissLoading, toastLoading } from '../../shared/utils'
import { CashierList, CashierTotals } from '../cashier/components'
import { getCashiersWithRevenues, getCashiersWithRevenuesByIds } from '../../store/collections/cashier'
import { EmptyContent, FilterComponent } from '../../components/common/commons'
import Moment from 'moment';
import { sendScreenView } from '../../shared/analytics';

class ReportCashier extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notFound: false,
            isLoading: false,
            random: Math.random(),
            tickets: [],
            auxResultado: null,
            resultado: null
        }
    }

    componentDidMount = () => {
        const estacionamentosIds = queryString("es")
        const startDate = new Moment(queryString("startDate"))
        const endDate = new Moment(queryString("endDate"))
        if (estacionamentosIds !== undefined && startDate.isValid() && endDate.isValid()) {
            this.setState({ estacionamentosIds: estacionamentosIds?.split(",") ?? [] })
            this.setState({ startDate: startDate.toDate() })
            this.setState({ endDate: endDate.toDate() }, () => {
                this.find()
            })
        }
        const from = queryString("from")
        if (from === "email") {
            sendScreenView("Relatório", "Caixas | Por e-mail")
        } else {
            sendScreenView("Relatório", "Caixas")
        }
    }

    async find() {
        const startDateURL = Moment(this.state.startDate).format("MM/DD/YYYY")
        const endDateURL = Moment(this.state.endDateURL).format("MM/DD/YYYY")
        setPathWindow('/relatorios/caixas?es=' + this.state.estacionamentosIds?.join(",") + "&startDate=" + startDateURL + "&endDate=" + endDateURL);
        this.setState({ isLoading: true });
        this.setState({ notFound: false });
        const resultado = await getCashiersWithRevenues({
            estacionamentosIds: this.state.estacionamentosIds,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        })
        if (resultado === null) {
            this.setState({ notFound: true });
            this.setState({ isLoading: false });
            return
        }
        this.setState({ auxResultado: resultado })
        this.setState({ resultado: resultado }, () => {
            this.setState({ random: Math.random() })
            this.setState({ isLoading: false });
        })
    }

    onCashiersSelected = async (cashiersId) => {
        toastLoading("Atualizando....")
        const cashiers = await getCashiersWithRevenuesByIds({
            caixaIds: cashiersId,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        })
        this.setState({ resultado: cashiers }, () => {
            this.setState({ random: Math.random() })
            this.setState({ isLoading: false });
            toastDismissLoading()
        })
    }

    onApplyFilter = (item) => {
        this.setState({ startDate: item.startDate })
        this.setState({ endDate: item.endDate })
        this.setState({ estacionamentosIds: item.estacionamentosIds }, () => {
            this.find()
        })
    }

    render() {
        return (
            <>
                <NavBar>
                    <FilterComponent onApplyFilter={this.onApplyFilter} />
                    {
                        this.state.isLoading ?
                            <div className="card">
                                <div className="card-body">
                                    <EmptyContent text="Carregando informações..." />
                                </div>
                            </div>
                            :
                            this.state.notFound ?
                                <div className="card">
                                    <div className="card-body text-center">
                                        <EmptyContent text="Nenhum caixa foi localizado com esse filtro!" />
                                        <div className='m-5 d-flex justify-content-center'>
                                            <div className='col-lg-4'>
                                                <div className="alert alert-warning">
                                                    Selecione no filtro a <strong>data de abertura do caixa</strong>.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className='row'>
                                    {
                                        this.state.auxResultado !== null &&
                                        <>
                                            <div className="col-lg-7">
                                                <CashierTotals key={this.state.random}
                                                    resultado={this.state.resultado} />
                                            </div>
                                            <div className="col-lg-5">
                                                <CashierList
                                                    onCashiersSelected={this.onCashiersSelected}
                                                    resultado={this.state.auxResultado} />
                                            </div>
                                        </>
                                    }
                                </div>
                    }
                </NavBar>
            </>
        )
    }
}

export { ReportCashier };