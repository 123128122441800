import firebase from '../../config/firebase';
import { getCliente, getEstacionamento, getUsuarioCompact, getRevendaIdByPark } from '../../config/auth'
import { docsToItem } from '../transform.docs';
import { Collection, WhereField } from '../../shared/constants';
import { getAllDocsByClientId, updateDoc } from './collectionBaseWorker';

export const getTicketSettings = async ({ plate }) => {
    if (plate === '') {
        return null
    }
    const estacionamentoId = getEstacionamento().id ?? ""
    const result = await firebase.firestore().collection(Collection.TICKET_SETTINGS)
        .where(WhereField.PARKID, "==", estacionamentoId)
        .where("plate", "==", plate).get()
    if (result.empty) {
        return null
    }
    return docsToItem(result)
}

export const updateTicketSettings = async ({ plate, allowToPark, allowToParkReason }) => {
    const clienteId = getCliente().id
    const user = getUsuarioCompact()
    const settings = await getTicketSettings({ plate: plate })
    if (settings) {
        const data = {
            allowToPark: allowToPark,
            allowToParkReason: allowToParkReason,
            plate: plate,
            estacionamentoId: getEstacionamento().id ?? "",
            alterAt: new Date(),
            alterBy: user,
            isDeleted: false,
        }
        await updateDoc({ collection: Collection.TICKET_SETTINGS, id: settings.id, data: data })
    } else {
        const data = {
            allowToPark: allowToPark,
            allowToParkReason: allowToParkReason,
            plate: plate,
            estacionamentoId: getEstacionamento().id ?? "",
            revendaId: getRevendaIdByPark(),
            clienteId: clienteId,
            createAt: new Date(),
            createBy: user,
            isDeleted: false,
        }
        await updateDoc({ collection: Collection.TICKET_SETTINGS, id: data.id, data: data })
    }
}

export const getAllTicketSettingsByClientId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.TICKET_SETTINGS, clienteId: clienteId })
}

export const updateTicketSettingsById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.TICKET_SETTINGS, id: id, data: data })
}