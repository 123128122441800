import moment from 'moment';
import { getRevenda } from '../../config/auth';
import firebase from '../../config/firebase';
import { docsToItems } from '../transform.docs';
import { hoursToMin } from '../../shared/utils';
import { addDoc, deleteDoc, getAllDocsByClientId, updateDoc } from './collectionBaseWorker';
import { Collection, WhereField } from '../../shared/constants';

export const getAllPaymentTablesByClientId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.PRICE_TABLE, clienteId: clienteId })
}

export const getAllPaymentTables = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.PRICE_TABLE).where(WhereField.PARKID, "==", estacionamentoId).get()
    return docsToItems(result).sort((a, b) => b.nome - a.nome).sort((a, b) => a.nome.localeCompare(b.nome))
}

export const getAllActivePaymentTables = async ({ estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.PRICE_TABLE).where("status", "==", "true").where(WhereField.PARKID, "==", estacionamentoId).get()
    return docsToItems(result).sort((a, b) => b.nome - a.nome).sort((a, b) => a.nome.localeCompare(b.nome))
}

export const getAllPaymentTablesBySectorId = async ({ sectorId }) => {
    const result = await firebase.firestore().collection(Collection.PRICE_TABLE).where("setorAssociadoId", "==", sectorId).get()
    return docsToItems(result)
}

export const deletePaymentTableById = async ({ id }) => {
    await deleteDoc({ collection: Collection.PRICE_TABLE, id: id })
}

export const addPaymentTable = async ({ clienteId, estacionamentoId, revendaId }) => {
    const data = {
        nome: "Periodo Integral",
        estacionamentoId: estacionamentoId,
        clienteId: clienteId,
        valorMaximo: 20.0,
        valorHoraAdicional: 2.0,
        periodoMaximo: "12:00",
        tipo: "Carro",
        principal: "true",
        status: "true",
        periodos: [],
        revendaId: revendaId ?? getRevenda()?.id,
        semanas: {
            horarioInicio: "07:00", horarioFim: "22:00",
            dias: {
                segunda: true,
                terca: true,
                quarta: true,
                quinta: true,
                sexta: true,
                sabado: true,
                domingo: true
            }
        },
        isDeleted: false,
    }
    const doc = await addDoc({ collection: Collection.PRICE_TABLE, id: data.id, data: data })
    return doc.id;
}

export const getCurrentPriceTableByDayAndTime = async ({ parkId, type }) => {
    const currentDate = moment()
    const currentMinute = hoursToMin(currentDate.format("HH:mm"))
    var field = ""
    switch (currentDate.isoWeekday()) {
        case 1: field = "semanas.dias.segunda"; break
        case 2: field = "semanas.dias.terca"; break
        case 3: field = "semanas.dias.quarta"; break
        case 4: field = "semanas.dias.quinta"; break
        case 5: field = "semanas.dias.sexta"; break
        case 6: field = "semanas.dias.sabado"; break
        case 7: field = "semanas.dias.domingo"; break
    }
    const result = await firebase.firestore().collection(Collection.PRICE_TABLE).where(field, "==", true).where("status", "==", 'true').where(WhereField.PARKID, "==", parkId).get()
    const items = docsToItems(result)
    const found = items.filter(e => e.tipo === (type ?? "carro") && e.habilitarVinculacaoNoTotem !== false && e.isAllDay === true || (currentMinute >= hoursToMin(e.semanas.horarioInicio) && currentMinute <= hoursToMin(e.semanas.horarioFim)))
    if (found.length >= 1) {
        return found[0]
    }
    return null
}

export const updatePaymentTableById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.PRICE_TABLE, id: id, data: data })
}