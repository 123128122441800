import React from 'react';
import './login.css';
import 'firebase/auth';
import { fetchRevendaByHost } from '../../shared/service/revendaService';
import { getURLFromURI } from '../../store/filesWorker';

class TermosDeUsoPolitica extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            logoAdministrativo: ""
        }
        this.loadRevenda()
    }

    loadRevenda = async () => {
        const revenda = await fetchRevendaByHost()
        if (revenda.logoAdministrativo) {
            const logoAdministrativo = await getURLFromURI(revenda.logoAdministrativo)
            this.setState({ logoAdministrativo: logoAdministrativo })
        }
        this.setState({ revenda: revenda })
        this.setState({ isPagginLoading: false })
    }

    render() {
        return (
            <div className='row'>
                <div className='col-lg-6'>
                    <div className="image-container my-3">
                        <img height='50' src={this.state.logoAdministrativo} />
                    </div>
                    <div className="login-content">
                        <div className='row' style={{ textAlign: "justify" }}>
                            <h3 style={{ color: this.state.revenda?.siteCor, textAlign: "center" }}><strong>TERMOS E CONDIÇÕES GERAIS DE USO</strong></h3> <p />

                            Boas-vindas!<p />
                            Estes Termos e Condições Gerais de Uso aplicam-se aos serviços oferecidos pela SELFGROUP CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA, pessoa jurídica de direito privado inscrita no CNPJ sob nº 50.677.599/0001-70, com sede na Rua Desembargador Bandeira de Melo, n° 527, Sala 3, Santo Amaro, São Paulo/SP, CEP 04743-001 e responsabiliza você, Usuário, ao cumprimento destes Termos e Condições Gerais de Uso.
                            Qualquer pessoa (“Usuário”), que pretenda utilizar os serviços ora disponibilizados, deverá aceitar estes Termos e Condições Gerais de Uso, bem como a Política de Privacidade e demais legislações e princípios aplicáveis.
                            Ao utilizar as funcionalidades da Plataforma, de forma gratuita ou onerosa, você concorda em cumprir e em estar legalmente vinculado por este instrumento denominado Termos e Condições Gerais de Uso, o qual possui validade de um contrato de adesão entre você, Usuário, e a SELFGROUP CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA (SELFPARKING).
                            Os Termos e Condições Gerais de Uso são inteiramente publicizados, desta forma, não será considerado que seja alegado desconhecimento das regras e obrigações aqui estabelecidas.<p />
                            DEFINIÇÕES IMPORTANTES:<p />
                            1.1. Plataforma: Plataforma desenvolvida exclusivamente pela SELFGROUP CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA (SELFPARKING) e de sua legítima propriedade, através da qual o Usuário terá acesso às funcionalidades disponíveis, de forma gratuita ou onerosa.<br />
                            1.2. SaaS: Software as a Service. É o modelo de contratação (prestação de serviços) baseado na disponibilização do Sistema como serviço, utilizando infraestrutura/ambiente virtual oferecido pela SELFGROUP CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA (SELFPARKING).<br />
                            1.2. Conteúdo: Todo material disponibilizado e/ou criado na plataforma, o qual permanecerá de propriedade exclusiva do Usuário que o desenvolveu, de modo que o acesso aos conteúdos e funcionalidade da plataforma não ensejam qualquer transferência de direitos de propriedade intelectual dos Usuários.<br />
                            1.3. Usuário: É todo usuário, pessoa física devidamente cadastrado, que utiliza os serviços da Plataforma, podendo ser: Administradores de estacionamentos, lava-rápidos, manobristas e relacionados.<p />
                            1.4. Planos: Diferentes escolhas ou pacotes que a SELFPARKING oferece aos seus clientes ou usuários ao contratar seus produtos ou serviços. Esses planos são projetados para atender às variadas necessidades, proporcionando flexibilidade e opções personalizadas, incluindo diferentes combinações de recursos, níveis de serviço e preços. Em caso de upgrade de plano por maior volume de veículos do que foi contratado, a SELFPARKING manterá a cobrança do novo plano por, no mínimo, 3 (três meses), quando poderá o usuário solicitar o downgrade, se aplicável. 2. OBRIGAÇÕES E RESPONSABILIDADE DO USUÁRIO: <br />
                            1.5. Valores adicionais: Diferentes valores por consumo excedente que a SELFPARKING oferece aos seus clientes ou usuários ao contratar seus produtos ou serviços. Esses valores excedentes são projetados para atender às variadas necessidades, proporcionando flexibilidade e opções personalizadas, incluindo diferentes combinações de recursos, níveis de serviço e preços. <br />
                            1.6. Os valores estabelecidos neste contrato serão reajustados anualmente com base no Índice Nacional de Preços ao Consumidor Amplo (IPCA), divulgado pelo Instituto Brasileiro de Geografia e Estatística (IBGE) ou por outro índice que venha a substituí-lo oficialmente.
                            2. OBRIGAÇÕES E RESPONSABILIDADE DO USUÁRIO:<p />
                            2.1.  Para utilização da Plataforma, o Usuário deverá fornecer todos os dados que lhe forem solicitados, de maneira verídica, responsabilizando-se civil e criminalmente pela utilização de dados incorretos ou pertencentes a terceiros, sem prejuízo da respectiva suspensão ou cancelamento de acesso à Plataforma.<br />
                            2.1.1.  O uso da Plataforma é de responsabilidade exclusiva do Usuário. Dessa maneira, é absolutamente vedado qualquer uso ilícito, ilegal ou que provoque danos à Plataforma, aos demais usuários ou a terceiros. Ocorrendo tais danos, será de inteira e exclusiva responsabilidade do Usuário o ressarcimento devido.<br />
                            2.1.2. É vedado ao Usuário, ainda, divulgar quaisquer informações, dados, relatórios e materiais em geral obtidos na Plataforma a quaisquer terceiros não autorizados expressamente. O Usuário deverá, ainda, respeitar as diretrizes de tratamento de dados pessoais a que eventualmente tenha acesso em conformidade com a nossa Política de Privacidade. Tal vedação visa resguardar os Direitos de Propriedade Intelectual da Plataforma em relação a seu conteúdo e funcionalidades, bem como a privacidade dos demais usuários.<br />
                            2.2. Caso seja verificado a utilização indevida, ilegal, irregular, ou, ainda, contrária às disposições do presente instrumento, poderá ocorrer a suspensão ou ao cancelamento imediato do acesso do Usuário.<br />
                            2.3. A utilização de aparelhos compatíveis com as funcionalidades oferecidas, bem como a existência de uma conexão com a internet em velocidade adequada são de responsabilidade exclusiva do Usuário.<br />
                            2.4. É vedado ao Usuário utilizar contas cadastradas em nome de terceiros, bem como divulgar ou fornecer seu login e senha para terceiros alheios à empresa Contratante.<br />
                            2.5. É vedado aos Usuários realizar qualquer gravação ou divulgação de conteúdo disponibilizado na Plataforma, sob pena de suspensão imediata do acesso à Plataforma, sem prejuízo de eventual responsabilidade civil e criminal.<br />
                            2.6.  Os Usuários não poderão, dentre outras atitudes previstas nestes Termos e Condições Gerais de Uso:<br />
                            Utilizar o conteúdo da Plataforma em qualquer outro ambiente de computadores em rede, independente da finalidade a que se destina, bem como reproduzir ou copiar esse conteúdo para quaisquer fins;<br />
                            Utilizar qualquer dispositivo software ou outro recurso que venha a interferir nas atividades e operações da Plataforma, bem como nas descrições, contas ou seus bancos de dados. Qualquer intromissão, tentativa ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas nestes Termos e Condições Gerais de Uso, tornarão o responsável sujeito às ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados;<br />
                            Coletar informações pessoais sobre os Usuários da Plataforma sem o consentimento, incluindo o uso de qualquer tipo de robô, spider, busca do site ou aplicativo de recuperação ou outro dispositivo manual ou automático ou processo para recuperar, indexar ou minerar dados;<br />
                            Transmitir ou tornar disponível em conexão com a Plataforma qualquer vírus, worm, cavalo de Tróia, bomba relógio/time bomb, bug web, spyware, ou qualquer outro código de computador, arquivo ou programa que pode ou se destina a ocasionar danos ou sequestrar a operação de qualquer equipamento de hardware, software ou de telecomunicações, ou de qualquer outro fato ou potencialmente prejudicial, perturbador, ou código invasivo ou componente;<br />
                            Transferir ou ceder o acesso e a senha de sua conta na Plataforma a terceiros, ainda que temporariamente;<br />
                            Praticar condutas consideradas "spamming", seja em opiniões, perguntas, respostas e/ou no envio solicitado de e-mails.<br />
                            Copiar, modificar, adaptar, traduzir, fazer engenharia reversa, descompilar ou desmontar qualquer parte do conteúdo disponibilizado na Plataforma, bem como de hardwares e softwares ou de qualquer forma exibir publicamente, executar ou distribuir esse conteúdo em desacordo com os limites expressamente permitidos nestes Termos e Condições Gerais de Uso;<br />
                            Vender, licenciar, ou explorar para quaisquer fins comerciais qualquer uso ou acesso ao conteúdo e serviços da Plataforma;<br />
                            Adaptar ou espelhar qualquer parte da Plataforma;
                            Encaminhar qualquer dado ou conteúdo produzido a partir da utilização dos serviços do Plataforma para utilização de terceiros.<p />
                            3.  OBRIGAÇÕES E RESPONSABILIDADES DA EMPRESA/PLATAFORMA:<p />
                            3.1.  A Plataforma poderá, a qualquer tempo e sem a necessidade de anuência do usuário, passar por modificações, podendo suspender, adicionar ou cancelar, temporária ou permanentemente, qualquer funcionalidade da Plataforma.<br />
                            3.2.  Dessa maneira, a Plataforma poderá sofrer adaptações constantes em decorrência de mudanças de tecnologia, inclusive de terceiros, como banco de dados, sistemas operacionais dos dispositivos, bibliotecas, fixes de segurança, entre outros.<br />
                            3.3.  Realizar o tratamento dos dados pessoais em conformidade com a legislação em vigor, dando transparência ao Usuário acerca dos dados coletados, armazenamento e exclusão, sendo que tais informações estarão contidas na respectiva Política de Privacidade.<br />
                            3.4. A SELFGROUP CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA (SELFPARKING) se reserva o direito de alterar os valores dos serviços ofertados na plataforma a qualquer tempo, independentemente de notificação prévia aos Usuários.<p />
                            4. DO FUNCIONAMENTO DA PLATAFORMA:<p />
                            4.1. Para o Usuário cadastrar-se na Plataforma deve atender todas as exigências nela descritas, fornecendo as informações requeridas de forma precisa e verdadeira. O Usuário declara e assume o compromisso de atualizar os dados inseridos em seu cadastro ("Dados Pessoais") sempre que for necessário ou solicitado pela Plataforma.<br />
                            4.2. Os serviços da Plataforma estão disponíveis apenas para as pessoas físicas que tenham capacidade legal. Em caso de utilização da Plataforma por Usuários que não tenham capacidade civil (com relação a pessoas físicas), como os menores de 18 (dezoito) anos, os responsáveis legais arcarão com todas as responsabilidades decorrentes de eventuais ilícitos e/ou danos ocasionados.<br />
                            4.3. É reservado a Plataforma o direito de recusar qualquer solicitação de cadastro ou de suspender um cadastro previamente aceito que esteja em desacordo com esses Termos e Condições Gerais de Uso ou com a Política de Privacidade.<br />
                            4.4. A SELFGROUP CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA (SELFPARKING) poderá, a seu exclusivo critério, realizar as buscas que julgar necessárias para apurar dados incorretos ou inverídicos, solicitar dados adicionais e documentos que considere pertinentes para conferência dos Dados Pessoais cadastrados e, ainda, recorrer à base de dados públicas ou privadas, podendo o Usuário editá-las caso entenda que tais dados não estejam atualizados.<br />
                            4.5. Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta de acesso. Também não se permitirá a manutenção de mais de um cadastro por uma mesma pessoa, ou ainda a criação de novos cadastros por pessoas cujos cadastros originais tenham sido suspensos temporária ou definitivamente por infrações a esses Termos e Condições Gerais de Uso e outras políticas e legislações aplicáveis.<br />
                            4.6. Ao completar o envio de seu cadastro, o Usuário concorda em receber mensagens na sua caixa de correspondência eletrônica (e-mail),através das ferramentas específicas a respeito do uso da Plataforma.<br />
                            4.7. O Usuário será o único responsável por seu login e senha e responderá por todos os atos praticados em sua conta de acesso. Portanto, é dever do Usuário zelar pela guarda e confidencialidade de sua senha. Caso o usuário deseje trocar sua senha, deverá seguir as orientações descritas na Plataforma.<br />
                            4.8. O Usuário compromete-se a comunicar imediatamente a respeito de qualquer uso não autorizado de sua conta, bem como em caso de acesso não autorizado por terceiros. O Usuário será o único responsável pelas operações efetuadas em sua conta, uma vez que o acesso só será possível mediante a inclusão da senha, que deverá ser de conhecimento e propriedade exclusiva do Usuário.<br />
                            4.9. O Usuário compromete-se em criar seus conteúdos levando em conta a veracidade das informações. As ferramentas de criação de aulas, material impresso, exercícios, avaliação e quaisquer outras de criação de criação de conteúdo são utilizadas pelo usuário para desenvolvimento e compartilhamento com outros usuários. Todo o conteúdo criado é de responsabilidade do usuário.<p />
                            5. SUSPENSÃO OU CANCELAMENTO DO ACESSO:<p />
                            5.1. Sem prejuízo de outras medidas cabíveis, a SELFGROUP CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA (SELFPARKING) poderá advertir, suspender, ou cancelar temporária ou definitivamente, a conta de um Usuário, a qualquer tempo, iniciando as ações legais cabíveis e/ou suspendendo a funcionalidade da Plataforma em caso de:<br />
                            Suspeita de ilegitimidade, ilegalidade e/ou fraude;
                            Impossibilidade em verificar a identidade do Usuário ou se qualquer informação fornecida por ele no cadastro estiver incorreta;
                            Descumprimento dos deveres de Usuário;
                            Prática de atos pelo Usuário que tenha causado algum dano a terceiros ou a própria Plataforma ou tenham a potencialidade de assim o fazer;
                            Violação ou descumprimento de qualquer dispositivo destes Termos e Condições Gerais de Uso, Política de Privacidade e demais políticas e legislações aplicáveis;
                            Não pagamento dos valores devidos a título de mensalidade para a licença de uso da Plataforma que, além da suspensão ou cancelamento do acesso, estarão sujeitos a cobrança de juros e mora.<br />
                            5.2. Em qualquer hipótese de suspensão da conta do Usuário este não terá direito a qualquer indenização ou ressarcimento.<p />
                            6. ALTERAÇÕES DOS TERMOS E CONDIÇÕES GERAIS DE USO:<p />
                            6.1. O presente termo poderá ser alterado, a qualquer tempo, visando seu aprimoramento e melhoria dos serviços prestados. Os novos Termos e Condições Gerais de Uso entrarão em vigor imediatamente após sua publicação na Plataforma.<p />

                            7. LIMITAÇÃO DE RESPONSABILIDADE:<p />
                            7.1. A Plataforma se reserva o direito de auxiliar e cooperar com qualquer autoridade judicial ou órgão governamental, podendo enviar informações cadastrais do Usuário quando for obrigada por decisão judicial ou por força de determinação legal.<br />
                            7.2. A Plataforma não se responsabiliza por:<br />
                            Caso fortuito ou força maior, nos termos da legislação aplicável;
                            Inadimplência por parte dos Usuários;
                            Ações de terceiros que afetem a estabilidade da Plataforma;
                            Qualquer fraude, declaração fraudulenta ou violação do dever por parte de qualquer Usuário ou terceiro;
                            Qualquer comentário realizado na Plataforma, ou fora dela, de maneira ofensiva, ilícita, grosseira ou desrespeitosa por Usuários ou terceiros;
                            Qualquer inexatidão nas informações inseridas por Usuários.
                            7.3. São adotadas todas as medidas técnicas e organizativas para segurança das informações presentes em sua Plataforma. Nada obstante, nenhum sistema é absolutamente impenetrável, podendo, portanto, sofrer alguma espécie de invasão por hackers ou qualquer outro agente malicioso. Nessas hipóteses, não será a Plataforma responsável por qualquer exclusão, obtenção, utilização ou divulgação não autorizada de informações resultantes de ataques que ela não poderia razoavelmente impedir.<br />
                            7.4. Caso a Plataforma seja acionada judicialmente por atos atribuíveis aos Usuários, caberá a estes requerer a respectiva exclusão do polo passivo, sob pena do ajuizamento de ação de regresso.<br />
                            7.5. Todas as comunicações que consistam em avisos na Plataforma serão consideradas como efetivamente recebidas e compreendidas.<br />
                            7.6. É de inteira responsabilidade do Usuário manter o ambiente de seu dispositivo (computador, celular, tablet, entre outros) seguro, com o uso de ferramentas disponíveis, como antivírus, firewall, entre outras, de modo a contribuir na prevenção de riscos eletrônicos.<br />
                            7.7. É possível que a Plataforma possa conter links para sites e aplicativos de terceiros, assim como ter tecnologias integradas. Isso não significa, de maneira alguma, que tais links sejam verificados ou possuem garantia de ligação com os proprietários da Plataforma, não sendo responsável pelo seu conteúdo, precisão, políticas, práticas ou opiniões. É recomendado que você leia os Termos de Uso e Políticas de Privacidade de cada site de terceiros ou serviço que o usuário vier a visitar ou utilizar.<p />
                            8. ALCANCE DOS SERVIÇOS:<p />
                            8.1. Estes Termos não geram nenhum contrato de sociedade, de mandato, de franquia, relação de trabalho, parceria, ou congênere entre os Usuários e a Plataforma, servindo somente para reger a utilização das funcionalidades nela disponíveis.<br />
                            8.2. Não vale o presente instrumento, de nenhum modo, como meio válido para transmissão da propriedade intelectual referente à Plataforma ou a qualquer conteúdo disponível.<br />
                            8.2.1. É vedado ao Usuário modificar, copiar, distribuir, transmitir, exibir, realizar, reproduzir, publicar, disponibilizar, licenciar ou criar obras derivadas a partir das informações coletadas na Plataforma, bem como transferir ou utilizar para fins comerciais tais informações, softwares, produtos ou serviços, sob pena de violação deste instrumento e caracterização de infração legal.<br />
                            8.2.2. Quaisquer marcas, registradas ou não, ou outros elementos que remetam à identidade visual da Plataforma são exclusivamente de propriedade da SELFGROUP CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA (SELFPARKING), sendo sua violação punível nos termos da legislação aplicável, sem prejuízo do imediato impedimento e por tempo indeterminado de acesso à Plataforma, bem como das sanções de natureza cível e criminal.<br />
                            8.2.3. A Plataforma e suas funcionalidades, aprimoramentos e derivações são protegidos pelas Leis nº 9.609/1998 (Proteção de Softwares) e 9.610/1998 (Direitos Autorais), bem como pelas demais disposições de propriedade intelectual aplicáveis no Brasil, sendo esta a única jurisdição aplicável em qualquer possível controvérsia que venha a surgir em relação a estes Termos e Condições Gerais de Uso.<br />
                            8.2.4. Todos os direitos relativos a Plataforma e suas funcionalidades são de propriedade exclusiva do seu criador, inclusive no que diz respeito aos seus textos, imagens, layouts, software, códigos, bases de dados, gráficos, artigos, relatórios, fotografias, vídeos, artigos, manuais e demais conteúdos produzidos.<br />
                            8.2.5. O conteúdo da Plataforma é protegido pela lei de direitos autorais e de propriedade intelectual. É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do Conteúdo da Plataforma, para qualquer finalidade, sem o consentimento prévio e expresso. Qualquer uso não autorizado do Conteúdo da Plataforma será considerado como violação dos direitos autorais e de propriedade intelectual.<p />
                            9. PROBLEMAS DECORRENTES DO USO DA PLATAFORMA:<p />
                            9.1. A Plataforma é disponibilizada aos Usuários no estado técnico em que se encontra. Dessa maneira, apesar dos inúmeros esforços para que a Plataforma seja livre de interrupções e qualquer defeitos, não há garantia de que as funções contidas na Plataforma atendam às necessidade específicas do Usuário, tampouco garante que a operação da Plataforma será ininterrupta ou livre de erros, que qualquer serviço continuará disponível, nem que os defeitos da Plataforma serão corrigidos.<p />
                            10. MODIFICAÇÃO DOS TERMOS DE USO:<p />
                            10.1. Estes Termos de Uso e os documentos integrados por referência expressam o acordo total entre Usuários e a Plataforma.<br />
                            10.2. Ocasionalmente, poderão ser realizadas alterações nestes Termos. Quando tais alterações forem realizadas, os Usuários serão avisados dentro da Plataforma e, caso não aceite os novos termos, poderá  ser suspenso o seu acesso.<br />
                            10.3. Caso qualquer das cláusulas destes Termos seja considerada inválida, as demais permanecerão hígidas e eficazes.<p />
                            11. LEGISLAÇÃO APLICÁVEL E FORO DE ELEIÇÃO:<p />
                            11.1. Todos os itens destes Termos são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou a qualquer outro questionamento relacionado a estes Termos, as partes concordam em se submeter ao Foro da Comarca de São Paulo/SP.<p />
                            12. FALE CONOSCO:<p />
                            12.1. Sempre que necessário, para tratar de qualquer tema envolvendo o uso da Plataforma, ou caso haja qualquer espécie de dúvidas a respeito do conteúdo do presente Termo, por favor, entre em contato através do nosso e-mail: parceiros@selfparking.com.br<br />
                            12.2.  Qualquer notificação ao Usuário, quando necessária, será feita por e-mail ou dentro da própria Plataforma.<br />
                            12.3. Ao utilizar a Plataforma, o Usuário declara que leu e entendeu todas as informações aqui constantes, aderindo a este instrumento para todos os fins e efeitos de direito.<p />

                            Obrigado por ler nossos Termos!<p />

                            Última atualização em: 06 de Dezembro de 2023 <p /><p />
                            <h3 style={{ color: this.state.revenda?.siteCor, textAlign: "center" }}><strong>POLÍTICA DE PRIVACIDADE SELFPARKING</strong></h3> <p />
                            1. Introdução<p />
                            Estamos cientes do quão importante é a sua privacidade e, por isso, lidamos com os seus dados pessoais com muita responsabilidade.
                            A fim de alcançar este objetivo e atentos às disposições das leis de proteção de dados pessoais, elaboramos essa Política de Privacidade assumindo uma missão: tratar os seus dados pessoais de forma que seja evitada qualquer perda, alteração, roubo ou qualquer outra forma de uso que não esteja de acordo com essa Política de Privacidade.
                            Dessa forma, dando transparência ao nosso tratamento dos dados pessoais coletados tornamos pública esta política. Aceitar esta política é uma condição para que possamos fornecer uma experiência positiva no uso de nossa plataforma, atendendo a todas as regulações e legislação vigente. Caso não concorde com esta política, você não poderá utilizar nossos serviços.<p />
                            Lembrando que, caso você tenha qualquer dúvida, você sempre pode nos contatar através do seguinte e-mail: parceiros@selfparking.com.br<p />

                            2. Quais são os direitos dos titulares dos dados pessoais? <p />
                            Em relação aos seus dados pessoais, você possui os seguintes direitos:<br />
                            Direito de limitar o tratamento de seus dados pessoais, podendo obtê-la quando contesta a exatidão dos dados, quando o tratamento for ilícito, quando não se precisar mais dos dados para as finalidades propostas, quando tiver oposição ao tratamento dos dados e ainda em caso de tratamento de dados desnecessários;
                            Direito de, a qualquer momento, se opor por motivos relacionados com a sua situação particular, ao tratamento dos dados pessoais que lhe digam respeito; <br />
                            Direito de revogar o consentimento, observando as disposições legais; <br />
                            Direito do usuário de obter a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais; <br />
                            É concedido o direito do usuário de obter a retificação dos dados pessoais inexatos aos quais lhe digam respeito; <br />
                            O direito do usuário de ter seus dados apagados, observando-se as exceções previstas em lei. <br />
                            Esses direitos poderão ser exercidos via e-mail através do seguinte endereço parceiros@selfparking.com.br <br />
                            Garantimos, adicionalmente, que o tratamento dos seus dados pessoais observará os seguintes princípios: <br />
                            Minimização dos dados: os dados pessoais do usuário serão coletados de forma adequada, pertinente e limitada às necessidades do objetivo para os quais eles são processados; <br />
                            Limitação das finalidades: os dados pessoais do usuário serão coletados apenas para finalidades determinadas, explícitas e legítimas descritas nesta Política de Privacidade, não podendo ser tratados posteriormente de uma forma incompatível com essas finalidades; <br />
                            Integridade e transparência: os dados pessoais do usuário serão tratados de forma segura, protegidos do tratamento não autorizado ou ilícito e contra a sua perda, destruição ou danificação acidental, adotando as medidas técnicas ou organizativas adequadas; <br />
                            Licitude, lealdade e transparência: os dados pessoais do usuário serão processados de forma lícita, leal e transparente, para os fins previstos na presente Política. <br />
                            Exatidão: os dados pessoais do usuário serão exatos e atualizados sempre que necessário, de maneira que os dados inexatos sejam apagados ou retificados quando possível; <br />
                            Limitação da conservação: os dados pessoais do usuário serão conservados de uma forma que permita a identificação dos titulares dos dados apenas durante o período necessário para as finalidades para as quais são tratados; <p />
                            3. Quais dados e informações coletamos e para qual finalidade? <p />
                            Todos os seus dados coletados e/ou fornecidos estarão em conformidade com o disposto nas legislações de proteção de dados, bem como com esta Política de Privacidade, e serão  tratados como confidenciais, utilizando-os somente para fins aqui dispostos e/ou autorizados por você. <br />
                            Os dados são coletados quando você os insere ou os submete voluntariamente ao acessar e interagir com as funcionalidades disponibilizadas, que inclui:
                            Dados coletados<br />
                            Finalidade
                            Qual é o fundamento legal para a utilização dos seus dados pessoais?
                            Dados Pessoais para Cadastro<p />

                            Nome completo, e-mail, telefone, CNPJ, Endereço.<p />
                            Tais dados são utilizados para que você se cadastre em nossa plataforma e, adicionalmente, possa utilizar as funcionalidades nela disponíveis como forma de testar gratuitamente nossos serviços, por um período de tempo determinado, bem como possa entrar em contato conosco para obter maiores informações.
                            Cumprimento de obrigação legal e consentimento do titular.<p />
                            Dados Pessoais de Utilização<p />

                            Endereço IP, Registros de interações com este website ou landing pages, tipo e versão de navegador, informações de geolocalização, etc.<p />
                            Estes são os dados pessoais coletados sobre você quando acessa nossa plataforma, coletando informações sobre o device utilizado e comportamento no site, com a finalidade de melhorar nossos conteúdos e serviços.
                            Consentimento, quando se tratar de cookies de terceiros e/ou não essenciais; legítimo interesse quando se tratar de cookies necessários.
                            <p />
                            Eventualmente, poderemos utilizar seus dados para finalidades não previstas nesta Política, mas estas estarão dentro das suas legítimas expectativas. O eventual uso dos seus dados para as finalidades que não cumpram com essa prerrogativa será feito mediante a sua autorização prévia.
                            Ao navegar em nossas páginas, poderemos utilizar uma tecnologia comum na rede internacional de computadores, chamada “cookies”, através dos quais são armazenadas algumas informações em seu computador. Este armazenamento possibilita uma maior facilidade de navegação, em muitos casos, uma experiência personalizada para você.  Assim, quando você visita e/ou interage com o nosso site, nosso servidor da web grava automaticamente detalhes sobre suas interações (por exemplo, seu endereço IP, a localização da qual você nos visita, o tipo de software do navegador usado, as páginas da que você realmente visita, incluindo a data e duração da sua visita).
                            A maioria dos navegadores permitem que você apague esses arquivos de seu computador, no entanto, caso o usuário configure seu navegador para bloquear os cookies, a experiência de navegação poderá restar prejudicada.
                            É importante destacar também que não compartilhamos estes dados com terceiros, além daqueles responsáveis pelos próprios cookies.<p />
                            Os cookies que utilizamos, bem como as finalidades para cada cookie, estão listados no nosso cookie banner, no qual você poderá dar o aceite de uso deles.<p />
                            4. Alterações na presente política<p />
                            Nos reservamos o direito de modificar essa Política de Privacidade a qualquer tempo e quantas vezes forem necessárias, em função da adequação a eventuais alterações feitas em âmbito legislativo, visando fornecer a você cada vez mais segurança e conveniência. Para tanto, é recomendado que você a revise sempre que possível.
                            Com a finalidade de facilitar, sempre indicaremos no fim do documento a data da última atualização, e se alterações substanciais forem feitas, notificaremos você sobre tais alterações antes que entrem em vigor.
                            Certifique-se, pois, de ler atentamente qualquer aviso desta natureza.
                            Ao continuar a acessar ou usar nossos serviços após essas revisões entrarem em vigor, você concorda em ficar vinculado aos termos revisados.<p />
                            5. Compartilhamento ou Divulgação de Dados Pessoais<p />
                            As informações coletadas são de nosso uso exclusivo para as finalidades aqui referidas, e  não serão vendidas a terceiros em qualquer hipótese.
                            No entanto, podemos divulgar os seus dados pessoais na crença de boa fé de que tal ação é necessária para:<p />
                            Conformidade legal. Compreendendo (i) processos e requisições legais; (ii) estabelecimento ou exercício de nossos direitos legais ou defesa em processos judiciais; ou (iii) conforme requerido por lei; e
                            Prestação de Serviços por Terceiros. Podemos, ainda, atuar em conjunto com outras empresas em diversas atividades para facilitar o nosso serviço, para executar serviço, bem como patrocinadores dos serviços que serão prestados. Estes recebem seus dados apenas na medida do necessário para a prestação dos serviços contratados. Ressaltamos que nossos contratos são orientados pelas normas de proteção de dados do ordenamento jurídico brasileiro, proibindo estes terceiros de divulgar ou usar os dados para qualquer outra finalidade.
                            6. Responsável pelo tratamento de dados pessoais
                            A responsável pelo tratamento dos dados pessoais coletados é a <p />
                            SELFGROUP CONSULTORIA EM TECNOLOGIA DA INFORMACAO LTDA pessoa jurídica de direito privado inscrita no CNPJ sob nº 50.677.599/0001-70, com sede na Rua Desembargador Bandeira de Melo, n° 527, Sala 3, Santo Amaro, São Paulo/SP, CEP 04743-001.<p />
                            7. Segurança no tratamento dos seus dados pessoais<p />
                            A SELFPARKING compromete-se a proteger sua privacidade aplicando medidas técnicas e organizativas aptas a proteger os seus dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.
                            Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas; os custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do usuário.<p />
                            8. Transferência internacional de dados pessoais<p />
                            A SELFPARKING possui sua sede no Brasil e os dados que coletamos são regidos pela lei brasileira. Entretanto, os dados que coletamos estão armazenados em servidores do Google Cloud localizados no (Brasil, São Paulo/SP), nos termos do que dispõe a Lei Geral de Proteção de Dados Pessoais em seu artigo 33. A SELFPARKING neste contexto, adota todas as medidas de segurança para proteger seus dados pessoais.<p />
                            9. Agradecemos por ler nossa Política de Privacidade!<p />
                            Estaremos sempre à disposição para atendê-lo. Caso tenha restado alguma dúvida ou deseje obter mais informações sobre essa Política, estamos à disposição para atendê-los no endereço eletrônico: parceiros@selfparking.com.br <p />

                            Última atualização em: 06 de Dezembro de 2023 <p />
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 pos-container' style={{ backgroundColor: this.state.revenda?.siteCor }}>
                    <div className="row">
                        <div className="promotion-content" style={{ backgroundColor: this.state.revenda?.siteCor }}>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export { TermosDeUsoPolitica };