import { fetchFunction } from '../function-request'

export const getRevendaByCode = async ({ code }) => {
    const options = {
        method: "GET",
        headers: {
            'Cache-Control': 'public, max-age=300, s-maxage=600'
        }
    }
    return await fetchFunction(`/resele/reseleIdByCode/${code}`, options)
}

export const fetchRevendaByHost = async () => {
    const origin = window.location.origin
    var resaleId = ""
    if (origin.includes("madis.neotagus.com.br")) {
        resaleId = "feHh4buNASnnh6ahmYJ3"
    } else if (origin.includes("dimep.neotagus.com.br")) {
        resaleId = "UhkTEfwE0GH4wpLpfZZm"
    } else if (origin.includes("parking.neotagus.com.br")) {
        resaleId = "bjhYBaWdULPY9XO7K0VH"
    } else {
        resaleId = process.env.REACT_APP_REVENDA_ID
    }
    const options = {
        method: "GET",
        headers: {
            'Cache-Control': 'public, max-age=300, s-maxage=600'
        }
    }
    const resale = await fetchFunction(`/resele/reseleById/${resaleId}`, options)
    document.title = resale.nome
    return resale
}