import React from 'react'
import { NavBar } from '../../components/navbar'
import firebase from '../../config/firebase';
import { EmptyContent } from '../../components/common/commons'
import { getUsuario } from '../../config/auth'
import 'firebase/auth';
import 'firebase/storage';
import Moment from 'moment';
import { fetchFunction } from '../../shared/function-request';
import { deleteDoc } from '../../store/collections/collectionBaseWorker';
import { toastSuccess, toastWarning } from '../../shared/utils';

class Marketing extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isEmpty: false,
            isSending: false,
            reSend: false,
            templateId: '',
            templates: [
                {
                    id: 'd-e6a061e8af774216b85169b196c7236e',
                    nome: "Primeiro contato"
                },
                {
                    id: 'd-b8f27e30eeb3401aa9c41cab40ce7cd7',
                    nome: "30 dias grátis"
                }
            ],
            isSendingMessage: '',
            random: Math.random(),
            emails: '',
            allEmails: []
        }
    }

    componentDidMount = () => {
        this.load()
    }

    send = async () => {
        if (this.state.emails === undefined || this.state.emails === "" || this.state.templateId === '') {
            toastWarning("Informe pelo menos um e-mail ou vários separando-os por virgula.")
            return
        }
        const sendedEmails = this.state.allEmails.map(e => e.email)
        const emails = this.state.emails.split(',')
        for (var i = 0; i < emails.length; ++i) {
            const email = emails[i].replace('\n', '').trim()
            if (this.state.reSend === false && sendedEmails.indexOf(email) !== -1) {
            } else {
                if (email !== '') {
                    var template = this.state.templates.filter(e => e.id === this.state.templateId)[0]
                    this.setState({ isSending: true })
                    const body = {
                        to: email,
                        template_id: template.id
                    }
                    const options = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: body
                    };
                    this.setState({ isSendingMessage: "Enviando e-mail para: " + email })
                    const result = await fetchFunction("/sendEmail", options)
                    if (result.success) {
                        firebase.firestore().collection('email-marketing').add({
                            email: email,
                            template: template,
                            createdAt: new Date(),
                            createdBy: getUsuario().email,
                        })
                    }
                }
            }
        }
        this.setState({ isSending: false })
        toastSuccess("Lista executada com sucesso!")
        this.load()
    }

    load = async () => {
        this.setState({ loading: true })
        const result = await firebase.firestore().collection('email-marketing').get()
        this.setState({ isEmpty: result.empty })
        this.setState({ loading: false })
        if (!result.empty) {
            var list = [];
            result.docs.forEach(doc => {
                list.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            list = list.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
            this.setState({ allEmails: list })
        }
        this.setState({ emails: '' })
    }

    onRemove = async (item) => {
        var confirm = window.confirm('Deseja mesmo remover este e-mail marketing?');
        if (confirm) {
            await deleteDoc({ collection: "email-marketing", id: item.id })
            this.load()
        }
    }

    onReSend = (item) => {
        const emails = this.state.emails.split(',')
        if (emails.indexOf(item.email) === -1) {
            if (emails.length <= 1) {
                const newEmails = item.email + ","
                this.setState({ emails: newEmails })
            } else {
                const char = this.state.emails.slice(-1);
                if (char === ',') {
                    const newEmails = this.state.emails + item.email + ","
                    this.setState({ emails: newEmails })
                } else {
                    const newEmails = this.state.emails + "," + item.email + ","
                    this.setState({ emails: newEmails })
                }
            }
        }
    }

    render() {
        return (
            <>
                <NavBar>
                    {this.state.isEmpty === false && this.state.loading === true && <EmptyContent text="Carregando informações..." />}
                    {this.state.loading === false &&
                        <>
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    E-mail Marketing
                                </div>
                                <div className="card-body">
                                    <div className='row'>
                                        {
                                            this.state.isSending === false ?
                                                <>
                                                    <div className='col-lg-12'>
                                                        <label>E-mails para envio</label>
                                                        <textarea rows={5} value={this.state.emails} onChange={(e) => this.setState({ emails: e.target.value })} className="form-control" />
                                                    </div>
                                                    <div className='col-lg-2 col-md-12 my-2'>
                                                        <label>Selecionar template</label>
                                                        <select className="form-select" value={this.state.templateId} onChange={(e => this.setState({ templateId: e.target.value }))}>
                                                            <option value=''>Selecione</option>
                                                            {
                                                                this.state.templates.map(e => <option value={e.id}>{e.nome}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className='col-lg-2 col-md-12 my-2'>
                                                        <label>Repetir envio</label>
                                                        <select className="form-select" value={this.state.reSend} onChange={(e => this.setState({ reSend: e.target.value }))}>
                                                            <option value={false}>Não</option>
                                                            <option value={true}>Sim</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-lg-12 my-2'>
                                                        <button type="button" className="btn btn-danger" onClick={(e) => this.send()}>Enviar e-mails</button>
                                                    </div>
                                                </> :
                                                <div className='col-lg-12'>
                                                    <h5>{this.state.isSendingMessage}</h5>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    E-mails enviados
                                </div>
                                {
                                    this.state.allEmails !== null &&
                                    <div className='table-responsive'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <td><strong>Email</strong></td>
                                                    <td><strong>Último Envio</strong></td>
                                                    <td><strong>Enviado por</strong></td>
                                                    <td><strong>Template</strong></td>
                                                    <td width="80"></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.allEmails.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{item.email}</td>
                                                            <td>
                                                                {Moment(item.createdAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}
                                                            </td>
                                                            <td>{item.createdBy}</td>
                                                            {
                                                                item.templateId === undefined ?
                                                                    <td>{item.template.nome}</td> :
                                                                    <td>{item.templateId === "d-e6a061e8af774216b85169b196c7236e" && "Primeiro contato"}</td>
                                                            }
                                                            <div className='btn-group'>
                                                                <button type="button" onClick={() => { this.onReSend(item) }} className="btn btn-warning text-white">
                                                                    <i className="fas fa-copy"></i>
                                                                </button>
                                                                <button type="button" onClick={() => { this.onRemove(item) }} className="btn btn-danger">
                                                                    <i className="fas fa-trash" />
                                                                </button>
                                                            </div>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </NavBar>
            </>
        )
    }
}

export { Marketing };