import React from 'react';
import { NavBar } from '../../components/navbar'
import { getEstacionamento } from '../../config/auth'
import { EmptyContent, FilterComponent } from '../../components/common/commons'
import { getAllTicketsByDatesInRange } from '../../store/collections/ticketWorker';
import { TicketAnalyticsBody } from '../buscas/ticketSearch';

class ReportAccess extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notFound: false,
            screen: "search",
            tickets: [],
            random: Math.random(),
            estacionamentoId: getEstacionamento()?.id,
            estacionamentosIds: []
        }
    }

    find = async () => {
        this.setState({ state: "loading" })
        const tickets = await getAllTicketsByDatesInRange({
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            estacionamentosIds: this.state.estacionamentosIds
        })
        this.setState({ tickets: tickets })
        this.setState({ random: Math.random() })
        this.setState({ screen: "fill" })
    }

    onApplyFilter = (item) => {
        this.setState({ startDate: item.startDate })
        this.setState({ endDate: item.endDate })
        this.setState({ estacionamentosIds: item.estacionamentosIds }, () => {
            this.find()
        })
    }

    render() {
        return (
            <>
                <NavBar>
                    <FilterComponent onApplyFilter={this.onApplyFilter}>
                        {
                            this.state.screen === "fill" &&
                            <TicketAnalyticsBody key={this.state.random} tickets={this.state.tickets} />
                        }
                        {
                            this.state.screen === "loading" &&
                            <EmptyContent text="Carregando informações..." />
                        }
                    </FilterComponent>
                </NavBar>
            </>
        )
    }
}

export { ReportAccess };