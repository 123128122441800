import moment from "moment"
import { getAllChagesByDatesRange } from "../../../store/collections/monthlyChagesWorker"
import { getAllTicketsByDatesInRange } from "../../../store/collections/ticketWorker"

export const getAllPaymentsInPastDays = async ({ days, estacionamentosIds }) => {
    var startDate = moment().add(-parseInt(days), 'days').toDate()
    var endDate = moment().toDate()
    const result = await getAllPaymentsInRange({ startDate: startDate, endDate: endDate, estacionamentosIds: estacionamentosIds })
    const item = {
        totals: {
            tpv: generateTotalItem({ items: result }),
            payed: generateTotalItem({ items: result, status: "payed" }),
            pending: generateTotalItem({ items: result, status: "pending" }),
            ticketPayed: generateTotalItem({ items: result.filter(e => e.type === "ticket") }),
            monthlyPayed: generateTotalItem({ items: result.filter(e => e.type === "monthly-charge") }),
        },
        items: result
    }
    console.log(item)
    return item
}

export const generateTotalItem = ({ items, status }) => {
    if (status) {
        const total = items.filter(e => e.status === status)
        return {
            quantity: total.length,
            total: total.map(e => e.value).reduce((a, b) => a + b, 0)
        }
    } else {
        return {
            quantity: items.length,
            total: items.map(e => e.value).reduce((a, b) => a + b, 0)
        }
    }
}

export const getAllPaymentsInRange = async ({ startDate, endDate, estacionamentosIds }) => {
    var payments = await getAllMonthlyChargesInRange({ startDate: startDate, endDate: endDate, estacionamentosIds: estacionamentosIds })
    const tickets = await getAllTicketsInRange({ startDate: startDate, endDate: endDate, estacionamentosIds: estacionamentosIds })
    payments = payments.concat(tickets)
    return payments.sort((a, b) => b.date - a.date)
}

const getAllTicketsInRange = async ({ startDate, endDate, estacionamentosIds }) => {
    const result = await getAllTicketsByDatesInRange({
        startDate: startDate,
        endDate: endDate,
        estacionamentosIds: estacionamentosIds
    })
    return result.filter(e => e.spun !== true).map(e => {
        var status = ""
        switch (e.status) {
            case "Pago e Entregue":
            case "Pago e Estacionado":
            case "Pago e Aguardando":
                status = "payed"; break
            case "Estacionado":
                status = "pending"; break
            case "Cancelado":
                status = "canceled"; break
        }
        if (e.paymentsLogged && e.paymentsLogged.length > 0) {
            return e.paymentsLogged.filter(e => (e.resumo?.valorCobrado ?? 0) > 0).map(f => {
                return {
                    id: e.id,
                    path: `/buscar/ticket?id=${e.id}`,
                    type: "ticket",
                    status: status,
                    value: f.resumo?.valorCobrado ?? 0,
                    date: f.createAt,
                    paymentMethod: f.formaPagamento && {
                        id: f.formaPagamento?.id,
                        name: f.formaPagamento?.nome,
                        type: f.formaPagamento?.tipo,
                    }
                }
            })
        }
        return []
    }).flatMap(e => e)
}

const getAllMonthlyChargesInRange = async ({ startDate, endDate, estacionamentosIds }) => {
    const result = await getAllChagesByDatesRange({
        startDate: startDate,
        endDate: endDate,
        estacionamentosIds: estacionamentosIds
    })
    return result.map(e => {
        return {
            id: e.id,
            path: `/visualizar/mensalista/${e.monthlyId}?chargeId=${e.id}`,
            type: "monthly-charge",
            status: e.status,
            value: e.payment?.total ?? e.netValue,
            date: e.changeDate,
            paymentMethod: e.payment && {
                id: e.payment?.formaPagamento?.id,
                name: e.payment?.formaPagamento?.nome,
                type: e.payment?.formaPagamento?.tipo,
            }
        }
    })
}