import { createStore } from 'redux';
import usuarioReducer from './usuarioReducer.js';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'parkmob',
    storage
}

const store = createStore(persistReducer(persistConfig, usuarioReducer));

export default store