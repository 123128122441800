import React from 'react'
import { NavBar } from '../../components/navbar'
import { ClientButton, EmptyContent, Loading } from '../../components/common/commons'
import firebase from '../../config/firebase';
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Link } from 'react-router-dom';
import 'firebase/auth';
import 'firebase/storage';
import { getAllUsers } from '../../store/collections/userWorker';
import { getEstacionamento } from '../../config/auth';
import { getParameterByParkId } from '../../store/collections/parameterWorker';
import { fetchFunction } from '../../shared/function-request';
import moment from 'moment';
import { Tooltip } from '@mui/material';

class AnalyticsConsumo extends React.Component {

    render() {
        return (
            <>
                <NavBar>
                    <AnalyticsConsumoBody props={this.props} />
                </NavBar>
            </>)
    }
}

class AnalyticsConsumoBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            monthiesAgo: "1",
            items: [],
            isLoading: true,
        }
        this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        const option = {
            method: 'POST',
            body: {
                monthiesAgo: parseInt(this.state.monthiesAgo)
            }
        }
        const result = await fetchFunction(`/analytics/use/all`, option)
        if (result.success) {
            this.setState({ items: result.data })
            this.setState({ isLoading: false })
        }
    }

    handlerMonth = (e) => {
        this.setState({ monthiesAgo: e.target.value }, () => {
            this.load()
        })
    }

    render() {
        return (
            <>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Consumo do sistema
                        <div className='col-lg-2'>
                            <select className='form-select form-select-sm' value={this.state.monthiesAgo} onChange={this.handlerMonth}>
                                <option value="1">Mês atual</option>
                                <option value="2">2 meses atrás</option>
                                <option value="3">3 meses atrás</option>
                            </select>
                        </div>
                    </div>
                    {
                        this.state.isLoading ? <Loading /> :
                            <div className="row">
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">Estabelecimento</th>
                                                <th scope="col">Plano</th>
                                                <th scope="col">Veículos</th>
                                                <th scope="col">Usuários</th>
                                                <th scope="col">Notas</th>
                                                <th scope="col" width={100}>Consumo ticket</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.items.map(item =>
                                                    <tr>
                                                        <td>{item.park.name}</td>
                                                        <td>{item.plan?.name ?? "Sem plano"}</td>
                                                        <td>{item.plan?.quantity?.vehicles ?? "0"}</td>
                                                        <td>{item.plan?.quantity?.users ?? "0"}</td>
                                                        <td>{item.plan?.quantity?.notes ?? "0"}</td>
                                                        <td width={100}>
                                                            {
                                                                item.analytics.map(e => {
                                                                    return <Tooltip title={`Faltam ${e.tickets.remaining}`} placement="top">
                                                                        <div>
                                                                            {
                                                                                (e.tickets.average === "LOW") &&
                                                                                <h6><span className="badge text-bg-success w-100">Mês {moment(e.startDate).format("MM")} - {e.tickets.quantity} veículos</span></h6>
                                                                            }
                                                                            {
                                                                                (e.tickets.average === "MEDIUM") &&
                                                                                <h6><span className="badge text-bg-warning w-100">Mês {moment(e.startDate).format("MM")} - {e.tickets.quantity} veículos</span></h6>
                                                                            }
                                                                            {
                                                                                (e.tickets.average === "HIGH" || e.tickets.average === "CRITICAL") &&
                                                                                <h6><span className="badge text-bg-danger text-white w-100">Mês {moment(e.startDate).format("MM")} - {e.tickets.quantity} veículos</span></h6>
                                                                            }
                                                                        </div>
                                                                    </Tooltip>
                                                                })
                                                            }
                                                        </td>
                                                        <td width='10'>
                                                            <ClientButton item={item} />
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                </div>
            </>)
    }
}

export { AnalyticsConsumo };