import React from 'react'
import { NavBar } from '../../components/navbar'
import firebase from '../../config/firebase';
import { getCliente, getRevenda, getRevendaIdByPark, getUsuario, getUsuarioCompact, isAdministrador } from '../../config/auth'
import { Loading, EmptyContent, ComboBoxCollection } from '../../components/common/commons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import 'firebase/auth';
import 'firebase/storage';
import Moment from 'moment';
import { sendClickButton, sendLog, sendLogByUser } from '../../shared/analytics';
import { sendCurrentChargeByWhatsApp } from '../../store/collections/queueMailingWorker';
import { saveLastUpdate, saveLastUpdateNoSkip } from '../../store/collections/lastUpdateWorker';
import Tooltip from '@mui/material/Tooltip';
import { RevendaFinanceiroGerenciarCobrancasBody } from '../revenda/financeiro/gerenciar-cobrancas';
import NumberFormat from 'react-number-format';
import { fetchFunction } from '../../shared/function-request';
import '../dashboard/index.css'
import { getParameterByParkId } from '../../store/collections/parameterWorker';
import { ItemPlan, LicencaRenvendasProjectionTable } from './my-licence';
import CurrencyInput from '../../components/currencyInput/CurrencyInput'
import { isSelfParking, reloadWindow, reloadWithAlert, reloadWithAlertError, toastError, toastSuccess, toastWarning, toCurrency } from '../../shared/utils';
import { getAllLicencePlan } from '../../store/collections/planosLicencaWorker';
import { getParkById } from '../../store/collections/parkWorker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import { getClient, getClientById } from '../../store/collections/clienteWorker';
import { searchCEP } from '../../shared/cepWorker';
import { AnalyticsPark } from '../estacionamento';
import { AlertDanger, AlertWarning } from '../../shared/alert-manager';
import { Collection } from '../../shared/constants';
import { addDoc, updateDoc } from '../../store/collections/collectionBaseWorker';

const initialState = {
    id: '',
    estacionamentoId: '',
    estacionamentoNome: '',
    vencimento: Date()
}

class CriarLicenca extends React.Component {

    constructor(props) {
        super(props)
        this.state = initialState
    }

    onEstacionamentoSelected = (item) => {
        this.setState({ estacionamentoId: item.id })
        this.setState({ estacionamentoNome: item.nome })
        // this.loadLicenca(item.id)
    }

    loadLicenca = (id) => {

    }

    addLicenca = async () => {
        if (this.checkAllFields()) {
            const data = {
                vencimento: new Date(this.state.vencimento),
                alterAt: new Date(),
                alterBy: getUsuario().email
            }
            await updateDoc({ collection: Collection.LICENCE, id: this.state.id, data: data })
            let cliente = getCliente()
            const dataClient = {
                revendaId: getRevendaIdByPark(),
                clienteId: cliente.id,
                clienteNome: cliente.nome,
                estacionamentoId: this.state.estacionamentoId,
                estacionamentoNome: this.state.estacionamentoNome,
                vencimento: this.state.vencimento
            }
            await addDoc({ collection: Collection.LICENCE_HISTORY, data: dataClient })
            toastWarning('Licença atualizada com sucesso')
            this.reset()
            this.setState({ random: Math.random() })
        }
    }

    onEditLicence = (licence) => {
        this.setState({ ...licence })
    }

    checkAllFields = () => {
        if (this.state.nome === '') {
            toastWarning('Selecione o estacionamento')
            return false
        }
        if (this.state.vencimento === null) {
            toastWarning('Informe o vencimento')
            return false
        }
        return true
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Criar Licença
                                </div>
                                <div className="card-body">
                                    <ComboBoxCollection key={this.state.random}
                                        id={this.state.estacionamentoId}
                                        title='Selecionar estacionamento'
                                        collection='estacionamentos'
                                        onSelected={this.onEstacionamentoSelected} />
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <label>Vencimento</label>
                                            <input type="date" className="form-control" onChange={(e) => this.setState({ vencimento: e.target.value })} />
                                        </div>
                                        <div className="col-lg-12">
                                            <button type="button" onClick={this.addLicenca} className="btn btn-primary my-3">Confirmar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </NavBar>
            </>
        )
    }
}

class ListarLicencas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: props.estacionamentoId,
            days: 0,
            motivo: '',
            startDate: new Moment(new Date()),
            formaCobranca: getRevenda()?.formaCobranca,
            isShowLicenceModal: false,
            isShowDetailModal: false,
            isLoadingLicence: false,
            isShowChangeStatus: false,
            isOpenModalBills: false,
            isOpenCancelSubscription: false,
            isShowLoadingChangeDueModal: false,
            isShowLoadingChangeValueModal: false,
            isShowChangeValueModal: false,
            isShowChangePlanModal: false,
            isShowLoadingChangePlanModal: false,
            isShowChangeDueModal: false,
            sendFirstCharge: false,
            newDueDateDay: 1,
            newChangeValue: 0.0,
            newStatus: "",
            newStatusUpdateMain: "NAO",
            paymentMethod: "banksplit",
            isLoading: true
        }
        this.load()
    }

    onEditLicence = (licence) => {
        this.props.onEditLicence(licence)
    }

    load = async () => {
        this.setState({ isLoading: true })
        const option = {
            method: 'GET'
        }
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        const result = await fetchFunction(`/newSubscription/current-licence`, option, parameter?.X_API_KEY)
        if (result.success) {
            this.setState({ currentLicence: result.data })
        }
        this.setState({ isLoading: false })
    }

    showLicence = () => {
        this.setState({ isShowLicenceModal: true })
    }

    showAllBills = () => {
        this.setState({ isOpenModalBills: true })
    }

    showChangeDueDate = () => {
        this.setState({ isShowChangeDueModal: true })
    }

    showChangeValue = () => {
        this.setState({ isShowChangeValueModal: true })
    }

    showChangePlan = async () => {
        this.setState({ isShowLoadingChangePlanModal: true })
        const plans = await getAllLicencePlan()
        this.setState({ plans: plans });
        this.setState({ isShowChangePlanModal: true })
        this.setState({ isShowLoadingChangePlanModal: false })
    }

    changePlanSelected = (e) => {
        const value = e.target.value
        this.setState({ newPlanSelectedId: value })
        const plan = this.state.plans.filter(e => e.id === value)[0]
        this.setState({ selectedPlan: plan })
    }

    showSigneture = async () => {
        this.setState({ isChangeLoading: true })
        this.setState({ isOpenSigneture: true })
        const option = {
            method: 'GET'
        }
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        const result = await fetchFunction(`/newSubscription/all-changes`, option, parameter?.X_API_KEY)
        this.setState({ isChangeLoading: false })
        if (result.success) {
            this.setState({ cobrancas: result.data.sort((a, b) => a.changeDate - b.changeDate) })
            // this.setState({ isAllPayed: result.data.filter(e => e.status !== 'GENERATED').length >= (result.data.length - 1) })
            this.setState({ isAllPayed: false })
        }
    }

    hideSigneture = () => {
        this.setState({ isOpenSigneture: false })
    }

    showGenerateChargesModal = async () => {
        this.setState({ isOpenGenerateSignetureModal: true })
        const lastCharge = this.state.cobrancas[this.state.cobrancas.length - 1]
        this.setState({ lastChargePrice: lastCharge.netValue })
        this.setState({ lastChargeDate: lastCharge.changeDate })
    }

    confirmGenerateCharges = async () => {
        if (this.state.quantityNewCharges === 0) {
            toastWarning("Informe a quantidade de cobranças")
            return
        }
        this.setState({ isChangeLoading: true })
        const option = this.generateSubscriptionBody()
        const result = await fetchFunction(`/newSubscription/generate`, option)
        if (result.success) {
            reloadWithAlert(result.message)
        } else {
            toastError(result.message)
            this.setState({ isChangeLoading: false })
        }
    }

    showSignetureNewPlan = async () => {
        this.setState({ isOpenSigneturePlan: true })
        this.setState({ isEditingData: false })
        this.setState({ signeturePlanState: "loading" })
        await this.loadParking()
        const plans = await getAllLicencePlan()
        this.setState({ plans: plans });
        this.setState({ selectedPlan: null })
        this.setState({ signeturePlanState: "select-plan" })
    }

    loadParking = async () => {
        const park = await getParkById({ id: this.state.estacionamentoId })
        this.setState({ park: park })
        const data = await getClientById({ id: park.clienteId })
        if (data) {
            this.setState({ name: data.nome ?? "" })
            this.setState({ document: data.documento ?? "" })
            this.setState({ phone: data.celular ?? "" })
            this.setState({ email: data.email ?? "" })
            this.setState({ tipoDocumento: data.tipoDocumento ?? "" })
            const address = data.endereco
            if (address) {
                this.setState({ street: address.logradouro })
                this.setState({ number: address.numero })
                this.setState({ district: address.bairro })
                this.setState({ zipCode: address.cep })
                this.setState({ city: address.cidade })
                this.setState({ state: address.estado })
                this.setState({ ibge: address.ibge })
            }
        }
    }

    backToSignaturePlan = async () => {
        this.setState({ signeturePlanState: "select-plan" })
        this.setState({ isEditingData: false })
    }

    hideChangeDueDate = () => {
        this.setState({ isShowChangeDueModal: false })
    }

    hideChangeValue = () => {
        this.setState({ isShowChangeValueModal: false })
    }

    hideChangePlanValue = () => {
        this.setState({ isShowChangePlanModal: false })
    }

    showCancelSubscription = () => {
        this.setState({ isOpenCancelSubscription: true })
    }

    hideLicence = () => {
        this.setState({ isShowLicenceModal: false })
    }

    showDetail = (detail) => {
        this.setState({ detail: detail })
        this.setState({ isShowDetailModal: true })
    }

    hideDetail = () => {
        this.setState({ isShowDetailModal: false })
    }

    releaseEmergencyAccess = async () => {
        var confirm = window.confirm('Deseja mesmo remover a restrição de licença deste estabelecimento?');
        if (confirm) {
            const currentLicence = { ...this.state.currentLicence }
            currentLicence.emergencyAccess = false
            this.setState({ currentLicence: currentLicence })
            toastSuccess("Modo emergencial removido com sucesso!")
            const data = {
                emergencyAccess: false
            }
            await updateDoc({ collection: Collection.LICENCE, id: this.state.currentLicence.id, data: data })
            await saveLastUpdateNoSkip()
        }
    }

    changeDays = (days) => {
        this.setState({ days: days }, () => {
            if (this.state.currentLicence?.vencimento !== null) {
                var newDate = Moment(this.state.currentLicence.vencimento._seconds * 1000).add(days, 'days')
                this.setState({ novoVencimento: newDate })
            } else {
                var newDate = Moment().add(days, 'days')
                this.setState({ novoVencimento: newDate })
            }
        })
    }

    confirmChangeDueDate = async () => {
        if (!this.state.newDueDateDay) {
            toastWarning("Informe a nova data de vencimento")
        }
        const option = {
            method: 'PUT',
            body: {
                dueDateDay: this.state.newDueDateDay
            }
        }
        this.setState({ isShowLoadingChangeDueModal: true })
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        const result = await fetchFunction(`/newSubscription/change-due-date`, option, parameter?.X_API_KEY)
        sendLogByUser("Licença", `Alterou o dia de vencimento para ${this.state.newDueDateDay}.`, option)
        if (result.success) {
            reloadWithAlert(result.message)
        } else {
            toastWarning(result.message)
            this.setState({ isShowLoadingChangeDueModal: false })
        }
    }

    confirmChangeValue = async () => {
        if (this.state.newChangeValue === 0.0) {
            toastWarning("Informe o novo valor da cobrança.")
            return
        }
        if (!this.state.motivo || this.state.motivo === "") {
            toastWarning("Informe o motivo do valor da cobrança.")
            return
        }
        const option = {
            method: 'PUT',
            body: {
                value: this.state.newChangeValue
            }
        }
        this.setState({ isShowLoadingChangeValueModal: true })
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        const result = await fetchFunction(`/newSubscription/change-value`, option, parameter?.X_API_KEY)
        sendLogByUser("Licença", `Alterou o valor da assinatura para ${this.state.newChangeValue}, motivo: ${this.state.motivo ?? "Não informado"}.`, option)
        if (result.success) {
            reloadWithAlert(result.message)
        } else {
            toastError(result.message)
        }
        this.setState({ isShowLoadingChangeValueModal: false })
    }

    confirmChangePlanValue = async () => {
        if (!this.state.newPlanSelectedId || this.state.newPlanSelectedId === "") {
            toastWarning("Informe o novo plano da cobrança.")
            return
        }
        if (!this.state.motivo || this.state.motivo === "") {
            toastWarning("Informe o motivo do valor da cobrança.")
            return
        }
        const option = {
            method: 'PUT',
            body: {
                planId: this.state.newPlanSelectedId
            }
        }
        this.setState({ isShowLoadingChangePlanModal: true })
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        const result = await fetchFunction(`/newSubscription/change-plan`, option, parameter?.X_API_KEY)
        const plan = this.state.selectedPlan
        sendLogByUser("Licença", `Alterou o plano da assinatura para ${plan.nome} (${plan.id}), motivo: ${this.state.motivo ?? "Não informado"}.`, option)
        if (result.success) {
            reloadWithAlert(result.message)
        } else {
            toastError(result.message)
        }
        this.setState({ isShowLoadingChangePlanModal: false })
    }

    addLicence = async () => {
        if (this.state.days === 0) {
            toastWarning("Informe a quantidade de dias")
            return
        }
        if (this.state.motivo.trim() === '') {
            toastWarning("Informe o motivo")
            return
        }
        this.hideLicence()
        var newDate = Moment(Moment(this.state.currentLicence.vencimento._seconds * 1000)).add(this.state.days, 'days')
        const data = {
            vencimento: newDate.toDate()
        }
        await updateDoc({ collection: Collection.LICENCE, id: this.state.currentLicence.id, data: data })
        await this.load()
        await saveLastUpdate()
        const message = `Adicionou ${this.state.days} dias na licença do cliente ${this.state.currentLicence.clienteId}.`
        sendLogByUser("Licença", message, true)
    }

    confirmCancelSubscription = async () => {
        if (!this.state.reason) {
            toastWarning("Informe o motivo")
            return
        }
        var confirm = window.confirm(`Deseja mesmo cancelar a assinatura deste estabelecimento?\nEsta ação não poderá ser desfeita.`);
        if (confirm) {
            const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
            const option = {
                method: 'GET'
            }
            const result = await fetchFunction(`/newSubscription/current-licence`, option, parameter?.X_API_KEY)
            const licenca = result.data
            const options = {
                method: "PUT"
            }
            const response = await fetchFunction(`/newSubscription/cancel/${licenca.groupId}`, options, parameter?.X_API_KEY)
            if (response.success) {
                sendLogByUser("Licença", `Assinatura cancelada: ${this.state.reason}`, true)
                reloadWithAlert("Assinatura cancelada com sucesso")
            } else {
                toastError(response.message)
            }
        }
    }

    renderStatus(status) {
        return (
            <>
                {status === 'PAYED' && <h5><span className="badge text-bg-success btn-block text-white">Pago</span></h5>}
                {status === 'GENERATED' && <h5><span className="badge text-bg-secondary btn-block text-white">Gerada</span></h5>}
                {status === 'CANCELED' && <h5><span className="badge text-bg-danger btn-block text-white">Cancelada</span></h5>}
                {status === 'PENDING' && <h5><span className="badge text-bg-danger btn-block text-white">Pendente</span></h5>}
                {(!status || status === 'FREE') && <h5><span className="badge text-bg-warning btn-block text-white">Plano gratuito</span></h5>}
            </>
        )
    }

    renderAlert(status) {
        switch (status) {
            case "PAYED": return "alert alert-success"
            case "GENERATED": return "alert alert-secondary"
            case "CANCELED": return "alert alert-danger"
            case "PENDING": return "alert alert-danger"
            default: return "alert alert-warning"
        }
    }

    updateChangeValue = (event, maskedvalue, floatvalue) => {
        this.setState({ newChangeValue: floatvalue })
    }

    reloadPage = () => {
        reloadWindow()
    }

    confirmSignetureNew = async () => {
        const confirm = window.confirm(`Deseja mesmo confirmar a adesão do plano:\n${this.state.currentPlan?.nome}\n\nPara o estabelecimento:\n${this.state.park.nomeInterno && this.state.park.nomeInterno !== "" ? this.state.park.nomeInterno : this.state.park.nome}?`);
        if (!confirm) {
            return
        }
        const option = this.generateSubscriptionBody()
        this.setState({ signeturePlanState: "loading" })
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.park.id })
        sendLog("Cliente", `Processando cobrança`, false, option)
        const result = await fetchFunction(`/newSubscription/create`, option, parameter.X_API_KEY)
        sendLog("Cliente", `Processando cobrança: ${result.message}`, false, result)
        if (result.success) {
            this.setState({ signeturePlanState: "success" })
        } else {
            toastError(result.message)
            this.setState({ signeturePlanState: "select-infos" })
        }
    }

    selectPlan = async (plan) => {
        this.setState({ currentPlan: plan })
        this.setState({ signeturePlanState: "select-infos" })
        sendClickButton("Cliente", "Plano selecionado: " + plan.nome)
        this.setState({ isOpenModal: true })
        var frequence = ""
        if (plan.anual === true) {
            frequence = "anual"
        } else if (plan.semestral === true) {
            frequence = "semestral"
        } else if (plan.trimestral === true) {
            frequence = "trimestral"
        } else if (plan.bimestral === true) {
            frequence = "bimestral"
        } else if (plan.mensal === true) {
            frequence = "mensal"
        }
        this.setState({ frequence: frequence })
    }

    handleStartDateChange = (newValue) => {
        this.setState({ startDate: newValue })
    }

    handleDueDayChange = (e) => {
        if (e.target.value === "") {
            return
        }
        this.setState({ dueDay: e.target.value }, () => {
            this.simulateSubscription()
        })
    }

    simulateSubscription = async () => {
        if (this.state.formaCobranca === "SELFPARKING") {
            const option = this.generateSubscriptionBody()
            const result = await fetchFunction(`/newSubscription/simulate`, option)
            sendLog("Cliente", `Simulando assinatura: ${result.message}`, false, option)
            this.setState({ messageSimulate: result.message })
        } else {
            this.setState({ messageSimulate: null })
        }
    }

    handleFrequenceChange = (e) => {
        if (e.target.value === "") {
            return
        }
        this.setState({ frequence: e.target.value }, () => {
            this.simulateSubscription()
        })
    }

    generateSubscriptionBody = () => {
        return {
            method: 'POST',
            body: {
                payment: {
                    type: this.state.paymentMethod,
                    card: {
                        name: this.state.nameCardUpdate,
                        number: this.state.cardNumber,
                        date: this.state.date,
                        cvv: this.state.cvv
                    }
                },
                plan: {
                    id: this.state.currentPlan.id,
                    frequence: this.state.frequence,
                    dueDay: this.state.dueDay,
                    firstChange: this.state.startDate.format("MM/yyyy")
                },
                sendFirstCharge: this.state.sendFirstCharge,
                coupon: this.state.coupon,
                customer: {
                    name: this.state.name,
                    document: this.state.document,
                    email: this.state.email,
                    phone: this.state.phone,
                    address: {
                        zipCode: this.state.zipCode,
                        street: this.state.street,
                        number: this.state.number,
                        district: this.state.district,
                        city: this.state.city,
                        state: this.state.state,
                        ibge: this.state.ibge
                    }
                }
            }
        };
    }

    editData = () => {
        this.setState({ isEditingData: true })
    }

    fetchCEP = async (value) => {
        const result = await searchCEP(value)
        if (result !== null) {
            this.setState({ zipCode: result.cep })
            this.setState({ street: result.logradouro })
            this.setState({ district: result.bairro })
            this.setState({ city: result.localidade })
            this.setState({ state: result.uf })
            this.setState({ ibge: result.ibge })
        }
    }

    render() {
        return (
            <>
                {
                    this.state.currentLicence ?
                        <div className="row">
                            <div className='col-lg-12'>
                                {
                                    this.state.currentLicence.emergencyAccess === true &&
                                    <div className='alert alert-danger alert-block'>
                                        <button onClick={this.releaseEmergencyAccess} className='btn btn-danger'>Remover restrição</button>
                                        <span className='mx-2'><strong>Atenção: </strong> este estabelecimento esta usando os 5 dias de emergência quando a licença vence.</span>
                                    </div>
                                }
                                <div className={this.renderAlert(this.state.currentLicence.status)}>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <Tooltip title={this.state.currentLicence.id} placement="left">
                                                <div>
                                                    {this.renderStatus(this.state.currentLicence.status)}
                                                </div>
                                            </Tooltip>
                                            {
                                                this.state.currentLicence.status === "FREE" ?
                                                    <span>
                                                        {`Este estacionamento esta usando um plano gratuito que tem vencimento em: ${this.state.currentLicence.vencimentoFormatted}.`}
                                                    </span> : this.state.currentLicence.status === "CANCELED" ?
                                                        <span>
                                                            {`A assinatura deste estabelecimento foi cancelada e pode ser usada até ${this.state.currentLicence.vencimentoFormatted}.`}
                                                        </span> :
                                                        <span>
                                                            {`Plano atual: ${this.state.currentLicence.plan?.nome ?? "Não migrado"}`} <br /> {`A licença para este estacionamento vence em: ${this.state.currentLicence.vencimentoFormatted}.`}
                                                        </span>
                                            }
                                        </div>
                                        <div className='col-lg-4'>
                                            <AnalyticsPark estacionamentoId={this.state.estacionamentoId} />
                                        </div>
                                    </div>
                                </div>
                                <span className='btn-group'>
                                    <button type="button" onClick={this.showLicence} className="btn btn-sm btn-warning text-white">
                                        <i className="fas fa-clock mx-2" />
                                        Adicionar dias
                                    </button>
                                    <button type="button" onClick={this.showAllBills} className="btn btn-sm btn-primary">
                                        <i className="fas fa-file-invoice-dollar mx-2" />
                                        Cobranças avulsas
                                    </button>
                                    {
                                        (this.state.currentLicence.status !== "FREE" && this.state.currentLicence.status !== "CANCELED") ?
                                            <>
                                                <button type="button" onClick={this.showSigneture} className="btn btn-sm btn-success">
                                                    <i className="fas fa-file-signature mx-2" />
                                                    Assinatura
                                                </button>
                                                <button type="button" onClick={this.showChangeDueDate} className="btn btn-sm btn-secondary text-white">
                                                    <i className="fas fa-calendar mx-2" />
                                                    Alterar vencimento
                                                </button>
                                                <button type="button" onClick={this.showChangeValue} className="btn btn-sm btn-secondary text-white">
                                                    <i className="fas fa-dollar mx-2" />
                                                    Alterar valor
                                                </button>
                                                <button type="button" onClick={this.showChangePlan} className="btn btn-sm btn-secondary text-white">
                                                    <i className="fas fa-dollar mx-2" />
                                                    Alterar plano
                                                </button>
                                                <button type="button" onClick={this.showCancelSubscription} className="btn btn-sm btn-danger">
                                                    <i className="fas fa-trash mx-2"></i>
                                                    Cancelar assinatura
                                                </button>
                                            </> :
                                            <button type="button" onClick={this.showSignetureNewPlan} className="btn btn-sm btn-success">
                                                <i className="fas fa-file-signature mx-2" />
                                                Assinar plano
                                            </button>
                                    }
                                </span>
                            </div>
                        </div> : <Loading />
                }
                <Dialog fullWidth maxWidth="xs" onClose={(e) => this.setState({ isOpenCancelSubscription: false })} open={this.state.isOpenCancelSubscription}>
                    <MuiDialogTitle className='text-center'>
                        Cancelar assinatura
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <AlertDanger message="Cancelando a assinatura este cliente não terá mais acesso após o vencimento e para continuar usando deverá assinar um novo plano." />
                        <div className='col-lg-12'>
                            <label>Motivo</label>
                            <textarea rows='4' onChange={(e) => this.setState({ reason: e.target.value })} value={this.state.reason} className="form-control" />
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isOpenCancelSubscription: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={(e) => this.confirmCancelSubscription()} className='btn btn-danger'>Confirmar cancelamento</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog fullWidth maxWidth={this.state.signeturePlanState === "select-plan" ? "md" : "xs"} open={this.state.isOpenSigneturePlan}>
                    <MuiDialogContent>
                        {
                            this.state.signeturePlanState === "loading" && <Loading />
                        }
                        {
                            this.state.signeturePlanState === "success" && <>
                                {
                                    this.state.formaCobranca === "SELFPARKING" &&
                                    <div className='text-center'>
                                        <h5>Cobrança assinada com sucesso e o cliente receberá o link para pagamento em breve.</h5>
                                    </div>
                                }
                                {
                                    this.state.formaCobranca !== "SELFPARKING" &&
                                    <div className='text-center'>
                                        <h5>Plano associado com sucesso para este estabelecimento.</h5>
                                    </div>
                                }
                            </>
                        }
                        {
                            this.state.signeturePlanState === "select-plan" && <div className='row'>
                                <MuiDialogTitle className='text-center'>
                                    Escolha o plano para o estabelecimento
                                </MuiDialogTitle>
                                {
                                    this.state.plans?.map(item =>
                                        <div className='col-lg-4'>
                                            <ItemPlan key={item.id} plan={item} onSelected={this.selectPlan} />
                                        </div>)
                                }
                            </div>
                        }
                        {
                            this.state.signeturePlanState === "select-infos" && <div className='row'>
                                <MuiDialogTitle className='text-center'>
                                    Informe os dados para cobrança
                                </MuiDialogTitle>
                                <div className='col-lg-12'>
                                    {
                                        this.state.formaCobranca !== "SELFPARKING" ?
                                            <div className='alert alert-warning'>
                                                <strong>Atenção: </strong> Esta assinatura é apenas uma forma para controle e liberação do acesso ao cliente. Será preciso fazer o envio da cobrança por meios internos da revenda.
                                            </div> :
                                            !this.state.isEditingData ?
                                                <div className="alert alert-secondary">
                                                    <span className="badge text-bg-secondary">Dados para cobrança</span> <br />
                                                    <strong>E-mail: </strong>
                                                    <label>{this.state.email}</label> <br />
                                                    <strong>Celular: </strong>
                                                    <label>{this.state.phone !== "(00)00000-0000" ? this.state.phone : "Não informado"}</label> <br />
                                                    <strong>Número do documento: </strong>
                                                    <label>{this.state.document !== "00.000.000/0000-00" ? this.state.document : "Não informado"}</label> <br />
                                                    {
                                                        this.state.zipCode && this.state.zipCode !== "00000-00" ? <>
                                                            <strong>Endereço: </strong>
                                                            <label>{this.state.zipCode} - {this.state.street}, {this.state.number} - {this.state.district}</label> <br />
                                                        </> : <><strong>Endereço: </strong> Não informado <br /></>
                                                    }
                                                    <small>* Dados utilizados para envio de cobranças e suporte.</small> <br />
                                                    <button type="button" onClick={this.editData} className="btn btn-sm btn-secondary mt-2">
                                                        <i className="fas fa-edit mx-2" />
                                                        Editar dados
                                                    </button> <br />
                                                </div> :
                                                <>
                                                    <div className='row'>
                                                        <div className="col-lg-12">
                                                            <label>E-mail</label>
                                                            <input type="email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" required />
                                                            <label>Celular</label>
                                                            <NumberFormat className="form-control" value={this.state.phone} mask='_' onChange={(e) => this.setState({ phone: e.target.phone })} format="(##)#####-####" />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-6">
                                                            <label>Tipo documento</label>
                                                            <div className="input-group mb-3">
                                                                <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} >
                                                                    <option value='CPF'>CPF</option>
                                                                    <option value='CNPJ'>CNPJ</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>Número documento</label>
                                                            <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.document} onChange={(e) => this.setState({ document: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-4">
                                                            <label>CEP</label>
                                                            <NumberFormat autoComplete='off' className="form-control" value={this.state.zipCode} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label>Logradouro</label>
                                                            <input type="text" onChange={(e) => this.setState({ street: e.target.value })} value={this.state.street} className="form-control" required />
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label>Número</label>
                                                            <input type="text" onChange={(e) => this.setState({ number: e.target.value })} value={this.state.number} className="form-control" required />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-lg-4">
                                                            <label>Bairro</label>
                                                            <input type="text" onChange={(e) => this.setState({ district: e.target.value })} value={this.state.district} className="form-control" required />
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label>Cidade</label>
                                                            <input type="text" onChange={(e) => this.setState({ city: e.target.value })} value={this.state.city} className="form-control" required />
                                                        </div>
                                                        <div className="col-lg-4">
                                                            <label>Estado</label>
                                                            <input type="text" onChange={(e) => this.setState({ state: e.target.value })} value={this.state.state} className="form-control" required />
                                                        </div>
                                                    </div>
                                                </>
                                    }
                                    {
                                        this.state.messageSimulate &&
                                        <div className="col-lg-12 mt-3">
                                            <div className="alert alert-warning" role="alert">
                                                <strong>Atenção: </strong>{this.state.messageSimulate}
                                            </div>
                                        </div>
                                    }
                                    <div className='mt-2'>
                                        <MobileDatePicker
                                            label="Mês da primeira cobrança"
                                            inputFormat="MM"
                                            value={this.state.startDate}
                                            onChange={this.handleStartDateChange}
                                            views={['month']}
                                            renderInput={(params) => <TextField {...params} size="small" className='form-control mt-2' />}
                                        />
                                    </div>
                                    <label>Frequência</label>
                                    <div className="input-group">
                                        <select className="form-select" onChange={this.handleFrequenceChange} value={this.state.frequence} >
                                            {this.state.currentPlan?.anual && <option value="anual">Anual - R${this.state.currentPlan?.valorAnual?.toFixed(2)}</option>}
                                            {this.state.currentPlan?.semestral && <option value="semestral">Semestral - R${this.state.currentPlan?.valorSemestral?.toFixed(2)}</option>}
                                            {this.state.currentPlan?.trimestral && <option value="trimestral">Trimestral - R${this.state.currentPlan?.valorTrimestral?.toFixed(2)}</option>}
                                            {this.state.currentPlan?.bimestral && <option value="bimestral">Bimestral - R${this.state.currentPlan?.valorBimestral?.toFixed(2)}</option>}
                                            {this.state.currentPlan?.mensal && <option value="mensal">Mensal - R${this.state.currentPlan?.valorMensal?.toFixed(2)}</option>}
                                        </select>
                                    </div>
                                    <label>Dia de cobrança</label>
                                    <div className="input-group">
                                        <select className="form-select" onChange={this.handleDueDayChange} value={this.state.dueDay} >
                                            <option value="">Selecionar</option>
                                            {this.state.currentPlan?.dia1 && <option value="1">1</option>}
                                            {this.state.currentPlan?.dia5 && <option value="5">5</option>}
                                            {this.state.currentPlan?.dia10 && <option value="10">10</option>}
                                            {this.state.currentPlan?.dia15 && <option value="15">15</option>}
                                            {this.state.currentPlan?.dia20 && <option value="20">20</option>}
                                            {this.state.currentPlan?.dia25 && <option value="25">25</option>}
                                        </select>
                                    </div>
                                    {
                                        this.state.formaCobranca === "SELFPARKING" &&
                                        <>
                                            <label>Forma de pagamento</label>
                                            <div className="input-group">
                                                <select className="form-select" value={this.state.paymentMethod} disabled>
                                                    <option value='banksplit'>Pix ou Boleto bancário</option>
                                                </select>
                                            </div>
                                        </>
                                    }
                                    <label>Cupom de desconto</label>
                                    <input type="text" onBlur={this.simulateSubscription} onChange={(e) => this.setState({ coupon: e.target.value?.toUpperCase() })} value={this.state.coupon} className="form-control" required />
                                    {
                                        this.state.formaCobranca === "SELFPARKING" && <>
                                            <div className="col-lg-12 mt-2">
                                                <div className='alert alert-danger'>
                                                    <span className="badge text-bg-danger">Cobrança</span><br />
                                                    <div className="form-check form-switch mt-2">
                                                        <input className="form-check-input text-success" id="sendFirstCharge" checked={this.state.sendFirstCharge} onChange={(e) => this.setState({ sendFirstCharge: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="sendFirstCharge">
                                                            <span>Enviar boleto da primeira cobrança imediatamente</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            {
                                this.state.signeturePlanState === "select-plan" && <>
                                    <button onClick={(e) => this.setState({ isOpenSigneturePlan: false })} className='btn btn-secondary'>Fechar</button>
                                </>
                            }
                            {
                                this.state.signeturePlanState === "select-infos" && <>
                                    <button onClick={this.backToSignaturePlan} className='btn btn-secondary'>Voltar</button>
                                    <button onClick={this.confirmSignetureNew} className='btn btn-success'>Confirmar adesão</button>
                                </>
                            }
                            {
                                this.state.signeturePlanState === "success" &&
                                <button onClick={this.reloadPage} className='btn btn-secondary'>Fechar</button>
                            }
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog fullWidth maxWidth="md" onClose={(e) => this.setState({ isOpenModalBills: false })} open={this.state.isOpenModalBills}>
                    <MuiDialogContent>
                        <RevendaFinanceiroGerenciarCobrancasBody key={this.state.estacionamentoId} estacionamentoId={this.state.estacionamentoId} />
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <button onClick={(e) => this.setState({ isOpenModalBills: false })} className='btn btn-secondary m-3'>Fechar</button>
                    </MuiDialogActions>
                </Dialog>
                {/* Implementar depois */}
                <Dialog maxWidth={'xs'} fullWidth onClose={(e) => this.setState({ isOpenGenerateSignetureModal: false })} open={this.state.isOpenGenerateSignetureModal}>
                    <MuiDialogTitle className='text-center'>
                        Gerar cobranças
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <h4>Última cobrança</h4>
                        <h6>{Moment(this.state.lastChargeDate).format("DD/MM/YYYY")}</h6>
                        <h4>Valor do plano por vaga</h4>
                        {toCurrency(this.state.lastChargePrice)}
                        <label>Adicionar:</label>
                        <select className='form-select' value={this.state.quantityNewCharges} onChange={(e) => this.setState({ quantityNewCharges: e.target.value })}>
                            <option value="0">Selecionar</option>
                            <option value="1">1 cobrança</option>
                            <option value="3">3 cobranças</option>
                            <option value="6">6 cobranças</option>
                            <option value="12">12 cobranças</option>
                            <option value="16">16 cobranças</option>
                            <option value="24">24 cobranças</option>
                        </select>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className="btn-group m-3">
                            <button onClick={(e) => this.setState({ isOpenGenerateSignetureModal: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={this.confirmGenerateCharges} className='btn btn-danger'>Confirmar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog maxWidth='lg' fullWidth open={this.state.isOpenSigneture} onClose={this.hideSigneture}>
                    <MuiDialogTitle className='text-center'>
                        Cobranças disponíveis
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        {
                            this.state.isChangeLoading ? <Loading /> :
                                <LicencaRenvendasProjectionTable estacionamentoId={this.state.estacionamentoId} selectedChanges={this.state.cobrancas} currentNewSubscriptionId={this.state.currentNewSubscriptionId} key={this.state.cobrancas} />
                        }
                    </MuiDialogContent>
                    <MuiDialogActions className='m-3'>
                        <div className='btn-group m-3'>
                            {
                                this.state.isAllPayed &&
                                <button onClick={this.showGenerateChargesModal} className='btn btn-success'>Gerar cobranças</button>
                            }
                            <button onClick={this.hideSigneture} className='btn btn-secondary'>Fechar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog maxWidth='xs' fullWidth open={this.state.isShowChangeDueModal} onClose={this.hideChangeDueDate}>
                    {
                        this.state.isShowLoadingChangeDueModal && <Loading />
                    }
                    {
                        !this.state.isShowLoadingChangeDueModal && <>
                            <MuiDialogTitle className='text-center'>
                                Alterar data de vencimento
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <AlertDanger>
                                    Esta alteração irá refletir no dia de vencimento das cobranças atrasadas e pendentes.
                                </AlertDanger>
                                <div className='col-lg-12'>
                                    <label>Novo dia de vencimento</label>
                                    <select className="form-select" value={this.state.newDueDateDay} onChange={(e) => this.setState({ newDueDateDay: e.target.value })}>
                                        <option value={1}>Dia 1º</option>
                                        <option value={5}>Dia 5º</option>
                                        <option value={10}>Dia 10º</option>
                                        <option value={15}>Dia 15º</option>
                                        <option value={20}>Dia 20º</option>
                                        <option value={25}>Dia 25º</option>
                                    </select>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='m-3 btn-group'>
                                    <button onClick={this.hideChangeDueDate} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={this.confirmChangeDueDate} className='btn btn-danger'>Confirmar</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>
                <Dialog maxWidth='xs' fullWidth open={this.state.isShowChangeValueModal} onClose={this.hideChangeValue}>
                    {
                        this.state.isShowLoadingChangeValueModal && <Loading />
                    }
                    {
                        !this.state.isShowLoadingChangeValueModal && <>
                            <MuiDialogTitle className='text-center'>
                                Alterar valor da cobrança
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <AlertDanger>
                                    Esta alteração irá refletir no valor das cobranças atrasadas e pendentes.
                                </AlertDanger>
                                <div className='col-lg-12'>
                                    <label>Novo valor</label>
                                    <CurrencyInput value={this.state.newChangeValue} prefix="R$" className='form-control' onChangeEvent={this.updateChangeValue} />
                                </div>
                                <div className='col-lg-12'>
                                    <label>Motivo</label>
                                    <textarea rows='4' onChange={(e) => this.setState({ motivo: e.target.value })} value={this.state.motivo} className="form-control" />
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='m-3 btn-group'>
                                    <button onClick={this.hideChangeValue} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={this.confirmChangeValue} className='btn btn-danger'>Confirmar</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>
                <Dialog maxWidth='xs' fullWidth open={this.state.isShowChangePlanModal} onClose={this.hideChangePlanValue}>
                    {
                        this.state.isShowLoadingChangePlanModal && <Loading />
                    }
                    {
                        !this.state.isShowLoadingChangePlanModal && <>
                            <MuiDialogTitle className='text-center'>
                                Alterar plano das cobranças
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <AlertDanger>
                                    <strong>Atenção: </strong> Esta alteração irá refletir no plano das cobranças atrasadas e pendentes.
                                </AlertDanger>
                                <div className='col-lg-12'>
                                    <label>Selecionar plano</label>
                                    <select className='form-select' value={this.state.newPlanSelectedId} onChange={this.changePlanSelected}>
                                        <option value="">Selecionar</option>
                                        {
                                            this.state.plans?.map(item => { return <option value={item.id}>{item.nome}</option> })
                                        }
                                    </select>
                                </div>
                                {
                                    this.state.selectedPlan &&
                                    <div className='col-lg-12 mt-3'>
                                        <ItemPlan key={this.state.selectedPlan?.id} plan={this.state.selectedPlan} />
                                    </div>
                                }
                                <div className='col-lg-12'>
                                    <label>Motivo</label>
                                    <textarea rows='4' onChange={(e) => this.setState({ motivo: e.target.value })} value={this.state.motivo} className="form-control" />
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='m-3 btn-group'>
                                    <button onClick={this.hideChangePlanValue} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={this.confirmChangePlanValue} className='btn btn-danger'>Confirmar</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>
                <Dialog onClose={this.hideLicence} maxWidth={'sm'} open={this.state.isShowLicenceModal}>
                    {
                        this.state.isLoadingLicence === true &&
                        <Loading />
                    }
                    {
                        this.state.isLoadingLicence === false &&
                        <>
                            <MuiDialogTitle className='text-center'>
                                Adicionar dias
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='row'>
                                    {
                                        this.state.currentLicence?.vencimento &&
                                        <AlertWarning>
                                            <span>Vencimento licença atual: </span><strong>{this.state.currentLicence.vencimento !== null ? Moment(this.state.currentLicence.vencimento._seconds * 1000).format("DD/MM/YYYY") : "Não disponível"} </strong> <br />
                                            {
                                                this.state.days === 0 ?
                                                    <span>Selecione o dia abaixo</span> :
                                                    <><span>Novo vencimento: </span><strong>{this.state.novoVencimento !== null && Moment(this.state.novoVencimento).format("DD/MM/YYYY")}</strong></>
                                            }
                                        </AlertWarning>
                                    }
                                    <div className='col-lg-12'>
                                        <label>Dias a serem adicionados na licença atual</label>
                                        <select className="form-select" value={this.state.days} onChange={(e) => this.changeDays(e.target.value)}>
                                            <option value={0}>Selecionar</option>
                                            <option value={1}>1 dia</option>
                                            <option value={2}>2 dias</option>
                                            <option value={3}>3 dias</option>
                                            {
                                                isSelfParking() && <>
                                                    <option value={5}>5 dias</option>
                                                    <option value={10}>10 dias</option>
                                                    <option value={15}>15 dias</option>
                                                    <option value={20}>20 dias</option>
                                                    <option value={25}>25 dias</option>
                                                    <option value={30}>30 dias</option>
                                                    <option value={365}>1 ano</option>
                                                </>
                                            }
                                        </select>
                                    </div>
                                    <div className='col-lg-12'>
                                        <label>Motivo</label>
                                        <textarea rows='4' onChange={(e) => this.setState({ motivo: e.target.value })} value={this.state.motivo} className="form-control" />
                                    </div>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={this.hideLicence} className='btn btn-secondary text-white'>Cancelar</button>
                                    <button onClick={this.addLicence} className='btn btn-danger'>Confirmar</button>
                                </div>
                            </MuiDialogActions>
                        </>
                    }
                </Dialog>
            </>
        )
    }
}


class ListarLicencaHistorico extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            historico: [],
            isLoading: true,
            estacionamentoId: props.estacionamentoId,
            isShowChangeStatus: false,
            newStatusUpdateMain: "NAO",
            newStatus: ""
        }
        this.preLoading()
    }

    preLoading = async () => {
        const histories = await firebase.firestore().collection('licencas-historico').where('estacionamentId', '==', this.state.estacionamentoId).get()
        this.setState({ isLoading: false })
        if (histories.empty) {
            this.setState({ historico: [] });
            return
        }
        var listHistorico = [];
        histories.docs.forEach(doc => {
            listHistorico.push({
                ...doc.data(),
                id: doc.id
            })
        })
        const result = listHistorico.sort((a, b) => b.buyAt.toDate() - a.buyAt.toDate())//.filter(item => item.transaction?.idTransaction !== undefined || item.motivo !== undefined)
        this.setState({ historico: result });
    }

    showDetail = (detail) => {
        this.setState({ detail: detail })
        this.setState({ isShowDetailModal: true })
    }

    hideDetail = () => {
        this.setState({ isShowDetailModal: false })
    }


    renderStatus(status) {
        return (
            <>
                {status === 'Adicionado' && <h5><span className="badge text-bg-success btn-block my-2 w-100 text-white">Adicionada</span></h5>}
                {status === 'ATIVA' && <h5><span className="badge text-bg-success btn-block my-2 w-100 text-white">Pago</span></h5>}
                {status === 'DESATIVADA' && <h5><span className="badge text-bg-danger btn-block my-2 w-100 text-white">Desativada</span></h5>}
                {status === 'ATRASADA' && <h5><span className="badge text-bg-warning btn-block my-2 w-100 text-white">Atrasada</span></h5>}
                {status === 'PENDENTE' && <h5 ><span className="badge text-bg-warning btn-block my-2 w-100 text-white">Pendente</span></h5>}
                {status === 'INADIMPLENTE' && <h5><span className="badge text-bg-danger btn-block my-2 w-100 text-white">Inadimplente</span></h5>}
                {status === 'ENCERRADA' && <h5><span className="badge text-bg-danger btn-block my-2 w-100 text-white">Encerrada </span></h5>}
                {status === undefined && <h5><span className="badge text-bg-secondary btn-block my-2 w-100 text-white">Plano gratuito</span></h5>}
            </>
        )
    }

    confirmChangeStatus = async () => {
        if (this.state.motivo === undefined || this.state.motivo.trim() === '') {
            toastWarning("Informe o motivo")
            return
        }
        const newStatus = this.state.newStatus
        const newStatusUpdateMain = this.state.newStatusUpdateMain
        var licenceSelected = this.state.licenceSelected
        licenceSelected.subscription.statusName = newStatus
        licenceSelected.status = newStatus
        licenceSelected.motivo = `Status alterado manualmente:\n${this.state.motivo}.\n\nPor:\n${getUsuario().nome}`
        const data = {
            ...licenceSelected
        }
        await updateDoc({ collection: Collection.LICENCE, id: licenceSelected.id, data: data })
        if (newStatusUpdateMain === "SIM") {
            const licence = await firebase.firestore().collection('licencas').where('estacionamentoId', '==', this.state.estacionamentoId).get()
            if (!licence.empty) {
                var currentLicence = { ...licence.docs[0].data(), id: licence.docs[0].id }
                currentLicence.status = newStatus
                currentLicence.subscription.statusName = newStatus
                const data = {
                    ...currentLicence
                }
                await updateDoc({ collection: Collection.LICENCE, id: currentLicence.id, data: data })
            }
        }
        reloadWithAlert("Status atualizado com sucesso!")
    }

    showModalChangeStatus = (licenceSelected) => {
        this.setState({ isShowChangeStatus: true })
        this.setState({ licenceSelected: licenceSelected })
        this.setState({ newStatus: licenceSelected.subscription.statusName?.toUpperCase() })
    }

    hideModalChangeStatus = () => {
        this.setState({ isShowChangeStatus: false })
    }

    sendBillByWhatsApp = async (licenca) => {
        const parkSelected = (await firebase.firestore().collection('estacionamentos').doc(this.state.estacionamentoId).get()).data()
        const url = licenca?.transaction?.PaymentObject?.BankSlipUrl
        sendCurrentChargeByWhatsApp({ park: parkSelected, url: url })
    }

    render() {
        return (
            <>
                {
                    this.state.historico.length === 0 && this.state.isLoading === false ?
                        <EmptyContent text='Nenhum histórico de licença encontrado' />
                        :
                        this.state.isLoading === false ?
                            <div className='row'>
                                <h5>Histórico da assinatura</h5>
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td className="d-none d-sm-table-cell"><strong>Plano</strong></td>
                                                <td><strong>Última atualização</strong></td>
                                                <td align='center' className="d-none d-sm-table-cell"><strong>Dias</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Valor</strong></td>
                                                <td width="80"></td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.historico.map(licenca =>
                                                    <tr key={licenca.id}>
                                                        <td className="d-none d-sm-table-cell">
                                                            <Tooltip title={licenca.id} placement="left">
                                                                <span>{licenca.plano?.nome ?? "Não informado"}</span>
                                                            </Tooltip>
                                                        </td>
                                                        <td>{Moment(licenca.buyAt.toDate()).format("DD/MM/YYYY [às] HH:mm")}</td>
                                                        <td align='center' className="d-none d-sm-table-cell">{licenca.days}</td>
                                                        {
                                                            licenca.transaction?.Amount ?
                                                                <td align='center'>
                                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={licenca.transaction?.Amount ?? 0} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                </td> :
                                                                <td align='center'>
                                                                    -
                                                                </td>
                                                        }
                                                        <td>
                                                            {this.renderStatus(licenca.status)}
                                                        </td>
                                                        {
                                                            this.props.read ?
                                                                <td width={10} align='right'>
                                                                    {
                                                                        licenca.motivo !== null &&
                                                                        <button type="button" onClick={() => { this.showDetail(licenca) }} className="btn btn-primary">
                                                                            <i className="fas fa-eye" />
                                                                        </button>
                                                                    }
                                                                </td> :
                                                                <td width={10} align='right'>
                                                                    <div className="btn-group">
                                                                        {
                                                                            (licenca?.status?.toUpperCase() === "ATRASADA" ||
                                                                                licenca?.status?.toUpperCase() === "PENDENTE" ||
                                                                                licenca?.status?.toUpperCase() === "INADIMPLENTE") &&
                                                                            <button type="button" onClick={() => { this.showModalChangeStatus(licenca) }} className="btn btn-secondary">
                                                                                <i className="fas fa-edit" />
                                                                            </button>
                                                                        }
                                                                        {
                                                                            licenca.type !== "PAGAMENTO_AVULS" && licenca?.transaction?.PaymentObject !== undefined &&
                                                                            <button type="button" onClick={() => { this.sendBillByWhatsApp(licenca) }} className="btn btn-success">
                                                                                <i className="fa-brands fa-whatsapp" />
                                                                            </button>
                                                                        }
                                                                        {
                                                                            licenca.motivo !== null &&
                                                                            <button type="button" onClick={() => { this.showDetail(licenca) }} className="btn btn-primary">
                                                                                <i className="fas fa-eye" />
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </td>
                                                        }
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-4'>
                                        <div className="card text-light bg-success mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title text-center text-white">Total pago</h5>
                                                <h3 className="card-text text-center text-white">
                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.historico.filter(e => e.transaction?.Amount).map(e => e.transaction?.Amount).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                </h3>
                                                <h5 className='monthly-card monthly-card-green'>
                                                    <span>{this.state.historico.filter(e => e.transaction?.Amount).length} Pagamentos</span>
                                                </h5>
                                                <div className="text-center">
                                                    <small className="card-text text-white">Licença</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        !this.props.read &&
                                        <div className='col-lg-4'>
                                            <div className="card text-light bg-warning mb-3">
                                                <div className="card-body">
                                                    <h5 className="card-title text-center text-white">Dias adicionados</h5>
                                                    <h3 className="card-text text-center text-white">
                                                        {this.state.historico.filter(e => !e.transaction?.Amount).map(e => parseInt(e.days ?? 0)).reduce((a, b) => a + b, 0)}
                                                    </h3>
                                                    <h5 className='monthly-card monthly-card-yellow'>
                                                        <span>{this.state.historico.filter(e => !e.transaction?.Amount).length} Vezes</span>
                                                    </h5>
                                                    <div className="text-center">
                                                        <small className="card-text text-white">Sem pagamentos</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className='col-lg-4'>
                                        <div className="card text-light bg-info mb-3">
                                            <div className="card-body">
                                                <h5 className="card-title text-center text-white">Meses</h5>
                                                <h3 className="card-text text-center text-white">
                                                    {parseInt(this.state.historico.map(e => parseInt(e.days ?? 0)).reduce((a, b) => a + b, 0) / 30)}
                                                </h3>
                                                <div className="text-center">
                                                    <small className="card-text text-white">Tempo adicionado</small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <Loading />
                }
                <Dialog onClose={this.hideDetail} maxWidth={'sm'} open={this.state.isShowDetailModal}>
                    <MuiDialogContent>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h5 className='text-left'>Dados do plano</h5>
                            </div>
                            {
                                this.state.detail?.transaction?.idTransaction !== undefined &&
                                <div className='col-lg-12'>
                                    <label>Código da Transação</label>
                                    <h5>
                                        {this.state.detail?.transaction?.idTransaction}
                                    </h5>
                                </div>
                            }
                            <div className='col-lg-12'>
                                <label>Representante</label>
                                <h5>{this.state.detail?.representante}</h5>
                            </div>
                            <div className='col-lg-12'>
                                <label>Plano</label>
                                <h5>{this.state.detail?.plano?.nome ?? "Não informado"}</h5>
                            </div>
                            <div className='col-lg-12'>
                                <label>Data</label>
                                <h5>{Moment(this.state.detail?.buyAt.toDate()).format("DD/MM/YYYY [às] HH:mm")}</h5>
                            </div>
                            {
                                this.state.detail?.transaction?.Amount &&
                                <div className='col-lg-12'>
                                    <label>Valor</label>
                                    <h5><NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.detail?.transaction?.Amount ?? 0} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></h5>
                                </div>
                            }
                            <div className='col-lg-12'>
                                <label>Dias</label>
                                <h5>{this.state.detail?.days}</h5>
                            </div>

                            {
                                !this.props.read && <>
                                    {
                                        this.state.detail?.motivo !== undefined && this.state.detail?.motivo !== '' &&
                                        <div className='col-lg-12'>
                                            <label>Motivo</label>
                                            <h5>{this.state.detail?.motivo}</h5>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            {
                                !this.props.read && <>
                                    {
                                        (Number(this.state.detail?.transaction?.PaymentMethod) === 1 && this.state.detail?.transaction?.PaymentObject?.BankSlipUrl) &&
                                        <button onClick={(e) => { this.sendBillByWhatsApp(this.state.detail) }} className='btn btn-success btn-block'>Enviar boleto por WhatsApp</button>
                                    }
                                </>
                            }
                            {
                                (Number(this.state.detail?.transaction?.PaymentMethod) === 1 && this.state.detail?.transaction?.PaymentObject?.BankSlipUrl) &&
                                <a href={this.state.detail?.transaction?.PaymentObject?.BankSlipUrl} target="_blank" className="btn btn-info btn-block">Visualizar boleto</a>
                            }
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog onClose={this.hideModalChangeStatus} maxWidth={'sm'} open={this.state.isShowChangeStatus}>
                    <MuiDialogTitle className='text-center'>
                        Alterar status de pagamento
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <label>Novo status</label>
                                <select className="form-select" value={this.state.newStatus} onChange={(e) => this.setState({ newStatus: e.target.value })}>
                                    <option value='ATIVA'>Pago</option>
                                    <option value='PENDENTE'>Pendente</option>
                                    <option value='INADIMPLENTE'>Inadimplente</option>
                                    <option value='DESATIVADA'>Desativada</option>
                                    <option value='ATRASADA'>Atrasada</option>
                                    <option value='ENCERRADA'>Encerrada</option>
                                </select>
                            </div>
                            <div className='col-lg-12'>
                                <label>Alterar status principal</label>
                                <select className="form-select" value={this.state.newStatusUpdateMain} onChange={(e) => this.setState({ newStatusUpdateMain: e.target.value })}>
                                    <option value="NAO">Não</option>
                                    <option value="SIM">Sim</option>
                                </select>
                            </div>
                            <div className='col-lg-12'>
                                <label>Motivo</label>
                                <textarea rows='4' onChange={(e) => this.setState({ motivo: e.target.value })} value={this.state.motivo} className="form-control" />
                            </div>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group my-2 mx-3'>
                            <button onClick={this.hideModalChangeStatus} className='btn btn-secondary text-white'>Cancelar</button>
                            <button onClick={() => this.confirmChangeStatus()} className='btn btn-danger'>Confirmar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}


export { CriarLicenca, ListarLicencas, ListarLicencaHistorico };